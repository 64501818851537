import { FC, HTMLAttributes } from "react";
import { twMerge } from "tailwind-merge";

export const LoadingImage: FC<HTMLAttributes<HTMLDivElement>> = ({
  ...props
}) => {
  return (
    <div
      {...props}
      className={twMerge(
        "relative h-full w-full overflow-hidden rounded-2xl bg-gray-300",
        props.className,
      )}
    >
      <div className="absolute inset-0 h-[200%] w-[200%] animate-loader bg-opacity-50 bg-gradient-to-br from-gray-400 via-gray-300 to-gray-400"></div>
    </div>
  );
};

export default LoadingImage;
