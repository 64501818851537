import ButtonPrimary from "../../../../components/common/buttons/Button";

const SubmitFundraising = () => {
  return (
    <section id="fundraising-submit" className="container">
      <div className="flex justify-end">
        <ButtonPrimary title="Submit" type="submit">
          Submit & Get an Offer
        </ButtonPrimary>
      </div>
      <p className="my-10 italic text-primary-green">
        We Respect Your Privacy! All information provided in this form will be
        used to asses your property and create an offer for you. It be kept
        private and secure. We will not sell or share your information with any
        third party organizations.
      </p>
    </section>
  );
};

export default SubmitFundraising;
