import ErrorDisplay from "../../components/section/ErrorDisplay";

export const Unauthorized = () => {
  return (
    <ErrorDisplay
      status={401}
      message="You are not authorized to access this page."
      path="/login"
      page="login"
    />
  );
};
export default Unauthorized;
