import SvgAradinaIcon from "../../../../components/svgs/logos/AradinaIcon";
import SvgLocation from "../../../../components/svgs/icons/Location";
import BannerGradientHeading from "../../../../components/common/BannerGradientHeading";
import { useAtomValue } from "jotai";
import { offeringDetailsAtom } from "../../atoms";

export const Banner = () => {
  const offering = useAtomValue(offeringDetailsAtom);
  if (!offering) return null;
  return (
    <section
      id="offering-banner"
      style={{ backgroundImage: `url(${offering.thumbnail})` }}
      className="rounded-xl bg-cover bg-center"
    >
      <div className="rounded-t-xl bg-dark-green bg-opacity-40 text-primary-green">
        <div className="container flex flex-col gap-4 bg-[url(/public/assets/images/backgrounds/logo-aradina-icon-semi-transparent-white.png)] bg-center bg-no-repeat">
          <article className="relative grid place-items-center gap-2 self-center rounded-b-3xl bg-white bg-opacity-90 px-10 py-4 text-center lg:self-end lg:px-28">
            <SvgAradinaIcon className="absolute right-0 top-0 box-content w-12 fill-primary-green p-5" />
            <div className="flex h-32 w-32 flex-col justify-between rounded-full border-[1rem] border-solid border-primary-green p-5 text-center lg:h-40 lg:w-40">
              <div>
                <h3 className="font-extrabold lg:text-3xl">68.2%</h3>
                <p className="font-bold leading-none max-lg:text-sm">Funded</p>
              </div>
              <span className="text-base max-lg:text-xs">$348K</span>
            </div>
            <p className="font-thin">
              The opportunity is{" "}
              <span className="font-extrabold">{offering.status}</span>
            </p>
          </article>
          <BannerGradientHeading title={offering.title} />
          <address className="flex w-max gap-4 rounded-t-2xl bg-gradient-to-r from-white to-[#fff8] px-10 py-5 max-lg:max-w-xs lg:px-20">
            <SvgLocation className="w-4" />
            <span className="-mb-2 font-extrabold">{offering.location}</span>
          </address>
        </div>
      </div>
      <div className="rounded-b-xl bg-gradient-to-r from-[#364F1D] to-[#AFF36A] py-4 text-white">
        <div className="container grid lg:grid-cols-[9fr_1fr]">
          <div className="items-center gap-8 lg:flex">
            <h4 className="text-2xl font-bold lg:whitespace-nowrap lg:text-3xl">
              Min. Investment:
              <span className="text-3xl font-bold lg:text-4xl">
                ${offering.minimum_investment}
              </span>
            </h4>
            <div className="h-full w-0.5 bg-white max-lg:hidden"></div>
            <p className="text-base">
              <span className="mr-1 font-bold">Description: </span>
              {offering.long_description}
            </p>
          </div>
          <img
            className="-mt-[50%] max-lg:hidden"
            src="/assets/images/common/safe-investment.png"
            alt="Safe Investment"
          />
        </div>
      </div>
    </section>
  );
};
export default Banner;
