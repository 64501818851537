const Description = () => {
  return (
    <section id="account-description" className="container section-margin">
      <p>
        Aradina has implemented this verification step to stay legally compliant
        with KYC/AML (Know Your Customer/Anti-Money Laundering) regulations.
        This is an additional measure to ensure against accepting fraudulent
        contributions. All investors must complete the KYC/AML form before
        making any investments through Aradina.
      </p>
    </section>
  );
};

export default Description;
