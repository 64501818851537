import CustomDropdown from "../../../../components/common/inputs/Dropdown";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import CustomInput from "../../../../components/common/inputs/Input";
import { useState } from "react";

const ProjectInformation = () => {
  const [ownProperty, setOwnProperty] = useState(false);
  return (
    <>
      <section
        id="fundraising-project-information"
        className="section-margin container"
      >
        <div className="my-10">
          <h3 className="mb-6 text-3xl font-bold text-dark-green">
            Project Information
          </h3>
          <div className="grid gap-6">
            <CustomDropdown
              label="Project Type*"
              name="project_type"
              options={["option 1", "option 2"]}
            />
            <CustomInput
              type="text"
              name="type_of_crop_or_livestock"
              label="What type of crop/livestock will you be producing?*"
            />
            <div className="flex gap-10">
              <p className="text-xl text-dark-green">Do you own a property?*</p>
              <div className="[&_span]:text-primary-green">
                <RadioGroup
                  onChange={(e) => {
                    setOwnProperty(
                      (prev) => (prev = e.target.value === "true"),
                    );
                  }}
                  name="property_own_question"
                >
                  <FormControlLabel
                    value="true"
                    control={<Radio color="success" />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value="false"
                    control={<Radio color="success" />}
                    label="No"
                  />
                </RadioGroup>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        id="fundraising-own-property"
        className={`container origin-left bg-light-green bg-opacity-25 duration-100 ${
          ownProperty ? "scale-x-100" : "absolute w-full scale-x-0"
        }`}
      >
        <div className="grid gap-8 py-14">
          <div className="flex gap-10">
            <p className="text-xl text-dark-green">Who owns the property?</p>
            <div className="[&_span]:text-primary-green">
              <RadioGroup name="property_owner">
                <FormControlLabel
                  value="I own the property"
                  control={<Radio color="success" />}
                  label="I own the property"
                />
                <FormControlLabel
                  value="I currently lease the property the owner is selling"
                  control={<Radio color="success" />}
                  label="I currently lease the property the owner is selling"
                />
                <FormControlLabel
                  value="I've indentified property i'd like to farm but not buy"
                  control={<Radio color="success" />}
                  label="I've indentified property i'd like to farm but not buy"
                />
                <FormControlLabel
                  value="I have indentified property i would like to part own"
                  control={<Radio color="success" />}
                  label="I have indentified property i would like to part own"
                />
              </RadioGroup>
            </div>
          </div>
          <CustomInput
            type="text"
            name="parcel_size"
            label="What is the legal parcel size?"
          />
          <CustomInput
            type="text"
            name="farmable"
            label="What are the farmable acres?"
          />
          <CustomInput
            type="text"
            name="non_farmable"
            label="What are the non farmable acres?"
          />
          <div className="flex gap-10">
            <p className="text-xl text-dark-green">
              Is there currently a crop on the property ?
            </p>
            <div className="[&_span]:text-primary-green">
              <RadioGroup name="crop">
                <FormControlLabel
                  value="false"
                  control={<Radio color="success" />}
                  label="No - Vacant Land"
                />
                <FormControlLabel
                  value="true"
                  control={<Radio color="success" />}
                  label="Yes"
                />
              </RadioGroup>
            </div>
          </div>
          <CustomInput
            type="text"
            name="water_sources"
            label="What are the available water sources?"
          />
          <h4 className="text-xl text-dark-green">Address of property</h4>
          <CustomInput
            type="text"
            name="property_street_address"
            label="Street Address"
          />
          <CustomInput
            type="text"
            name="property_street_address_line_two"
            label="Street Address Line 2"
          />
          <div className="grid grid-cols-3 gap-4">
            <CustomInput type="text" name="property_city" label="City" />
            <CustomInput
              type="text"
              name="property_state"
              label="State/Province"
            />
            <CustomInput
              type="number"
              name="property_postal"
              label="Postal/Zip Code"
            />
          </div>
        </div>
        <div className="my-10 grid gap-8">
          <h4 className="text-xl text-dark-green">Address of property</h4>
          <CustomInput
            type="text"
            name="street_address"
            label="Street Address*"
          />
          <CustomInput
            type="text"
            name="street_address_line_two"
            label="Street Address Line 2"
          />
          <div className="grid gap-4 sm:grid-cols-3">
            <CustomInput type="text" name="city" label="City*" />
            <CustomInput type="text" name="state" label="State/Province*" />
            <CustomInput type="number" name="postal" label="Postal/Zip Code*" />
          </div>
        </div>
      </section>
    </>
  );
};

export default ProjectInformation;
