import { twMerge } from "tailwind-merge";
import SvgLocation from "../svgs/icons/Location";
import { DetailedHTMLProps, HTMLAttributes, SVGProps } from "react";

type TProps = {
  city?: string;
  country?: string;
  location?: string;
  className?: DetailedHTMLProps<
    HTMLAttributes<HTMLElement>,
    HTMLElement
  >["className"];
  svgClassName?: SVGProps<SVGSVGElement>["className"];
};

const AddressLocation = ({
  city,
  country,
  location,
  className,
  svgClassName,
}: TProps) => {
  return (
    <address
      className={twMerge(
        "flex items-center gap-2 text-primary-green",
        className,
      )}
    >
      <SvgLocation
        className={twMerge("size-4 fill-primary-green", svgClassName)}
      />
      {location ?? (
        <>
          {city},&nbsp;
          {country}
        </>
      )}
    </address>
  );
};

export default AddressLocation;
