import SvgQuotes from "../../../../components/svgs/icons/Quotes";
import useTeam from "../hooks";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

const QuotesSlider = () => {
  const { quotes, isLoading } = useTeam();
  return (
    <section
      id="team-quotes"
      className="relative bg-primary-green bg-opacity-50 section-margin"
    >
      <img
        className="absolute bottom-0 left-0 z-0 w-1/2"
        src="/assets/images/backgrounds/background-giant-blob.png"
        alt=""
      />
      <img
        className="absolute bottom-0 right-0 h-full object-contain"
        src="/assets/images/backgrounds/logo-aradina-icon-semi-transparent-white.png"
        alt="Aradina Logo"
      />
      <img
        className="absolute right-0 top-0 h-1/2 max-h-40 -translate-y-1/2 object-contain"
        src="/assets/images/pages/about/team/quotes-image.png"
        alt=""
      />
      <div className="container absolute inset-0">
        <SvgQuotes className="-mt-4 w-14 rotate-180" />
      </div>
      <div className="container absolute inset-0 grid place-items-end">
        <SvgQuotes className="-mb-4 w-14" />
      </div>
      <div className="container section-padding">
        {isLoading ? (
          <p className="text-center text-white">Loading quotes...</p>
        ) : (
          <Swiper slidesPerView={1}>
            {quotes?.map(({ id, quote, team_member_name, postfix }) => (
              <SwiperSlide
                key={id}
                className="flex cursor-pointer flex-col items-center justify-center px-[10%] text-center text-white"
              >
                <q className="mb-3 block text-4xl italic">{quote}</q>
                <p className="font-extrabold">
                  {team_member_name} - {postfix}
                </p>
              </SwiperSlide>
            ))}
          </Swiper>
        )}
      </div>
    </section>
  );
};

export default QuotesSlider;
