import SvgArrowStraightLong from "../../../../components/svgs/arrows/ArrowStraightTall";
import SvgAradinaIcon from "../../../../components/svgs/logos/AradinaIcon";
import InnerCard from "./InnerCard";

type TProps = {
  step_number: number;
  title: string;
  content: { sub_title?: string; description: string }[];
};

const Card = ({ data, arrow = false }: { data: TProps; arrow?: boolean }) => {
  const { step_number, title, content } = data;
  return (
    <div className="mb-10">
      <article className="relative z-10 rounded-xl bg-secondary-green text-white">
        <div className="absolute left-[6%] top-0 -translate-y-full rounded-t-xl bg-white px-6 py-2.5">
          <span className="text-2xl text-primary-green">
            Step {step_number}
          </span>
        </div>
        <div className="grid grid-cols-[6%_auto] items-center rounded-t-xl bg-white bg-opacity-30 text-3xl">
          <div className="grid h-16 w-16 -translate-x-1/3 scale-105 place-items-center rounded-full border-4 border-primary-green bg-white text-primary-green max-md:hidden">
            <span>{step_number}</span>
          </div>
          <h3 className="max-md:px-10 max-md:py-2">{title}</h3>
        </div>
        <div className="px-[6%] pb-4 pt-10">
          {content.map((item, index) => {
            return (
              <InnerCard
                key={`inner_content_${step_number}_${index}`}
                data={{
                  title: item.sub_title,
                  text: item.description,
                }}
              />
            );
          })}
          <div className="flex justify-center">
            <SvgAradinaIcon className="w-14 text-center" />
          </div>
        </div>
      </article>
      {arrow && (
        <SvgArrowStraightLong className="z-0 ml-4 h-28 -translate-y-2" />
      )}
    </div>
  );
};

export default Card;
