import { TSvgComponent } from "../../../types/utils/components";

const SvgDollarCard: TSvgComponent = (props) => {
  return (
    <svg
      viewBox="0 0 61 61"
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M37.6359 55.198H22.5817C10.0366 55.198 5.01855 50.18 5.01855 37.6349V22.5807C5.01855 10.0356 10.0366 5.01758 22.5817 5.01758H37.6359C50.181 5.01758 55.199 10.0356 55.199 22.5807V37.6349C55.199 50.18 50.181 55.198 37.6359 55.198Z" />
      <path
        d="M21.7578 35.9541C21.7578 39.1907 24.2417 41.8001 27.3278 41.8001H33.6255C36.3101 41.8001 38.493 39.5169 38.493 36.7068C38.493 33.6458 37.1632 32.5669 35.1811 31.8644L25.0697 28.3517C23.0876 27.6492 21.7578 26.5703 21.7578 23.5093C21.7578 20.6992 23.9407 18.416 26.6253 18.416H32.923C36.0091 18.416 38.493 21.0254 38.493 24.262"
        stroke="url(#paint0_linear_5538_2387)"
        strokeWidth="3.76353"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.1084 15.0542V45.1625"
        stroke="url(#paint1_linear_5538_2387)"
        strokeWidth="3.76353"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_5538_2387"
          x1="21.7578"
          y1="41.8001"
          x2="38.493"
          y2="41.8001"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#40730E" />
          <stop offset="1" stopColor="#002B14" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_5538_2387"
          x1="30.1084"
          y1="45.1625"
          x2="31.1084"
          y2="45.1625"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#40730E" />
          <stop offset="1" stopColor="#002B14" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default SvgDollarCard;
