import LinkPrimary from "../../../../components/common/buttons/Link";
import CardBelonging from "../../../../components/common/cards/OfferingBelonging";
import LaravelResponse from "../../../../components/common/LaravelResponse";
import { TOfferingBelongingUser } from "../../../../types/offering";
import { useCreateListingPreview } from "./_hooks";

const PageCreatingListingPreview = () => {
  const { query } = useCreateListingPreview<TOfferingBelongingUser>();
  return (
    <section id="create-listing-preview" className="container section-margin">
      <LaravelResponse<TOfferingBelongingUser>
        query={query}
        success={(res) => (
          <div className="my-10 rounded-lg bg-gray-200 py-10">
            <div className="mx-auto h-[35rem] max-w-sm">
              <CardBelonging disabled offeringBelonging={res} />
            </div>
          </div>
        )}
      />
      <div className="flex justify-end">
        <LinkPrimary to="/user/offerings/my-belongings" title="My Belongings">
          Done
        </LinkPrimary>
      </div>
    </section>
  );
};

export default PageCreatingListingPreview;
