import { TSvgComponent } from "../../../types/utils/components";

const SvgClose: TSvgComponent = ({ ...props }) => {
  return (
    <svg
      viewBox="0 0 18 19"
      fill="#E01C1C"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M15.5122 18.2626C15.0493 18.2626 14.5845 18.0852 14.2316 17.7322L0.530847 4.03146C-0.176949 3.32366 -0.176949 2.17614 0.530847 1.46835C1.23864 0.760551 2.38616 0.760551 3.09396 1.46835L16.7947 15.1691C17.5025 15.8769 17.5025 17.0244 16.7947 17.7322C16.4418 18.0852 15.977 18.2626 15.5141 18.2626H15.5122Z" />
      <path d="M1.81144 18.2626C1.34857 18.2626 0.883781 18.0852 0.530847 17.7322C-0.176949 17.0244 -0.176949 15.8769 0.530847 15.1691L14.2316 1.46835C14.9394 0.760551 16.0869 0.760551 16.7947 1.46835C17.5025 2.17614 17.5025 3.32366 16.7947 4.03146L3.09203 17.7322C2.73909 18.0852 2.2743 18.2626 1.81144 18.2626Z" />
    </svg>
  );
};

export default SvgClose;
