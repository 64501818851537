import CustomDropdown from "../../common/inputs/Dropdown";
import CustomSearch from "../../common/inputs/Search";
import SliderComponent from "../../common/InputFields/SliderComponent";
import SvgFilters from "../../svgs/icons/Filters";
import { useOfferingsFilters } from "./hook";
import { TLabelItem } from "../../../types/utils/components";

const OfferingsFilters = () => {
  const {
    allFiters,
    isLoading,
    offeringsFilters,
    setOfferingsFilters,
    sliderValue,
    setSliderValue,
  } = useOfferingsFilters();
  return (
    <>
      {/* <section id="investors-banner" className="container py-4">
        <div className="flex justify-center gap-2 text-section-heading font-bold">
          <h2 className="py-1.5 text-primary-green">Our</h2>
          <ThreeNeedlesHeading
            backgroundColor="primary-green"
            needlesColor="black"
            className="text-white"
          >
            Offerings
          </ThreeNeedlesHeading>
        </div>
      </section> */}
      <section id="investors-filters">
        <div className="container my-10">
          <div className="flex">
            <SvgFilters className="w-9" />
            <h2 className="ml-2 text-section-heading font-bold text-dark-green">
              Filters
            </h2>
          </div>
          <div className="mb-12 mt-4 grid sm:grid-cols-3 items-end gap-4 lg:grid-cols-5">
            <CustomDropdown
              options={allFiters?.type ? allFiters.type : ["Loading..."]}
              value={offeringsFilters.type[0]}
              onChange={(_, value) =>
                setOfferingsFilters((prev) => ({
                  ...prev,
                  type: [(value as TLabelItem & { id: number })?.id],
                  page: 1,
                }))
              }
              name="type"
              label="Type"
              className="max-sm:col-span-3"
            />
            <CustomDropdown
              loading={isLoading}
              options={
                allFiters?.location ? allFiters.location : ["Loading..."]
              }
              value={offeringsFilters.location}
              onChange={(_, value) =>
                setOfferingsFilters((prev) => ({
                  ...prev,
                  location: (value as TLabelItem)?.label,
                  page: 1,
                }))
              }
              name="location"
              label="Location"
              className="max-sm:col-span-3"
            />
            <CustomDropdown
              loading={isLoading}
              options={allFiters?.status ? allFiters.status : ["Loading..."]}
              value={offeringsFilters.status}
              onChange={(_, value) =>
                setOfferingsFilters((prev) => ({
                  ...prev,
                  status: (value as TLabelItem)?.label,
                  page: 1,
                }))
              }
              name="status"
              label="Status"
              className="max-sm:col-span-3"
            />
            <div className="sm:col-span-3 my-2 flex items-center justify-center lg:col-span-2">
              {!isLoading && allFiters ? (
                <SliderComponent
                  changeInstantly={(price) => setSliderValue(price as number[])}
                  onChangeMouseUp={(price) => {
                    setOfferingsFilters((prev) => ({
                      ...prev,
                      priceone: (price as number[])[0],
                      pricetwo: (price as number[])[1],
                      page: 1,
                    }));
                  }}
                  maxNumber={parseInt(allFiters?.max_price)}
                  value={sliderValue}
                />
              ) : (
                <p className="text-center">Loading the price ranges...</p>
              )}
            </div>
            <div className="sm:col-span-3">
              <CustomSearch
                label="Search"
                name="search"
                value={offeringsFilters.search}
                onChange={(e) =>
                  setOfferingsFilters((prev) => ({
                    ...prev,
                    search: e.target.value,
                    page: 1,
                  }))
                }
              />
            </div>
            <div className="sm:col-span-3 flex items-center justify-between self-center text-primary-green max-lg:row-start-3 max-sm:row-start-5 lg:col-span-2">
              <div className="flex h-full items-center rounded-lg border border-primary-green border-opacity-50 px-2">
                <p>$ {offeringsFilters.priceone}</p>
              </div>
              <div className="mx-4 h-px flex-grow rounded-lg bg-primary-green bg-opacity-50"></div>
              <p>to</p>
              <div className="mx-4 h-px flex-grow rounded-lg bg-primary-green bg-opacity-50"></div>
              <div className="flex h-full items-center rounded-lg border border-primary-green border-opacity-50 px-2">
                <p>$ {offeringsFilters.pricetwo}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default OfferingsFilters;
