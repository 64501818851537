import { passiveInvestment } from "./passiveInvestmentData";
import ThreeNeedlesHeading from "../../../components/common/ThreeNeedlesHeading";
import SvgArrowCurved from "../../../components/svgs/arrows/ArrowCurved";
export const PassiveInvestment = () => {
  return (
    <section id="investors-passive-investments">
      <div className="section-margin container">
        <div className="mb-14 flex flex-wrap gap-2 text-section-heading font-bold text-primary-green lg:justify-end">
          <SvgArrowCurved className="w-20 -scale-x-100 max-xl:hidden" />
          <h2 className="py-1.5">Passive Investment in</h2>
          <ThreeNeedlesHeading
            backgroundColor="primary-green"
            needlesColor="black"
            className="text-white"
          >
            Farming
          </ThreeNeedlesHeading>
          <h2 className="py-1.5">and</h2>
          <ThreeNeedlesHeading
            backgroundColor="primary-green"
            needlesColor="black"
            className="text-white"
          >
            Timberland
          </ThreeNeedlesHeading>
        </div>
        <div className="grid xl:grid-cols-2">
          <div>
            <p className="font-semibold leading-tight">
              {passiveInvestment.firstText}
            </p>
            <div className="my-10 grid auto-rows-[100px] grid-cols-3 gap-x-4 gap-y-6">
              {passiveInvestment.data.map(({ label, icon }, index) => (
                <article
                  key={`passive_investment_${index}`}
                  className="relative grid place-items-center rounded-xl p-4 shadow"
                >
                  <div className="absolute right-0 top-0 box-border grid h-10 w-10 -translate-y-1/4 place-items-center rounded-full bg-primary-green">
                    {icon({ className: "w-7 h-7" })}
                  </div>
                  <span className="text-center text-xl font-bold text-primary-green">
                    {label}
                  </span>
                </article>
              ))}
            </div>
            <p className="font-semibold leading-tight">
              {passiveInvestment.secondText}
            </p>
          </div>
          <img
            className="max-xl:hidden"
            src="/assets/images/pages/for-investors/passive-investments.png"
            alt="Passive Investment"
          />
        </div>
      </div>
    </section>
  );
};
export default PassiveInvestment;
