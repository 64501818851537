import { Link } from "react-router-dom";
import { useCreateListingType } from "./_hooks";
import Main from "../../../../components/layout/Main";

const PageCreateListingType = () => {
  const { data, isLoading } = useCreateListingType();
  return (
    <Main id="create-listing-type">
      <section id="create-listing-type" className="container section-margin">
        {!isLoading ? (
          data?.success ? (
            data.data.length > 0 ? (
              <ul className="grid grid-cols-4 gap-10">
                {data.data.map((type) => {
                  return (
                    <li key={`listing_type_${type.id}`}>
                      <Link
                        to={{
                          pathname: "/user/offerings/create-listing/details",
                          search: `?type_id=${type.id}&type_name=${type.type_name.replaceAll(" ", "%20")}`,
                        }}
                        title={type.type_name}
                        className="relative size-full after:absolute after:inset-0 after:-z-10 after:size-full after:rounded-lg after:bg-secondary-green"
                      >
                        <div className="flex flex-col items-center gap-2 rounded-lg border border-tertiary-green bg-white p-4 text-center hover-link">
                          <div className="grid size-16 place-items-center rounded-full bg-tertiary-green p-3">
                            <img
                              src={type.logo_path}
                              alt={type.type_name}
                              title={type.type_name}
                              className="size-8 object-contain"
                              height={32}
                              width={32}
                            />
                          </div>
                          <p>{type.type_name}</p>
                        </div>
                      </Link>
                    </li>
                  );
                })}
              </ul>
            ) : (
              <p>No listing types found</p>
            )
          ) : (
            <p className="text-red-500">Something went wrong</p>
          )
        ) : (
          <p>Loading...</p>
        )}
      </section>
    </Main>
  );
};

export default PageCreateListingType;
