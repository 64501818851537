import {
  DetailedHTMLProps,
  ForwardedRef,
  forwardRef,
  InputHTMLAttributes,
  LabelHTMLAttributes,
  ReactNode,
} from "react";
import { twMerge } from "tailwind-merge";

type TProps = DetailedHTMLProps<
  InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  label?: ReactNode;
  labelClass?: LabelHTMLAttributes<HTMLLabelElement>["className"];
  ref?: ForwardedRef<HTMLInputElement>;
};

const CustomCheckbox = forwardRef<HTMLInputElement, TProps>(
  ({ label, labelClass, ...props }, ref) => {
    return (
      <label
        htmlFor={props.name}
        className={twMerge(
          "focus-within:animate-pulse focus-within:outline hover:animate-pulse size-fit",
          labelClass,
        )}
      >
        <input
          title={props.name}
          id={props.name}
          {...props}
          type="checkbox"
          ref={ref}
          className={twMerge(
            "grid size-5 appearance-none place-items-center rounded-sm border border-primary-gray [-webkit-appearance:none] before:size-5 before:scale-0 before:rounded-sm before:bg-primary-green before:duration-100 before:content-[url('/public/assets/svgs/checked-box.svg')] checked:border-none checked:before:scale-90 hover:animate-pulse hover:cursor-pointer",
            props.className,
          )}
        />
        {label}
      </label>
    );
  },
);

export default CustomCheckbox;
