import LinkPrimary from "../../../../../components/common/buttons/Link";
import { TMyBelonging } from "../../../../../types/offering";

type TProps = {
  data: TMyBelonging;
};

const Information = ({ data }: TProps) => {
  const unit =
    data.purchased_offering.offering.unit &&
    data.purchased_offering.offering.unit.unit !== "unit" &&
    ` ${data.purchased_offering.offering.unit.unit}`;
  return (
    <>
      <section id="my-belonging-description" className="container my-10">
        <h3 className="text-tertiary-green section-heading">Description</h3>
        <p>{data.purchased_offering.offering.long_description}</p>
      </section>
      <section
        id="my-belonging-quantity"
        className="container my-10 flex flex-wrap items-center justify-between gap-4"
      >
        <h3 className="text-tertiary-green section-heading">
          Unit
          {unit && (
            <>
              <br />({unit})
            </>
          )}
        </h3>
        <div className="grid place-items-center rounded-md border border-tertiary-green px-2.5 py-1">
          <p>{+data.quantity + data.qty_purchased}</p>
        </div>
      </section>
      <section id="my-belonging-sell" className="container my-10">
        <div className="container flex flex-wrap items-center justify-between gap-4 rounded-lg border border-primary-green py-6">
          <div>
            <h3 className="text-tertiary-green section-heading">Sell now</h3>
            <p>You have the option of selling to Aradina</p>
          </div>
          <LinkPrimary
            to="/user/offerings/sell-to-aradina"
            title="Sell to Aradina"
            className="h-fit"
          >
            Sell to Aradina
          </LinkPrimary>
        </div>
      </section>
      <section id="my-belonging-overview" className="container my-10">
        <h3 className="mb-2 text-tertiary-green section-heading">Overview</h3>
        <ul className="container space-y-1 rounded-lg bg-gray-100 py-3 capitalize">
          <li className="flex flex-wrap justify-between gap-2">
            <p>Quantity purchased:</p>
            <p>
              {data.qty_purchased}
              {unit}
            </p>
          </li>
          <li className="flex flex-wrap justify-between gap-2">
            <p>Quantity remaining:</p>
            <p>
              {(+data.quantity).toFixed(0)}
              {unit}
            </p>
          </li>
          <li className="flex flex-wrap justify-between gap-2">
            <p>Clients:</p>
            <p>{data.clients}</p>
          </li>
        </ul>
      </section>
      {/* <section id="my-belonging-overview" className="container my-10">
        <h3 className="mb-2 text-tertiary-green section-heading">Details</h3>
        <ul className="container space-y-1 rounded-lg bg-gray-100 py-3 capitalize">
          <li className="flex flex-wrap justify-between gap-2">
            <p>Quantity produced:</p>
            <p>
              {(+data.quantity).toFixed(0)}
              {unit}
            </p>
          </li>
          <li className="flex flex-wrap justify-between gap-2">
            <p>Harvest date:</p>
            <p>{new Date(data.harvest_date).toLocaleDateString()}</p>
          </li>
          <li className="flex flex-wrap justify-between gap-2">
            <p>Estimate Time for next batch:</p>
            <p>TODO</p>
          </li>
          <li className="flex flex-wrap justify-between gap-2">
            <p>Target IRR:</p>
            <p>TODO</p>
          </li>
          <li className="flex flex-wrap justify-between gap-2">
            <p>My investment size:</p>
            <p>TODO</p>
          </li>
        </ul>
      </section> */}
    </>
  );
};

export default Information;
