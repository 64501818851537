import { TSvgComponent } from "../../../types/utils/components";

const SvgCartAdd: TSvgComponent = (props) => {
  return (
    <svg
      viewBox="0 0 26 24"
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.61328 21.5831C6.61328 20.5245 7.45941 19.6664 8.50316 19.6664C9.54692 19.6664 10.393 20.5245 10.393 21.5831C10.393 22.6416 9.54692 23.4998 8.50316 23.4998C7.45941 23.4998 6.61328 22.6416 6.61328 21.5831Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.8457 21.5831C19.8457 20.5245 20.6918 19.6664 21.7356 19.6664C22.7793 19.6664 23.6255 20.5245 23.6255 21.5831C23.6255 22.6416 22.7793 23.4998 21.7356 23.4998C20.6918 23.4998 19.8457 22.6416 19.8457 21.5831Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 1.45834C0 0.929066 0.423065 0.5 0.944941 0.5H4.72471C5.18323 0.5 5.57558 0.833846 5.65527 1.2918L8.35232 16.7919H22.6786C23.2005 16.7919 23.6235 17.2209 23.6235 17.7502C23.6235 18.2795 23.2005 18.7086 22.6786 18.7086H7.55953C7.101 18.7086 6.70865 18.3747 6.62897 17.9168L3.93192 2.41669H0.944941C0.423065 2.41669 0 1.98762 0 1.45834Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.6397 3.96655C25.1514 4.07035 25.4833 4.57523 25.3809 5.09423L23.6801 13.7193C23.6157 14.0453 23.4419 14.3388 23.1883 14.5494C22.9348 14.7601 22.617 14.8751 22.2892 14.8749L22.2899 13.9165V14.8749H22.2892H7.55822C7.03635 14.8749 6.61328 14.4458 6.61328 13.9165C6.61328 13.3872 7.03635 12.9582 7.55822 12.9582H21.9029L21.8269 13.3433C21.8483 13.2346 21.8532 13.2099 21.8601 13.1751C21.8737 13.1058 21.8707 13.1213 21.8846 13.0506L21.9029 12.9582L23.5278 4.71834C23.6301 4.19934 24.1279 3.86275 24.6397 3.96655Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.3724 1.93652C15.7999 1.93652 16.1465 2.28799 16.1465 2.72155L16.1465 9.00177C16.1465 9.43533 15.7999 9.78679 15.3724 9.78679C14.9449 9.78679 14.5984 9.43533 14.5984 9.00177L14.5984 2.72155C14.5984 2.28799 14.9449 1.93652 15.3724 1.93652Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.2402 5.86256C19.2402 6.29611 18.8937 6.64758 18.4662 6.64758L12.2738 6.64758C11.8463 6.64758 11.4998 6.29611 11.4998 5.86256C11.4998 5.429 11.8463 5.07753 12.2738 5.07753L18.4662 5.07753C18.8937 5.07753 19.2402 5.429 19.2402 5.86256Z"
      />
    </svg>
  );
};

export default SvgCartAdd;
