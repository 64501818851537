const PageBanner = ({
  id,
  children,
  imageUrl,
}: {
  id: string;
  children: React.ReactNode;
  imageUrl: string;
}) => {
  return (
    <section
      id={`${id}-banner`}
      className="relative h-[450px] overflow-x-clip bg-cover bg-center bg-no-repeat md:h-[600px]"
      style={{
        backgroundImage: `url('${imageUrl}')`,
      }}
    >
      <div className="absolute inset-0 grid place-items-center">
        <img
          src="/assets/images/backgrounds/logo-aradina-icon-semi-transparent-white.png"
          alt="Logo Aradina"
        />
      </div>
      <div className="single-grid h-full bg-primary-green bg-opacity-40 backdrop-brightness-50">
        {children}
      </div>
    </section>
  );
};

export default PageBanner;
