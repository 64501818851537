import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import SvgAradinaFull from "../../svgs/logos/AradinaFull";
import Navigation from "./Navigation";
import Socials from "./Socials";
import UserButton from "./UserButton";
import { useAtom } from "jotai";
import userAtom from "../../../pages/authentication/_atom";
import SvgArrowStraight from "../../svgs/arrows/ArrowStraight";
import SvgCart from "../../svgs/icons/Cart";
import MobileNavigation from "./MobileNavigation";
import { useEmailVerified } from "../../../utils/hooks";
import SvgHeart from "../../svgs/icons/Heart";

export const Header = ({ loggedIn = false }: { loggedIn?: boolean }) => {
  const [user] = useAtom(userAtom);
  const isVerified = useEmailVerified();
  const location = useLocation();
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    if (!location.pathname.startsWith("/user/offerings/create-listing")) {
      window.scrollTo(0, 0);
    }
  }, [location]);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1280);
      if (window.innerWidth >= 1280) {
        document.body.classList.remove("active-menu");
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <header
      id={`${user?.token && loggedIn ? "user" : "site"}-header`}
      className={`container z-10 flex flex-wrap justify-between p-10 text-xl ${
        location.pathname === "/" && "absolute left-0 right-0 w-full"
      }`}
    >
      {isVerified && loggedIn ? (
        <Link to="/" className="flex flex-col items-center gap-2">
          <SvgAradinaFull
            className={`w-32 md:w-40 ${
              location.pathname === "/" ? "fill-white" : "fill-dark-green"
            }`}
          />
          <div className="flex items-center gap-2 text-primary-green">
            <SvgArrowStraight className="w-4 rotate-90 fill-primary-green" />
            Back to the website
          </div>
        </Link>
      ) : (
        <SvgAradinaFull
          className={`w-32 md:w-40 ${
            location.pathname === "/" ? "fill-white" : "fill-dark-green"
          }`}
        />
      )}
      {!isMobile ? (
        <div className="flex items-center gap-4">
          <Navigation loggedIn={loggedIn} />
          <Socials />
          {isVerified && (
            <div className="flex items-center gap-2">
              <Link to="/cart/user" title="Cart" className="hover-link">
                <SvgCart
                  className={`size-6 ${
                    location.pathname === "/"
                      ? "fill-white"
                      : "fill-primary-green"
                  }`}
                />
              </Link>
              <Link to="/wishlist/user" title="Wishlist" className="hover-link">
                <SvgHeart
                  className={`size-6 ${
                    location.pathname === "/"
                      ? "fill-white"
                      : "fill-primary-green"
                  }`}
                />
              </Link>
            </div>
          )}
          <UserButton />
        </div>
      ) : (
        <MobileNavigation />
      )}
    </header>
  );
};
export default Header;
