import { TSvgComponent } from "../../../types/utils/components";

const SvgAradinaSingleIcon: TSvgComponent = ({ ...props }) => {
  return (
    <svg
      viewBox="0 0 31 30"
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M30.005 29.0275H25.8623L23.2175 23.8593L15.229 8.12532L7.2406 23.8593L4.59578 29.0275H0.453125L3.09795 23.8593L15.229 0.0559082L27.3602 23.8458L30.005 29.014V29.0275Z" />
    </svg>
  );
};

export default SvgAradinaSingleIcon;
