import BannerGradientHeading from "../../components/common/BannerGradientHeading";

const PageSecuritySettings = () => {
  return (
    <section
      className="container section-margin"
      id="security-settings-information"
    >
      <BannerGradientHeading
        title="Security Settings"
        text="Security is about the safeguarding of data"
        mode={2}
      />
      <p className="mt-10 break-words text-primary-green">
        Cookies. This Cookie Statement explains how we use cookies and similar
        technologies in the course of our business, including through our
        websites that link to http://www.harvestreturns.com, or any website or
        mobile application owned, operated or controlled by us (collectively,
        “Websites” or “websites”), and when our users' subscribers visit
        websites or e-commerce stores operated by our users or open or interact
        with emails delivered through our Services. It explains what these
        technologies are and why we use them, as well as your rights to control
        our use of them. In some cases we may use cookies and other tracking
        technologies described in this Cookie Statement to collect Personal
        Information, or to collect information that becomes Personal Information
        if we combine it with other information. Cookies are small data files
        that are placed on your computer or mobile device when you visit a
        website. Cookies are widely used by online service providers in order to
        (for example) make their websites or services work, or to work more
        efficiently, as well as to provide reporting information. Cookies set by
        the website owner or service provider (in this case, Harvest Returns)
        are called “first party cookies”. Cookies set by parties other than the
        website owner are called “third party cookies”. Third party cookies
        enable third party features or functionality to be provided on or
        through the website or service you are using (such as advertising,
        interactive content and analytics). The third parties that set these
        third party cookies can recognize your computer both when it visits the
        website or service in question and also when it visits certain other
        websites or services. How We Use Your Personally Identifiable
        Information We may use the information we collect through our Websites
        for a range of reasons, including: To provide, operate, optimize, and
        maintain our Websites. To send you information for marketing purposes,
        in accordance with your marketing preferences. To respond to your online
        inquiries and requests, and to provide you with information and access
        to resources or services that you have requested from us. To manage our
        Websites and system administration and security. To improve the
        navigation and content of our Websites. To identify any server problems
        or other IT or network issues. To process transactions and to set up
        online accounts. To compile aggregated statistics about site usage and
        to better understand the preferences of our Visitors. To carry out
        research and development to improve our products and services. To
        customize content and layout of the Websites. To carry out other
        legitimate business purposes, as well as other lawful purposes. In
        addition, we may combine Personal Information with other information we
        collect or obtain about you (such as information we source from our
        third-party partners) to serve you specifically, such as to deliver a
        product or service according to your preferences or restrictions, or for
        advertising or targeting purposes in accordance with this privacy
        policy. When we combine Personal Information with other information in
        this way, we treat it as, and apply all of the safeguards in this
        privacy policy applicable to, Personal Information. We use your
        Personally Identifiable Information to process your transactions and
        facilitate your activities with respect to our Website as set out
        herein. We do not disclose your Personally Identifiable Information to
        third parties without your express consent except in the specific
        circumstances identified in “Third Party Access to Personally
        Identifiable Information” below. We will provide Investors with certain
        materials, including formal notices from regulators, and other
        information in order to comply with Applicable Law. We will use your
        Contact Information for these purposes. Where necessary to comply with
        Applicable Law, we will also use Investor Financial Information for
        these purposes. Investors cannot opt-out of this use of information. We
        use the Personally Identifiable Information you provide to process
        transactions that you have authorized us to undertake and to provide you
        with the Services you have requested. Investors cannot opt-out of this
        use of information. In order to communicate with you and to confirm
        (where necessary) whether you are eligible for a transaction, we will
        rely on an Investor's Contact Information and, where applicable, on his
        or her Investor Financial Information. We use your Personally
        Identifiable Information in the operation of our Website and to comply
        with Applicable Law. You cannot participate in our Website if you seek
        to opt-out of these uses of your Information. Our Website may offer a
        forum for online discussion and participation in which Investors may
        choose to participate. When we or a User participates in the Website,
        the participant will use Contact Information you have provided for
        purposes of your inclusion in the Website. Any Content you contribute to
        the Website becomes public on your transmittal of the information, and
        any Personally Identifiable Information you disclose becomes available
        to the other participants in the discussion. Our Privacy Policy does not
        cover the information you disclose in such online discussions on our
        Website. You should use discretion in what you submit to these Websites.
        You are entitled to opt-out of these uses of information. Periodically,
        we may send you materials for informational purposes, such as notices of
        investment opportunities through our Website. Such informal information
        may take a range of forms, including email, newsletters, or automatic
        notices generated by our Website. You are entitled to opt-out from
        receiving this informal information. Periodically, we may choose to send
        news, bulletins, marketing materials, or other information to Investors,
        and will use Personally Identifiable Information to send such
        communications. If we choose to undertake such communications, you will
        have the ability to opt-out of receiving these communications. Our Users
        may provide feedback to us about our Website and our Services. You are
        free to choose whether you participate in these activities. We may ask
        that, in addition to providing your responses, you also provide Contact
        Information. In these instances, we use any Personally Identifiable
        Information you choose provide to us in connection with these activities
        strictly for the purposes for which you submit the information. We will
        provide Investors with certain materials, including formal notices from
        regulators, and other information in order to comply with Applicable
        Law. We will use your Contact Information for these purposes. Where
        necessary to comply with Applicable Law, we will also use Investor
        Financial Information for these purposes. Investors cannot opt-out of
        this use of information. Google remarketing ads: Our Google remarketing
        ads feature (also known as web retargeting ads) allows our users to
        create and manage advertisements on the Google Display Network. When a
        user elects to use this feature, Harvest Returns installs a Google
        tracking pixel on that user’s Site through the Snippet. The Snippet also
        allows that user’s Site to set a Harvest Returns cookie that will
        recognize Site visitors via ads placed with the Google remarketing ads
        feature. These technologies facilitate the placement of advertising
        campaigns on the Google Display Network and enable Harvest Returns to
        provide reporting to our users about the performance of these
        advertising campaigns. To refuse these cookies, please click on the
        relevant opt-out link here: https://www.google.com/settings/ads/plugin
        Facebook ads: Our Facebook ads feature allows our users to display ads
        to their subscribers and others within the Facebook platform. When a
        user elects to use this feature, Harvest Returns installs a Facebook
        tracking pixel on that user’s Site through the Snippet. The Snippet also
        allows that user’ s Site to set a Harvest Returns cookie that will
        recognize Site visitors via ads placed with the Facebook ads feature.
        These tracking technologies facilitate the placement of advertising
        campaigns on the Facebook platform and enable Harvest Returns to provide
        reporting to our users about the performance of these advertising
        campaigns. Facebook does not provide an opt-out link for its cookies.
        For more information about Facebook cookies, please click here:
        https://www.facebook.com/policies/cookies/ Product retargeting emails:
        Our product retargeting emails feature allows our users to promote new
        items or best sellers to their subscribers. When a subscriber clicks a
        link within a product retargeting email, the user’s Site drops a Harvest
        Returns cookie on that subscriber’s device that is placed through the
        Snippet. This cookie allows Harvest Returns to track attribution rates
        and browser activity and provide reporting to the user regarding the
        success of their product retargeting emails. To refuse these cookies,
        please follow the instructions below under the heading “How can I
        control cookies?” Landing Pages: If a user elects to use the Harvest
        Returns feature known as “Landing Pages,” the use of that feature will
        permit additional cookies or tracking technologies to be employed by the
        user. Users who create Landing Pages will have the option to set cookies
        on the device of any visitors to those pages. When a user chooses to set
        cookies, Harvest Returns installs the Snippet on that user’s Landing
        Page to facilitate the deployment of the cookie(s) selected by that
        user. Subscribers should review the relevant user’s privacy notice and
        cookie disclosures for further information about the specific types of
        cookies and other tracking technologies used on any Landing Page. Notice
        of Security Incident If we detect, despite the safeguards set out above,
        an intrusion or other unauthorized access to or use of Personally
        Identifiable Information, we will (i) notify affected Users of the
        intrusion if we determine that the information at issue is sensitive;
        (ii) deliver this notice by the means we deem most efficient under the
        circumstances (such as, for example, first class mail or email); (iii)
        use contact information for each affected User in our files; and (iv)
        use commercially reasonable efforts to accomplish these steps and effect
        this notice in a timely manner. To the extent Applicable Law requires
        steps in addition to those specified above, we will under all
        circumstances comply with Applicable Law. Gramm-Leach-Bliley Act
        Notification Select personal investor data is shared in accordance our
        third party escrow provider Primetrust. Participation by Minors Due to
        federal law (as reflected in the Children's Online Privacy Protection
        Act), WE DO NOT ALLOW INDIVIDUALS WHO WE KNOW ARE UNDER 18 TO
        PARTICIPATE IN OUR WEBSITE OR SERVICES. YOU MUST BE AT LEAST 18 YEARS
        OLD TO USE OUR WEBSITE AND SERVICES. Notification of Changes From time
        to time, we may change our Privacy Policy. If we make any changes
        regarding disclosure of Personally Identifiable Information to third
        parties, we will attempt to contact you prior to the date the modified
        policy is scheduled to take effect. We will post notice of the new
        Privacy Policy at the privacy link on our Website. With respect to site
        visitors, your use of our Website following any such change constitutes
        your agreement to follow and be bound by the Privacy Policy, as changed.
        With respect to Investors, no such changes will take effect unless and
        until you have provided us with your consent to such changes in
        accordance with “Your Consent” above. List of cookies we collect The
        table below lists the cookies we collect and what information they
        store. COOKIE name COOKIE Description CART The association with your
        shopping cart. CATEGORY_INFO Stores the category info on the page, that
        allows to display pages more quickly. COMPARE The items that you have in
        the Compare Products list. CURRENCY Your preferred currency CUSTOMER An
        encrypted version of your customer id with the store. CUSTOMER_AUTH An
        indicator if you are currently logged into the store. CUSTOMER_INFO An
        encrypted version of the customer group you belong to.
        CUSTOMER_SEGMENT_IDS Stores the Customer Segment ID EXTERNAL_NO_CACHE A
        flag, which indicates whether caching is disabled or not. FRONTEND You
        sesssion ID on the server. GUEST-VIEW Allows guests to edit their
        orders. LAST_CATEGORY The last category you visited. LAST_PRODUCT The
        most recent product you have viewed. NEWMESSAGE Indicates whether a new
        message has been received. NO_CACHE Indicates whether it is allowed to
        use cache. PERSISTENT_SHOPPING_CART A link to information about your
        cart and viewing history if you have asked the site. POLL The ID of any
        polls you have recently voted in. POLLN Information on what polls you
        have voted on. RECENTLYCOMPARED The items that you have recently
        compared. STF Information on products you have emailed to friends. STORE
        The store view or language you have selected. USER_ALLOWED_SAVE_COOKIE
        Indicates whether a customer allowed to use cookies. VIEWED_PRODUCT_IDS
        The products that you have recently viewed. Contact Us If you have any
        questions about this Security Settings, the practices of this Website or
        your dealings with this Website, please contact us by email at:
        info@aradina.com, or send mail to: Aradina - Ghazir, Jounieh Highway,
        GAF & CO bld. 3rd fl. Kesrouan, Lebanon. We respond to all requests we
        receive from individuals wishing to exercise their data protection
        rights in accordance with applicable data protection laws. We may ask
        you to verify your identity in order to help us respond efficiently to
        your request. Updated January 20, 2023.
      </p>
    </section>
  );
};

export default PageSecuritySettings;
