import Banner from "./components/Banner";
import Benefits from "./components/Benefits";
import GlobalizedMarketplace from "./components/GlobalizedMarketplace";
import RaiseCapital from "./components/RaiseCapital";
import WhoShouldRaiseCapital from "./components/WhoShouldRaiseCapital";
import HowItWorks from "./components/HowItWorks";
import AradinaDestination from "./components/AradinaDestination";
import TestimonialsSection from "../../../components/section/testimonials/TestimonialsSection";
import Main from "../../../components/layout/Main";

const PageFarmers = () => {
  return (
    <Main id="farmers">
      <Banner />
      <Benefits />
      <div className="relative">
        <img
          className="absolute bottom-0 right-0 top-0 h-full"
          src="/assets/images/backgrounds/background-giant-blob.png"
          alt=""
        />
        <GlobalizedMarketplace />
        <RaiseCapital />
      </div>
      <WhoShouldRaiseCapital />
      <HowItWorks />
      <AradinaDestination />
      <TestimonialsSection page="empowerment" type="farmers" />
    </Main>
  );
};

export default PageFarmers;
