import { TSvgComponent } from "../../../types/utils/components";

const SvgChecked: TSvgComponent = ({ ...props }) => {
  return (
    <svg
      viewBox="0 0 26 18"
      fill="#2FA124"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M7.90992 17.9621C7.88823 17.9621 7.86655 17.9621 7.84684 17.9621C7.23776 17.9405 6.67599 17.6231 6.34879 17.1086C4.29685 13.8898 0.705466 10.8937 0.669986 10.8641C-0.116492 10.2117 -0.226875 9.04478 0.425567 8.25633C1.07801 7.46985 2.24491 7.35947 3.03139 8.00994C3.16543 8.12032 5.76731 10.2866 8.0065 13.0856C10.2772 10.5231 14.9961 5.7668 22.3582 1.03217C23.2176 0.478284 24.3648 0.728617 24.9168 1.58803C25.4706 2.44743 25.2203 3.59463 24.3609 4.14654C14.3673 10.5744 9.44739 17.1441 9.40008 17.2092C9.0512 17.6822 8.49731 17.9602 7.90992 17.9602V17.9621Z" />
    </svg>
  );
};

export default SvgChecked;
