import {
  DetailedHTMLProps,
  ForwardedRef,
  forwardRef,
  InputHTMLAttributes,
  LabelHTMLAttributes,
  ReactNode,
} from "react";
import { twMerge } from "tailwind-merge";

type TProps = Omit<
  DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
  "id"
> & {
  id: DetailedHTMLProps<
    InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >["id"];
  label?: ReactNode | boolean;
  labelClass?: LabelHTMLAttributes<HTMLLabelElement>["className"];
  containerClass?: DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  >["className"];
  ref?: ForwardedRef<HTMLInputElement>;
};

const CustomRadio = forwardRef<HTMLInputElement, TProps>(
  ({ label, labelClass, containerClass, ...props }, ref) => {
    return (
      <div className={twMerge("flex items-center gap-2", containerClass)}>
        <input
          {...props}
          type="radio"
          ref={ref}
          className={twMerge(
            "grid size-4 appearance-none place-items-center rounded-full border border-primary-gray [-webkit-appearance:none] before:size-2 before:scale-0 before:rounded-full before:bg-primary-green before:duration-100 checked:before:scale-100 hover:animate-pulse hover:cursor-pointer",
            props.className,
          )}
        />
        <label htmlFor={props.id} className={labelClass}>
          {label === true ? props.title : label}
        </label>
      </div>
    );
  },
);

export default CustomRadio;
