import { TSvgComponent } from "../../../types/utils/components";

const SvgGraphBars: TSvgComponent = (props) => {
  return (
    <svg
      viewBox="0 0 25 22"
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M0.808594 12.3568C0.808594 11.9575 0.967199 11.5746 1.24952 11.2923C1.53184 11.01 1.91475 10.8514 2.31401 10.8514H5.32483C5.7241 10.8514 6.107 11.01 6.38932 11.2923C6.67164 11.5746 6.83025 11.9575 6.83025 12.3568V19.8839C6.83025 20.2831 6.67164 20.666 6.38932 20.9483C6.107 21.2307 5.7241 21.3893 5.32483 21.3893H2.31401C1.91475 21.3893 1.53184 21.2307 1.24952 20.9483C0.967199 20.666 0.808594 20.2831 0.808594 19.8839V12.3568ZM9.84108 6.33513C9.84108 5.93587 9.99968 5.55296 10.282 5.27064C10.5643 4.98832 10.9472 4.82972 11.3465 4.82972H14.3573C14.7566 4.82972 15.1395 4.98832 15.4218 5.27064C15.7041 5.55296 15.8627 5.93587 15.8627 6.33513V19.8839C15.8627 20.2831 15.7041 20.666 15.4218 20.9483C15.1395 21.2307 14.7566 21.3893 14.3573 21.3893H11.3465C10.9472 21.3893 10.5643 21.2307 10.282 20.9483C9.99968 20.666 9.84108 20.2831 9.84108 19.8839V6.33513ZM18.8736 1.81889C18.8736 1.41963 19.0322 1.03672 19.3145 0.754402C19.5968 0.472082 19.9797 0.313477 20.379 0.313477H23.3898C23.7891 0.313477 24.172 0.472082 24.4543 0.754402C24.7366 1.03672 24.8952 1.41963 24.8952 1.81889V19.8839C24.8952 20.2831 24.7366 20.666 24.4543 20.9483C24.172 21.2307 23.7891 21.3893 23.3898 21.3893H20.379C19.9797 21.3893 19.5968 21.2307 19.3145 20.9483C19.0322 20.666 18.8736 20.2831 18.8736 19.8839V1.81889Z" />
    </svg>
  );
};

export default SvgGraphBars;
