export const DownloadAradinaMobileApp = () => {
  return (
    <section className="xl:single-grid section-margin">
      <img
        className="single-grid-item flex max-md:hidden"
        src="/assets/images/download-mobile/download-aradina-mobile.png"
        alt="Download Aradina Banner"
      />
      <div className="single-grid-item container flex flex-wrap justify-end gap-4 max-xl:my-10 max-xl:justify-center xl:-translate-y-1/4 xl:flex-col xl:items-end">
        <button
          title="Download Aradina for Apple"
          type="button"
          onClick={() => {
            alert("Redirecting to the app store");
          }}
          className="max-w-[200px] rounded-xl"
        >
          <img
            className="cursor-pointer"
            src="assets/images/download-mobile/download-apple-store.png"
            alt="Apple Download"
          />
        </button>
        <button
          title="Download Aradina for Apple"
          type="button"
          onClick={() => {
            alert("Redirecting to the play store");
          }}
          className="max-w-[200px] rounded-xl"
        >
          <img
            className="cursor-pointer"
            src="assets/images/download-mobile/download-android.png"
            alt="Android Download"
          />
        </button>
      </div>
    </section>
  );
};
export default DownloadAradinaMobileApp;
