import { TSvgComponent } from "../../../types/utils/components";

const SvgArrowStraightLong: TSvgComponent = ({ ...props }) => {
  return (
    <svg
      viewBox="0 0 22 177"
      fill="#547733"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_544_729)">
        <path d="M12.5295 175.57C14.9095 170.93 17.8095 166.81 21.0395 162.72C21.9195 161.61 21.5295 159.25 19.6895 159.47C17.2095 159.77 14.7095 159.88 12.2095 160C11.3795 107.16 12.9195 54.1801 10.6095 1.3901C10.5295 -0.4499 7.79949 -0.4599 7.74949 1.3901C6.08949 54.2801 8.29948 107.31 8.10948 160.23C7.54948 160.27 6.99949 160.32 6.43949 160.37C4.97949 160.44 3.52949 160.49 2.06949 160.52C0.649485 160.54 -0.620515 162.27 0.319485 163.57C3.19949 167.58 6.08948 171.57 8.97948 175.57C9.81948 176.73 11.7595 177.06 12.5195 175.57H12.5295Z" />
      </g>
      <defs>
        <clipPath id="clip0_544_729">
          <rect width="21.49" height="176.56" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SvgArrowStraightLong;
