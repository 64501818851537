import SvgAradinaIcon from "../../svgs/logos/AradinaIcon";
import SvgLocation from "../../svgs/icons/Location";
import ButtonPrimary from "../buttons/Button";
import {
  TOfferingBelonging,
  TOfferingBelongingUser,
  TOfferingToggleWishlistResponse,
} from "../../../types/offering";
import moment from "moment";
import SvgCalendar from "../../svgs/icons/Calendar";
import { MouseEvent, useState } from "react";
import SvgHeart from "../../svgs/icons/Heart";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { toast } from "sonner";
import { TLaravelResponse } from "../../../types/utils/laravel";
import { Link } from "react-router-dom";

type TProps = {
  offeringBelonging: TOfferingBelonging | TOfferingBelongingUser;
  disabled?: boolean;
};

export const CardBelonging = ({
  offeringBelonging,
  disabled = false,
}: TProps) => {
  const {
    offering_item: {
      purchased_offering: { offering },
    },
  } = offeringBelonging;
  const [isWishlisted, setIsWishlisted] = useState(
    offeringBelonging.isFavorite,
  );
  const queryClient = useQueryClient();
  const { mutate } = useMutation({
    mutationFn: async () => {
      const res = await axios.post<
        TLaravelResponse<TOfferingToggleWishlistResponse>
      >(`user-belongings/${offeringBelonging.id}/marketplace-wishlist`);
      return res.data;
    },
    onSuccess: (res) => {
      if (res.success) {
        queryClient.invalidateQueries({
          queryKey: ["user-offerings", "wishlist"],
        });
        setIsWishlisted(res.data.slice(0, 5) === "Added");
        toast.success(
          res.data.slice(0, 5) === "Added"
            ? "Successfully added to wishlist"
            : "Successfully removed from wishlist",
        );
      }
    },
    onError: () => {
      toast.error("Oops! Something went wrong...");
    },
  });
  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    mutate();
  };
  if (disabled)
    return (
      <article className="group relative flex h-full flex-col justify-between overflow-hidden rounded-2xl bg-cover bg-center text-white single-grid">
        <img
          loading="lazy"
          src={offering.thumbnail}
          alt={offering.title}
          title={offering.title}
          className="absolute inset-0 z-0 h-full object-cover"
        />
        <div className="relative flex h-full flex-col justify-between rounded-2xl bg-black bg-opacity-60">
          <div className="z-10 flex h-full flex-col justify-between p-5 pb-2">
            <div className="flex flex-col items-center">
              <div className="flex w-full items-center justify-between gap-2">
                <span className="self-start rounded bg-primary-green px-2 py-1 text-center capitalize">
                  {offeringBelonging.offering_item.main_type.type_name}
                </span>
                <button
                  disabled={disabled}
                  type="button"
                  onClick={handleClick}
                  className="rounded-full bg-dark-green p-3 hover-link"
                  title={`${isWishlisted ? "Add to " : "Remove from "} wishlist`}
                >
                  <SvgHeart
                    className={`size-4${isWishlisted ? " fill-white" : ""}`}
                  />
                </button>
              </div>
              <div className="my-10 flex flex-col items-center text-center">
                <SvgAradinaIcon className="mb-2 w-14" />
                <h3 className="gradient-text bg-gradient-to-l from-light-green to-white text-3xl font-extrabold md:text-4xl">
                  {offering.title}
                </h3>
                <div className="my-2.5 h-0.5 w-1/4 rounded-xl bg-gradient-to-l from-light-green to-white"></div>
                <p className="text-xl">{offering.short_description}</p>
              </div>
            </div>
          </div>
          <div>
            <div className="mx-5 my-2 flex gap-2">
              <SvgLocation className="mb-0.5 w-3.5 fill-white" />
              <p className="text-opacity-50">{offering.location}</p>
            </div>
            <ul className="rounded-b-2xl bg-black bg-opacity-50 px-5 py-2 text-sm md:text-xl">
              {offering.minimum_investment && (
                <li className="flex justify-between py-3">
                  <p>Date:</p>
                  <p className="flex items-center gap-2">
                    <SvgCalendar className="size-4" />
                    {moment(
                      offeringBelonging.offering_item.harvest_date,
                    ).format("DD-MM-YYYY")}
                  </p>
                </li>
              )}
              {offering.return_on_investment && (
                <li className="flex justify-between py-3">
                  {offering.unit && (
                    <p className="capitalize">Price | {offering.unit.unit}:</p>
                  )}
                  <p>${offeringBelonging.price}</p>
                </li>
              )}
              <li className="grid grid-rows-[0fr] duration-200 group-focus-within:grid-rows-[1fr] group-hover:grid-rows-[1fr]">
                <div className="overflow-hidden duration-200 group-focus-within:p-1.5 group-hover:p-1.5">
                  <ButtonPrimary
                    title="Add to Cart"
                    className="w-full"
                    disabled={disabled}
                  >
                    Add to cart
                  </ButtonPrimary>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </article>
    );
  return (
    <Link
      className="group relative flex h-full flex-col justify-between overflow-hidden rounded-2xl bg-cover bg-center text-white single-grid"
      to={`/user/offerings/belongings/${offeringBelonging.id}`}
      title={offeringBelonging.offering_item.title}
    >
      <img
        loading="lazy"
        src={offering.thumbnail}
        alt={offering.title}
        title={offering.title}
        className="absolute inset-0 z-0 h-full object-cover"
      />
      <div className="relative flex h-full flex-col justify-between rounded-2xl bg-black bg-opacity-60">
        <div className="z-10 flex h-full flex-col justify-between p-5 pb-2">
          <div className="flex flex-col items-center">
            <div className="flex w-full items-center justify-between gap-2">
              <span className="self-start rounded bg-primary-green px-2 py-1 text-center capitalize">
                {offeringBelonging.offering_item.main_type.type_name}
              </span>
              <button
                disabled={disabled}
                type="button"
                onClick={handleClick}
                className="rounded-full bg-dark-green p-3 hover-link"
                title={`${isWishlisted ? "Add to " : "Remove from "} wishlist`}
              >
                <SvgHeart
                  className={`size-4${isWishlisted ? " fill-white" : ""}`}
                />
              </button>
            </div>
            <div className="my-10 flex flex-col items-center text-center">
              <SvgAradinaIcon className="mb-2 w-14" />
              <h3 className="gradient-text bg-gradient-to-l from-light-green to-white text-3xl font-extrabold md:text-4xl">
                {offering.title}
              </h3>
              <div className="my-2.5 h-0.5 w-1/4 rounded-xl bg-gradient-to-l from-light-green to-white"></div>
              <p className="text-xl">{offering.short_description}</p>
            </div>
          </div>
        </div>
        <div>
          <div className="mx-5 my-2 flex gap-2">
            <SvgLocation className="mb-0.5 w-3.5 fill-white" />
            <p className="text-opacity-50">{offering.location}</p>
          </div>
          <ul className="rounded-b-2xl bg-black bg-opacity-50 px-5 py-2 text-sm md:text-xl">
            {offering.minimum_investment && (
              <li className="flex justify-between py-3">
                <p>Date:</p>
                <p className="flex items-center gap-2">
                  <SvgCalendar className="size-4" />
                  {moment(offeringBelonging.offering_item.harvest_date).format(
                    "DD-MM-YYYY",
                  )}
                </p>
              </li>
            )}
            {offering.return_on_investment && (
              <li className="flex justify-between py-3">
                {offering.unit && (
                  <p className="capitalize">Price | {offering.unit.unit}:</p>
                )}
                <p>${offeringBelonging.price}</p>
              </li>
            )}
            <li className="grid grid-rows-[0fr] duration-200 group-focus-within:grid-rows-[1fr] group-hover:grid-rows-[1fr]">
              <div className="overflow-hidden duration-200 group-focus-within:p-1.5 group-hover:p-1.5">
                <ButtonPrimary
                  title="Add to Cart"
                  className="w-full"
                  disabled={disabled}
                >
                  Add to cart
                </ButtonPrimary>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </Link>
  );
};
export default CardBelonging;
