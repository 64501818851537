import moment from "moment";
import { TMyBelonging } from "../../../types/offering";
import SvgCalendar from "../../svgs/icons/Calendar";
import SvgLocation from "../../svgs/icons/Location";
import SvgAradinaIcon from "../../svgs/logos/AradinaIcon";
import { Link } from "react-router-dom";

type TProps = {
  data: TMyBelonging;
};

const CardMyBelonging = ({ data }: TProps) => {
  const {
    purchased_offering: { offering },
  } = data;
  return (
    <Link
      to={`/user/offerings/my-belongings/${data.id}`}
      title={offering.title}
      className="relative flex h-full flex-col justify-between overflow-hidden rounded-2xl bg-cover bg-center text-white hover-link single-grid"
    >
      <img
        loading="lazy"
        src={offering.thumbnail}
        alt={offering.title}
        title={offering.title}
        className="absolute inset-0 z-0 h-full object-cover"
      />
      <div className="relative flex h-full flex-col justify-between rounded-2xl bg-black bg-opacity-60">
        <div className="z-10 flex h-full flex-col justify-between p-5 pb-2">
          <div className="flex flex-col items-center">
            <span className="self-start rounded bg-primary-green px-2 py-1 text-center capitalize">
              {data.main_type.type_name}
            </span>
            {/* <div className="flex w-full items-center justify-between gap-2">
              <button
                disabled={disabled}
                type="button"
                onClick={handleClick}
                className="rounded-full bg-dark-green p-3 hover-link"
                title={`${isWishlisted ? "Add to " : "Remove from "} wishlist`}
              >
                <SvgHeart
                  className={`size-4${isWishlisted ? " fill-white" : ""}`}
                />
              </button>
            </div> */}
            <div className="my-10 flex flex-col items-center text-center">
              <SvgAradinaIcon className="mb-2 w-14" />
              <h3 className="gradient-text bg-gradient-to-l from-light-green to-white text-3xl font-extrabold md:text-4xl">
                {offering.title}
              </h3>
              <div className="my-2.5 h-0.5 w-1/4 rounded-xl bg-gradient-to-l from-light-green to-white"></div>
              <p className="text-xl">{offering.short_description}</p>
            </div>
          </div>
        </div>
        <div>
          <div className="mx-5 my-2 flex gap-2">
            <SvgLocation className="mb-0.5 w-3.5 fill-white" />
            <p className="text-opacity-50">{offering.location}</p>
          </div>
          <ul className="rounded-b-2xl bg-black bg-opacity-50 px-5 py-2 text-sm md:text-xl">
            {offering.minimum_investment && (
              <li className="flex justify-between py-3">
                <p>Date:</p>
                <p className="flex items-center gap-2">
                  <SvgCalendar className="size-4" />
                  {moment(data.harvest_date).format("DD-MM-YYYY")}
                </p>
              </li>
            )}
            {offering.return_on_investment && (
              <li className="flex justify-between py-3">
                {offering.unit && (
                  <p className="capitalize">Price | {offering.unit.unit}:</p>
                )}
                <p>${data.purchased_offering.offering.unit_price}</p>
              </li>
            )}
            {/* <li className="grid grid-rows-[0fr] duration-200 group-focus-within:grid-rows-[1fr] group-hover:grid-rows-[1fr]">
              <div className="overflow-hidden duration-200 group-focus-within:p-1.5 group-hover:p-1.5">
                <ActionButton
                  title="Add to Cart"
                  className="w-full"
                  disabled={disabled}
                >
                  Add to cart
                </ActionButton>
              </div>
            </li> */}
          </ul>
        </div>
      </div>
    </Link>
  );
};

export default CardMyBelonging;
