import { TSvgComponent } from "../../../types/utils/components";

const SvgQuotes: TSvgComponent = ({ ...props }) => {
  return (
    <svg
      viewBox="0 0 45 32"
      fill="#547C2C"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M34.1549 -0.000199239C37.2956 -0.000198965 39.769 0.902769 41.5749 2.70869C43.3023 4.59314 44.166 7.10573 44.166 10.2465C44.166 14.6435 42.6742 18.7265 39.6905 22.4954C36.7068 26.2642 32.506 29.3657 27.0882 31.7998L22.966 24.0265C24.8505 23.3198 26.6564 22.2598 28.3838 20.8465C30.0327 19.5117 31.1712 18.1768 31.7994 16.842C29.6793 16.7635 27.9127 15.9391 26.4994 14.3687C25.086 12.7983 24.3794 11.0317 24.3794 9.06869C24.3794 6.32055 25.2823 4.12202 27.0882 2.47314C28.8942 0.824246 31.2497 -0.000199493 34.1549 -0.000199239ZM11.1882 -0.000201247C14.329 -0.000200973 16.8023 0.902767 18.6082 2.70869C20.3356 4.59314 21.1994 7.10573 21.1994 10.2465C21.1993 14.6435 19.7075 18.7265 16.7238 22.4954C13.7401 26.2642 9.53935 29.3657 4.12157 31.7998L-0.000651634 24.0265C1.88379 23.3198 3.68972 22.2598 5.41713 20.8465C7.06601 19.5117 8.20453 18.1768 8.83268 16.842C6.71269 16.7635 4.94602 15.9391 3.53268 14.3687C2.11935 12.7983 1.41268 11.0317 1.41268 9.06869C1.41268 6.32054 2.31564 4.12202 4.12157 2.47313C5.9275 0.824244 8.28305 -0.000201501 11.1882 -0.000201247Z" />
    </svg>
  );
};

export default SvgQuotes;
