import ThreeNeedlesHeading from "../../../../components/common/ThreeNeedlesHeading";
import SvgArrowCurved from "../../../../components/svgs/arrows/ArrowCurved";
import { offeringDetailsAtom } from "../../atoms";
import { useAtomValue } from "jotai";

export const PropertyAndOrchard = () => {
  const offering = useAtomValue(offeringDetailsAtom);
  return (
    <section
      id="offering-property-orchard"
      className="overflow-x-clip bg-primary-yellow bg-[url(/public/assets/images/backgrounds/background-blobs.png)] bg-cover bg-no-repeat"
    >
      <div className="section-padding container text-white">
        <div className="mb-10 flex flex-wrap gap-x-2 text-section-heading font-bold lg:justify-end">
          <SvgArrowCurved className="z-10 -mr-4 mt-2 w-24 -scale-x-100 fill-dark-yellow max-md:hidden" />
          <ThreeNeedlesHeading
            backgroundColor="white"
            needlesColor="gold"
            className="text-primary-yellow"
          >
            Property
          </ThreeNeedlesHeading>
          <h2 className="py-1.5">&</h2>
          <ThreeNeedlesHeading
            backgroundColor="white"
            needlesColor="gold"
            className="text-primary-yellow"
          >
            Orchard
          </ThreeNeedlesHeading>
          <h2 className="py-1.5">Details</h2>
        </div>
        <div className="grid items-center gap-10 xl:grid-cols-[7fr_3fr]">
          <p>{offering.description_property_orchard}</p>
          <img
            className="aspect-square origin-left rounded-full object-cover max-xl:row-start-1 max-xl:max-w-xs xl:translate-y-1/4 xl:scale-150"
            loading="lazy"
            src={offering.right_picture_property_orchard}
            alt="Property Orchard"
          />
        </div>
      </div>
    </section>
  );
};
export default PropertyAndOrchard;
