import ThreeNeedlesHeading from "../../../../components/common/ThreeNeedlesHeading";
import SvgArrowCurved from "../../../../components/svgs/arrows/ArrowCurved";
import { useAtomValue } from "jotai";
import { offeringDetailsAtom } from "../../atoms";

export const KeyValueDrivers = () => {
  const offering = useAtomValue(offeringDetailsAtom);
  return (
    <section id="offering-key-value">
      <div className="section-margin container text-primary-green">
        <div className="mb-10 flex flex-wrap gap-x-2 text-section-heading font-bold md:justify-end">
          <SvgArrowCurved className="z-10 -mr-4 mt-4 w-24 -scale-x-100 max-md:hidden" />
          <ThreeNeedlesHeading
            backgroundColor="primary-green"
            needlesColor="black"
            className="text-white"
          >
            Key Value
          </ThreeNeedlesHeading>
          <h2 className="py-1.5">Offering</h2>
        </div>
        <div className="grid gap-y-10">
          {offering.key_value_drivers?.map((value, index) => (
            <article
              key={value.id}
              className={`flex flex-col ${
                (index + 1) % 2 === 0 ? "items-end" : "items-start"
              }`}
            >
              <img
                className="mb-2"
                loading="lazy"
                src={value.logo}
                alt={`${value.title}`}
              />
              <h3
                className={`${
                  (index + 1) % 2 === 0 ? "text-right" : "text-left"
                } text-2xl font-extrabold`}
              >
                {value.title}
              </h3>
              <p className={(index + 1) % 2 === 0 ? "text-right" : "text-left"}>
                {value.description}
              </p>
            </article>
          ))}
        </div>
      </div>
    </section>
  );
};
export default KeyValueDrivers;
