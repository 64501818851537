import Main from "../../../components/layout/Main";
import BannerGradientHeading from "../../../components/common/BannerGradientHeading";
import FAQs from "./FAQs";

const PageFAQs = () => {
  return (
    <Main id="faqs">
      <section id="faq-banner" className="container">
        <BannerGradientHeading title="Frequently Asked Questions" mode={2} />
      </section>
      <FAQs />
    </Main>
  );
};

export default PageFAQs;
