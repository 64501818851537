import { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { TLaravelSuccess } from "../../../../../types/utils/laravel";
import { ZodFormattedError, z } from "zod";
import { useParams } from "react-router-dom";

type TResponse = {
  question: string;
  offering_id: number;
  id: number;
};

type TForm = {
  question: string;
  notifyInvestors: boolean;
  relationWithCompany: boolean;
};

const useOfferingQuestions = () => {
  const [form, setForm] = useState({
    question: "",
    notifyInvestors: false,
    relationWithCompany: false,
  });
  const [errors, setErrors] = useState<ZodFormattedError<
    typeof form,
    string
  > | null>();
  const { offeringId } = useParams();

  const formSchema = z.object({
    question: z
      .string()
      .min(1, "The question is required")
      .min(10, "Please, at least provide 10 characters."),
    notifyInvestors: z.boolean().optional(),
    relationWithCompany: z.boolean().optional(),
  });

  const { mutate, isPending, data, isSuccess } = useMutation({
    mutationFn: async (param: TForm) => {
      const response = await axios.post<TLaravelSuccess<TResponse>>(
        `/offerings/${offeringId}/questions-and-answers`,
        {
          question: param.question,
        },
      );
      return response.data.data.id;
    },
    onSuccess: (_) => setForm((prev) => (prev = { ...prev, question: "" })),
  });

  const submitForm = () => {
    const validated = formSchema.safeParse(form);
    if (!validated.success) {
      setErrors((prev) => (prev = validated.error.format()));
    } else {
      setErrors((prev) => (prev = null));
      mutate(form);
    }
  };

  return {
    error: errors?.question?._errors[0],
    data,
    isSuccess,
    isPending,
    form,
    setForm,
    submitForm,
  };
};

export default useOfferingQuestions;
