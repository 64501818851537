import { useAtomValue } from "jotai";
import { offeringDetailsAtom } from "../../../../atoms";

const VideosTab = () => {
  const offering = useAtomValue(offeringDetailsAtom);
  let embededId = "egM7jR2JF64";
  return (
    <section
      id="offering-tab-videos"
      className="container grid gap-5 section-margin md:grid-cols-2 xl:grid-cols-3"
    >
      {offering.videos.map(({ id, title, path }) => (
        <div key={id}>
          <iframe
            className="aspect-video"
            src={`https://www.youtube.com/embed/${embededId}`}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title={title}
          />
          <h6 className="rounded-b-2xl bg-[#F9FFF2] p-2 shadow-[0_2px_10px_rgba(0,0,0,.25)]">
            {title}
          </h6>
        </div>
      ))}
    </section>
  );
};

export default VideosTab;
