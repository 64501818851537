import { useAtom } from "jotai";
import { useNavigate } from "react-router-dom";
import userAtom from "../_atom";
import { useRef, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { TLaravelResponse } from "../../../types/utils/laravel";
import { TResponseUser } from "../../../types/user";

export const useEmailVerification = () => {
  const navigate = useNavigate();
  const [, setUser] = useAtom(userAtom);
  const form = useRef(null);
  const [error, setError] = useState("");
  const handleKeyUp = (
    e: React.KeyboardEvent<HTMLInputElement>,
    index: number,
  ) => {
    if (
      e.key !== "Backspace" &&
      e.key !== "ArrowRight" &&
      e.key !== "ArrowLeft" &&
      e.key !== "ArrowUp" &&
      e.key !== "ArrowDown" &&
      e.key !== "Tab"
    ) {
      if (document.getElementById(`nb-${index + 2}`)) {
        document.getElementById(`nb-${index + 2}`)?.focus();
      } else {
        document.getElementById("verify-submit")?.focus();
      }
    }
  };
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length > 1) {
      e.target.value = e.target.value.slice(1);
    }
  };
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError((prev) => (prev = ""));
    const formData = new FormData(form.current ?? undefined);
    const data = Object.fromEntries(formData);
    const otp = Object.values(data).join("");
    if (otp.length > 3) {
      mutate(otp);
    } else {
      setError((prev) => (prev = "Please provide the full OTP."));
    }
  };

  const { mutate, isPending } = useMutation({
    mutationFn: async (param: string) => {
      const response = await axios.post<TLaravelResponse<string>>(
        "verify-email",
        {
          otp: param,
        },
      );
      return response.data;
    },
    onSuccess: (res) => {
      if (res.success) {
        setUser(
          (prev) =>
            (prev = { ...(prev as TResponseUser), email_verified: res.data }),
        );
        navigate("/");
      }
    },
    onError: (e: AxiosError<{ message: string }>) => {
      setError(
        (prev) => (prev = e.response?.data.message ?? "Something went wrong!"),
      );
    },
  });
  return { form, handleSubmit, handleChange, handleKeyUp, isPending, error };
};
