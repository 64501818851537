import { Elements, PaymentElement } from "@stripe/react-stripe-js";
import AddressLocation from "../../../components/common/AddressLocation";
import LoadingImage from "../../../components/common/LoadingImage";
import SvgTrash from "../../../components/svgs/icons/Trash";
import { useCartMarketplace, useCartMarketplaceItem } from "./_hooks";
import Address from "./Address";
import ButtonPrimary from "../../../components/common/buttons/Button";
import { useStripeForm } from "../user/_hooks";

const Cart = () => {
  const {
    cart,
    isLoading,
    total,
    stripePromise,
    checkout,
    handleCheckout,
    isPending,
  } = useCartMarketplace();
  return (
    <div className="bg-gray-50 section-padding">
      <div className="container">
        <h3 className="mb-4 text-3xl font-bold capitalize text-dark-green">
          Your cart
        </h3>
        <div className="grid gap-6 lg:grid-cols-[3fr_2fr]">
          <section id="cart-items">
            {isLoading ? (
              <ul className="grid gap-4">
                {[...Array(3)].map((_, index) => {
                  return (
                    <LoadingImage
                      key={`cart_item_loading_${index}`}
                      className="h-48"
                    />
                  );
                })}
              </ul>
            ) : cart.length > 0 ? (
              <ul className="grid h-fit gap-4">
                {cart.map((item, index) => {
                  return (
                    <li key={`cart_item_${item.id}`}>
                      <Card key={`cart_item_${item.id}`} index={index} />
                    </li>
                  );
                })}
              </ul>
            ) : (
              <p>Your cart is currently empty</p>
            )}
          </section>
          <aside className="max-lg:row-start-1">
            <Address />
            {cart.length > 0 ? (
              <section
                id="cart-summary"
                className="h-max rounded-md bg-white p-6"
              >
                <h5 className="mb-4 text-xl text-dark-green">Order Summary</h5>
                <hr className="mb-4" />
                <ul className="mb-4 flex flex-col justify-between gap-4">
                  {cart.map((item) => {
                    return (
                      <li
                        key={`cart_total_${item.id}`}
                        className="flex flex-wrap justify-between gap-x-4 gap-y-2"
                      >
                        <p className="text-lg">
                          {item.user_belonging.offering_item.title}
                        </p>
                        <p className="text-lg text-primary-gray">
                          ${item.total_price}
                        </p>
                      </li>
                    );
                  })}
                </ul>
                <div className="flex flex-wrap items-center justify-between gap-2">
                  <div className="flex items-center gap-x-4 gap-y-2">
                    <span>Total: </span>
                    <p className="font-extrabold text-dark-green">${total}</p>
                  </div>
                  <ButtonPrimary
                    title="Checkout"
                    type="button"
                    onClick={() => handleCheckout()}
                    className="ml-auto"
                    isLoading={isPending}
                    disabled={checkout?.success}
                  >
                    Checkout
                  </ButtonPrimary>
                </div>
              </section>
            ) : (
              <p className="mb-8 text-center text-sm">
                Your cart is currently empty
              </p>
            )}
          </aside>
        </div>
        {checkout?.success && (
          <section id="cart-payment" className=" bg-gray-50 section-padding">
            <div className="container">
              <Elements
                stripe={stripePromise}
                options={{ clientSecret: checkout.data.client_secret }}
              >
                <StripeForm client_secret={checkout.data.client_secret} />
              </Elements>
            </div>
          </section>
        )}
      </div>
    </div>
  );
};

export default Cart;

const Card = ({ index }: { index: number }) => {
  const {
    item,
    removeFromCart,
    handleDecrement,
    handleIncrement,
    handleEdit,
    edit,
  } = useCartMarketplaceItem(index);
  return (
    <article className="flex flex-wrap gap-4 rounded-md bg-white p-6 duration-200 hover:-translate-y-1 hover:translate-x-1">
      <img
        loading="lazy"
        src={
          item.user_belonging.offering_item.purchased_offering.offering
            .thumbnail
        }
        alt={
          item.user_belonging.offering_item.purchased_offering.offering.title
        }
        className="size-32 rounded-xl object-cover"
      />
      <div className="flex-grow">
        <div className="mb-2 flex justify-between gap-4">
          <div>
            <h4 className="text-xl font-extrabold">
              {item.user_belonging.offering_item.title}
            </h4>
            <p className="text-base text-primary-gray">
              {item.user_belonging.description}
            </p>
          </div>
          <div className="flex gap-3">
            <button
              type="button"
              title={edit ? "Save" : "Edit"}
              onClick={handleEdit}
              className="text-primary-green hover-link"
            >
              {edit ? "Save" : "Edit"}
            </button>
            <button
              type="button"
              title="Remove from cart"
              onClick={() => removeFromCart()}
              className="hover-link"
            >
              <SvgTrash className="size-3.5" />
            </button>
          </div>
        </div>
        <div className="mb-4 flex flex-wrap items-center justify-between gap-x-8 gap-y-1">
          <p className="text-primary-gray">Unit:</p>
          <div className="flex flex-wrap items-center justify-center gap-6">
            <div className="grid w-36 grid-cols-3 rounded-md bg-secondary-green/25 has-[button:disabled]:bg-primary-gray/25">
              <button
                disabled={!edit}
                type="button"
                title="Increment"
                className="px-4 py-2 hover-link"
                onClick={handleDecrement}
              >
                -
              </button>
              <input
                id={`quantity-input-${item.id}`}
                type="number"
                className="w-12 bg-transparent text-center"
                readOnly
                value={Math.round(+item.quantity)}
                tabIndex={-1}
              />
              <button
                disabled={!edit}
                type="button"
                title="Increment"
                className="px-4 py-2 hover-link"
                onClick={handleIncrement}
              >
                +
              </button>
            </div>
          </div>
        </div>
        <div className="flex flex-wrap items-center justify-between gap-x-8 gap-y-1">
          <AddressLocation
            location={
              item.user_belonging.offering_item.purchased_offering.offering
                .location
            }
          />
          <span className="mt-1 font-extrabold">
            ${(+item.total_price).toFixed(2)}
          </span>
        </div>
      </div>
    </article>
  );
};

const StripeForm = ({ client_secret }: { client_secret: string }) => {
  const { stripe, elements, handleSubmit } = useStripeForm(client_secret);
  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement className="mb-4" />
      <ButtonPrimary type="submit" disabled={!stripe || !elements}>
        Pay
      </ButtonPrimary>
    </form>
  );
};
