import Main from "../../../components/layout/Main";
import AuthModal from "../../../components/section/AuthModal";
import SignUpForm from "./SignUpForm";

const PageSignUp = () => {
  return (
    <Main id="signup">
      <AuthModal
        id="signup-modal"
        background="/assets/images/pages/signup/background.jpg"
        title="Sign up"
      >
        <SignUpForm />
      </AuthModal>
    </Main>
  );
};

export default PageSignUp;
