import CustomDropdown from "../../../components/common/inputs/Dropdown";
import LoadingImage from "../../../components/common/LoadingImage";
import SvgTrash from "../../../components/svgs/icons/Trash";
import { useCartUser, useCartUserItem, useStripeForm } from "./_hooks";
import AddressLocation from "../../../components/common/AddressLocation";
import { TLabelOption } from "../../../types/utils/components";
import ButtonPrimary from "../../../components/common/buttons/Button";
import { Elements, PaymentElement } from "@stripe/react-stripe-js";

const Cart = () => {
  const { isLoading, cart, total, checkout, mutate, stripePromise } =
    useCartUser();
  return (
    <>
      <section id="cart-overview" className="bg-gray-50 section-padding">
        <div className="container">
          <h3 className="mb-4 text-3xl font-bold capitalize text-dark-green">
            Your cart
          </h3>
          <div className="grid gap-6 lg:grid-cols-[3fr_2fr]">
            <div className="grid gap-4">
              {isLoading ? (
                [...Array(3)].map((_, index) => {
                  return (
                    <LoadingImage
                      key={`cart_item_loading_${index}`}
                      className="h-60"
                    />
                  );
                })
              ) : cart.length > 0 ? (
                <ul className="grid gap-4">
                  {cart.map((item, index) => {
                    return (
                      <li key={`cart_item_${item.investment_number}`}>
                        <Card
                          key={`cart_item_${item.investment_number}`}
                          index={index}
                        />
                      </li>
                    );
                  })}
                </ul>
              ) : (
                <p>Your cart is currently empty</p>
              )}
            </div>
            <aside className="h-max rounded-md bg-white p-6 max-lg:row-start-1">
              <h5 className="mb-4 text-xl text-dark-green">
                Investment Summary
              </h5>
              <hr className="mb-4" />
              {cart.length > 0 ? (
                <ul className="mb-4 flex flex-col justify-between gap-4">
                  {cart.map((item) => {
                    return (
                      <li
                        key={`cart_total_${item.investment_number}`}
                        className="flex flex-wrap justify-between gap-x-4 gap-y-2"
                      >
                        <p className="text-lg">{item.offering.title}</p>
                        <p className="text-lg text-primary-gray">
                          ${item.total_price_insurance}
                        </p>
                      </li>
                    );
                  })}
                </ul>
              ) : (
                <p className="mb-8 text-center text-sm">
                  Your cart is currently empty
                </p>
              )}
              <div className="flex flex-wrap items-center justify-between gap-2">
                <div className="flex items-center gap-x-4 gap-y-2">
                  <span>Total: </span>
                  <p className="font-extrabold text-dark-green">${total}</p>
                </div>
                <ButtonPrimary
                  title="Checkout"
                  type="button"
                  onClick={() => mutate()}
                  className="ml-auto"
                  disabled={checkout?.success}
                >
                  Checkout
                </ButtonPrimary>
              </div>
            </aside>
          </div>
        </div>
      </section>
      {checkout?.success && (
        <section id="cart-payment" className=" bg-gray-50 section-padding">
          <div className="container">
            <Elements
              stripe={stripePromise}
              options={{ clientSecret: checkout.data.client_secret }}
            >
              <StripeForm client_secret={checkout.data.client_secret} />
            </Elements>
          </div>
        </section>
      )}
    </>
  );
};

export default Cart;

const Card = ({ index }: { index: number }) => {
  const {
    item,
    removeFromCart,
    handleDecrement,
    handleIncrement,
    handleEdit,
    handleInsuranceChange,
    edit,
  } = useCartUserItem(index);
  return (
    <article className="flex flex-wrap gap-4 rounded-md bg-white p-6 duration-200 hover:-translate-y-1 hover:translate-x-1">
      <img
        loading="lazy"
        src={item.offering.thumbnail}
        alt={item.offering.title}
        className="size-32 rounded-xl object-cover"
      />
      <div className="flex-grow">
        <div className="mb-2 flex justify-between gap-4">
          <div>
            <h4 className="text-xl font-extrabold">{item.offering.title}</h4>
            <p className="text-base text-primary-gray">
              {item.offering.short_description}
            </p>
          </div>
          <div className="flex gap-3">
            <button
              type="button"
              title={edit ? "Save" : "Edit"}
              onClick={handleEdit}
              className="text-primary-green hover-link"
            >
              {edit ? "Save" : "Edit"}
            </button>
            <button
              type="button"
              title="Remove from cart"
              onClick={() => removeFromCart()}
              className="hover-link"
            >
              <SvgTrash className="size-3.5" />
            </button>
          </div>
        </div>
        {item.offering.insurances && item.offering.insurances?.length > 0 && (
          <div className="mb-2 flex flex-wrap items-center justify-between gap-x-8 gap-y-1">
            <p className="text-primary-gray">Insurance:</p>
            <CustomDropdown
              disabled={!edit}
              isOptionEqualToValue={(option, value) =>
                (option as TLabelOption).id === (value as TLabelOption).id
              }
              labelClass="w-36"
              value={
                item.insurance
                  ? { id: item.insurance.id, label: item.insurance.name }
                  : null
              }
              options={[
                ...item.offering.insurances.map((e) => ({
                  id: e.id,
                  label: e.name,
                })),
              ]}
              onChange={(_, value) => {
                handleInsuranceChange(value as TLabelOption);
              }}
            />
          </div>
        )}
        <div className="mb-4 flex flex-wrap items-center justify-between gap-x-8 gap-y-1">
          <p className="text-primary-gray">Unit:</p>
          <div className="flex flex-wrap items-center justify-center gap-6">
            <div className="grid w-36 grid-cols-3 rounded-md bg-secondary-green/25 has-[button:disabled]:bg-primary-gray/25">
              <button
                disabled={!edit}
                type="button"
                title="Increment"
                className="px-4 py-2 hover-link"
                onClick={handleDecrement}
              >
                -
              </button>
              <input
                id={`quantity-input-${item.id}`}
                type="number"
                className="w-12 bg-transparent text-center"
                readOnly
                value={item.quantity}
                tabIndex={-1}
              />
              <button
                disabled={!edit}
                type="button"
                title="Increment"
                className="px-4 py-2 hover-link"
                onClick={handleIncrement}
              >
                +
              </button>
            </div>
          </div>
        </div>
        <div className="flex flex-wrap items-center justify-between gap-x-8 gap-y-1">
          <AddressLocation location={item.offering.location} />
          <span className="mt-1 font-extrabold">
            ${item.total_price_insurance}
          </span>
        </div>
      </div>
    </article>
  );
};

const StripeForm = ({ client_secret }: { client_secret: string }) => {
  const { stripe, elements, handleSubmit } = useStripeForm(client_secret);
  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement className="mb-4" />
      <ButtonPrimary type="submit" disabled={!stripe || !elements}>
        Pay
      </ButtonPrimary>
    </form>
  );
};
