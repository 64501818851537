import Main from "../../../../../components/layout/Main";
import SvgWallet from "../../../../../components/svgs/icons/Wallet";
import { useBelongingsOffers } from "../_hooks";

const PageOffersListing = () => {
  const { Display, offers, handleAcceptOffer, handleRejectOffer } =
    useBelongingsOffers();
  return (
    <Main id="offers">
      <section id="offers-list" className="container section-margin">
        <Display
          success={() =>
            offers.length > 0 ? (
              <div className="grid gap-10">
                {offers.map((item, index) => {
                  return (
                    <div key={`account_purchases_${index}`}>
                      <h3 className="mb-4 text-lg">{item.date}</h3>
                      <ul className="grid gap-4">
                        {item.offers.map((offer, i) => {
                          return (
                            <li
                              className="rounded-xl p-8 shadow-[0_0_15px_1px_#8884]"
                              key={`account_purchases_${index}_${i}`}
                            >
                              <article className="flex flex-wrap gap-8">
                                <div className="size-28 rounded-full bg-tertiary-green p-8">
                                  <SvgWallet className="size-12" />
                                </div>
                                <div className="my-2 flex flex-grow flex-col justify-between gap-2">
                                  <div>
                                    <h4 className="text-lg font-bold">
                                      An Aradina user made an offer
                                    </h4>
                                    <p className="text-base">
                                      {offer.user_belonging.description}
                                    </p>
                                  </div>
                                  <div>
                                    <h4 className="text-xl font-extrabold">
                                      Offering Details:
                                    </h4>
                                    <p>
                                      {offer.user_belonging.offering_item.title}
                                      : ${offer.offered_price} /
                                      <span className="text-sm">
                                        {offer.user_belonging.offering_item
                                          .purchased_offering.offering.unit
                                          ? offer.user_belonging.offering_item
                                              .purchased_offering.offering.unit
                                              .unit
                                          : "Unit"}
                                      </span>
                                    </p>
                                  </div>
                                </div>
                                <div className="flex content-center gap-4">
                                  <button
                                    type="button"
                                    title="Accept offer"
                                    className="text-primary-green hover-link"
                                    onClick={() => handleAcceptOffer(offer.id)}
                                  >
                                    Accept
                                  </button>
                                  <button
                                    type="button"
                                    title="Reject offer"
                                    className="text-red-500 hover-link"
                                    onClick={() => handleRejectOffer(offer.id)}
                                  >
                                    Reject
                                  </button>
                                </div>
                              </article>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  );
                })}
              </div>
            ) : (
              <p>No purchases yet</p>
            )
          }
        />
      </section>
    </Main>
  );
};

export default PageOffersListing;
