import { ComponentProps } from "react";
import { twMerge } from "tailwind-merge";
import { Link } from "react-router-dom";

type TProps = ComponentProps<typeof Link> & { secondary?: boolean };

export const LinkPrimary = ({ secondary, ...props }: TProps) => {
  return (
    <Link
      type="button"
      {...props}
      className={twMerge(
        `rounded-3xl hover-link ${secondary ? "bg-primary-green text-white shadow-light-green hover:bg-light-green hover:text-dark-green hover:shadow-primary-green focus:bg-light-green focus:text-dark-green focus:shadow-primary-green" : "bg-light-green text-dark-green shadow-primary-green hover:bg-primary-green hover:text-white hover:shadow-light-green focus:bg-primary-green focus:text-white focus:shadow-light-green"} px-8 py-2 text-center shadow-[3px_3px] transition-colors duration-500 disabled:bg-primary-gray/75 disabled:text-dark-green disabled:shadow-gray-300 disabled:hover:text-gray-900 disabled:focus:text-gray-900 md:text-xl`,
        props.className,
      )}
    />
  );
};
export default LinkPrimary;
