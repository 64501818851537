import { Link } from "react-router-dom";
import PageBanner from "../../../components/section/PageBanner";
import SvgArrowStraight from "../../../components/svgs/arrows/ArrowStraight";
import SvgAradinaIcon from "../../../components/svgs/logos/AradinaIcon";

const Banner = () => {
  return (
    <PageBanner
      id="home"
      imageUrl="/assets/images/pages/home/background-banner-home.jpg"
    >
      <div className="container flex h-full max-w-5xl flex-col items-center justify-end gap-4">
        <h2 className="gradient-text bg-gradient-to-r from-[#72A92D] to-white text-center text-4xl capitalize leading-tight md:text-6xl">
          {/* Catering your <br />
          <span className="font-extrabold">Investment Needs</span> */}
          Increase your profits{" "}
          <span className="font-extrabold">effectively & reliably!</span>
        </h2>
        <SvgAradinaIcon className="w-16" />
        <p className="text-pre text-center text-sm text-white">
          {/* Grow your wealth with passive investments
          <br /> in farming and agribusiness. */}
          For farmers and landowners, Aradina offers an opportunity to earn
          long-term profits by managing your land effectively based on
          scientific studies and assessments. This app is your chance to shift
          your financial future!
          <br /> Download the app{" "}
          <Link
            title="Download mobile app"
            className="text-light-green hover-link"
            to="#"
          >
            here
          </Link>{" "}
          or navigate through our website to know more about our concept.
        </p>
        <div className="rounded-3xl bg-gradient-to-r from-[#7FE11D] to-[#77AC33] px-5 py-2 text-white max-md:mb-6">
          <span>Explore more</span>
        </div>
        <div className="flex flex-col items-center max-md:hidden">
          <SvgArrowStraight className="box-content h-8 w-8 rounded-full border border-white p-2.5" />
          <div className="h-12 w-px bg-white"></div>
        </div>
      </div>
      {/* <div className="absolute bottom-0 right-0 z-10 hidden translate-y-1/2 items-center justify-end gap-7 lg:flex">
        <h2 className="gradient-text bg-gradient-to-r from-[#ECBD00] to-[#A3CF77] text-section-heading font-bold">
          Your Gateway <br /> to Opportunity
        </h2>
        <div className="h-1 w-32 rounded-md bg-primary-yellow"></div>
      </div> */}
    </PageBanner>
  );
};

export default Banner;
