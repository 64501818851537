import { TSvgComponent } from "../../../types/utils/components";

const SvgInfinity: TSvgComponent = ({ ...props }) => {
  return (
    <svg
      viewBox="0 0 30 13"
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M22.7072 13.0002H22.3099C22.2367 12.9852 22.1642 12.9618 22.0903 12.9575C21.0419 12.8985 20.0419 12.6356 19.1777 12.0464C18.4556 11.5545 17.8053 10.9575 17.1279 10.4003C16.8749 10.1921 16.6368 9.96533 16.3724 9.72937C16.9915 9.1814 17.5899 8.65119 18.1983 8.11174C18.7356 8.58865 19.258 9.05275 19.7811 9.51544C21.0938 10.6754 22.9574 10.8935 24.4826 10.0677C26.0085 9.24181 26.8451 7.59504 26.5679 5.8637C26.3227 4.33278 25.4449 3.25673 24.005 2.69952C22.5181 2.12454 21.091 2.33989 19.8891 3.38467C16.9012 5.98168 13.9481 8.61779 10.9901 11.2489C9.98793 12.1409 8.84365 12.708 7.51387 12.8992C7.26299 12.9355 7.01139 12.9668 6.7605 13.0002H6.3632C6.26156 12.9838 6.16064 12.9611 6.05829 12.9525C4.16704 12.8019 2.59987 12.0009 1.4364 10.5169C-0.608372 7.90919 -0.448457 4.50194 1.77827 2.05418C3.74202 -0.105028 7.24024 -0.628838 9.76192 0.837402C10.6311 1.34273 11.3156 2.06413 12.0725 2.70165C12.2793 2.87578 12.4769 3.06128 12.6972 3.25887C12.0754 3.80684 11.4769 4.3342 10.8714 4.86796C10.3469 4.40314 9.84579 3.95894 9.34401 3.51473C8.36178 2.64408 7.22745 2.28445 5.91615 2.46214C3.62546 2.77201 2.00073 5.12454 2.54088 7.36832C3.28431 10.4557 6.94031 11.5993 9.31913 9.48701C10.5551 8.38964 11.7832 7.28445 13.022 6.18921C14.6852 4.71871 16.3689 3.27166 18.0171 1.78481C19.5992 0.358369 21.4321 -0.245043 23.5274 0.0911335C25.9815 0.484879 27.6873 1.87934 28.6134 4.17855C28.8358 4.73008 28.9169 5.33847 29.0633 5.92127V7.05418C29.0434 7.14515 29.0178 7.23541 29.005 7.3271C28.6432 9.97955 26.7285 12.1267 24.1244 12.7706C23.6617 12.885 23.1798 12.9255 22.7072 13.0002Z" />
    </svg>
  );
};

export default SvgInfinity;
