import { TSvgComponent } from "../../../types/utils/components";

const SvgFilters: TSvgComponent = ({ ...props }) => {
  return (
    <svg
      viewBox="0 0 36 29"
      fill="#002B14"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M17.3307 28.0679C16.5702 27.805 16.2989 27.2687 16.301 26.4726C16.3199 20.9514 16.3104 15.4303 16.3104 9.90801V9.45027C13.5986 8.62068 12.3678 6.06382 12.8758 3.7374C13.3514 1.55867 15.33 -0.00938491 17.5852 4.22792e-05C19.8456 0.00946947 21.817 1.60266 22.2632 3.77406C22.7628 6.20208 21.4106 8.67305 18.8202 9.45027V9.90801C18.8202 15.4292 18.8118 20.9504 18.8296 26.4726C18.8317 27.2666 18.5614 27.805 17.801 28.0669H17.3307V28.0679ZM19.853 4.82571C19.8582 3.5499 18.8506 2.52967 17.5789 2.52234C16.3084 2.51501 15.2808 3.53209 15.2787 4.79953C15.2766 6.07953 16.2727 7.08929 17.5527 7.10395C18.8181 7.11757 19.8477 6.09838 19.853 4.82571Z" />
      <path d="M30.0362 28.068C29.2967 27.7422 29.0422 27.1776 29.0767 26.3794C29.127 25.2346 29.0893 24.0855 29.0893 23.0653C28.3959 22.6389 27.7129 22.3362 27.1745 21.8691C24.4186 19.4756 25.3006 15.0092 28.7478 13.8088C29.0202 13.7135 29.0956 13.5909 29.0956 13.3144C29.0862 9.38537 29.0893 5.45633 29.0914 1.52729C29.0914 1.33351 29.0903 1.13134 29.1385 0.945943C29.2904 0.365648 29.8529 -0.0229616 30.4353 0.0273167C31.0386 0.0796899 31.5257 0.557334 31.5906 1.1701C31.6053 1.31256 31.599 1.45711 31.599 1.60061C31.599 5.45109 31.6053 9.30262 31.5896 13.1531C31.5885 13.5312 31.6734 13.7041 32.0547 13.8434C33.9412 14.5337 35.1531 16.3437 35.1332 18.3621C35.1143 20.3398 33.8563 22.111 31.9635 22.7814C31.6828 22.8809 31.5844 22.994 31.5906 23.2957C31.6116 24.3526 31.5728 25.4116 31.6074 26.4674C31.6336 27.2593 31.3424 27.8009 30.5851 28.069H30.0362V28.068ZM32.6297 18.2857C32.6287 17.0162 31.6074 15.9991 30.3358 15.9991C29.0662 15.9991 28.0471 17.0245 28.0534 18.2941C28.0596 19.572 29.0683 20.5786 30.3463 20.5807C31.6116 20.5838 32.6318 19.5583 32.6308 18.2846L32.6297 18.2857Z" />
      <path d="M4.54914 28.068C3.79706 27.7946 3.51634 27.2604 3.52472 26.4643C3.553 23.9829 3.53205 21.5015 3.53938 19.02C3.53938 18.7665 3.48806 18.6408 3.21886 18.5455C1.25696 17.8531 0 16.0777 0 14.0425C0 12.0072 1.25591 10.2297 3.21781 9.53731C3.48492 9.44304 3.54148 9.32153 3.54043 9.0649C3.53205 6.5049 3.53205 3.94594 3.53729 1.38594C3.53938 0.41284 4.35536 -0.205164 5.21533 0.0996482C5.74011 0.286097 6.04283 0.748029 6.04387 1.38803C6.04597 3.92185 6.05016 6.45567 6.03759 8.98844C6.03654 9.30687 6.1151 9.45456 6.43877 9.56664C8.32839 10.2182 9.58116 12.024 9.58116 14.0404C9.58116 16.0578 8.33887 17.85 6.43981 18.5151C6.11929 18.6272 6.03445 18.7718 6.03654 19.0923C6.04911 21.5214 6.01978 23.9504 6.05749 26.3795C6.07006 27.1756 5.84171 27.7454 5.09801 28.068H4.54914ZM4.80367 16.327C6.0753 16.3301 7.09972 15.3109 7.10077 14.0425C7.10077 12.774 6.07844 11.7537 4.80682 11.7558C3.531 11.7579 2.51392 12.7698 2.51077 14.0393C2.50763 15.3057 3.52891 16.3238 4.80367 16.327Z" />
    </svg>
  );
};

export default SvgFilters;
