import CardMyBelonging from "../../../../components/common/cards/MyBelonging";
import LaravelResponse from "../../../../components/common/LaravelResponse";
import { TMyBelonging } from "../../../../types/offering";
import { useMyBelongings } from "./_hooks";

const Grid = () => {
  const { query } = useMyBelongings<TMyBelonging[]>();
  return (
    <section id="my-belongings-grid" className="container section-margin">
      <LaravelResponse<TMyBelonging[]>
        query={query}
        success={(data) =>
          data.length > 0 ? (
            <>
              <ul className="grid-offerings">
                {data.map((belonging) => {
                  return (
                    <li key={belonging.id}>
                      <CardMyBelonging data={belonging} />
                    </li>
                  );
                })}
              </ul>
            </>
          ) : (
            <p>No belongings yet</p>
          )
        }
      />
    </section>
  );
};

export default Grid;
