import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { TLaravelSuccess } from "../../../types/utils/laravel";
import { useAtom } from "jotai";
import { careersFiltersAtom } from "./atoms";

type TResponse = {
  contract_types: { label: string }[];
  location: { label: string }[];
  max_year_experience: number;
};

export type TCareer = {
  id: number;
  job_title: string;
  type_of_contract: string;
  location: string;
  minimum_years_of_experience: number;
  maximum_years_of_experience: number;
  number_of_position: number;
};

export const useCareersFilters = () => {
  const [careersFilters, setCareersFilters] = useAtom(careersFiltersAtom);

  const { data, isLoading } = useQuery({
    queryKey: ["careers", "filters"],
    queryFn: async () => {
      const response =
        await axios.get<TLaravelSuccess<TResponse>>("careers/filters");
      setCareersFilters(
        (prev) =>
          (prev = {
            ...prev,
            maximum_years: response.data.data.max_year_experience,
          }),
      );
      return response.data.data;
    },
  });
  return {
    careersFilters,
    setCareersFilters,
    allCareersFilters: data,
    isLoading,
  };
};

export const useCareers = () => {
  const [careersFilters] = useAtom(careersFiltersAtom);
  const { data, isLoading } = useQuery({
    queryKey: ["careers", careersFilters],
    queryFn: async () => {
      const response = await axios.get<TLaravelSuccess<TCareer[]>>("careers/", {
        params: careersFilters,
      });
      return response.data.data;
    },
  });
  return { careers: data, isLoading };
};
