import SvgCircledCheck from "../../svgs/icons/CircledCheck";

const ContactCard = () => {
  return (
    <div className="rounded-xl bg-light-green bg-opacity-25 px-5 py-10">
      <div className="mb-4 grid grid-cols-[20px_auto] gap-2">
        <SvgCircledCheck className="mt-1.5" />
        <p className="text-xl">
          Dedicated support to work with you on your setup and help you build
          the best plan for you.
        </p>
        <SvgCircledCheck className="mt-1.5" />
        <p className="text-xl">
          Entreprise freatures to sucurely manage user access and security.
        </p>
      </div>
    </div>
  );
};

export default ContactCard;
