import { useMutation, useQuery } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import {
  TLaravelError,
  TLaravelResponse,
} from "../../../../types/utils/laravel";
import { TListingType } from "../../../../types/listing";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  TResponseCreateListing,
  TResponseCreateListingDetails,
} from "../../../../types/utils/response";
import { FormEvent, useState } from "react";
import { toast } from "sonner";

export const useCreateListingType = () => {
  const { data, isLoading } = useQuery({
    queryKey: ["create-listing", "types"],
    queryFn: async () => {
      const res = await axios.get<TLaravelResponse<TListingType[]>>(
        "belongings/main-types",
      );
      return res.data;
    },
  });
  return { data, isLoading };
};

export const useCreateListingDetails = () => {
  const [searchParams] = useSearchParams();
  const [selectedOffering, setSelectedOffering] = useState(0);
  const navigate = useNavigate();
  const { data, isLoading } = useQuery({
    queryKey: ["create-listing", "details", searchParams.get("type_id")],
    queryFn: async () => {
      if (searchParams.get("type_id")) {
        const res = await axios.get<TLaravelResponse<TResponseCreateListing>>(
          `belongings/main-types/${searchParams.get("type_id")}/create`,
        );
        return res.data;
      }
      navigate("/user/offerings/create-listing/type");
      return null;
    },
  });
  const { mutate } = useMutation({
    mutationFn: async (form: FormData) => {
      form.delete("offering_item_id");
      if (!searchParams.get("type_id") || !data?.success) return null;
      form.append(
        "offering_item_id",
        data.data.offering_items[selectedOffering].id.toString(),
      );
      const res = await axios.post<
        TLaravelResponse<TResponseCreateListingDetails>
      >("belongings/create", form);
      return res.data;
    },
    onSuccess: (res) => {
      if (res?.success) {
        toast.success("Listing created successfully");
        navigate(
          "/user/offerings/create-listing/preview?offering_id=" + res.data.id,
        );
      }
    },
    onError: (err: AxiosError<TLaravelError>) =>
      toast.error(err.response?.data.message ?? "Something went wrong"),
  });
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    mutate(new FormData(e.currentTarget));
  };
  return {
    data,
    isLoading,
    selectedOffering,
    setSelectedOffering,
    category: searchParams.get("type_name"),
    handleSubmit,
  };
};

export const useCreateListingPreview = <T>() => {
  const [searchParams] = useSearchParams();
  const query = useQuery({
    queryKey: ["single-belonging", searchParams.get("offering_id")],
    queryFn: async () => {
      if (!searchParams.get("offering_id")) return null;
      const res = await axios.get<TLaravelResponse<T>>(
        "user-belongings/" + searchParams.get("offering_id"),
      );
      return res.data;
    },
  });
  return { query };
};
