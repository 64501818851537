import { useAtomValue } from "jotai";
import { offeringDetailsAtom } from "../../../../atoms";

export const DetailsTab = () => {
  const offering = useAtomValue(offeringDetailsAtom);
  return (
    <section
      id="offering-tab-details"
      className="container grid grid-cols-2 gap-10 text-primary-green section-margin md:grid-cols-3 xl:grid-cols-6"
    >
      {offering.details?.map(({ id, title, text }) => (
        <div
          key={`offering_detail_tab_${id}`}
          className="flex aspect-square flex-col items-center justify-center rounded-full bg-[#F0F2EE] bg-opacity-50 p-4 text-center"
        >
          <p className="text-lg uppercase sm:text-2xl">{title}</p>
          <p className="text-sm font-bold sm:text-xl">{text}</p>
        </div>
      ))}
    </section>
  );
};
export default DetailsTab;
