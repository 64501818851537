import ThreeNeedlesHeading from "../../../../components/common/ThreeNeedlesHeading";
import SvgArrowCurved from "../../../../components/svgs/arrows/ArrowCurved";
import { benefits } from "../data";

const BenefitsCards = () => {
  return (
    <div className="grid gap-4 sm:grid-cols-2 lg:grid-cols-4">
      {benefits.map(({ label, Icon, SmallIcon }, index) => (
        <article
          key={`farmers_benefits_${index}`}
          className="flex flex-col items-center gap-4 rounded-xl bg-[#f5f7f3] p-12 text-center shadow-lg"
        >
          <div>
            <div className="relative mb-6 grid place-items-center rounded-full bg-[#a7bb93] bg-opacity-75 p-4 outline outline-2 outline-offset-4 outline-[#a7bb93]">
              <Icon className="size-10" />
              <div className="absolute bottom-0 right-0 translate-x-1/4 translate-y-1/4 rounded-full bg-gradient-to-r from-primary-green to-dark-green p-1.5">
                <SmallIcon className="size-4" />
              </div>
            </div>
            <span className="text-2xl font-bold text-primary-yellow">
              {index + 1}
            </span>
          </div>
          <h3 className="my-auto font-extrabold text-primary-green">{label}</h3>
        </article>
      ))}
    </div>
  );
};

const Benefits = () => {
  return (
    <section
      id="farmers-benefits"
      className="overflow-x-hidden bg-[url('/public/assets/images/backgrounds/giant-blob-yellow.png')] bg-cover bg-left-bottom bg-no-repeat text-white section-padding"
    >
      <div className="container grid 2xl:grid-cols-[4fr_3fr]">
        <div className="mb-6 flex justify-end text-section-heading">
          <SvgArrowCurved className="-mr-2 mt-2 w-20 -scale-x-100 fill-dark-yellow" />
          <ThreeNeedlesHeading
            className="text-white"
            backgroundColor="primary-yellow"
          >
            Benefits
          </ThreeNeedlesHeading>
        </div>
        <BenefitsCards />
      </div>
    </section>
  );
};

export default Benefits;
