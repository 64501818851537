import { TSvgComponent } from "../../../types/utils/components";

const SvgArrow: TSvgComponent = ({ ...props }) => {
  return (
    <svg
      viewBox="0 0 20 36"
      fill="#547C2C"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M19.2601 2.10465C19.073 2.67341 18.6696 3.08045 18.2589 3.49113C13.696 8.05398 9.13865 12.6205 4.57762 17.1851C4.48313 17.2796 4.35593 17.3414 4.24326 17.4195V17.5685C4.35411 17.6449 4.48131 17.7066 4.5758 17.8011C9.14592 22.3731 13.7106 26.9486 18.2807 31.5187C18.6841 31.9222 19.0766 32.3237 19.2583 32.8816V33.5649C19.0911 33.8647 18.973 34.2099 18.7441 34.4516C18.5187 34.6897 18.1808 34.8223 17.8918 35.0022H17.1413C16.6144 34.8405 16.2255 34.4843 15.8457 34.1027C10.7922 29.0311 5.7315 23.9667 0.672577 18.9005C-0.0470114 18.1791 -0.197834 17.3559 0.261903 16.5873C0.370932 16.4037 0.51812 16.2402 0.67076 16.0876C5.80782 10.9396 10.9594 5.80435 16.0801 0.638213C17.2685 -0.559285 18.9458 0.0676298 19.2165 1.3687C19.2202 1.38869 19.2438 1.40323 19.2583 1.4214V2.10465H19.2601Z" />
    </svg>
  );
};

export default SvgArrow;
