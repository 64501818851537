import Main from "../../../components/layout/Main";

const PagePayment = () => {
  return (
    <Main id="payment">
      <section id="payment-description" className="container section-margin">
        <p>Payment Method</p>
      </section>
    </Main>
  );
};

export default PagePayment;
