import { TSvgComponent } from "../../../types/utils/components";

const SvgCircledCheck: TSvgComponent = ({ ...props }) => {
  return (
    <svg
      viewBox="0 0 23 23"
      xmlns="http://www.w3.org/2000/svg"
      fill="#739750"
      {...props}
    >
      <path d="M16.5835 7.85098C16.9345 8.20193 16.9345 8.77082 16.5835 9.1216L10.5563 15.149C10.2053 15.4998 9.63662 15.4998 9.28567 15.149L6.41646 12.2796C6.06551 11.9289 6.06551 11.36 6.41646 11.0092C6.76723 10.6582 7.33613 10.6582 7.6869 11.0092L9.92089 13.2432L15.3129 7.85098C15.6639 7.5002 16.2328 7.5002 16.5835 7.85098ZM23 11.5C23 17.8566 17.8557 23 11.5 23C5.14338 23 0 17.8557 0 11.5C0 5.14338 5.14426 0 11.5 0C17.8566 0 23 5.14426 23 11.5ZM21.2031 11.5C21.2031 6.13657 16.8627 1.79688 11.5 1.79688C6.13657 1.79688 1.79688 6.13728 1.79688 11.5C1.79688 16.8634 6.13728 21.2031 11.5 21.2031C16.8634 21.2031 21.2031 16.8627 21.2031 11.5Z" />
    </svg>
  );
};

export default SvgCircledCheck;
