import { ReactNode } from "react";
import { TLaravelResponse } from "../../types/utils/laravel";
import { useQuery } from "@tanstack/react-query";
import { toast } from "sonner";

type TProps<T> = {
  success: (data: T) => ReactNode;
  query: Omit<ReturnType<typeof useQuery>, "data"> & {
    data: TLaravelResponse<T> | undefined | null;
  };
  loading?: ReactNode;
  error?: ReactNode;
};

function LaravelResponse<T>({ success, loading, error, query }: TProps<T>) {
  if (query) {
    if (query.isError) {
      toast.error("Something went wrong");
    }
    if (!query.data && typeof query.isLoading === "undefined") return null;
    if (typeof query.isLoading !== "undefined" && query.isLoading) {
      return loading ? loading : <p>Loading...</p>;
    }
    if (query.data && query.data.success) return success(query.data.data);
  }
  return error ? error : <p>Something went wrong</p>;
}

export default LaravelResponse;
