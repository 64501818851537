import ErrorDisplay from "../../components/section/ErrorDisplay";

export const PageNotFound = () => {
  return (
    <ErrorDisplay
      status={404}
      message="This page is not available."
      path="/"
      page="home"
    />
  );
};
export default PageNotFound;
