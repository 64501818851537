import CustomInput from "../../../../components/common/inputs/Input";

const ProjectFinancials = () => {
  return (
    <section id="fundraising-project-financials" className="section-margin container">
      <h3 className="mb-6 text-3xl font-bold text-primary-green">
        Project Financials
      </h3>
      <div className="grid gap-6">
        <CustomInput
          type="text"
          name="capital_trying_to_raise"
          label="How much capital are you trying to raise?"
        />
        <CustomInput
          type="text"
          name="funds_used_for"
          label="What will the funds be used for?"
        />
        <CustomInput
          type="text"
          name="expected_roi"
          label="What is your expected return on investment?"
        />
        <div>
          <CustomInput type="text" name="traction" label="Traction" />
          <p className="text-base text-primary-green">
            Please provide some examples of company traction: funds raised to
            date, media mentions (include links), grants or other non-dilutive
            funding, revenue/growth rate, patents, etc.
          </p>
        </div>
      </div>
    </section>
  );
};

export default ProjectFinancials;
