import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { wishlistNavigation } from "../../constants/navigation";
import { useEffect } from "react";
import Header from "../section/header";

const LayoutWishlist = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const path = pathname.split("/").at(-1);
  useEffect(() => {
    if (pathname === "/wishlist") navigate("/wishlist/user");
    //eslint-disable-next-line
  }, [pathname]);
  return (
    <>
      <Header loggedIn={path === "marketplace"} />
      <section id="wishlist-navigations">
        <h2 className="container mb-8 section-heading">My Wishlist</h2>
        <div className="bg-gray-200 pt-8">
          <nav className="container">
            <ul className="navigation-list">
              {wishlistNavigation.map(({ path, title }, index) => {
                return (
                  <li
                    key={`user_offering_${index}`}
                    className={`grid text-lg uppercase text-primary-green after:h-[3px] after:rounded-full after:bg-primary-green after:duration-200 ${pathname === path ? "font-extrabold after:w-full" : "after:w-0"}`}
                  >
                    <Link className="pb-2" title={title} to={path}>
                      {title}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </nav>
        </div>
      </section>
      <Outlet />
    </>
  );
};

export default LayoutWishlist;
