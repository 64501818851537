import SvgCheckedStraight from "../../../components/svgs/icons/CheckedStraight";
import SvgCommunication from "../../../components/svgs/icons/Communication";
import SvgDollarCard from "../../../components/svgs/icons/DollarCard";
import SvgDollarPlant from "../../../components/svgs/icons/DollarPlant";
import SvgGear from "../../../components/svgs/icons/Gear";
import SvgGraphArrow from "../../../components/svgs/icons/GraphArrow";
import SvgGraphBars from "../../../components/svgs/icons/GraphBars";
import SvgTechnologies from "../../../components/svgs/icons/Technologies";

export const howItWorks = [
  {
    title: "Application",
    text: "Apply for Funding by filling out the form <a href='/farmers/fundraising' class='hover-link'>here</a>",
  },
  {
    title: "Due Diligence",
    text: "If your project meets our requirements, we will request additional documents to learn more about it. A clear and transparent business plan coupled with organized and complete financial projections are the best starting point.",
  },
  {
    title: "Listing Agreement",
    text: "The agreement lays out the terms of the crowdfunding raise and what you can expect from the process as far as time, effort, and costs to you.",
  },
  {
    title: "Live on Platform",
    text: "After the project is set up and ready for investors, it goes live on the platform to accept investments from qualified investors. Aradina will work to help you maximize the chances of a successful raise and maintain compliance with Securities regulations.",
  },
  {
    title: "Close Raise & Receive Funds",
    text: "You will receive the funds after the raise has successfully closed. You will work with Harvest Returns over the course of the operation to keep investors updated, provide financial statements and tax documents, and make investor distributions.",
  },
];

export const benefits = [
  {
    label: "Expert Advice and Business Plans",
    Icon: SvgCommunication,
    SmallIcon: SvgGraphBars,
  },
  {
    label: "New Technology to Improve Production",
    Icon: SvgTechnologies,
    SmallIcon: SvgGraphArrow,
  },
  {
    label: "Higher Profits",
    Icon: SvgDollarCard,
    SmallIcon: SvgCheckedStraight,
  },
  {
    label: "Financial Support",
    Icon: SvgDollarPlant,
    SmallIcon: SvgGear,
  },
];
