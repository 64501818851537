import { Fragment } from "react";
import LoadingSpinner from "../../../../components/common/LoadingSpinner";
import ThreeNeedlesHeading from "../../../../components/common/ThreeNeedlesHeading";
import SvgArrowCurved from "../../../../components/svgs/arrows/ArrowCurved";
import useMedia from "../hooks";
import { TMediaResource } from "../types";

const ResourceCard = ({ resource }: { resource: TMediaResource }) => {
  return (
    <article className="flex flex-col justify-between gap-6 text-xl">
      <div>
        <h5 className="mb-0.5 font-bold">{resource?.title}</h5>
        <p>{resource?.description}</p>
      </div>
      <a
        className="font-bold text-primary-green underline underline-offset-4"
        href={resource?.file}
        download={resource?.file}
      >
        Download File
      </a>
    </article>
  );
};

const Resources = () => {
  const { resources, isLoading } = useMedia();
  return (
    <section id="media-resources" className="container section-margin">
      <div className="relative z-10 -ml-2 -mt-32 mb-10 h-32 w-1 rounded-lg bg-primary-green bg-opacity-80"></div>
      <div className="mb-8 flex text-section-heading">
        <ThreeNeedlesHeading
          backgroundColor="primary-green"
          needlesColor="black"
          className="text-white"
        >
          Resources
        </ThreeNeedlesHeading>
        <SvgArrowCurved className="z-10 -ml-2 mt-4 w-24" />
      </div>
      {isLoading ? (
        <div className="container text-black section-margin">
          <LoadingSpinner>Loading Resources</LoadingSpinner>
        </div>
      ) : (
        <div className="grid gap-y-20 lg:auto-cols-auto lg:grid-cols-[2fr_1fr_2fr_1fr_2fr]">
          {resources && resources.length > 0 ? (
            <>
              {resources.map((resource, index) => {
                return (
                  <Fragment key={`media_resources_${resource.id}`}>
                    <ResourceCard
                      resource={resource}
                      key={`media_resources_${resource.id}`}
                    />
                    {index !== resources.length - 1 && (
                      <div className="h-full w-1 justify-self-center rounded-xl bg-black max-lg:hidden"></div>
                    )}
                  </Fragment>
                );
              })}
            </>
          ) : (
            <p>No resources filled yet</p>
          )}
        </div>
      )}
    </section>
  );
};

export default Resources;
