import { Outlet, useLocation, useNavigate } from "react-router-dom";
import SvgAradinaIcon from "../svgs/logos/AradinaIcon";
import { useEffect } from "react";

const LayoutUserCreateListing = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const step = pathname.split("/").at(-1) as "type" | "details" | "preview";
  let sectionTitle = () => {
    switch (step) {
      case "details":
        return "Add details";
      case "preview":
        return "Preview";
      default:
        return "Choose listing type";
    }
  };
  useEffect(() => {
    if (!["type", "details", "preview"].includes(step))
      navigate("/user/offerings/create-listing/type");
    //eslint-disable-next-line
  }, [step]);
  return (
    <>
      <section id="create-list-steps" className="container section-margin">
        <h2 className="mb-6 capitalize text-tertiary-green section-heading">
          {sectionTitle()}
        </h2>
        <div className="flex text-lg text-primary-gray">
          <div className="grid size-16 place-items-center rounded-full border bg-tertiary-green p-2.5 text-white">
            {step === "type" ? (
              <span>1</span>
            ) : (
              <SvgAradinaIcon className="mb-1" />
            )}
          </div>
          <div className="h-8 flex-grow border-b-2 border-dashed"></div>
          <div
            className={`grid size-16 place-items-center rounded-full border p-2.5 ${step === "type" ? "bg-transparent" : "bg-tertiary-green text-white"}`}
          >
            {step === "preview" ? (
              <SvgAradinaIcon className="mb-1" />
            ) : (
              <span>2</span>
            )}
          </div>
          <div className="h-8 flex-grow border-b-2 border-dashed"></div>
          <div
            className={`grid size-16 place-items-center rounded-full border p-2.5 ${step === "preview" ? "bg-tertiary-green text-white" : "bg-transparent text-primary-gray"}`}
          >
            <span>3</span>
          </div>
        </div>
      </section>
      <Outlet />
    </>
  );
};

export default LayoutUserCreateListing;
