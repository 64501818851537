import Main from "../../../../components/layout/Main";
import Belongings from "./Belongings";
import Quantity from "./Quantity";

const PageSellToAradina = () => {
  return (
    <Main id="sell-to-aradina">
      <Belongings />
      <Quantity />
    </Main>
  );
};

export default PageSellToAradina;
