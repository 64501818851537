import { Link } from "react-router-dom";
import ButtonPrimary from "../../../../../components/common/buttons/Button";
import CustomInput from "../../../../../components/common/inputs/Input";
import { useChangePassword } from "../_hooks";

const ChangePassword = () => {
  const { isPending, errors, register, onSubmit } = useChangePassword();
  return (
    <section id="account-password" className="container section-margin">
      <h2 className="mb-4 text-3xl font-bold uppercase text-dark-green">
        Change Password
      </h2>
      <form
        onSubmit={onSubmit}
        className="mb-4 grid gap-x-6 gap-y-3 sm:grid-cols-2 md:grid-cols-3"
      >
        <CustomInput
          type="password"
          label="Current Password"
          autoComplete="current-password"
          required
          {...register("current_password")}
          error={errors.current_password?.message}
        />
        <CustomInput
          type="password"
          label="Password"
          autoComplete="new-password"
          required
          {...register("password")}
          error={errors.password?.message}
        />
        <CustomInput
          type="password"
          label="Confirm Password"
          autoComplete="new-password"
          required
          {...register("password_confirmation")}
          error={errors.password_confirmation?.message}
        />
        <Link to="#" className="text-primary-green underline">
          Forgot your password?
        </Link>
        <ButtonPrimary
          isLoading={isPending}
          className="h-max w-max self-end justify-self-end px-12 sm:col-start-2 md:col-start-3"
          type="submit"
          title="Save"
        >
          Save
        </ButtonPrimary>
      </form>
    </section>
  );
};

export default ChangePassword;
