import { TSvgComponent } from "../../../types/utils/components";

const SvgLocation: TSvgComponent = ({ ...props }) => {
  return (
    <svg
      viewBox="0 0 20 28"
      fill="#44632"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.0316 27.2904C9.83397 27.099 9.59933 26.933 9.44427 26.7122C7.89439 24.5001 6.33216 22.295 4.82413 20.0541C3.57681 18.2008 2.48867 16.2542 1.73946 14.1361C1.27223 12.8137 0.978581 11.459 1.00122 10.0454C1.06503 6.07535 3.78127 2.50384 7.59525 1.38445C12.6476 -0.0984813 17.8468 2.83994 19.1751 7.93896C19.2972 8.40812 19.3453 8.89648 19.429 9.37524C19.4406 9.44109 19.4585 9.50625 19.4736 9.5721V10.9076C19.4592 10.9652 19.4365 11.0221 19.431 11.0804C19.3384 12.1429 19.0763 13.1662 18.7202 14.1677C17.9745 16.2672 16.8959 18.198 15.6596 20.0363C14.1481 22.2833 12.5825 24.4946 11.0291 26.7129C10.8741 26.9344 10.6394 27.099 10.4418 27.2904H10.0316ZM10.2381 25.1305C10.2765 25.0852 10.3053 25.0557 10.3286 25.0228C11.6206 23.1715 12.9337 21.3347 14.1968 19.4642C15.414 17.6623 16.5 15.783 17.2458 13.7273C17.7707 12.2807 18.0787 10.7985 17.8715 9.24972C17.3179 5.11303 13.6582 2.20136 9.4923 2.57861C6.3891 2.85846 3.69894 5.10068 2.84132 8.10014C2.33704 9.86292 2.5168 11.5859 3.07939 13.2952C3.68796 15.1437 4.57851 16.8592 5.64675 18.4717C7.11019 20.681 8.64429 22.8437 10.1489 25.0255C10.1715 25.0585 10.201 25.0873 10.2381 25.1305Z"
        stroke="#446325"
        strokeWidth="0.3"
      />
      <path
        d="M14.8567 10.2469C14.8539 12.7922 12.7765 14.8644 10.2304 14.8616C7.68703 14.8589 5.6164 12.7792 5.61914 10.2318C5.62189 7.68637 7.69937 5.61493 10.2455 5.61768C12.7895 5.62042 14.8594 7.69871 14.8567 10.2469ZM10.2352 7.15822C8.54189 7.15959 7.15599 8.55129 7.15942 10.2489C7.16285 11.9314 8.54807 13.3176 10.229 13.3204C11.925 13.3238 13.3164 11.9369 13.3178 10.2421C13.3192 8.54581 11.9312 7.15685 10.2352 7.15822Z"
        stroke="#446325"
        strokeWidth="0.3"
      />
    </svg>
  );
};

export default SvgLocation;
