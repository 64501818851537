import { useAtomValue } from "jotai";
import { offeringDetailsAtom } from "../../../../atoms";
import { Link } from "react-router-dom";
import { TOfferingUpdates } from "../../../../../../types/offering";

const TextUpdate = ({ update }: { update: TOfferingUpdates }) => {
  return (
    <article className="grid gap-y-6 xl:grid-cols-[2fr_1fr]">
      <div>
        <h3 className="text-3xl font-extrabold text-dark-green">
          {update.sub_title}
        </h3>
        <div
          className="[&_span]:!whitespace-normal"
          dangerouslySetInnerHTML={{
            __html: update.content as string,
          }}
        ></div>
      </div>
      <div className="relative aspect-square origin-left rounded-full max-xl:row-start-1 max-xl:max-w-xs max-xl:justify-self-end xl:scale-110">
        <div className="absolute inset-0 z-0 -translate-x-4 -translate-y-2 rounded-full bg-gradient-to-b from-[rgb(88,131,44,0.5)] to-transparent"></div>
        <img
          className="relative z-10 aspect-square rounded-full"
          src={update.gallery}
          alt={update.title}
        />
      </div>
    </article>
  );
};

const WebinarUpdate = ({ update }: { update: TOfferingUpdates }) => {
  return (
    <article className="relative grid gap-x-10 rounded-md bg-[#E7F1E1] text-primary-green lg:grid-cols-[5fr_2fr]">
      <div className="flex flex-col justify-between gap-y-4 p-10 lg:p-20">
        <div
          dangerouslySetInnerHTML={{
            __html: update.content as string,
          }}
        ></div>
        <Link
          to={update.url as string}
          target="_blank"
          rel="noopener nofollow noreferrer"
          className="text-2xl font-extrabold uppercase underline underline-offset-4"
        >
          Access the webinar here
        </Link>
      </div>
      <img
        className="aspect-video h-full rounded-t-md object-cover object-center max-lg:row-start-1 lg:aspect-square lg:-translate-x-10 lg:translate-y-10 lg:rounded-full"
        src={update.gallery}
        alt={update.title}
      />
      <div className="absolute right-0 top-0 max-w-[175px] -translate-y-1/3 translate-x-1/3 place-items-center single-grid max-sm:hidden">
        <img
          className="single-grid-item"
          src="/assets/images/pages/single-offering/update-webinar-circle.png"
          alt="Webinar Background"
        />
        <h3 className="text-2xl font-extrabold text-white single-grid-item">
          Webinar
        </h3>
      </div>
    </article>
  );
};

const VideoUpdate = ({ update }: { update: TOfferingUpdates }) => {
  return (
    <article>
      <iframe
        className="aspect-video"
        src={`https://www.youtube.com/embed/${update.content as string}`}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title={`${update.title} embedded`}
      />
      <div className="rounded-b-lg bg-[#F9FFF2] p-3 font-bold text-primary-green">
        <h6>{update.sub_title}</h6>
      </div>
    </article>
  );
};

const PicturesUpdate = ({ update }: { update: TOfferingUpdates }) => {
  return (
    <ul className="grid grid-cols-2 gap-10 text-primary-green md:grid-cols-3">
      {(
        update.content as {
          title: string;
          picture: string;
          description: string;
        }[]
      ).map((item, index) => {
        return (
          <li
            className="flex flex-col"
            key={`offering_updates_${update.id}_picture_${index}`}
          >
            <img
              className="mb-4 aspect-square rounded-xl object-cover"
              src={item.picture}
              alt={item.title}
            />
            <h4 className="text-3xl font-extrabold">{item.title}</h4>
            <p className="text-base">{item.description}</p>
          </li>
        );
      })}
    </ul>
  );
};

const UpdatesTab = () => {
  const offering = useAtomValue(offeringDetailsAtom);
  return (
    <section
      id="offering-updates-tab"
      className="overflow-x-clip bg-[url(/public/assets/images/backgrounds/background-giant-blob-green.png)] bg-repeat-y xl:single-grid"
    >
      <ul className="container section-padding xl:single-grid-item">
        {offering.updates.map((update) => {
          return (
            <li key={`offering_update_${update.id}`} className="max-xl:mb-14">
              <div className="grid-cols-[1fr_8fr] gap-14 xl:grid">
                <div className="mb-4 items-center xl:grid">
                  <div className="flex-col justify-center rounded-full text-primary-green xl:flex xl:aspect-square xl:bg-primary-green xl:p-3 xl:text-center xl:text-white">
                    <h6 className="mb-1 max-xl:text-xl">{update.date}</h6>
                    <h4 className="text-xl font-extrabold leading-none max-xl:text-2xl">
                      {update.title}
                    </h4>
                  </div>
                </div>
                <div className="xl:my-20">
                  {update.type === "text" ? (
                    <TextUpdate update={update} />
                  ) : update.type === "webinar" ? (
                    <WebinarUpdate update={update} />
                  ) : update.type === "video" ? (
                    <VideoUpdate update={update} />
                  ) : (
                    <PicturesUpdate update={update} />
                  )}
                </div>
              </div>
            </li>
          );
        })}
      </ul>
      <div className="container -z-10 grid grid-cols-[1fr_8fr] gap-10 single-grid-item">
        <div className="-translate-x-1/2 border-r-2 border-dashed border-primary-green"></div>
      </div>
    </section>
  );
};

export default UpdatesTab;
