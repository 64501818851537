import { useOfferingDetails } from "../hooks";
import LoadingPage from "../../LoadingPage";
import Banner from "./components/Banner";
import Tabs from "./components/TabsSection";
import OfferingSummary from "./components/OfferingSummary";
import OwnerShipAndManagement from "./components/OwnerShipAndManagement";
import Gallery from "./components/Gallery";
import InvestmentTimeline from "./components/InvestmentTimeline";
import KeyValueDrivers from "./components/KeyValueDrivers";
import EstimatedNetReturns from "./components/EstimatedNetReturns";
import PropertyAndOrchard from "./components/PropertyAndOrchard";
import FarmOperator from "./components/FarmOperator";
import Main from "../../../components/layout/Main";
import Quantity from "./components/Quantity";
import Insurance from "./components/Insurance";
import Cart from "./components/Cart";

export const PageOfferingDetails = () => {
  const { isLoading } = useOfferingDetails();
  if (isLoading) {
    return <LoadingPage />;
  }
  return (
    <Main id="offering-details">
      <Banner />
      <Tabs />
      <Quantity />
      <Insurance />
      <Cart />
      <OfferingSummary />
      <OwnerShipAndManagement />
      <Gallery />
      <InvestmentTimeline />
      <KeyValueDrivers />
      <EstimatedNetReturns />
      <PropertyAndOrchard />
      <FarmOperator />
    </Main>
  );
};
export default PageOfferingDetails;
