import { Link } from "react-router-dom";
import { socialPlatformData } from "../../../constants/social";

const ContactSocials = () => {
  return (
    <div>
      <div className="flex flex-col gap-4">
        <div>
          <h5 className="font-bold leading-none text-primary-green">Email</h5>
          <p>info@aradina.com</p>
        </div>
        <div className="h-px w-full rounded-lg bg-primary-gray bg-opacity-50"></div>
        <div>
          <h5 className="font-bold leading-none text-primary-green">Phone</h5>
          <p>+961 3 746 763</p>
        </div>
        <div className="h-px w-full rounded-lg bg-primary-gray bg-opacity-50"></div>
        <div>
          <h5 className="font-bold leading-none text-primary-green">Socials</h5>
          <div className="mb-4 mt-2 flex gap-1">
            {socialPlatformData.map(({ id, url, Icon }) => {
              return (
                <Link
                  key={id}
                  target="_blank"
                  to={url}
                  rel="noopener noreferrer"
                  className="grid place-items-center rounded-full bg-primary-gray p-2 hover-link"
                >
                  <Icon className="size-4" />
                </Link>
              );
            })}
          </div>
        </div>
      </div>
      <div className="h-px w-full rounded-lg bg-primary-gray bg-opacity-50"></div>
    </div>
  );
};

export default ContactSocials;
