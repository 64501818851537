import { TSvgComponent } from "../../../types/utils/components";

const SvgArrowCurved: TSvgComponent = ({ ...props }) => {
  return (
    <svg
      viewBox="0 0 81 31"
      fill="#22310E"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M1.74984 6.35014C27.3667 -4.34342 56.772 2.64968 75.3858 22.7651C76.2006 21.9759 77.017 21.1797 77.836 20.3879C78.1868 20.0495 78.6156 19.9779 78.9878 20.0904C79.1654 19.5508 80.1032 19.6967 80.1091 20.3121C80.1303 22.2871 80.1377 24.2588 80.1073 26.2364C80.0958 27.1364 80.3669 28.6873 79.6832 29.4779C79.034 30.229 77.7344 30.0534 76.8648 30.0732C74.7292 30.1226 72.5866 30.1703 70.4483 30.2154C69.5579 30.2304 68.9361 29.0654 69.6137 28.3977C70.9174 27.1123 72.2238 25.8312 73.5329 24.5544C55.7433 4.76316 27.1004 -2.09937 2.15469 7.69836C1.31309 8.02807 0.930132 6.69594 1.74984 6.35014Z" />
    </svg>
  );
};

export default SvgArrowCurved;
