import { TSvgComponent } from "../../../types/utils/components";

const SvgHeart: TSvgComponent = (props) => {
  return (
    <svg
      viewBox="0 0 39 35"
      fill="none"
      stroke="white"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20.7474 32.9814C20.147 33.1933 19.1582 33.1933 18.5578 32.9814C13.4368 31.2332 1.99414 23.9403 1.99414 11.5793C1.99414 6.12287 6.3911 1.70825 11.8122 1.70825C15.0261 1.70825 17.8691 3.2622 19.6526 5.66375C20.5599 4.43803 21.7416 3.44183 23.1031 2.75495C24.4646 2.06806 25.968 1.70959 27.493 1.70825C32.9141 1.70825 37.3111 6.12287 37.3111 11.5793C37.3111 23.9403 25.8684 31.2332 20.7474 32.9814Z"
        strokeWidth="2.64877"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SvgHeart;
