import { useCreateListingDetails } from "./_hooks";
import CustomDropdown from "../../../../components/common/inputs/Dropdown";
import { TLabelOption } from "../../../../types/utils/components";
import ButtonPrimary from "../../../../components/common/buttons/Button";
import CustomInput from "../../../../components/common/inputs/Input";
import CustomTextArea from "../../../../components/common/inputs/TextArea";
import SvgGoldCoins from "../../../../components/svgs/icons/GoldCoins";
import { uselessAutompleteWarning } from "../../../../utils/functions";

const PageCreateListingDetails = () => {
  const {
    data,
    isLoading,
    selectedOffering,
    setSelectedOffering,
    category,
    handleSubmit,
  } = useCreateListingDetails();
  return (
    <section id="create-listing-details" className="container section-margin">
      {!isLoading ? (
        data?.success ? (
          <>
            <img
              src={
                data.data.offering_items[selectedOffering].purchased_offering
                  .offering.thumbnail
              }
              alt={
                data.data.offering_items[selectedOffering].purchased_offering
                  .offering.title
              }
              className="mb-6 h-60 w-full object-cover"
              height={240}
              width="100%"
            />
            <form onSubmit={handleSubmit} className="grid grid-cols-3 gap-4">
              <CustomDropdown
                labelClass="col-span-2"
                className="rounded-xl bg-gray-100"
                options={data.data.offering_items.map((item) => ({
                  id: item.id,
                  label: item.title,
                }))}
                value={{
                  id: data.data.offering_items[selectedOffering].id,
                  label: data.data.offering_items[selectedOffering].title,
                }}
                onChange={(_, value) =>
                  setSelectedOffering(
                    data.data.offering_items.findIndex(
                      (item) => item.id === (value as TLabelOption).id,
                    ),
                  )
                }
                isOptionEqualToValue={uselessAutompleteWarning}
                disableClearable
                name="offering_item_id"
                title="Title"
              />
              <CustomInput
                required
                type="number"
                name="quantity"
                title="Quantity"
                className="bg-gray-100 text-primary-green placeholder:text-primary-green"
                placeholder="Quantity"
              />
              <CustomDropdown
                className="rounded-xl bg-gray-100"
                options={data.data.conditions.map((item) => ({
                  id: item,
                  label: item,
                }))}
                defaultValue={{
                  id: data.data.conditions[0],
                  label: data.data.conditions[0],
                }}
                isOptionEqualToValue={uselessAutompleteWarning}
                disableClearable
                name="condition"
                title="Condition"
              />
              <CustomInput
                required
                type="number"
                name="price"
                title="Price"
                className="bg-gray-100 text-primary-green placeholder:text-primary-green"
                placeholder="Price"
              />
              <CustomInput
                readOnly
                value={new Date(
                  data.data.offering_items[selectedOffering].harvest_date,
                ).toLocaleDateString()}
                type="text"
                id="harvest-date-input"
                title="Harvest Date"
                className="bg-gray-100 text-primary-green placeholder:text-primary-green"
                placeholder="Quantity"
              />
              <article className="col-span-2 rounded-xl bg-primary-green/50 p-4 text-white">
                <div className="mb-1 flex items-center gap-2 text-xl capitalize">
                  <SvgGoldCoins className="size-8" />
                  <h3>Recommended price</h3>
                </div>
                <p className="mb-4">
                  The recommended price is for you to get an idea about the
                  Aradina vendors rates
                </p>
                <h4 className="mb-1 text-lg">
                  Price
                  {data.data.offering_items[selectedOffering].purchased_offering
                    .offering.unit &&
                    ` per ${
                      data.data.offering_items[selectedOffering]
                        .purchased_offering.offering.unit.unit
                    }`}
                </h4>
                <div className="flex justify-between gap-2 capitalize">
                  <p>Category: {category}</p>
                  {data.data.offering_items[selectedOffering].recommended_price
                    .min_price ? (
                    <p>
                      Min price: $
                      {
                        data.data.offering_items[selectedOffering]
                          .recommended_price.min_price
                      }
                    </p>
                  ) : null}
                </div>
                <div className="flex justify-between gap-2 capitalize">
                  <p>
                    Item:&nbsp;
                    {
                      data.data.offering_items[selectedOffering]
                        .purchased_offering.offering.title
                    }
                  </p>
                  {data.data.offering_items[selectedOffering].recommended_price
                    .min_price ? (
                    <p>
                      Max price: $
                      {
                        data.data.offering_items[selectedOffering]
                          .recommended_price.max_price
                      }
                    </p>
                  ) : null}
                </div>
              </article>
              <CustomTextArea
                name="description"
                title="Description"
                className="h-full bg-gray-100 text-primary-green placeholder:text-primary-green"
                placeholder="Description"
              />
              <ButtonPrimary
                type="submit"
                title="Submit"
                className="col-start-3 ml-auto w-fit"
              >
                Next
              </ButtonPrimary>
            </form>
          </>
        ) : (
          <p>Something went wrong</p>
        )
      ) : (
        <p>Loading</p>
      )}
    </section>
  );
};

export default PageCreateListingDetails;
