import { atomWithStorage } from "jotai/utils";
import { TResponseUser } from "../../types/user";

const userAtom = atomWithStorage<TResponseUser | null>(
  "user",
  null,
  undefined,
  {
    getOnInit: true,
  },
);

export default userAtom;
