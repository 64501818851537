import { TSvgComponent } from "../../../types/utils/components";

const SvgWallet: TSvgComponent = (props) => {
  return (
    <svg
      viewBox="0 0 26 23"
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M23.3455 4.9032H3.74649C3.48659 4.9032 3.23734 4.79996 3.05356 4.61618C2.86979 4.4324 2.76654 4.18315 2.76654 3.92325C2.76654 3.66335 2.86979 3.4141 3.05356 3.23032C3.23734 3.04654 3.48659 2.9433 3.74649 2.9433H20.4057C20.6656 2.9433 20.9148 2.84005 21.0986 2.65628C21.2824 2.4725 21.3856 2.22325 21.3856 1.96335C21.3856 1.70345 21.2824 1.4542 21.0986 1.27042C20.9148 1.08664 20.6656 0.983398 20.4057 0.983398H3.74649C2.9668 0.983398 2.21903 1.29313 1.6677 1.84446C1.11637 2.39579 0.806641 3.14355 0.806641 3.92325V19.6025C0.806641 20.3822 1.11637 21.1299 1.6677 21.6812C2.21903 22.2326 2.9668 22.5423 3.74649 22.5423H23.3455C23.8653 22.5423 24.3638 22.3358 24.7314 21.9683C25.0989 21.6007 25.3054 21.1022 25.3054 20.5824V6.8631C25.3054 6.3433 25.0989 5.8448 24.7314 5.47724C24.3638 5.10969 23.8653 4.9032 23.3455 4.9032ZM18.9357 14.7027C18.645 14.7027 18.3608 14.6165 18.1191 14.455C17.8774 14.2935 17.6889 14.0639 17.5777 13.7953C17.4664 13.5267 17.4373 13.2312 17.494 12.946C17.5508 12.6609 17.6908 12.399 17.8963 12.1934C18.1019 11.9878 18.3638 11.8478 18.649 11.7911C18.9341 11.7344 19.2296 11.7635 19.4982 11.8747C19.7668 11.986 19.9964 12.1744 20.1579 12.4161C20.3194 12.6579 20.4057 12.9421 20.4057 13.2328C20.4057 13.6226 20.2508 13.9965 19.9751 14.2722C19.6995 14.5478 19.3256 14.7027 18.9357 14.7027Z" />
    </svg>
  );
};

export default SvgWallet;
