import SvgFacebook from "../components/svgs/social/Facebook";
import SvgInstagram from "../components/svgs/social/Instagram";
import SvgTikTok from "../components/svgs/social/TikTok";
import SvgTwitter from "../components/svgs/social/Twitter";

export const socialPlatformData = [
  {
    id: "social_facebook",
    platform: "Facebook",
    url: "https://www.facebook.com",
    Icon: SvgFacebook,
  },
  {
    id: "social_instagram",
    platform: "Instagram",
    url: "https://www.instagram.com",
    Icon: SvgInstagram,
  },
  {
    id: "social_twitter",
    platform: "Twitter",
    url: "https://www.twitter.com",
    Icon: SvgTwitter,
  },
  {
    id: "social_tiktok",
    platform: "TikTok",
    url: "https://www.tiktok.com",
    Icon: SvgTikTok,
  },
];
