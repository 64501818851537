import { TSvgComponent } from "../../../types/utils/components";

const SvgCheckedStraight: TSvgComponent = (props) => {
  return (
    <svg viewBox="0 0 23 17" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M1.96094 8.74375L8.23349 15.0163L20.7786 2.47119"
        stroke="white"
        strokeWidth="3.76353"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SvgCheckedStraight;
