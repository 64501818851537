import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { RequiresAuthentication } from "./pages/errorHandling/ProtectedRoutes";
import Unauthorized from "./pages/errorHandling/401";
import PageNotFound from "./pages/errorHandling/404";
import PageLogin from "./pages/authentication/login";
import PageSignUp from "./pages/authentication/signup";
import PageInvestors from "./pages/investors";
import PageOfferingDetails from "./pages/offering/details";
// import PageOfferingInvestment from "./pages/offering/invest";
import PageTeam from "./pages/about/team";
import PageOverview from "./pages/about/overview";
import PagePartners from "./pages/about/partners";
import PageMedia from "./pages/about/media";
import PageCareers from "./pages/about/careers";
import PageContact from "./pages/about/contact";
import PageFAQs from "./pages/about/faqs";
import PageFarmers from "./pages/farmers/empowerment";
import PageFundRaising from "./pages/farmers/fundraising";
import PageHowInvestmentWorks from "./pages/farmers/investment";
import PagePrivacyPolicy from "./pages/resources/PrivacyPolicy";
import LayoutUserOfferings from "./components/layout/UserOfferings";
import PageVerifyEmail from "./pages/authentication/verifyEmail";
import LayoutPage from "./components/layout/Page";
import LayoutFooter from "./components/layout/Footer";
import LayoutUser from "./components/layout/User";
import PageUserOfferingsTodaysPicks from "./pages/user/offerings/todaysPicks/_index";
import PageUserOfferingsBelongings from "./pages/user/offerings/belongings/_index";
import PageUserOfferingsMyBelongings from "./pages/user/offerings/myBelongings/_index";
import PageUserOfferingsSell from "./pages/user/offerings/sell/_index";
import LayoutWishlist from "./components/layout/Wishlist";
import PageWishlistUser from "./pages/wishlist/user/_index";
import PageWishlistMarketplace from "./pages/wishlist/marketplace/_index";
import LayoutCart from "./components/layout/Cart";
import PageCartUser from "./pages/cart/user/_index";
import PageCartMarketplace from "./pages/cart/marketplace/_index";
import PageCreateListingType from "./pages/user/offerings/createListing/Type";
import LayoutUserCreateListing from "./components/layout/UserCreateListing";
import PageCreateListingDetails from "./pages/user/offerings/createListing/Details";
import PageCreatingListingPreview from "./pages/user/offerings/createListing/Preview";
import PageUserOfferingsMyBelongingSingle from "./pages/user/offerings/myBelongings/single/_index";
import PageUserOfferingsBelongingsSingle from "./pages/user/offerings/belongings/single/_index";
import LayoutResources from "./components/layout/Resources";
import PageSecuritySettings from "./pages/resources/SecuritySettings";
import LayoutUserAccount from "./components/layout/UserProfile";
import PageAccount from "./pages/user/account/account/_index";
import PageProfile from "./pages/user/account/Profile";
import PageUsers from "./pages/user/account/Users";
import PagePurchases from "./pages/user/account/Purchases";
import PagePayment from "./pages/user/account/Payment";
import PageSellToAradina from "./pages/user/offerings/sellAradina/_index";
import PageOffersListing from "./pages/user/offerings/offers/list/_index";
import PageBelongingOfferSingle from "./pages/user/offerings/offers/single/_index";
import PageHome from "./pages/home/_index";

export const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="401" element={<Unauthorized />} />
        <Route path="login" element={<PageLogin />} />
        <Route path="signup" element={<PageSignUp />} />
        <Route path="verify-email" element={<PageVerifyEmail />} />
        <Route element={<LayoutFooter />}>
          <Route element={<LayoutPage />}>
            <Route path="/" element={<PageHome />} />
            <Route path="about">
              <Route path="overview" element={<PageOverview />} />
              <Route path="team" element={<PageTeam />} />
              <Route path="partners" element={<PagePartners />} />
              <Route path="media" element={<PageMedia />} />
              <Route path="careers" element={<PageCareers />} />
              <Route path="contact" element={<PageContact />} />
              <Route path="faqs" element={<PageFAQs />} />
            </Route>
            <Route path="investors">
              <Route path="" element={<PageInvestors />} />
              <Route
                path="offering-details/:offeringId"
                element={<PageOfferingDetails />}
              />
              {/* <Route
                path="offering-investment/:offeringId"
                element={<PageOfferingInvestment />}
              /> */}
            </Route>
            <Route path="farmers">
              <Route path="empowerment" element={<PageFarmers />} />
              <Route path="fundraising" element={<PageFundRaising />} />
              <Route path="investment" element={<PageHowInvestmentWorks />} />
            </Route>
            <Route path="resources" element={<LayoutResources />}>
              <Route path="privacy-policy" element={<PagePrivacyPolicy />} />
              <Route
                path="security-settings"
                element={<PageSecuritySettings />}
              />
            </Route>
          </Route>
          {/* User Routes */}
          <Route element={<RequiresAuthentication />}>
            <Route path="wishlist" element={<LayoutWishlist />}>
              <Route path="user" element={<PageWishlistUser />} />
              <Route path="marketplace" element={<PageWishlistMarketplace />} />
            </Route>
            <Route path="cart" element={<LayoutCart />}>
              <Route path="user" element={<PageCartUser />} />
              <Route path="marketplace" element={<PageCartMarketplace />} />
            </Route>
            <Route element={<LayoutUser />}>
              <Route path="user">
                <Route element={<LayoutUserAccount />}>
                  <Route path="account" element={<PageAccount />} />
                  <Route path="profile" element={<PageProfile />} />
                  <Route path="users" element={<PageUsers />} />
                  <Route path="payment" element={<PagePayment />} />
                  <Route path="purchases" element={<PagePurchases />} />
                </Route>
                <Route path="offerings" element={<LayoutUserOfferings />}>
                  <Route
                    path="todays-picks"
                    element={<PageUserOfferingsTodaysPicks />}
                  />
                  <Route path="belongings">
                    <Route path="" element={<PageUserOfferingsBelongings />} />
                    <Route
                      path=":belongingId"
                      element={<PageUserOfferingsBelongingsSingle />}
                    />
                    <Route
                      path="offer/:belongingId"
                      element={<PageBelongingOfferSingle />}
                    />
                  </Route>
                  <Route path="my-belongings">
                    <Route
                      path=""
                      element={<PageUserOfferingsMyBelongings />}
                    />
                    <Route
                      path=":belongingId"
                      element={<PageUserOfferingsMyBelongingSingle />}
                    />
                  </Route>
                  <Route
                    path="sell-to-aradina"
                    element={<PageSellToAradina />}
                  />
                  <Route path="sell" element={<PageUserOfferingsSell />} />
                  <Route path="offers" element={<PageOffersListing />} />
                  <Route
                    path="create-listing"
                    element={<LayoutUserCreateListing />}
                  >
                    <Route path="type" element={<PageCreateListingType />} />
                    <Route
                      path="details"
                      element={<PageCreateListingDetails />}
                    />
                    <Route
                      path="preview"
                      element={<PageCreatingListingPreview />}
                    />
                  </Route>
                </Route>
              </Route>
            </Route>
          </Route>
        </Route>
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
