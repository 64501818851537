import { Link } from "react-router-dom";
import ButtonPrimary from "../../../components/common/buttons/Button";
import IconButton from "../../../components/common/buttons/IconButton";
import useLogin from "./hooks";
import SvgGoogle from "../../../components/svgs/social/Google";
import CustomInput from "../../../components/common/inputs/Input";

const LoginForm = () => {
  const {
    togglePass,
    setTogglePass,
    errorData,
    googleLogin,
    handleLogin,
    isPending,
    validationErrors,
  } = useLogin();
  return (
    <form onSubmit={handleLogin} className="flex w-full flex-col items-center">
      <div className="flex w-full flex-col">
        <CustomInput
          label="Email*"
          type="email"
          name="email"
          className={validationErrors?.email && "border-red-600"}
        />
        <span className="mb-4 mt-1 text-red-600">
          {validationErrors?.email?._errors[0]}
        </span>
        <CustomInput
          label="Password*"
          name="password"
          type={togglePass ? "password" : "text"}
          className={validationErrors?.password && "border-red-600"}
        />
        <div className="flex justify-between">
          <span className="mt-1 text-red-600">
            {validationErrors?.password?._errors[0]}
          </span>
          <button
            className="mt-1 self-end"
            type="button"
            title={togglePass ? "Show Password" : "Hide Password"}
            onClick={() => setTogglePass((prev) => (prev = !togglePass))}
          >
            {togglePass ? "Show Password" : "Hide Password"}
          </button>
        </div>
        {isPending && <p>Logging in, hang in there...</p>}
        {!errorData?.errors && errorData?.message && (
          <p className="text-red-600">{errorData?.message}</p>
        )}
      </div>
      <div className="my-10 grid">
        <IconButton
          title="Google Login"
          type="button"
          onClick={() => googleLogin()}
          icon={<SvgGoogle className="w-6" />}
        >
          Log in with Google
        </IconButton>
        {/* <FacebookLogin
          appId={process.env.REACT_APP_FACEBOOK_CLIENT_ID ?? ""}
          render={(renderProps) => (
            <IconButton
              onClick={renderProps.onClick}
              title="Facebook Login"
              icon={<SvgFacebookModren className="w-6" />}
            >
              Log in with FaceBook
            </IconButton>
          )}
        /> */}
      </div>
      <ButtonPrimary title="Login" type="submit" className="mb-12">
        Log in
      </ButtonPrimary>
      <Link to="/login" className="mb-6 underline underline-offset-1">
        Forgot your password?
      </Link>
      <div className="inline text-gray-600">
        <p>
          Don't have an account?{" "}
          <Link
            to="/signup"
            className="text-primary-green underline underline-offset-1"
          >
            Sign up here
          </Link>
        </p>
      </div>
    </form>
  );
};

export default LoginForm;
