import AddressLocation from "../../../components/common/AddressLocation";
import Main from "../../../components/layout/Main";
import { useMyPurchases } from "./_hooks";

const PagePurchases = () => {
  const { Display, purchases } = useMyPurchases();
  return (
    <Main id="purchases">
      <section id="purchases-list" className="container section-margin">
        <Display
          success={() => {
            return purchases.length > 0 ? (
              <div className="grid gap-10">
                {purchases.map((item, index) => {
                  return (
                    <div key={`account_purchases_${index}`}>
                      <h3 className="mb-4 text-lg">{item.date}</h3>
                      <ul className="grid gap-4">
                        {item.purchases.map((purchase, i) => {
                          return (
                            <li
                              className="flex flex-wrap justify-between gap-4 rounded-md p-6 shadow-[0_0_15px_5px_#8884]"
                              key={`account_purchases_${index}_${i}`}
                            >
                              <div className="flex flex-wrap gap-4">
                                <img
                                  src={
                                    purchase.user_belonging.offering_item
                                      .purchased_offering.offering.thumbnail
                                  }
                                  alt={
                                    purchase.user_belonging.offering_item.title
                                  }
                                  title={
                                    purchase.user_belonging.offering_item.title
                                  }
                                  className="size-32 rounded-xl"
                                />
                                <div className="flex flex-col justify-between gap-2">
                                  <div>
                                    <h4 className="text-lg font-extrabold">
                                      {
                                        purchase.user_belonging.offering_item
                                          .title
                                      }
                                    </h4>
                                    <p className="text-base">
                                      {purchase.user_belonging.description}
                                    </p>
                                  </div>
                                  {purchase.address && (
                                    <AddressLocation
                                      city={
                                        purchase.address.address_details.city
                                      }
                                      country={
                                        purchase.address.address_details.country
                                      }
                                    />
                                  )}
                                </div>
                              </div>
                              {purchase.user_belonging.offering_item
                                .purchased_offering.offering.unit && (
                                <p className="ml-auto">
                                  ${purchase.user_belonging.price} |&nbsp;
                                  {
                                    purchase.user_belonging.offering_item
                                      .purchased_offering.offering.unit.unit
                                  }
                                </p>
                              )}
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  );
                })}
              </div>
            ) : (
              <p>No purchases yet</p>
            );
          }}
        />
      </section>
    </Main>
  );
};

export default PagePurchases;
