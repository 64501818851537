import AuthModal from "../../../components/section/AuthModal";
import VerifyEmailForm from "./VerifyEmailForm";
import { Link } from "react-router-dom";
import Main from "../../../components/layout/Main";
import { useEmailVerified } from "../../../utils/hooks";

const PageVerifyEmail = () => {
  const isVerified = useEmailVerified();
  return (
    <Main id="verify-email">
      <AuthModal
        id="verify-modal"
        background="/assets/images/pages/signup/background.jpg"
        title="Verify Email"
      >
        {isVerified ? (
          <div className="my-10 text-center text-2xl">
            <p>Email is already verified.</p>
            <p>
              Click&nbsp;
              <Link to="/" className="underline underline-offset-2">
                here
              </Link>
              &nbsp;to return home.
            </p>
          </div>
        ) : (
          <VerifyEmailForm />
        )}
      </AuthModal>
    </Main>
  );
};

export default PageVerifyEmail;
