import {
  DetailedHTMLProps,
  ForwardedRef,
  InputHTMLAttributes,
  LabelHTMLAttributes,
  forwardRef,
} from "react";
import { twMerge } from "tailwind-merge";

type TProps = DetailedHTMLProps<
  InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  label?: LabelHTMLAttributes<HTMLLabelElement>["children"] | boolean;
  labelClass?: LabelHTMLAttributes<HTMLLabelElement>["className"];
  containerClass?: DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  >["className"];
  error?: string | null;
  ref: ForwardedRef<HTMLInputElement>;
};

const CustomInput = forwardRef<HTMLInputElement, TProps>(
  ({ label = false, labelClass, containerClass, error, ...props }, ref) => {
    return (
      <div className={containerClass}>
        <label
          hidden={!label}
          htmlFor={
            props.id ? props.id : props.name ? `${props.name}-input` : undefined
          }
          className={twMerge(
            "block text-xl font-bold text-primary-green",
            labelClass,
          )}
        >
          {label === true ? props.title : label}
          {props.required && label && (
            <span className="text-red-600">&nbsp;*</span>
          )}
        </label>
        <input
          id={props.name ? `${props.name}-input` : undefined}
          {...props}
          ref={ref}
          className={twMerge(
            "w-full rounded-xl border bg-transparent px-4 py-2 text-lg outline-none",
            error ? "border-red-600" : "border-primary-green",
            props.className,
          )}
        />
        <div
          className={`grid duration-300 ${error ? "grid-rows-[1fr]" : "grid-rows-[0fr]"}`}
        >
          <p className="overflow-hidden text-red-500">{error}</p>
        </div>
      </div>
    );
  },
);

export default CustomInput;
