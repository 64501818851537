import { useAtom, useAtomValue } from "jotai";
import { atomCartMarketplace } from "./_atom";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { TLaravelResponse } from "../../../types/utils/laravel";
import { TBelongingCart } from "../../../types/offering";
import { FormEvent, useState } from "react";
import { toast } from "sonner";
import { useCountries } from "../../../utils/hooks";
import { toggleModal } from "../../../utils/functions";
import { useLaravelQuery } from "../../../utils/hooks/Laravel";
import { TUserAddress } from "../../../types/user";
import { TResponseCheckout } from "../../../types/utils/response";
import { loadStripe } from "@stripe/stripe-js";

export const useCartMarketplace = () => {
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY ?? "");
  const [cart, setCart] = useAtom(atomCartMarketplace);
  const { data, isLoading } = useQuery({
    queryKey: ["cart-marketplace"],
    queryFn: async () => {
      const res = await axios.get<TLaravelResponse<TBelongingCart[]>>(
        "marketplace-cart-items",
      );
      if (res.data.success) setCart(res.data.data);
      return res.data;
    },
  });
  let total = 0;
  if (data?.success)
    total = data.data.reduce((acc, item) => acc + +item.total_price, 0);
  const {
    mutate: handleCheckout,
    data: checkout,
    isPending,
  } = useMutation({
    mutationFn: async () => {
      const address_id = document.querySelector(
        "input[name=address_id]:checked",
      );
      if (!address_id) {
        toast.warning("Please select an address");
        return null;
      }
      const res = await axios.post<TLaravelResponse<TResponseCheckout>>(
        "marketplace-checkout-information",
        {
          address_id: (address_id as HTMLInputElement).value,
        },
      );
      return res.data;
    },
  });
  return {
    cart,
    isLoading,
    total,
    stripePromise,
    handleCheckout,
    isPending,
    checkout,
  };
};

export const useCartMarketplaceAddress = () => {
  const [selectedCountry, setSelectedCountry] = useState<string>("");
  const { Display, data: addresses } = useLaravelQuery<TUserAddress[]>({
    queryKey: ["cart-marketplace-addresses"],
    endPoint: "user-addresses",
  });
  const queryClient = useQueryClient();
  const [selectedAddress, setSelectedAddress] = useState<TUserAddress | null>(
    null,
  );
  const { mutate, isPending } = useMutation({
    mutationFn: async (form: FormData) => {
      const payload = {
        name: form.get("name"),
        address_details: {
          country: form.get("country"),
          state: form.get("state"),
          city: form.get("city"),
          street_address: form.get("street_address"),
          zip_code: form.get("zip_code"),
        },
        is_default: !!form.get("is_default"),
      };
      const res = await axios.post<TLaravelResponse<TUserAddress>>(
        selectedAddress !== null
          ? `update-user-addresses/${selectedAddress.id}`
          : "add-user-addresses",
        payload,
      );
      return res.data;
    },
    onSuccess: (s) => {
      if (s.success) {
        toast.success("Success");
        queryClient.invalidateQueries({
          queryKey: ["cart-marketplace-addresses"],
        });
        setSelectedAddress(null);
      }
    },
    onError: () => toast.error("Something went wrong..."),
    onSettled: () => toggleModal("cart-add-address", "close"),
  });
  const countries = useCountries();
  function handleSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    mutate(new FormData(e.currentTarget));
  }
  const handleEdit = (address: TUserAddress) => {
    setSelectedAddress(address);
    setSelectedCountry(address.address_details.country);
    toggleModal("cart-add-address", "open");
  };
  const handleSelectAddress = (id: number) => {
    setSelectedAddress(
      addresses?.success
        ? (addresses.data.find((item) => item.id === id) ?? null)
        : null,
    );
    setSelectedCountry(selectedAddress?.address_details.country ?? "");
  };
  const handleAddAddress = () => {
    console.log(selectedAddress);
    setSelectedAddress(null);
    setSelectedCountry("");
    toggleModal("cart-add-address", "open");
  };
  const handleSelectCountry = (country: string) => {
    setSelectedCountry(country);
    if (selectedAddress !== null)
      setSelectedAddress(
        (_prev) =>
          (_prev = {
            ...(_prev as TUserAddress),
            address_details: {
              ...(_prev as TUserAddress)?.address_details,
              country: selectedCountry,
            },
          }),
      );
  };
  return {
    handleEdit,
    countries,
    handleSubmit,
    isPending,
    Display,
    selectedAddress,
    handleSelectAddress,
    addresses,
    handleAddAddress,
    selectedCountry,
    handleSelectCountry,
  };
};

export const useCartMarketplaceItem = (index: number) => {
  const [edit, setEdit] = useState(false);
  const cart = useAtomValue(atomCartMarketplace);
  const [item, setItem] = useState(cart[index]);
  const handleDecrement = () => {
    const quantity = +item.quantity > 1 ? +item.quantity - 1 : 1;
    setItem(
      (_prev) =>
        (_prev = {
          ..._prev,
          quantity: quantity.toString(),
          total_price: (quantity * +item.user_belonging.price).toString(),
        }),
    );
  };
  const handleIncrement = () => {
    const quantity =
      +item.quantity < +item.user_belonging.quantity
        ? +item.quantity + 1
        : +item.user_belonging.quantity;
    setItem(
      (_prev) =>
        (_prev = {
          ..._prev,
          quantity: quantity.toString(),
          total_price: (+quantity * +item.user_belonging.price).toString(),
        }),
    );
  };
  const handleEdit = () => {
    if (!edit) {
      setEdit(true);
    } else {
      mutate();
    }
  };
  const queryClient = useQueryClient();
  const { mutate } = useMutation({
    mutationFn: async () => {
      const res = await axios.post(`marketplace-cart-items/${item.id}`, {
        quantity: item.quantity,
      });
      return res.data;
    },
    onSuccess: () => {
      setEdit(false);
      toast.success("Saved successfully");
      queryClient.invalidateQueries({ queryKey: ["cart-marketplace"] });
    },
    onError: () => {
      toast.error("Something went wrong...");
    },
  });

  const { mutate: removeFromCart } = useMutation({
    mutationFn: async () => {
      const res = await axios.delete<TLaravelResponse<"Deleted">>(
        `marketplace-cart-items/${item.id}`,
      );
      return res.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["cart-marketplace"] });
      toast.success("Successfully removed from cart");
    },
    onError: () => toast.error("Something went wrong..."),
  });
  return {
    item,
    removeFromCart,
    handleDecrement,
    handleIncrement,
    handleEdit,
    edit,
  };
};
