import { useQuery } from "@tanstack/react-query";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { useState } from "react";
import {
  offeringBelongingsFiltersAtom,
  offeringBelongingsMaxPriceAtom,
} from "./_atom";
import {
  TLaravelPagination,
  TLaravelResponse,
} from "../../../../types/utils/laravel";
import {
  TOfferingBelonging,
  TOfferingBelongingUser,
} from "../../../../types/offering";
import axios from "axios";
import { scrollToId } from "../../../../utils/functions";
import { useParams } from "react-router-dom";

export const useOfferingBelongingsFilters = () => {
  const [filters, setFilters] = useAtom(offeringBelongingsFiltersAtom);
  const maxPrice = useAtomValue(offeringBelongingsMaxPriceAtom);
  const [sliderValue, setSliderValue] = useState<number[]>([0, 0]);
  const options = [
    {
      type: "price",
      value: "lowest",
      label: "Price: Lowest to Highest",
    },
    {
      type: "price",
      value: "highest",
      label: "Price: Highest to Lowest",
    },
    {
      type: "date",
      value: "oldest",
      label: "Date: Oldest to Newest",
    },
    {
      type: "date",
      value: "newest",
      label: "Date: Newest to Oldest",
    },
  ];
  if (sliderValue[1] === 0 && maxPrice !== 0) setSliderValue([0, maxPrice]);
  return {
    filters,
    setFilters,
    options,
    sliderValue,
    setSliderValue,
    maxPrice,
  };
};

export const useOfferingBelongings = () => {
  const [filters, setFilters] = useAtom(offeringBelongingsFiltersAtom);
  const setMaxPrice = useSetAtom(offeringBelongingsMaxPriceAtom);
  const { minPrice, maxPrice, page, filter } = filters;
  const { data, isLoading } = useQuery({
    queryKey: ["offerings", filters],
    queryFn: async () => {
      const payload: Omit<typeof filters, "filter"> & {
        date: undefined | "oldest" | "newest";
        price: undefined | "lowest" | "highest";
      } = {
        minPrice,
        maxPrice,
        page,
        date: undefined,
        price: undefined,
      };
      if (filter) {
        filter.type === "date"
          ? (payload.date = filter.value as "oldest" | "newest")
          : (payload.price = filter.value as "lowest" | "highest");
      }
      const res = await axios.get<
        TLaravelResponse<{
          belongings: TLaravelPagination<TOfferingBelonging[]>;
          max_price: string;
        }>
      >("belongings", {
        params: payload,
      });
      if (res.data.success && res.data.data) {
        const { max_price } = res.data.data;
        setMaxPrice(+max_price);
        setFilters((prev) => ({
          ...prev,
          maxPrice: +max_price,
        }));
      }
      return res.data;
    },
  });
  const handlePage = (value: "decrease" | "increase") => {
    scrollToId("all-offerings-filters");
    setFilters((prev) => ({
      ...prev,
      page: value === "decrease" ? filters.page - 1 : filters.page + 1,
    }));
  };
  return {
    data,
    isLoading,
    handlePage,
  };
};

export const useOfferingBelongingsSingle = () => {
  const { belongingId } = useParams();
  const query = useQuery({
    queryKey: ["offerings", belongingId],
    queryFn: async () => {
      const res = await axios.get<TLaravelResponse<TOfferingBelongingUser>>(
        `user-belongings/${belongingId}`,
      );
      return res.data;
    },
  });
  return {
    query,
  };
};
