import "./style.css";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import useFAQs from "./hook";
import SvgArrow from "../../../components/svgs/arrows/Arrow";

export const FAQs = () => {
  const { expanded, handleChange, faqs, isLoading } = useFAQs();
  return (
    <section id="faqs">
      <div className="section-margin container text-xl text-primary-green">
        {!isLoading ? (
          <div>
            {faqs?.map(({ id, question, answer }) => (
              <Accordion
                key={`faqs-${id}`}
                expanded={expanded === id.toString()}
                onChange={handleChange(id.toString())}
              >
                <AccordionSummary
                  expandIcon={<SvgArrow className="mx-3 w-2.5 -rotate-90" />}
                  aria-controls={`panel-${id}-content`}
                  id={id.toString()}
                >
                  {question}
                </AccordionSummary>
                <AccordionDetails>{answer}</AccordionDetails>
              </Accordion>
            ))}
          </div>
        ) : (
          <p>Loading FAQs</p>
        )}
      </div>
    </section>
  );
};

export default FAQs;
