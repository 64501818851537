import ThreeNeedlesHeading from "../../../../components/common/ThreeNeedlesHeading";
import SvgArrowCurved from "../../../../components/svgs/arrows/ArrowCurved";
import overviewPageData from "../data";

const InvestmentFramework = () => {
  return (
    <section
      id="overview-ivestment-framework"
      className="relative overflow-x-clip text-primary-green"
    >
      <img
        className="absolute bottom-0 left-0 top-0 h-full -translate-x-1/4 -translate-y-10 max-lg:hidden"
        src="/assets/images/pages/about/overview/investment-framework.png"
        alt=""
      />
      <div className="container relative grid max-lg:section-margin lg:grid-cols-[1fr_2fr] lg:section-padding">
        <div className="col-start-2 flex flex-col lg:items-end lg:text-right">
          <div className="mb-4 flex flex-wrap gap-x-2 text-section-heading font-bold lg:justify-end">
            <SvgArrowCurved className="z-10 -mr-4 mt-2 w-28 -scale-x-100 max-lg:hidden" />
            <ThreeNeedlesHeading
              backgroundColor="primary-green"
              needlesColor="black"
              className="text-white"
            >
              Operational
            </ThreeNeedlesHeading>
            <h2 className="py-1.5"> Framework</h2>
          </div>
          <p className="font-semibold">{overviewPageData.investmentFramwork}</p>
          <div className="absolute -bottom-4 right-0 h-1/2 w-1 rounded-xl bg-primary-green md:-right-4"></div>
        </div>
      </div>
    </section>
  );
};

export default InvestmentFramework;
