import CustomInput from "../../../../../components/common/inputs/Input";
import CustomDropdown from "../../../../../components/common/inputs/Dropdown";
import ButtonPrimary from "../../../../../components/common/buttons/Button";
import { usePersonalAddress } from "../_hooks";

const PersonalAddress = () => {
  const { handleSubmit, countries, isPending, user, selectedCountry } =
    usePersonalAddress();
  return (
    <section id="account-address" className="container section-margin">
      <h2 className="mb-4 text-3xl font-bold uppercase text-dark-green">
        Personal Address
      </h2>
      <form
        onSubmit={handleSubmit}
        className="mb-4 grid gap-x-6 gap-y-3 sm:grid-cols-2 md:grid-cols-3"
      >
        <CustomDropdown
          labelClass="sm:col-span-2 md:col-span-3"
          label="Country"
          name="country"
          options={
            countries?.map(({ name }) => name) ?? ["Loading countries..."]
          }
          defaultValue={selectedCountry?.name ?? user?.user.country ?? ""}
        />
        <CustomInput
          type="text"
          label="City"
          name="city"
          defaultValue={user?.user.city ?? ""}
        />
        <CustomInput
          type="text"
          label="State/Province"
          name="state"
          defaultValue={user?.user.state ?? ""}
        />
        <CustomInput
          type="number"
          inputMode="numeric"
          label="Postal/ZIP Code"
          name="zip_code"
          defaultValue={user?.user.zip_code ?? ""}
        />
        <CustomInput
          labelClass="sm:col-span-2 md:col-span-3"
          type="text"
          label="Street Address"
          name="street_address"
          autoComplete="street-address"
          defaultValue={user?.user.street_address ?? ""}
        />
        <CustomInput
          labelClass="sm:col-span-2 md:col-span-3"
          type="text"
          label="Street Address Line 2"
          name="second_street_address"
          defaultValue={user?.user.second_street_address ?? ""}
        />
        <ButtonPrimary
          isLoading={isPending}
          className="h-max w-max self-end justify-self-end px-12 md:col-start-3"
          type="submit"
          title="Save"
        >
          Save
        </ButtonPrimary>
      </form>
    </section>
  );
};

export default PersonalAddress;
