import ThreeNeedlesHeading from "../../../../components/common/ThreeNeedlesHeading";
import SvgArrowCurved from "../../../../components/svgs/arrows/ArrowCurved";

const WhoShouldRaiseCapital = () => {
  const list = [
    "Experienced farmers, growers, ranchers, and landowners interested in expanding their operations or acquiring new land.",
    "Young farmers who have trouble getting traditional ag credit and want to get their own piece of land or buy out siblings on a multi-generational farm.",
    " Livestock ranchers who want flexible financing to grow their herds.",
    "Established agribusinesses that need outside capital to expand their operations or develop value-added processing facilities.",
    "Controlled Environment Agriculture developers.",
    "Early stage Agtech companies raising a seed round (our average check size is $100,000).",
  ];
  return (
    <section id="farmers-who-should-raise" className="relative">
      <div className="container grid 2xl:grid-cols-[1fr_3fr]">
        <div>
          <img
            className="absolute bottom-0 left-0 h-full -translate-x-20 scale-110 max-2xl:hidden"
            src="/assets/images/pages/farmers/empowerment/who-should-raise.png"
            alt=""
          />
        </div>
        <div className="section-margin 2xl:ml-40">
          <div className="mb-8 flex flex-wrap justify-end gap-x-2 text-right text-section-heading text-primary-green">
            <SvgArrowCurved className="-mb-4 w-20 -scale-x-100" />
            <h2 className="py-1.5">Who should raise</h2>
            <ThreeNeedlesHeading
              backgroundColor="primary-green"
              className="text-white"
              needlesColor="black"
            >
              Capital
            </ThreeNeedlesHeading>
            <h2 className="py-1.5">with Aradina?</h2>
          </div>
          <ul>
            {list.map((item, index) => (
              <li
                key={`farmers_raise_capital_${index}`}
                className="mb-3 grid grid-cols-[36px_auto] gap-2"
              >
                <span className="grid h-9 w-9 place-items-center rounded-full bg-primary-green text-white">
                  {++index}
                </span>
                <p className="font-bold text-primary-green">{item}</p>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default WhoShouldRaiseCapital;
