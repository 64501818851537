import Banner from "./components/Banner";
import PartnersGrid from "./components/PartnersGrid";
import BestPartners from "./components/BestPartners";
import TestimonialsSection from "../../../components/section/testimonials/TestimonialsSection";
import Main from "../../../components/layout/Main";

const PagePartners = () => {
  return (
    <Main id="partners">
      <Banner />
      <BestPartners />
      <PartnersGrid />
      <TestimonialsSection page="partners" type="partners" />
    </Main>
  );
};

export default PagePartners;
