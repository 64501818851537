import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { TLaravelResponse } from "../../../../types/utils/laravel";
import { useParams } from "react-router-dom";

export const useMyBelongings = <T>() => {
  const query = useQuery({
    queryKey: ["my-belongings"],
    queryFn: async () => {
      const res = await axios.get<TLaravelResponse<T>>("offering-items");
      return res.data;
    },
  });
  return { query };
};

export const useMyBelongingsSingle = <T>() => {
  const { belongingId } = useParams();
  const query = useQuery({
    queryKey: ["my-belonging", belongingId],
    queryFn: async () => {
      const res = await axios.get<TLaravelResponse<T>>(
        `offering-items/${belongingId}`,
      );
      return res.data;
    },
  });
  return { query };
};
