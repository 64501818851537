import ButtonPrimary from "../../../../components/common/buttons/Button";
import CardBelonging from "../../../../components/common/cards/OfferingBelonging";
import LoadingImage from "../../../../components/common/LoadingImage";
import { useOfferingBelongings } from "./_hooks";

const OfferingsGrid = () => {
  const { data, isLoading, handlePage } = useOfferingBelongings();
  return (
    <section id="all-offerings-grid" className="container section-margin">
      <div className="my-10 grid-offerings">
        {isLoading
          ? [...Array(9)].map((_, index) => {
              return <LoadingImage key={`offering_loading_${index}`} />;
            })
          : data?.success &&
            data.data.belongings.data.map((offering) => {
              return (
                <CardBelonging
                  key={`offerings_belongings_${offering.id}`}
                  offeringBelonging={offering}
                />
              );
            })}
      </div>
      {data?.success && (
        <div className="flex justify-between">
          <div className="flex justify-center">
            {data.data.belongings.prev_page_url && (
              <ButtonPrimary
                onClick={() => handlePage("decrease")}
                className="capitalize"
              >
                View previous page
              </ButtonPrimary>
            )}
          </div>
          <div className="flex justify-center">
            {data.data.belongings.next_page_url && (
              <ButtonPrimary
                onClick={() => handlePage("increase")}
                className="capitalize"
              >
                View next page
              </ButtonPrimary>
            )}
          </div>
        </div>
      )}
      {!isLoading && data?.success && data.data.belongings.data.length < 1 && (
        <h3 className="text-center text-2xl">
          Sorry, but it seems no offerings match your desires!
        </h3>
      )}
    </section>
  );
};

export default OfferingsGrid;
