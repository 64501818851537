import BannerGradientHeading from "../../../../components/common/BannerGradientHeading";
import SvgCrop from "../../../../components/svgs/icons/Crop";
import SvgAradinaSingleIcon from "../../../../components/svgs/logos/AradinaSingleIcon";
import PageBanner from "../../../../components/section/PageBanner";

const Banner = () => {
  return (
    <PageBanner
      id="overview"
      imageUrl="/assets/images/pages/about/overview/banner-background.jpg"
    >
      <img
        className="absolute right-0 top-0 translate-x-1/3 scale-125 max-md:hidden"
        src="/assets/images/common/safe-investment.png"
        alt=""
      />
      <div className="single-grid-item flex h-full justify-end max-xl:hidden">
        <img
          src="/assets/images/pages/about/overview/banner-invest.png"
          alt=""
        />
        <img
          className="translate-y-1/3"
          src="/assets/images/pages/about/overview/banner-grow.png"
          alt=""
        />
      </div>
      <div className="single-grid-item container relative flex h-full flex-col items-center justify-center gap-2">
        <div className="flex flex-col items-center">
          <BannerGradientHeading title="Overview" text="Aradina History" />
          <SvgCrop className="mt-4 box-content h-8 w-8 rounded-full bg-primary-green p-3" />
        </div>
        <div className="absolute bottom-0 left-0 grid justify-items-center">
          <SvgAradinaSingleIcon className="w-6" />
          <div className="mt-1 rounded-t-lg bg-gradient-to-r from-white to-[#FFF4] px-14 py-2">
            <span className="text-xl font-bold text-primary-green">
              Grow with Aradina
            </span>
          </div>
        </div>
      </div>
    </PageBanner>
  );
};

export default Banner;
