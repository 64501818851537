import { Outlet } from "react-router-dom";
import Footer from "../section/footer/_index";

const LayoutFooter = () => {
  return (
    <>
      <Outlet />
      <Footer />
    </>
  );
};

export default LayoutFooter;
