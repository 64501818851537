import ButtonPrimary from "../../../components/common/buttons/Button";
import CustomCheckbox from "../../../components/common/inputs/Checkbox";
import CustomDropdown from "../../../components/common/inputs/Dropdown";
import CustomInput from "../../../components/common/inputs/Input";
import CustomRadio from "../../../components/common/inputs/Radio";
import Modal from "../../../components/common/Modal";
import { useCartMarketplaceAddress } from "./_hooks";

const Address = () => {
  const {
    Display,
    handleSubmit,
    countries,
    isPending,
    selectedAddress,
    handleSelectAddress,
    handleEdit,
    handleAddAddress,
    selectedCountry,
    handleSelectCountry,
  } = useCartMarketplaceAddress();
  return (
    <section id="cart-address" className="mb-6 h-max rounded-md bg-white p-6">
      <h5 className="mb-4 text-xl text-dark-green">Shipping Address</h5>
      <Display
        success={(res) => {
          return res.length === 0 ? (
            <p className="mb-4">No addresses yet...</p>
          ) : (
            <ul className="mb-4 grid h-fit gap-4">
              {res.map((address) => (
                <li
                  key={address.id}
                  className="flex flex-wrap justify-between gap-4"
                >
                  <CustomRadio
                    containerClass="items-start gap-4"
                    labelClass="flex-grow"
                    className="mt-1"
                    id={`address-selection-${address.id}`}
                    value={address.id}
                    name="address_id"
                    checked={
                      selectedAddress
                        ? selectedAddress?.id === address.id
                        : !!address.is_default
                    }
                    onChange={() => handleSelectAddress(address.id)}
                    label={
                      <div className="flex w-full flex-wrap justify-between gap-4">
                        <div className="flex-grow">
                          <h5 className="text-lg font-bold">{address.name}</h5>
                          <p className="text-base">
                            {address.address_details.city}
                          </p>
                        </div>
                      </div>
                    }
                  />
                  <button
                    type="button"
                    title="Edit"
                    className="text-primary-gray underline hover-link hover:text-primary-green"
                    onClick={() => {
                      handleEdit(address);
                    }}
                  >
                    Edit
                  </button>
                </li>
              ))}
            </ul>
          );
        }}
      />
      <ButtonPrimary
        type="button"
        title="Add address"
        className="ml-auto block"
        onClick={handleAddAddress}
      >
        Add Address
      </ButtonPrimary>
      <Modal id="cart-add-address" className="max-w-5xl">
        <div className="container">
          <h2 className="mb-4 text-3xl font-bold capitalize text-dark-green">
            Add new address
          </h2>
          <form
            onSubmit={handleSubmit}
            className="mb-4 grid items-center gap-x-6 gap-y-4 sm:grid-cols-2 md:grid-cols-3"
          >
            <CustomInput
              key={selectedAddress?.name}
              required
              type="text"
              label="Address Name"
              name="name"
              defaultValue={selectedAddress?.name}
              autoComplete="off"
            />
            <CustomDropdown
              labelClass="sm:col-span-2"
              label="Country"
              name="country"
              options={
                countries?.map(({ name }) => name) ?? ["Loading countries..."]
              }
              value={selectedCountry}
              onChange={(_, value) => {
                handleSelectCountry(value as string);
              }}
              required
            />
            <CustomInput
              key={selectedAddress?.address_details.state}
              required
              type="text"
              label="State/Province"
              name="state"
              defaultValue={selectedAddress?.address_details.state}
            />
            <CustomInput
              key={selectedAddress?.address_details.city}
              required
              type="text"
              label="City"
              name="city"
              defaultValue={selectedAddress?.address_details.city}
            />
            <CustomInput
              key={selectedAddress?.address_details.zip_code}
              required
              type="number"
              inputMode="numeric"
              label="Postal/ZIP Code"
              name="zip_code"
              defaultValue={selectedAddress?.address_details.zip_code}
            />
            <CustomInput
              key={selectedAddress?.address_details.street_address}
              required
              containerClass="sm:col-span-2 md:col-span-3"
              type="text"
              label="Street Address"
              name="street_address"
              autoComplete="street-address"
              defaultValue={selectedAddress?.address_details.street_address}
            />
            <CustomCheckbox
              key={selectedAddress?.is_default}
              labelClass="flex gap-2 text-lg text-primary-green items-center"
              value="true"
              name="is_default"
              title="Default address"
              label="Default"
              defaultChecked={
                selectedAddress ? !!selectedAddress.is_default : undefined
              }
            />
            <ButtonPrimary
              isLoading={isPending}
              className="h-max w-max self-end justify-self-end px-12 md:col-start-3"
              type="submit"
              title={selectedAddress ? "Save" : "Add new"}
            >
              {selectedAddress ? "Save" : "Add"}
            </ButtonPrimary>
          </form>
        </div>
      </Modal>
    </section>
  );
};

export default Address;
