import { InputAdornment, TextField } from "@mui/material";
import { ComponentProps, HTMLAttributes } from "react";
import { twMerge } from "tailwind-merge";
import SvgMagnifyingGlass from "../../svgs/icons/MagnifyingGlass";

type TProps = ComponentProps<typeof TextField> & {
  labelClass?: HTMLAttributes<HTMLLabelElement>["className"];
};

export const CustomSearch = (props: TProps) => {
  return (
    <TextField
      {...props}
      className={twMerge(
        "w-full [&:hover_.MuiOutlinedInput-root_fieldset]:border-primary-green [&_.MuiOutlinedInput-root]:rounded-xl [&_fieldset]:!border-primary-green [&_input]:text-primary-green [&_label]:!text-primary-green",
        props.className,
      )}
      InputProps={{
        endAdornment: (
          <InputAdornment className="input-adorment" position="end">
            <SvgMagnifyingGlass className="h-20 w-20" />
          </InputAdornment>
        ),
      }}
      variant="outlined"
    />
  );
};

export default CustomSearch;
