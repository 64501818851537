import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import axios from "axios";
import { TLaravelSuccess } from "../../../types/utils/laravel";

type TTestimonial = {
  id: number;
  photo_url: string;
  name: string;
  message: string;
  type_id: number;
};

const useTestimonials = (type: "investors" | "farmers" | "partners") => {
  const [activeStep, setActiveStep] = useState(0);
  const { data, isLoading } = useQuery({
    queryKey: ["testimonials", type],
    queryFn: async () => {
      const response = await axios.get<TLaravelSuccess<TTestimonial[]>>(
        "testimonials",
        {
          params: { type: type },
        },
      );
      return response.data;
    },
  });
  return {
    activeStep,
    setActiveStep,
    data,
    isLoading,
  };
};

export default useTestimonials;
