import useMedia from "../hooks";
import ThreeNeedlesHeading from "../../../../components/common/ThreeNeedlesHeading";
import SvgArrowCurved from "../../../../components/svgs/arrows/ArrowCurved";
import SvgLink from "../../../../components/svgs/icons/Link";
import ButtonPrimary from "../../../../components/common/buttons/Button";
import { TMediaArticle } from "../types";
import { useState } from "react";
import { Link } from "react-router-dom";
import LoadingSpinner from "../../../../components/common/LoadingSpinner";

const ArticleCard = ({
  article,
  reversed,
}: {
  article: TMediaArticle;
  reversed?: boolean;
}) => {
  const [copied, setCopied] = useState(false);
  const handleClick = () => {
    navigator.clipboard.writeText(article.url);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1000);
  };
  return (
    <article className="grid gap-x-20 gap-y-5 xl:grid-cols-2">
      <img
        className={
          reversed ? "w-full rounded-xl xl:col-start-2" : "w-full rounded-xl"
        }
        src={article.thumbnail}
        alt={article.title}
      />
      <div
        className={`flex flex-col justify-between gap-4 text-xl xl:row-start-1 ${
          reversed ? "xl:col-start-1" : "xl:col-start-2"
        }`}
      >
        <div>
          <img src={article.logo} alt="Logo" />
          <h3 className="font-bold text-primary-green">{article.title}</h3>
          <p className="leading-tight">{article.description}</p>
          <span className="my-2 text-base italic">
            <b>{article.author}</b> - {article.release_date}
          </span>
          <div
            className={`relative w-max text-white before:absolute before:inset-0 before:grid before:place-content-center before:rounded-2xl before:bg-primary-yellow before:py-1 before:text-center before:duration-150 before:content-['Link_copied!'] ${
              !copied && "before:scale-0"
            }`}
          >
            <button
              type="button"
              onClick={handleClick}
              className={`flex items-center gap-1 rounded-2xl bg-primary-green bg-opacity-75 px-5 py-1 duration-150 ${
                copied && "scale-0"
              }`}
            >
              <SvgLink className="mr-1 h-5" /> Copy Link
            </button>
          </div>
        </div>
        <div className="flex justify-end">
          <Link to={article.url} target="_blank" rel="noopener noreferrer">
            <ButtonPrimary>View Full Article</ButtonPrimary>
          </Link>
        </div>
      </div>
    </article>
  );
};

const Articles = () => {
  const { articles, isLoading } = useMedia();
  return (
    <section id="media-articles" className="container section-margin">
      <div className="mb-10 flex justify-end text-section-heading">
        <SvgArrowCurved className="z-10 -mr-2 mt-4 w-20 -scale-x-100" />
        <ThreeNeedlesHeading
          backgroundColor="primary-green"
          needlesColor="black"
          className="text-white"
        >
          Articles
        </ThreeNeedlesHeading>
      </div>
      {isLoading ? (
        <div className="section-margin">
          <LoadingSpinner>Loading Articles</LoadingSpinner>
        </div>
      ) : (
        <ul className="grid auto-rows-fr gap-y-20">
          {articles?.map((article, index) => {
            return (
              <li key={`article_${article.id}`}>
                <ArticleCard article={article} reversed={index % 2 !== 0} />
              </li>
            );
          })}
        </ul>
      )}
    </section>
  );
};

export default Articles;
