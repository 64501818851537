import SvgAradinaIcon from "../svgs/logos/AradinaIcon";

const BannerGradientHeading = ({
  title,
  text,
  mode = 1,
}: {
  title: string;
  text?: string;
  mode?: 1 | 2;
}) => {
  return (
    <div className="flex flex-col items-center text-center">
      <SvgAradinaIcon
        className={`mb-4 w-16 ${
          mode === 1 ? "fill-white" : "fill-primary-green"
        }`}
      />
      <h2
        className={`gradient-text bg-gradient-to-r to-light-green text-4xl font-extrabold md:text-6xl md:leading-tight ${
          mode === 1 ? "from-white" : "from-primary-green"
        }`}
      >
        {title}
      </h2>
      {text && (
        <span
          className={`text-xl ${
            mode === 1 ? "text-white" : "text-primary-green"
          }`}
        >
          {text}
        </span>
      )}
    </div>
  );
};

export default BannerGradientHeading;
