import ThreeNeedlesHeading from "../../../../components/common/ThreeNeedlesHeading";
import overviewPageData from "../data";

const AgtechCompany = () => {
  return (
    <section
      id="overview-agtech-company"
      className="relative bg-primary-yellow bg-[url(/public/assets/images/backgrounds/background-giant-blob.png)] bg-[length:50%] bg-bottom bg-no-repeat text-white single-grid"
    >
      <img
        className="absolute bottom-0 right-4 w-20"
        src="/assets/images/pages/about/overview/agtech-tree.png"
        alt=""
      />
      <div className="grid grid-cols-[1fr_3fr] single-grid-item max-lg:hidden">
        <img
          className="origin-top-right scale-125 max-lg:hidden"
          src="/assets/images/pages/about/overview/agtech-company.png"
          alt=""
        />
      </div>
      <div className="container grid lg:gap-x-20 section-padding single-grid-item lg:grid-cols-[1fr_3fr] ">
        <div className="col-start-2 flex flex-col max-md:gap-2">
          <div className="flex flex-wrap gap-x-2 text-section-heading">
            <h2 className="py-1.5">Aradina is an</h2>
            <ThreeNeedlesHeading
              backgroundColor="white"
              needlesColor="black"
              className="font-bold text-primary-yellow"
            >
              agri-platform
            </ThreeNeedlesHeading>
            <h2 className="py-1.5">offering valuable </h2>
          </div>
          <div className="mb-4 flex flex-wrap gap-x-2 text-section-heading">
            <h2 className="py-1.5">opportunities in the</h2>
            <ThreeNeedlesHeading
              backgroundColor="white"
              needlesColor="black"
              className="font-bold text-primary-yellow"
            >
              farming sector
            </ThreeNeedlesHeading>
          </div>
          <ul className="z-10">
            {overviewPageData.agtechCompany.map((text, index) => {
              return (
                <li key={`overview_agtech_${index}`} className="mb-2">
                  <p>• {text}</p>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default AgtechCompany;
