import { atom } from "jotai";
import { TOfferingFilters } from "./types";
import { TOfferingDisplay } from "../../../types/offering";

export const offeringsFiltersAtom = atom<TOfferingFilters>({
  type: [],
  location: "",
  search: "",
  status: "",
  priceone: 0,
  pricetwo: 0,
  page: 1,
});

export const offeringsAtom = atom<TOfferingDisplay[] | []>([]);
