import SvgChecked from "../../../../components/svgs/icons/Checked";
import SvgErrorX from "../../../../components/svgs/icons/ErrorX";
import { homeData } from "../../data";
export default function WhyInvestmentTable() {
  return (
    <div className="mb-20 hidden self-end md:block">
      <div className="grid grid-cols-5 place-items-center rounded-t-xl bg-[#7BAD49]">
        {homeData.whyInvestment.table.heading.map((heading, index) => (
          <h4
            key={`home_why_investment_table_heading_${index}`}
            className={
              index === 0
                ? "justify-self-start p-5 text-white"
                : "p-5 text-white"
            }
          >
            {heading}
          </h4>
        ))}
      </div>
      <div className="rounded-b-lg bg-[#F6FFED]">
        {homeData.whyInvestment.table.data.map((row, parentIndex) => (
          <div
            key={`home_why_investment_table_row_${parentIndex}`}
            className={`grid grid-cols-5 place-items-center ${
              parentIndex % 2 !== 0 && "bg-[#DDF2C7]"
            } ${parentIndex === row.checked.length - 1 && "rounded-b-xl"}`}
          >
            <h4 className="justify-self-start p-4">{row.title}</h4>
            {row.checked.map((checked, index) => (
              <div
                key={`home_why_investment_table_cell_${parentIndex}_${index}`}
                className="px-5 py-4"
              >
                {checked ? (
                  <SvgChecked className="box-content w-8" />
                ) : (
                  <SvgErrorX className="box-content w-6" />
                )}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}
