import { Link, useLocation } from "react-router-dom";

type TProps = {
  id: string;
  data: {
    path: string;
    title: string;
  }[];
};

const TabsNavigation = ({ id, data }: TProps) => {
  const { pathname } = useLocation();
  return (
    <section
      id={`${id}-navigation`}
      className="grid after:h-px after:w-full after:bg-primary-gray/25"
    >
      <ul className="container flex flex-wrap gap-x-16 gap-y-2">
        {data.map(({ path, title }) => (
          <li
            key={path}
            className={`grid after:bottom-0 after:h-1 after:-mb-0.5 after:rounded-full after:bg-primary-green after:duration-200 ${pathname === path ? "mt-0.5 font-extrabold after:w-full" : "after:w-0"}`}
          >
            <Link
              to={path}
              title={title}
              className="mb-2 inline-block text-xl uppercase text-primary-green"
            >
              {title}
            </Link>
          </li>
        ))}
      </ul>
    </section>
  );
};

export default TabsNavigation;
