import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { TLaravelSuccess } from "../../../types/utils/laravel";
import { TPartner } from "./types";

const useFetchPartners = () => {
  const { data, isLoading } = useQuery({
    queryKey: ["partners-logos"],
    queryFn: async () => {
      const response =
        await axios.get<TLaravelSuccess<TPartner[]>>("our-partners");
      return response.data.data;
    },
  });
  return { data, isLoading };
};

export default useFetchPartners;
