import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { useRef, FormEvent } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { TOfferingBelongingUser } from "../../../../types/offering";
import { TLaravelResponse } from "../../../../types/utils/laravel";
import { useLaravelQuery } from "../../../../utils/hooks/Laravel";
import { TResponseOffers } from "../../../../types/utils/response";
import moment from "moment";

export const useBelongingsOffers = () => {
  const { Display, data } = useLaravelQuery<TResponseOffers>({
    queryKey: ["offers", "belongings"],
    endPoint: "offered-belongings",
  });
  const offers: { date: string; offers: TResponseOffers }[] = [];
  if (data?.success) {
    const dates: string[] = [];
    data.data.forEach((item) => {
      const date = moment(item.created_at).format("MMMM Do, YYYY");
      if (dates.includes(date)) {
        const index = offers.findIndex((item) => item.date === date);
        offers[index].offers =
          offers[index].offers.length > 0
            ? [...offers[index].offers, item]
            : [item];
        return;
      }
      dates.push(date);
      offers.push({
        date,
        offers: [item],
      });
    });
  }
  const queryClient = useQueryClient();
  const { mutate: acceptOffer } = useMutation({
    mutationFn: async (id: number) => {
      const res = await axios.post<TLaravelResponse>(
        `offered-belongings/${id}/accept`,
      );
      return res.data;
    },
    onSuccess: (data) => {
      toast.success(data.message ?? "Offer accepted!");
      queryClient.invalidateQueries({
        queryKey: ["offers", "belongings"],
      });
    },
    onError: () => toast.error("Something went wrong!"),
  });
  const { mutate: rejectOffer } = useMutation({
    mutationFn: async (id: number) => {
      const res = await axios.post<TLaravelResponse>(
        `offered-belongings/${id}/reject`,
      );
      return res.data;
    },
    onSuccess: (data) => {
      toast.success(data.message ?? "Offer rejected!");
      queryClient.invalidateQueries({
        queryKey: ["offers", "belongings"],
      });
    },
    onError: () => toast.error("Something went wrong!"),
  });
  const handleAcceptOffer = (id: number) => {
    acceptOffer(id);
  };
  const handleRejectOffer = (id: number) => {
    rejectOffer(id);
  };
  return { Display, offers, handleAcceptOffer, handleRejectOffer };
};

export const useBelongingsOfferSingle = () => {
  const input = useRef<HTMLInputElement>(null);
  const { belongingId } = useParams();
  const navigate = useNavigate();
  const { Display } = useLaravelQuery<TOfferingBelongingUser>({
    queryKey: ["belongings", belongingId],
    endPoint: `user-belongings/${belongingId}`,
  });
  const { mutate } = useMutation({
    mutationFn: async (form: FormData) => {
      const res = await axios.post<TLaravelResponse>(
        `user-belongings/${belongingId}/offered-belonging`,
        form,
      );
      return res.data;
    },
    onSuccess: (data) => {
      toast.success(data.message ?? "Offer sent!");
      navigate(`/user/offerings/belongings/${belongingId}`);
    },
    onError: () => toast.error("Something went wrong!"),
  });
  function handleSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    mutate(new FormData(e.currentTarget));
  }
  return { Display, input, handleSubmit };
};
