import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { FormEvent, useState } from "react";
import { ZodFormattedError, z } from "zod";
import { zodRequiredString } from "../../../utils/functions";

export const useFundaising = () => {
  const [ownProperty, setOwnProperty] = useState(false);
  const objectSchema = {
    first_name: zodRequiredString,
    last_name: zodRequiredString,
    email: zodRequiredString.email("Must be a valid email"),
    phone_number: zodRequiredString.regex(
      /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/,
      "Please enter a valid number",
    ),
    company_name: zodRequiredString,
    project_type: zodRequiredString,
    type_of_crop_or_livestock: zodRequiredString,
    property_own_question: z.boolean(),
    street_address: zodRequiredString,
    street_address_line_two: zodRequiredString,
    city: zodRequiredString,
    state: zodRequiredString,
    postal: z.number().min(1, "Please enter a valid postal code"),
    capital_trying_to_raise: zodRequiredString,
    funds_used_for: zodRequiredString,
    expected_roi: zodRequiredString,
    exposure_type: zodRequiredString,
  };
  const validationSchema = z.object(objectSchema);
  type TFundraisingRequest = z.infer<typeof validationSchema>;
  const [validationErrors, setValidationErrors] = useState<ZodFormattedError<
    TFundraisingRequest,
    string
  > | null>();

  const { mutate } = useMutation({
    mutationFn: async (formParams: FormData) => {
      const response = await axios.post("fundraising-request", formParams);
      return response.data;
    },
  });

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.target as HTMLFormElement);
    const fundraisingForm = validationSchema.safeParse(
      Object.fromEntries(formData),
    );
    if (fundraisingForm.success) {
      setValidationErrors((prev) => (prev = null));
      mutate(formData);
    } else {
      setValidationErrors((prev) => (prev = fundraisingForm.error.format()));
    }
  };
  return { handleSubmit, validationErrors, ownProperty, setOwnProperty };
};

export const useTypes = () => {};
