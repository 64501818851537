import { useAtomValue } from "jotai";
import ThreeNeedlesHeading from "../../../../components/common/ThreeNeedlesHeading";
import UpdatedChart from "../../../../components/common/UpdatedChart";
import SvgArrowCurved from "../../../../components/svgs/arrows/ArrowCurved";
import SvgAradinaIcon from "../../../../components/svgs/logos/AradinaIcon";
import { offeringDetailsAtom } from "../../atoms";

const EstimatedNetReturns = () => {
  const offering = useAtomValue(offeringDetailsAtom);
  return (
    <section
      id="offering-estimated-return"
      className="relative bg-primary-green bg-[url(/public/assets/images/backgrounds/background-giant-blob.png)] bg-[length:50%_150%] bg-right-bottom bg-no-repeat"
    >
      <div className="section-padding container">
        <div className="mb-10 flex flex-wrap gap-x-2 text-section-heading text-white">
          <h2 className="py-1.5">Estimated</h2>
          <ThreeNeedlesHeading
            backgroundColor="white"
            needlesColor="#22310E"
            className="text-primary-green"
          >
            Net Returns
          </ThreeNeedlesHeading>
          <SvgArrowCurved className="z-10 -ml-4 mt-2 w-28" />
        </div>
        <div className="grid place-items-center gap-x-6 gap-y-10 xl:grid-cols-2">
          {offering.estimated_net_returns?.map(
            ({ id, small_description, title, x_axis_and_values }) => {
              const xValues = x_axis_and_values.map((item) => {
                return parseInt(item.year);
              });
              const yValues = x_axis_and_values.map((item) => {
                return parseInt(item.result);
              });
              return (
                <div
                  className="flex flex-col items-center text-center [&_.MuiBarElement-root]:-translate-y-2 [&_.MuiChartsAxis-line]:stroke-white [&_.MuiChartsAxis-tickLabel]:fill-white [&_.MuiChartsAxis-tick]:stroke-white [&_div[class$='-MuiResponsiveChart-container']]:h-[300px] [&_div[class$='-MuiResponsiveChart-container']]:w-[300px] sm:[&_div[class$='-MuiResponsiveChart-container']]:h-[600px] sm:[&_div[class$='-MuiResponsiveChart-container']]:w-[600px]"
                  key={`offering_${offering.id}_chart_${id}`}
                >
                  <h4 className="mb-2.5 text-3xl text-primary-yellow">
                    {title}
                  </h4>
                  <h6 className="text-xl text-white">{small_description}</h6>
                  <UpdatedChart
                    xAxis={[
                      {
                        scaleType: "band",
                        data: xValues,
                        categoryGapRatio: 0.75,
                      },
                    ]}
                    series={[{ data: yValues, color: "#fff" }]}
                  />
                </div>
              );
            },
          )}
        </div>
      </div>
      <div className="absolute left-0 top-0 grid aspect-square -translate-x-1/4 -translate-y-1/2 place-items-center rounded-full border-[16px] border-dark-green border-opacity-75 bg-[#547C2C] p-5">
        <SvgAradinaIcon className="w-28 opacity-75" />
      </div>
    </section>
  );
};

export default EstimatedNetReturns;
