import { useAtomValue } from "jotai";
import { offeringDetailsAtom } from "../../atoms";

export const Gallery = () => {
  const offering = useAtomValue(offeringDetailsAtom);
  return (
    <section id="offering-gallery" className="grid grid-cols-2 lg:grid-cols-4">
      {offering.gallery?.map(({ id, path }) => (
        <img
          key={`offering_gallery_${id}`}
          loading="lazy"
          src={path}
          className="h-full w-full object-cover"
          alt={`gallery-${id}`}
        />
      ))}
    </section>
  );
};
export default Gallery;
