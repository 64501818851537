import ButtonPrimary from "../../../components/common/buttons/Button";
import LoadingSpinner from "../../../components/common/LoadingSpinner";
import { useEmailVerification } from "./hooks";

const VerifyEmailForm = () => {
  const { form, handleSubmit, handleChange, handleKeyUp, isPending, error } =
    useEmailVerification();
  return (
    <>
      <p className="mb-6 text-center text-base">
        Kindly check your inbox,
        <br />
        We've sent you an email with the OTP to verify.
      </p>
      <form
        ref={form}
        onSubmit={handleSubmit}
        className="mx-auto grid max-w-xs auto-rows-[75px] grid-cols-4 gap-x-6 gap-y-2"
      >
        {[...Array(4)].map((_, index) => {
          return (
            <input
              key={`verify_email_${index + 1}`}
              onChange={handleChange}
              onKeyUp={(e) => handleKeyUp(e, index)}
              className="h-full rounded-xl border border-primary-green text-center text-3xl"
              type="number"
              max={9}
              min={0}
              inputMode="numeric"
              title={`otp-number-${index + 1}`}
              name={`nb-${index + 1}`}
              id={`nb-${index + 1}`}
            />
          );
        })}
        <div className="col-span-4 grid auto-rows-fr place-items-center gap-4">
          {isPending ? (
            <LoadingSpinner className="w-4" />
          ) : (
            <p className="text-center text-base text-red-600">{error}</p>
          )}
          <ButtonPrimary
            id="verify-submit"
            title="Submit OTP"
            className="block h-max"
            type="submit"
          >
            Sumbit
          </ButtonPrimary>
        </div>
      </form>
    </>
  );
};

export default VerifyEmailForm;
