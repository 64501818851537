import BannerGradientHeading from "../../../../components/common/BannerGradientHeading";
import PageBanner from "../../../../components/section/PageBanner";
import SvgArrowStraight from "../../../../components/svgs/arrows/ArrowStraight";

const Banner = () => {
  return (
    <PageBanner
      id="media"
      imageUrl="/assets/images/pages/about/media/banner-background.jpg"
    >
      <div className="container flex flex-col items-center justify-end">
        <BannerGradientHeading
          title="Aradina in the Media"
          text="We appeared in several articles"
        />
        <SvgArrowStraight className="mt-4 box-content h-6 w-6 rounded-full border-2 p-2" />
        <div className="h-32 w-0.5 bg-white"></div>
      </div>
    </PageBanner>
  );
};

export default Banner;
