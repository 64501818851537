import LaravelResponse from "../../../../../components/common/LaravelResponse";
import Main from "../../../../../components/layout/Main";
import { TMyBelonging } from "../../../../../types/offering";
import { useMyBelongingsSingle } from "../_hooks";
import Banner from "./Banner";
import Information from "./Information";

const PageUserOfferingsMyBelongingSingle = () => {
  const { query } = useMyBelongingsSingle<TMyBelonging>();
  return (
    <Main id="my-belonging">
      <LaravelResponse<TMyBelonging>
        query={query}
        success={(data) => (
          <>
            <Banner data={data} />
            <Information data={data} />
          </>
        )}
      />
    </Main>
  );
};

export default PageUserOfferingsMyBelongingSingle;
