import { Outlet } from "react-router-dom";
import TabsNavigation from "../section/TabsNavigation";
import { navigationUser } from "../../constants/navigation";
import { useUpdateUser } from "../../pages/user/account/_hooks";

const LayoutUserAccount = () => {
  useUpdateUser();
  return (
    <>
      <TabsNavigation id="user" data={navigationUser} />
      <Outlet />
    </>
  );
};

export default LayoutUserAccount;
