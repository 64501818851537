import ThreeNeedlesHeading from "../../../../components/common/ThreeNeedlesHeading";
import SvgArrowCurved from "../../../../components/svgs/arrows/ArrowCurved";
import HowItWorksCard from "./Card";
import { useInvestmentFlow } from "../hooks";

const HowItWorks = () => {
  const { data, isLoading } = useInvestmentFlow();
  return (
    <section
      id="how-investment-works"
      className="relative bg-dark-yellow bg-[url(/public/assets/images/backgrounds/background-giant-blob.png)] bg-contain"
    >
      <div className="section-padding container">
        <div className="mb-20 flex flex-wrap gap-x-2 text-section-heading text-white md:justify-end md:text-right">
          <SvgArrowCurved className="mt-2 w-20 -scale-x-100 max-md:hidden" />
          <h2 className="py-1.5">How it</h2>
          <ThreeNeedlesHeading
            backgroundColor="primary-green"
            className="text-white"
            needlesColor="black"
          >
            Works
          </ThreeNeedlesHeading>
        </div>
        {isLoading ? (
          <p>Loading...</p>
        ) : (
          data?.map((item) => {
            return (
              <HowItWorksCard
                key={`how_investment_works_${item.step_number}`}
                data={item}
                arrow={item.step_number !== data.length}
              />
            );
          })
        )}
      </div>
      <img
        className="absolute bottom-0 left-0 z-10 -translate-x-1/2 translate-y-1/3 scale-75"
        src="/assets/images/circular-text/circular-gateway-opportunity-green.png"
        alt=""
      />
    </section>
  );
};

export default HowItWorks;
