import { useQuery } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { TLaravelSuccess } from "../types/utils/laravel";
import { useAtom } from "jotai";
import userAtom from "../pages/authentication/_atom";

export const useCountries = () => {
  const { data: countries } = useQuery<
    TLaravelSuccess<{ id: number; name: string; iso2: string }[]>,
    AxiosError
  >({
    queryKey: ["countries"],
    queryFn: async () => {
      const response =
        await axios.get<
          TLaravelSuccess<{ id: number; name: string; iso2: string }[]>
        >("countries");
      return response.data;
    },
  });
  return countries?.data;
};

export const useEmailVerified = () => {
  const [user] = useAtom(userAtom);
  return !!user?.token && user.email_verified !== false;
};
