import { Link } from "react-router-dom";
import Main from "../../../../components/layout/Main";
import AccountBanner from "../../../../components/section/AccountBanner";
import Overview from "./Overview";

const PageUserOfferingsSell = () => {
  return (
    <Main id="sell">
      <AccountBanner id="sell" listings className="my-10" />
      <section
        id="sell-create-listing"
        className="container flex flex-wrap items-center gap-10"
      >
        <p className="text-primary-green">
          To be able to sell please start by creating a list
        </p>
        <Link
          to="/user/offerings/create-listing"
          title="Create listing"
          className="flex-grow rounded-xl bg-tertiary-green px-6 py-4 text-center text-white hover-link"
        >
          + Create Listing
        </Link>
      </section>
      <Overview />
    </Main>
  );
};

export default PageUserOfferingsSell;
