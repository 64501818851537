import Main from "../../../../components/layout/Main";
import OfferingsFilters from "./OfferingsFilters";
import OfferingsGrid from "./OfferingsGrid";

const PageUserOfferingsBelongings = () => {
  return (
    <Main id="all-offerings">
      <OfferingsFilters />
      <OfferingsGrid />
    </Main>
  );
};

export default PageUserOfferingsBelongings;
