import { Link } from "react-router-dom";
import { socialPlatformData } from "../../../constants/social";

const Socials = () => {
  return (
    <div className="flex gap-2.5">
      {socialPlatformData.map(({ id, platform, url, Icon }) => {
        return (
          <Link
            key={`header_${id}`}
            to={url}
            title={platform}
            target="_blank"
            rel="noopener noreferrer"
            className="grid place-items-center rounded-full bg-primary-green p-2.5 hover-link"
          >
            <Icon className="size-3.5" />
          </Link>
        );
      })}
    </div>
  );
};

export default Socials;
