import AddQuestion from "./AddQuestion";
import QuestionsAnswers from "./QuestionsAnswers";

const QuestionAndAnswers = () => {
  return (
    <section id="offering-tab-questions" className="container section-margin">
      <AddQuestion />
      <QuestionsAnswers />
    </section>
  );
};

export default QuestionAndAnswers;
