import { TSvgComponent } from "../../../types/utils/components";

const SvgGraphArrow: TSvgComponent = (props) => {
  return (
    <svg
      viewBox="0 0 31 19"
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M25.4542 0.751381C22.9939 1.16773 20.9122 1.53362 20.8302 1.56516C20.5967 1.6724 20.4264 2.00674 20.4643 2.29062C20.4895 2.51772 20.6157 2.66281 21.7575 3.80462C22.4577 4.50484 23.0255 5.10414 23.0255 5.13568C23.0255 5.17353 21.625 6.59922 19.9154 8.30878L16.8117 11.4125L14.0991 8.69989C12.2256 6.82632 11.3235 5.96838 11.1784 5.91791C10.7179 5.76651 10.9071 5.59619 5.37471 11.116C2.51703 13.9674 0.138784 16.3771 0.0883168 16.4718C0.0378501 16.5664 0 16.7493 0 16.8755C0 17.0963 0.0630834 17.1783 0.895785 18.0173C1.83573 18.9573 2.00605 19.0708 2.36563 18.9699C2.4981 18.932 3.81024 17.6641 6.71839 14.7559C9.00832 12.466 10.9071 10.5924 10.945 10.5924C10.9765 10.5924 12.2445 11.8288 13.7522 13.3365C16.4017 15.986 16.5089 16.0807 16.736 16.0807C16.8685 16.0807 17.0515 16.0428 17.1461 15.9923C17.2407 15.9419 19.1774 14.0368 21.4484 11.7657L25.5803 7.62748L26.842 8.88914C27.5359 9.57675 28.1857 10.176 28.2866 10.2139C28.5137 10.2959 28.8165 10.2076 29.0184 9.9931C29.1509 9.85432 29.2392 9.38119 29.9079 5.3754C30.3179 2.92145 30.6586 0.820772 30.6586 0.71353C30.6586 0.467505 30.4504 0.196245 30.1791 0.082695C30.0593 0.0322285 29.9583 -0.00562096 29.952 0.000686646C29.9394 0.000686646 27.9207 0.341337 25.4542 0.751381Z" />
    </svg>
  );
};

export default SvgGraphArrow;
