import { TSvgComponent } from "../../../types/utils/components";

const SvgPDF: TSvgComponent = ({ ...props }) => {
  return (
    <svg
      viewBox="0 0 35 45"
      fill="#9C243B"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M0 43.5055V2.11754C0.0188785 2.07978 0.0471962 2.04202 0.0566354 2.00112C0.320934 0.49084 0.893581 0.00943924 2.42274 0.00943924C9.65319 0.00943924 16.8837 0.0125857 24.1141 0C24.7623 0 25.2594 0.204517 25.7125 0.663893C28.4971 3.47049 31.2848 6.27395 34.1008 9.04594C34.6923 9.62802 34.9346 10.2605 34.9346 11.0722C34.9252 21.7637 34.9252 32.4552 34.9346 43.1468C34.9346 43.9019 34.5948 44.3927 33.9687 44.7326C33.7767 44.8364 33.5722 44.9119 33.374 45H1.5795C0.858971 44.7042 0.191931 44.3581 0 43.5055ZM24.0606 10.8551V1.56691H23.4659C16.4557 1.56691 9.44239 1.56691 2.43218 1.56691C1.67389 1.56691 1.59523 1.64557 1.58265 2.41959C1.58265 2.47937 1.58265 2.53601 1.58265 2.59579C1.58265 11.648 1.58265 20.6971 1.58265 29.7493C1.58265 29.9067 1.58265 30.0608 1.58265 30.2213H33.352V10.8551H24.0606ZM1.58265 31.8323V32.3074C1.58265 35.7779 1.58265 39.2452 1.58265 42.7157C1.58265 43.3922 1.62984 43.4394 2.30632 43.4394C12.4157 43.4394 22.5283 43.4394 32.6377 43.4394C33.3237 43.4394 33.3709 43.3922 33.3709 42.7251C33.3709 39.2421 33.3709 35.7558 33.3709 32.2728C33.3709 32.1312 33.3583 31.9896 33.352 31.8323H1.58265ZM31.9833 9.3008C29.8752 7.1927 27.7545 5.06887 25.6496 2.96392V9.3008H31.9833Z" />
      <path d="M18.8751 8.55186C18.6675 9.71604 18.5165 10.7072 18.3245 11.8745C18.2931 12.0758 18.2994 12.3213 18.3875 12.4943C19.3597 14.4231 20.5239 16.2228 21.9586 17.8401C22.1191 18.0226 22.2607 18.0792 22.5155 18.0509C23.4154 17.9565 24.3184 17.8873 25.2215 17.859C25.5613 17.8495 25.92 17.9376 26.2503 18.0446C26.996 18.2837 27.3138 18.9759 27.0558 19.7279C26.6279 20.977 25.4165 21.6158 24.18 21.1092C23.4406 20.804 22.783 20.2848 22.1191 19.816C21.8517 19.6272 21.6471 19.5203 21.301 19.6052C19.2244 20.1086 17.1918 20.7442 15.2253 21.5812C15.0334 21.663 14.854 21.8518 14.7439 22.0342C14.0517 23.167 13.3878 24.3185 12.6893 25.445C12.5225 25.7124 12.274 25.9389 12.0254 26.1403C11.5566 26.5242 11.0343 26.8105 10.4176 26.5179C9.76943 26.2095 9.35725 25.6904 9.29747 24.9541C9.23454 24.1864 9.64043 23.6137 10.147 23.1009C11.0343 22.2041 12.1104 21.5749 13.2399 21.0557C13.7433 20.8229 14.0265 20.4988 14.2405 19.9954C15.2473 17.623 16.1 15.2002 16.7576 12.7114C16.8111 12.5069 16.7922 12.2458 16.7136 12.0507C16.2479 10.8959 15.7602 9.7475 15.7224 8.48264C15.7099 8.09563 15.7602 7.68975 15.8672 7.31847C16.1 6.51928 16.6947 6.12913 17.5222 6.15744C17.9658 6.17318 18.6769 6.93775 18.7902 7.53872C18.8405 7.80931 18.8531 8.08934 18.8814 8.36623C18.9003 8.37252 18.8563 8.54872 18.8751 8.55186ZM20.5868 18.3844C19.6523 17.0252 18.6958 15.6313 17.7519 14.2595C17.0943 16.1221 16.4241 18.01 15.7633 19.8852C17.3712 19.385 18.9853 18.8847 20.5868 18.3844ZM10.9493 25.316C11.8461 24.6772 12.318 23.7207 12.7963 22.7485C12.0978 23.2236 11.437 23.7333 10.8329 24.3091C10.4553 24.6709 10.5183 24.951 10.9462 25.3128L10.9493 25.316ZM17.2831 7.5513C16.6443 8.41342 16.6915 9.40454 17.3743 9.96146C17.6795 9.15598 17.8494 8.36623 17.2831 7.5513ZM25.8476 19.2088H23.6199C24.4726 20.1716 25.3882 20.1967 25.8476 19.2088Z" />
      <path d="M14.4707 34.17C15.5814 34.17 16.67 34.0693 17.7367 34.192C19.42 34.3839 20.688 35.8281 20.71 37.5272C20.7195 38.2729 20.6031 39.0469 20.3923 39.7612C20.043 40.9442 19.1683 41.6868 17.9538 41.8095C16.8148 41.9259 15.6569 41.8346 14.4707 41.8346V34.1731V34.17ZM15.7481 35.0604V40.9316C16.3176 40.9316 16.8588 40.9725 17.3906 40.9222C18.2747 40.8435 18.8662 40.3464 19.1463 39.4969C19.4263 38.6473 19.4924 37.7821 19.3193 36.9042C19.1935 36.2686 18.8411 35.7872 18.2779 35.46C17.4913 35.0069 16.6323 35.0415 15.7481 35.0604Z" />
      <path d="M8.01758 34.041C9.03072 34.0914 10.0502 34.0473 11.0287 34.2204C12.3061 34.4438 13.0141 35.5261 12.9165 36.8256C12.8253 38.0244 11.9097 38.9211 10.6417 39.0312C10.1949 39.0721 9.74496 39.0847 9.25412 39.113V41.8693H8.01758V34.041ZM9.27614 35.0762V38.1408C9.67574 38.1408 10.0407 38.1597 10.4057 38.1376C11.1105 38.0967 11.5164 37.7443 11.6643 37.0742C11.894 36.0264 11.444 35.1863 10.5662 35.0856C10.1508 35.0384 9.72293 35.0762 9.27614 35.0762Z" />
      <path d="M23.912 37.5052H27.1119V38.3578H23.8931V41.8755H22.6094V34.1196H27.4454V35.0447H23.9088V37.5052H23.912Z" />
    </svg>
  );
};

export default SvgPDF;
