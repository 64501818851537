import { DetailedHTMLProps, ButtonHTMLAttributes, ReactNode } from "react";

type TProps = DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & { icon: ReactNode };

const IconButton = ({ icon, ...props }: TProps) => {
  return (
    <button
      type="button"
      className="flex items-center justify-center gap-2 rounded-full border border-primary-green px-5 py-1.5 md:text-xl"
      {...props}
    >
      {icon}
      {props.children}
    </button>
  );
};

export default IconButton;
