import { Link } from "react-router-dom";
import SvgAradinaIcon from "../../svgs/logos/AradinaIcon";
import SvgLocation from "../../svgs/icons/Location";
import ButtonPrimary from "../buttons/Button";
import { TOfferingDisplay } from "../../../types/offering";

type TProps = {
  offering: TOfferingDisplay;
};

export const CardOffering = ({ offering }: TProps) => {
  return (
    <article className="group relative flex h-full cursor-pointer flex-col justify-between overflow-hidden rounded-2xl bg-cover bg-center text-white single-grid">
      <img
        loading="lazy"
        src={offering.thumbnail}
        alt={offering.title}
        className="absolute inset-0 z-0 h-full object-cover"
      />
      <div className="relative flex h-full flex-col justify-between rounded-2xl bg-black bg-opacity-60 single-grid-item">
        <div className="z-10 flex h-full flex-col justify-between p-5 pb-2">
          <div className="flex flex-col items-center">
            <span
              className={`${
                offering.status === "open"
                  ? "bg-primary-green"
                  : "bg-primary-yellow"
              } self-start rounded px-2 py-1 text-center capitalize`}
            >
              {offering.status}
            </span>
            <div className="my-10 flex flex-col items-center text-center">
              <SvgAradinaIcon className="mb-2 w-14" />
              <h3 className="gradient-text bg-gradient-to-l from-light-green to-white text-3xl font-extrabold md:text-4xl">
                {offering.title}
              </h3>
              <div className="my-2.5 h-0.5 w-1/4 rounded-xl bg-gradient-to-l from-light-green to-white"></div>
              <p className="text-xl">{offering.short_description}</p>
            </div>
          </div>
        </div>
        <div className="duration-500 group-hover:translate-y-full">
          <div className="mx-5 my-2 flex gap-2">
            <SvgLocation className="mb-0.5 w-3.5 fill-white" />
            <p className="text-opacity-50">{offering.location}</p>
          </div>
          <ul className="rounded-b-2xl bg-black bg-opacity-50 px-5 py-2 text-sm md:text-xl">
            <li className="flex justify-between py-3">
              <p>Type{offering.types.length > 1 && "s"}:</p>
              <div className="flex gap-2">
                {offering.types.map(({ id, type, logo_path }) => {
                  return (
                    <img
                      key={`${offering.id}_type_${id}`}
                      src={logo_path}
                      alt={type}
                      className="h-7 object-contain"
                    />
                  );
                })}
              </div>
            </li>
            {offering.minimum_investment && (
              <li className="flex justify-between py-3">
                <p>Min. Investment:</p>
                <p>${offering.minimum_investment}</p>
              </li>
            )}
            {offering.return_on_investment && (
              <li className="flex justify-between py-3">
                <p>Return on Investment:</p>
                <p>{offering.return_on_investment}%</p>
              </li>
            )}
          </ul>
        </div>
        <div className="absolute inset-0 box-border grid h-full items-end rounded-2xl bg-black bg-opacity-30 opacity-0 duration-500 group-hover:opacity-100">
          <div className="grid translate-y-full px-5 py-6 duration-500 group-hover:translate-y-0">
            <Link
              title={`${offering.title} details`}
              to={`/investors/offering-details/${offering.id}`}
              className="flex h-full flex-col justify-between rounded-xl"
            >
              <ButtonPrimary>View Details</ButtonPrimary>
            </Link>
            {/* <Link
              title={`${offering.title} investment`}
              to={`/investors/offering-investment/${offering.id}`}
              className="flex h-full flex-col justify-between rounded-xl bg-black bg-opacity-50"
            >
              <ButtonPrimary>Invest Now</ButtonPrimary>
            </Link> */}
          </div>
        </div>
      </div>
    </article>
  );
};
export default CardOffering;
