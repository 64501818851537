import ButtonPrimary from "../../common/buttons/Button";
import CardOffering from "../../common/cards/Offering";
import LoadingImage from "../../common/LoadingImage";
import { useOfferings } from "./hook";

const OfferingsGrid = () => {
  const { offerings, isOfferingsLoading, offeringsResponse, handlePage } =
    useOfferings();
  return (
    <section id="investors-grid" className="container my-10">
      <div className="my-10 grid-offerings">
        {isOfferingsLoading
          ? [...Array(9)].map((_, index) => {
              return <LoadingImage key={`offering_loading_${index}`} />;
            })
          : offerings?.map((offering) => {
              return (
                <CardOffering
                  key={`offering_${offering.id}`}
                  offering={offering}
                />
              );
            })}
      </div>
      <div className="flex justify-between">
        <div className="flex justify-center">
          {offeringsResponse?.prev_page_url && (
            <ButtonPrimary
              onClick={() => handlePage("decrease")}
              className="capitalize"
            >
              View previous investments
            </ButtonPrimary>
          )}
        </div>
        <div className="flex justify-center">
          {offeringsResponse?.next_page_url && (
            <ButtonPrimary
              onClick={() => handlePage("increase")}
              className="capitalize"
            >
              View next investments
            </ButtonPrimary>
          )}
        </div>
      </div>
      {!isOfferingsLoading && offerings && offerings.length < 1 && (
        <h3 className="text-center text-2xl">
          Sorry, but it seems no offerings match your desires!
        </h3>
      )}
    </section>
  );
};

export default OfferingsGrid;
