import CheckBox from "../../../../../../../components/common/InputFields/CheckBox";
import ButtonPrimary from "../../../../../../../components/common/buttons/Button";
import useOfferingQuestions from "../../hooks";
import CustomTextArea from "../../../../../../../components/common/inputs/TextArea";
import LoadingSpinner from "../../../../../../../components/common/LoadingSpinner";

export const AddQuestion = () => {
  const { form, setForm, data, isSuccess, submitForm, error, isPending } =
    useOfferingQuestions();

  return (
    <>
      <h3 className="mb-4 text-section-heading text-primary-green">
        Ask Our Experts a Question
      </h3>
      <p className="mb-4">
        Ask them anything, and when answered, it will be published for others to
        see. Your name will not be shared. Please include only one question per
        post.
      </p>
      <CustomTextArea
        label="Enter your question"
        maxLength={150}
        name="question"
        value={form.question}
        onChange={(e) =>
          setForm((prev) => ({ ...prev, question: e.target.value }))
        }
        className={`min-h-[150px] ${error && "border-red-600"}`}
      />
      {error && <p className="text-red-500">{error}</p>}
      {isPending ? (
        <LoadingSpinner className="my-4" />
      ) : (
        data && (
          <p className={isSuccess ? "text-primary-green" : "text-red-500"}>
            {isSuccess
              ? `This is our ${data} message recieved!`
              : "There was an error sending the message"}
          </p>
        )
      )}
      <div className="mb-4 mt-2 flex flex-wrap items-center justify-between">
        <p className="text-xl">
          {150 - form.question.length} of 150 characters left
        </p>
        <div className="flex flex-wrap items-center gap-x-5">
          <div className="flex items-center">
            <CheckBox
              label="test"
              name="notify"
              value={form.notifyInvestors}
              onChange={(_, value) =>
                setForm((prev) => ({ ...prev, notifyInvestors: value }))
              }
            />
            <span className="text-xl">Notify Investors</span>
          </div>
          <div className="flex items-center">
            <CheckBox
              label="test"
              name="notify-me"
              value={form.relationWithCompany}
              onChange={(_, value) =>
                setForm((prev) => ({ ...prev, relationWithCompany: value }))
              }
            />
            <span className="text-xl">
              I have a relationship with this company or am being compensated to
              promote this offer.
            </span>
          </div>
        </div>
      </div>
      <div className="mb-10 flex justify-end">
        <ButtonPrimary onClick={submitForm}>SUBMIT Question</ButtonPrimary>
      </div>
    </>
  );
};
export default AddQuestion;
