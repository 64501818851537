import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { useAtom, useAtomValue } from "jotai";
import { FormEvent } from "react";
import { toast } from "sonner";
import { z } from "zod";
import { TResponseUser } from "../../../../types/user";
import {
  TLaravelSuccess,
  TLaravelError,
  TLaravelResponse,
} from "../../../../types/utils/laravel";
import { zodRequiredString } from "../../../../utils/functions";
import { useCountries } from "../../../../utils/hooks";
import userAtom from "../../../authentication/_atom";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

export const useContactInformation = () => {
  const [user] = useAtom(userAtom);
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    mutate(new FormData(e.currentTarget));
  };
  const queryClient = useQueryClient();
  const { mutate, isPending } = useMutation({
    mutationFn: async (form: FormData) => {
      const response = await axios.post<
        TLaravelResponse<TResponseUser["user"]>
      >("contact-information", form);
      return response.data;
    },
    onSuccess: (res) => {
      if (res) {
        toast.success("Successfully updated contact information");
        queryClient.invalidateQueries({
          queryKey: ["user"],
        });
      }
    },
    onError: (error: AxiosError<TLaravelError>) =>
      toast.error(error.response?.data.message ?? "Something went wrong"),
  });
  return {
    handleSubmit,
    isPending,
    user,
  };
};

export const useChangePassword = () => {
  const validationSchema = z
    .object({
      current_password: zodRequiredString,
      password: z
        .string()
        .min(1, "This field is required")
        .min(8, "Password should be 8 characters long")
        .max(30, "Password must not exceed 30 characters")
        .regex(
          new RegExp(".*[A-Z].*"),
          "Password requires one uppercase character",
        )
        .regex(
          new RegExp(".*[a-z].*"),
          "Password requires one lowercase character",
        )
        .regex(new RegExp(".*\\d.*"), "Password requires one number")
        .regex(
          new RegExp(".*[`~<>?,./!@#$%^&*()\\-_+=\"'|{}\\[\\];:\\\\].*"),
          "Password requires one special character",
        ),
      password_confirmation: zodRequiredString,
    })
    .superRefine(({ password_confirmation, password }, ctx) => {
      if (password_confirmation !== password) {
        ctx.addIssue({
          code: "custom",
          message: "Passwords did not match",
          path: ["password_confirmation"],
        });
      }
    });
  type TSchema = z.infer<typeof validationSchema>;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<TSchema>({ resolver: zodResolver(validationSchema) });
  const onSubmit = handleSubmit((data) => mutate(data));
  const { mutate, isPending, isSuccess } = useMutation({
    mutationFn: async (params: TSchema) => {
      const response = await axios.post<TLaravelSuccess>(
        "password-change",
        params,
      );
      return response.data;
    },
    onSuccess: (response) => {
      toast.success(response?.message ?? "Successfully changed password");
    },
    onError: (error: AxiosError<TLaravelError>) =>
      toast.error(error.response?.data.message ?? "Something went wrong"),
  });
  return { handleSubmit, register, onSubmit, isPending, isSuccess, errors };
};

export const usePersonalAddress = () => {
  const user = useAtomValue(userAtom);
  const countries = useCountries();
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    mutate(new FormData(e.currentTarget));
  };
  const { mutate, isPending } = useMutation({
    mutationFn: async (form: FormData) => {
      const response = await axios.post<TLaravelSuccess>(
        "personal-address",
        form,
      );
      return response.data;
    },
    onSuccess: (res) => {
      if (res.success) {
        toast.success("Successfully updated personal address");
      }
    },
    onError: (error: AxiosError<TLaravelError>) =>
      toast.error(error.response?.data.message ?? "Something went wrong"),
  });
  const selectedCountry = countries?.find(
    ({ name }) => name === user?.user.country,
  );
  return { handleSubmit, countries, isPending, user, selectedCountry };
};

export const useIdentityVerification = () => {
  const user = useAtomValue(userAtom);
  const countries = useCountries();
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    mutate(new FormData(e.currentTarget));
  };
  const queryClient = useQueryClient();
  const { mutate } = useMutation({
    mutationFn: async (form: FormData) => {
      const res = await axios.post<TLaravelResponse>(
        "identity-verification",
        form,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      );
      return res.data;
    },
    onSuccess: (res) => {
      if (res.success) {
        queryClient.invalidateQueries({
          queryKey: ["user"],
        });
        toast.success("Successfully updated identity verification");
      }
    },
    onError: (error: AxiosError<TLaravelError>) =>
      toast.error(error.response?.data.message ?? "Something went wrong"),
  });
  const identifications = [
    { label: "Passport", id: "passport" },
    { label: "National Identity Card (ID)", id: "id" },
  ];
  return { countries, handleSubmit, user, identifications };
};

export const useNextOfKin = () => {
  const user = useAtomValue(userAtom);
  const queryClient = useQueryClient();
  const { mutate, isPending } = useMutation({
    mutationFn: async (form: FormData) => {
      const res = await axios.post<TLaravelResponse>("next-of-kin", form);
      return res.data;
    },
    onSuccess: (res) => {
      if (res.success) {
        queryClient.invalidateQueries({
          queryKey: ["user"],
        });
        toast.success("Successfully updated next of kin");
      }
    },
    onError: () => {
      toast.error("Something went wrong");
    },
  });
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    mutate(new FormData(e.currentTarget));
  };
  return {
    handleSubmit,
    user,
    isPending,
  };
};
