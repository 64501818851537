import { TSvgComponent } from "../../../types/utils/components";

const SvgTag: TSvgComponent = (props) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M38.7198 19.4375L20.875 1.59275C20.609 1.32462 20.2924 1.11204 19.9435 0.967363C19.5946 0.822689 19.2204 0.748808 18.8428 0.750015H2.18751C1.80627 0.750015 1.44063 0.901465 1.17105 1.17105C0.901465 1.44063 0.750015 1.80627 0.750015 2.18751V18.8428C0.748808 19.2204 0.822689 19.5946 0.967363 19.9435C1.11204 20.2924 1.32462 20.609 1.59275 20.875L19.4375 38.7198C19.7045 38.9868 20.0215 39.1986 20.3703 39.3432C20.7192 39.4877 21.0931 39.5621 21.4707 39.5621C21.8483 39.5621 22.2222 39.4877 22.571 39.3432C22.9199 39.1986 23.2369 38.9868 23.5038 38.7198L38.7198 23.5038C38.9868 23.2369 39.1986 22.9199 39.3432 22.571C39.4877 22.2222 39.5621 21.8483 39.5621 21.4707C39.5621 21.0931 39.4877 20.7192 39.3432 20.3703C39.1986 20.0215 38.9868 19.7045 38.7198 19.4375ZM10.0938 12.25C9.6673 12.25 9.25041 12.1236 8.89582 11.8866C8.54122 11.6497 8.26485 11.3129 8.10165 10.9189C7.93845 10.5249 7.89575 10.0914 7.97895 9.6731C8.06215 9.25483 8.26751 8.87062 8.56907 8.56907C8.87062 8.26751 9.25483 8.06215 9.6731 7.97895C10.0914 7.89575 10.5249 7.93845 10.9189 8.10165C11.3129 8.26485 11.6497 8.54122 11.8866 8.89582C12.1236 9.25041 12.25 9.6673 12.25 10.0938C12.25 10.6656 12.0228 11.2141 11.6185 11.6185C11.2141 12.0228 10.6656 12.25 10.0938 12.25Z" />
    </svg>
  );
};

export default SvgTag;
