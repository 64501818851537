import ButtonPrimary from "../../../../../components/common/buttons/Button";
import CustomDropdown from "../../../../../components/common/inputs/Dropdown";
import CustomInput from "../../../../../components/common/inputs/Input";
import { GENDERS, TITLES } from "../../../../../constants/account";
import { useContactInformation } from "../_hooks";
import CustomPhoneInput from "../../../../../components/common/inputs/Phone";

const ContactInformation = () => {
  const { handleSubmit, isPending, user } = useContactInformation();
  return (
    <section id="account-contact" className="container section-margin">
      <h2 className="mb-4 text-3xl font-bold uppercase text-dark-green">
        Contact Information
      </h2>
      <form
        onSubmit={handleSubmit}
        className="mb-4 grid gap-x-6 gap-y-3 sm:grid-cols-2 md:grid-cols-3"
      >
        <CustomInput
          type="text"
          label
          title="First Name"
          name="first_name"
          id="contact-first-name-input"
          autoComplete="given-name"
          defaultValue={user?.user.first_name ?? ""}
          required
        />
        <CustomInput
          type="text"
          label
          title="Middle Name"
          name="middle_name"
          id="contact-middle-name-input"
          autoComplete="additional-name"
          defaultValue={user?.user.middle_name ?? ""}
          required
        />
        <CustomInput
          type="text"
          label
          title="Last Name"
          name="last_name"
          id="contact-last-name-input"
          autoComplete="family-name"
          defaultValue={user?.user.last_name ?? ""}
          required
        />
        <div>
          <CustomDropdown
            defaultValue={TITLES.find((val) => val === user?.user.title)}
            label="Title"
            name="title"
            id="contact-title-input"
            options={TITLES}
          />
        </div>
        <CustomPhoneInput
          label
          title="Phone Number"
          name="phone_number"
          id="contact-phone-number-input"
          required
          defaultValue={user?.user.phone_number ?? ""}
        />
        <CustomInput
          type="date"
          label
          title="Date of Birth"
          name="date_of_birth"
          id="contact-date-of-birth-input"
          defaultValue={
            new Date(user?.user.date_of_birth ?? "1999-09-23")
              .toISOString()
              .split("T")[0]
          }
          required
        />
        <div>
          <CustomDropdown
            defaultValue={GENDERS.find((val) => val === user?.user.gender)}
            label
            title="Gender"
            name="gender"
            id="contact-gender-input"
            options={GENDERS}
            required
          />
        </div>
        <ButtonPrimary
          isLoading={isPending}
          className="h-max w-max self-end justify-self-end px-12 md:col-start-3"
          type="submit"
          title="Save"
        >
          Save
        </ButtonPrimary>
      </form>
    </section>
  );
};

export default ContactInformation;
