import { TSvgComponent } from "../../../types/utils/components";

const SvgLink: TSvgComponent = ({ ...props }) => {
  return (
    <svg
      viewBox="0 0 13 13"
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M12.9967 3.78766C12.9644 3.96529 12.9386 4.14468 12.8994 4.32055C12.7546 4.97363 12.4339 5.53174 11.9661 6.00425C11.3147 6.66261 10.6605 7.31744 10.0015 7.96818C9.58472 8.37972 8.94337 8.21147 8.80208 7.65981C8.74874 7.45111 8.79212 7.25706 8.91816 7.08353C8.95744 7.02901 9.00551 6.98035 9.053 6.93228C9.68321 6.30089 10.3158 5.67127 10.9448 5.03871C11.643 4.33638 11.7919 3.28818 11.313 2.47564C10.5925 1.25449 8.98265 1.0493 7.96962 2.05237C7.32357 2.69196 6.68398 3.33801 6.04028 3.97936C5.78057 4.23789 5.46635 4.293 5.17381 4.13647C4.78865 3.9307 4.6626 3.3978 4.96628 3.08592C5.72371 2.30739 6.45769 1.49602 7.28547 0.798391C8.8654 -0.532974 11.2409 -0.148983 12.4016 1.5652C12.7194 2.0342 12.9076 2.55537 12.9726 3.11992C12.9779 3.16506 12.9885 3.20962 12.9967 3.25476V3.78766Z" />
      <path d="M3.27861 12.9998C3.07518 12.9641 2.86765 12.9442 2.6695 12.889C1.33696 12.5191 0.455833 11.672 0.118156 10.3307C-0.202521 9.05617 0.136329 7.92823 1.05205 6.98555C1.68871 6.33071 2.34237 5.69229 2.98842 5.04566C3.19243 4.84106 3.43279 4.75313 3.71595 4.83755C3.97565 4.91493 4.14039 5.09374 4.20253 5.35696C4.2506 5.56097 4.20781 5.75209 4.08352 5.92093C4.04366 5.97486 3.99617 6.02411 3.94869 6.07218C3.31788 6.70357 2.68474 7.33261 2.05628 7.96634C1.39793 8.62997 1.22851 9.56679 1.61308 10.3846C2.23568 11.7083 3.96686 12.0026 5.02445 10.9603C5.67518 10.3189 6.31653 9.6688 6.96375 9.02393C7.22697 8.76187 7.54472 8.71146 7.84194 8.87678C8.21245 9.08372 8.31094 9.58555 8.04537 9.91678C8.02661 9.93964 8.00844 9.96368 7.98792 9.9842C7.2897 10.6795 6.60028 11.3842 5.89033 12.0671C5.32636 12.61 4.63577 12.8973 3.8584 12.9787C3.82557 12.9823 3.79333 12.9922 3.7605 12.9987H3.27861V12.9998Z" />
      <path d="M4.47769 9.19342C4.23201 9.17759 4.01214 9.05563 3.88726 8.79472C3.76295 8.53439 3.79579 8.28579 3.96993 8.05653C4.00276 8.01314 4.04087 7.97269 4.07957 7.93399C5.36538 6.64759 6.65061 5.36178 7.937 4.07597C8.21903 3.79395 8.53681 3.73649 8.84405 3.90946C9.20405 4.11232 9.30666 4.60132 9.05571 4.93083C9.01467 4.98419 8.96835 5.03403 8.92086 5.08211C7.6415 6.36264 6.36096 7.642 5.08219 8.92312C4.92388 9.08202 4.74739 9.19283 4.47769 9.194V9.19342Z" />
    </svg>
  );
};

export default SvgLink;
