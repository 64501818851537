import ThreeNeedlesHeading from "../../../../components/common/ThreeNeedlesHeading";
import SvgArrowCurved from "../../../../components/svgs/arrows/ArrowCurved";
import { offeringDetailsAtom } from "../../atoms";
import { useAtomValue } from "jotai";

export const OwnerShipAndManagement = () => {
  const offering = useAtomValue(offeringDetailsAtom);
  return (
    <section
      id="offering-ownership-management"
      className="relative bg-primary-yellow bg-[url(/public/assets/images/backgrounds/background-giant-blob.png)] bg-cover bg-left-bottom bg-no-repeat text-white"
    >
      <div className="section-padding container">
        <div className="mb-10 flex flex-wrap gap-x-2 text-section-heading font-bold lg:justify-end lg:text-right">
          <SvgArrowCurved className="z-10 -mr-4 mt-2 w-24 -scale-x-100 fill-dark-yellow max-lg:hidden" />
          <ThreeNeedlesHeading
            backgroundColor="white"
            needlesColor="gold"
            className="text-primary-yellow"
          >
            Ownership
          </ThreeNeedlesHeading>
          <h2 className="py-1.5">&</h2>
          <ThreeNeedlesHeading
            backgroundColor="white"
            needlesColor="gold"
            className="text-primary-yellow"
          >
            Management
          </ThreeNeedlesHeading>
          <h2 className="py-1.5">Structure</h2>
        </div>
        <div className="grid items-center gap-10 lg:grid-cols-2">
          <p className="text-2xl">{offering.description_ownership}</p>
          <img
            className="object-contain max-lg:row-start-1"
            loading="lazy"
            src="/assets/images/pages/single-offering/ownership-management.png"
            alt="Ownership and Management"
          />
        </div>
      </div>
    </section>
  );
};
export default OwnerShipAndManagement;
