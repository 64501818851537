import AddressLocation from "../../../../../components/common/AddressLocation";
import ButtonPrimary from "../../../../../components/common/buttons/Button";
import Main from "../../../../../components/layout/Main";
import SvgDollar from "../../../../../components/svgs/icons/Dollar";
import { useBelongingsOfferSingle } from "../_hooks";

const PageBelongingOfferSingle = () => {
  const { Display, input, handleSubmit } = useBelongingsOfferSingle();
  return (
    <Main id="belonging-offer">
      <section
        id="belonging-offer-overview"
        className="container section-margin"
      >
        <form onSubmit={handleSubmit}>
          <Display
            success={(data) => (
              <>
                <h2 className="mb-4 font-bold text-tertiary-green section-heading">
                  Make an offer
                </h2>
                <article className="mb-8 flex flex-wrap gap-4 rounded-xl border border-primary-green p-6">
                  <img
                    src={
                      data.offering_item.purchased_offering.offering.thumbnail
                    }
                    alt={data.offering_item.title}
                    title={data.offering_item.title}
                    className="size-40 rounded-2xl object-cover"
                    height={620}
                    width={620}
                  />
                  <div className="flex flex-grow flex-col justify-between gap-4 py-4">
                    <div>
                      <h3 className="text- font-extrabold">
                        {data.offering_item.title}
                      </h3>
                      <p>
                        {
                          data.offering_item.purchased_offering.offering
                            .short_description
                        }
                      </p>
                    </div>
                    <AddressLocation
                      location={
                        data.offering_item.purchased_offering.offering.location
                      }
                    />
                  </div>
                  <div className="ml-auto flex flex-col flex-wrap items-end justify-between gap-4 py-4">
                    <p className="font-extrabold">
                      ${data.price}&nbsp;
                      {data.offering_item.purchased_offering.offering.unit
                        ?.unit !== "unit" && (
                        <span className="text-sm font-normal text-primary-gray/75">
                          |&nbsp;
                          {
                            data.offering_item.purchased_offering.offering.unit
                              ?.unit
                          }
                        </span>
                      )}
                    </p>
                    <div className="flex flex-wrap items-center justify-center gap-6">
                      <div className="grid grid-cols-3 rounded-md bg-secondary-green/25">
                        <button
                          type="button"
                          title="Increment"
                          className="px-4 py-2 hover-link"
                          onClick={() => {
                            input.current?.value &&
                              +input.current?.value > 1 &&
                              input.current?.stepDown();
                          }}
                        >
                          -
                        </button>
                        <input
                          ref={input}
                          id="quantity-input"
                          type="number"
                          className="w-12 bg-transparent text-center"
                          readOnly
                          defaultValue="1"
                          tabIndex={-1}
                          name="quantity"
                          required
                        />
                        <button
                          type="button"
                          title="Increment"
                          className="px-4 py-2 hover-link"
                          onClick={() => {
                            input.current?.value &&
                              +input.current?.value < +data.quantity &&
                              input.current?.stepUp();
                          }}
                        >
                          +
                        </button>
                      </div>
                    </div>
                  </div>
                </article>
                <h3 className="mb-6 text-primary-green section-heading">
                  Listed price ${data.price}
                </h3>
                <div className="mb-6 flex items-center gap-4">
                  <div className="flex-grow">
                    <p className="text-primary-green">
                      I want to offer another amount!
                    </p>
                    <p>
                      Your offer is not payment. Purchase details are arranged
                      later with the seller.
                    </p>
                  </div>
                  <input
                    type="number"
                    min="0"
                    step="0.01"
                    className="w-full max-w-xs rounded-md bg-primary-gray/15 px-4 py-2 text-tertiary-green placeholder:text-tertiary-green"
                    placeholder="Enter Your Preffered Amount"
                    name="offered_price"
                    required
                  />
                </div>
              </>
            )}
          />
          <ButtonPrimary
            className="group ml-auto flex items-center gap-2"
            type="submit"
            title="Submit"
          >
            <SvgDollar className="group-hover:fill-whit size-8 fill-black duration-300 group-hover:fill-white [&_path]:stroke-black group-hover:[&_path]:stroke-white" />
            Make an offer
          </ButtonPrimary>
        </form>
      </section>
    </Main>
  );
};

export default PageBelongingOfferSingle;
