import { useAtom, useSetAtom } from "jotai";
import { atomSelectedBelongings } from "./_atom";
import { useRef } from "react";
import ButtonPrimary from "../../../../components/common/buttons/Button";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { TLaravelResponse } from "../../../../types/utils/laravel";
import { toast } from "sonner";
import SvgTrash from "../../../../components/svgs/icons/Trash";
import AddressLocation from "../../../../components/common/AddressLocation";
import Modal from "../../../../components/common/Modal";
import { toggleModal } from "../../../../utils/functions";
import SvgAradinaIcon from "../../../../components/svgs/logos/AradinaIcon";
import SvgTag from "../../../../components/svgs/icons/Tag";

const Quantity = () => {
  const [selectedBelongings, setSelectedBelongings] = useAtom(
    atomSelectedBelongings,
  );
  const { mutate, isPending } = useMutation({
    mutationFn: async (form: FormData) => {
      const res = await axios.post<TLaravelResponse>(
        "user-belongings/sell",
        form,
      );
      return res.data;
    },
    onSuccess: (res) => {
      if (res?.success) {
        toast.success("Success");
        setSelectedBelongings([]);
        toggleModal("sell-to-aradina-confirmation", "close");
        toggleModal("sold-to-aradina");
      }
    },
  });
  const form = useRef<HTMLFormElement>(null);
  const handleClick = () => {
    form && form.current && mutate(new FormData(form.current));
  };
  return (
    <>
      {selectedBelongings.length > 0 && (
        <section id="sell-to-aradina-quantity" className="container my-10">
          <form ref={form}>
            <ul className="mb-8 grid gap-8 rounded-xl bg-white p-5 shadow-[0_0_15px_2px_#0002]">
              {selectedBelongings.map((belonging, index) => (
                <li
                  key={belonging.id}
                  className="grid after:w-full after:rounded-full after:bg-primary-gray/30 [&:not(:last-of-type)]:after:mt-8 [&:not(:last-of-type)]:after:h-0.5"
                >
                  <SingleBelonging
                    index={index}
                    selectedBelongings={selectedBelongings}
                    setSelectedBelongings={setSelectedBelongings}
                  />
                </li>
              ))}
            </ul>
            <ButtonPrimary
              type="button"
              title="Submit"
              className="ml-auto block px-10"
              onClick={() => toggleModal("sell-to-aradina-confirmation")}
            >
              Submit
            </ButtonPrimary>
          </form>
        </section>
      )}
      <Modal id="sell-to-aradina-confirmation" className="max-w-sm text-center">
        <div className="mx-auto mb-4 size-28 content-center rounded-full bg-tertiary-green">
          <SvgAradinaIcon className="m-auto size-16 translate-y-1" />
        </div>
        <h4 className="mb-4 text-xl font-extrabold">Sell to Aradina</h4>
        <p className="mb-6">
          Are you sure you want to sell your produce to Aradina?
        </p>
        <ButtonPrimary
          title="Yes"
          onClick={handleClick}
          isLoading={isPending}
          type="button"
        >
          Yes
        </ButtonPrimary>
      </Modal>
      <Modal id="sold-to-aradina" className="max-w-sm text-center">
        <div className="mx-auto mb-4 size-28 content-center rounded-full bg-tertiary-green">
          <SvgTag className="mx-auto size-16 translate-x-1 translate-y-1" />
        </div>
        <h4 className="mb-4 text-xl font-extrabold">Request Sent</h4>
        <p className="mb-6">
          You will receive a notification once Aradina prices the items for you.
        </p>
        <ButtonPrimary
          title="Thank You"
          onClick={() => toggleModal("sold-to-aradina")}
          isLoading={isPending}
          type="button"
        >
          Great!
        </ButtonPrimary>
      </Modal>
    </>
  );
};

export default Quantity;

type TProps = {
  index: number;
  selectedBelongings: ReturnType<
    typeof useAtom<typeof atomSelectedBelongings>
  >[0];
  setSelectedBelongings: ReturnType<
    typeof useSetAtom<typeof atomSelectedBelongings>
  >;
};

const SingleBelonging = ({
  index,
  selectedBelongings,
  setSelectedBelongings,
}: TProps) => {
  const ref = useRef<HTMLInputElement>(null);
  const handleDecrement = () => {
    if (ref.current && +ref.current.value > 1) {
      ref.current.stepDown();
    }
  };
  const handleIncrement = () => {
    if (ref.current && +ref.current.value < 10) ref.current.stepUp();
  };
  return (
    <article className="relative flex gap-2">
      <img
        className="size-32 rounded-xl object-cover"
        src={
          selectedBelongings[index].offering_item.purchased_offering.offering
            .thumbnail
        }
        alt={selectedBelongings[index].offering_item.title}
      />
      <div className="flex flex-grow flex-col justify-between gap-4">
        <div>
          <h3 className="text-xl font-extrabold">
            {selectedBelongings[index].offering_item.title}
          </h3>
          <p className="text-base">
            {
              selectedBelongings[index].offering_item.purchased_offering
                .offering.short_description
            }
          </p>
        </div>
        <AddressLocation
          location={
            selectedBelongings[index].offering_item.purchased_offering.offering
              .location
          }
        />
      </div>
      <div className="flex flex-col justify-end">
        <button
          className="absolute right-2 top-2 hover-link"
          onClick={() =>
            setSelectedBelongings(
              selectedBelongings.filter((_, i) => i !== index),
            )
          }
        >
          <SvgTrash className="size-5" />
        </button>
        <div className="flex flex-wrap items-center justify-center gap-6">
          <div className="grid w-36 grid-cols-3 rounded-md bg-secondary-green/25 has-[button:disabled]:bg-primary-gray/25">
            <input
              hidden
              type="text"
              name={`items[${index}][user_belonging_id]`}
              defaultValue={selectedBelongings[index].id}
            />
            <button
              type="button"
              title="Increment"
              className="px-4 py-2 hover-link"
              onClick={handleDecrement}
            >
              -
            </button>
            <input
              ref={ref}
              name={`items[${index}][sold_qty]`}
              type="number"
              step="1"
              min="1"
              defaultValue="1"
              className="w-12 bg-transparent text-center"
            />
            <button
              type="button"
              title="Increment"
              className="px-4 py-2 hover-link"
              onClick={handleIncrement}
            >
              +
            </button>
          </div>
        </div>
      </div>
    </article>
  );
};
