import Banner from "./components/Banner";
import Resources from "./components/Resources";
import Articles from "./components/Articles";
import Inquiries from "./components/Inquiries";
import Main from "../../../components/layout/Main";

const PageMedia = () => {
  return (
    <Main id="media">
      <Banner />
      <Resources />
      <Articles />
      <Inquiries />
    </Main>
  );
};

export default PageMedia;
