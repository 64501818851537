import { TSvgComponent } from "../../../types/utils/components";

const SvgGoldCoins: TSvgComponent = (props) => {
  return (
    <svg
      viewBox="0 0 43 32"
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M31.5325 8.86974V7.88117C31.5325 3.42996 24.8184 0.0720215 15.9142 0.0720215C7.00999 0.0720215 0.295898 3.42996 0.295898 7.88117V14.9804C0.295898 18.688 4.95477 21.6341 11.6547 22.5162V23.4995C11.6547 27.9507 18.3688 31.3086 27.273 31.3086C36.1772 31.3086 42.8913 27.9507 42.8913 23.4995V16.4002C42.8913 12.7264 38.3797 9.77666 31.5325 8.86974ZM40.0516 16.4002C40.0516 18.7465 34.5869 21.3697 27.273 21.3697C26.611 21.3697 25.9543 21.3466 25.3065 21.304C29.1347 19.909 31.5325 17.6426 31.5325 14.9804V11.7396C36.8338 12.5294 40.0516 14.6734 40.0516 16.4002ZM11.6547 19.6393V15.417C13.067 15.601 14.4899 15.6923 15.9142 15.6903C17.3385 15.6923 18.7614 15.601 20.1737 15.417V19.6393C18.7635 19.8476 17.3398 19.9514 15.9142 19.9499C14.4886 19.9514 13.0649 19.8476 11.6547 19.6393ZM28.6928 12.4832V14.9804C28.6928 16.4695 26.4903 18.0686 23.0134 19.0394V14.8917C25.3047 14.3361 27.2446 13.5091 28.6928 12.4832ZM15.9142 2.91171C23.2282 2.91171 28.6928 5.53488 28.6928 7.88117C28.6928 10.2275 23.2282 12.8506 15.9142 12.8506C8.60022 12.8506 3.13559 10.2275 3.13559 7.88117C3.13559 5.53488 8.60022 2.91171 15.9142 2.91171ZM3.13559 14.9804V12.4832C4.58383 13.5091 6.52369 14.3361 8.81497 14.8917V19.0394C5.33812 18.0686 3.13559 16.4695 3.13559 14.9804ZM14.4944 23.4995V22.7594C14.9611 22.7771 15.4332 22.7895 15.9142 22.7895C16.6028 22.7895 17.2755 22.7665 17.9357 22.7274C18.6692 22.99 19.4163 23.2128 20.1737 23.3948V27.5584C16.6969 26.5876 14.4944 24.9885 14.4944 23.4995ZM23.0134 28.1583V23.9254C24.4253 24.1152 25.8484 24.2101 27.273 24.2094C28.6972 24.2114 30.1201 24.1201 31.5325 23.9361V28.1583C28.7079 28.5724 25.838 28.5724 23.0134 28.1583ZM34.3722 27.5584V23.4107C36.6635 22.8552 38.6033 22.0282 40.0516 21.0023V23.4995C40.0516 24.9885 37.849 26.5876 34.3722 27.5584Z" />
    </svg>
  );
};

export default SvgGoldCoins;
