import ThreeNeedlesHeading from "../../../../components/common/ThreeNeedlesHeading";
import overviewPageData from "../data";

const AradinaEmpowers = () => {
  return (
    <section
      id="overview-aradina-empowers"
      className="relative overflow-x-clip bg-primary-green bg-opacity-50 text-white"
    >
      <div className="single-grid">
        <div className="container grid section-padding single-grid-item md:grid-cols-[2fr_1fr]">
          <div>
            <div className="mb-4 flex flex-wrap gap-x-2 text-section-heading">
              {/* <h2 className="py-1.5">Aradina</h2> */}
              <ThreeNeedlesHeading
                backgroundColor="primary-green"
                needlesColor="black"
              >
                Aradina
              </ThreeNeedlesHeading>
            </div>
            <ul className="ml-5 mt-2 list-disc">
              {overviewPageData.aradinaEmpowers.map((text, index) => {
                return (
                  <li key={`overview_aradina_empowers_${index}`}>
                    <p className="font-semibold">{text}</p>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        <div className="grid grid-cols-[3fr_1fr] items-center single-grid-item max-sm:hidden">
          <img
            className="col-start-2 h-max scale-125 object-contain"
            src="/assets/images/pages/about/overview/aradina-empowers.png"
            alt=""
          />
        </div>
      </div>
      <img
        className="absolute left-0 top-0 origin-top-left -translate-x-1/3 -translate-y-1/2 md:-translate-y-1/3"
        src="/assets/images/pages/about/overview/aradina-empowers-plants.png"
        alt=""
      />
    </section>
  );
};

export default AradinaEmpowers;
