import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { TLaravelSuccess } from "../../../types/utils/laravel";
import { TOfferingDisplay } from "../../../types/offering";

export const useOurOfferings = () => {
  const { data, isLoading } = useQuery({
    queryKey: ["offerings", "featured"],
    queryFn: async () => {
      const response = await axios.get<TLaravelSuccess<TOfferingDisplay[]>>(
        "offerings/",
        {
          params: { isFeatured: true },
        },
      );
      return response.data.data;
    },
  });
  return { data, isLoading };
};
