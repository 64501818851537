import { TSvgComponent } from "../../../types/utils/components";

const SvgCommunication: TSvgComponent = (props) => {
  return (
    <svg
      viewBox="0 0 74 73"
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M36.1237 0.133801C32.9191 0.610777 30.2361 2.54849 28.9542 5.27618C28.1493 6.99031 28.1344 7.16918 28.1791 19.4513L28.2238 30.7943L28.6412 31.3309C29.1778 32.0464 29.7591 32.3296 30.6087 32.3296C31.503 32.3296 31.7713 32.1805 36.0492 29.2889L39.7011 26.8146H49.5387C60.5836 26.8146 60.3898 26.8295 62.4766 25.7414C64.6826 24.5937 66.3371 22.5069 66.978 20.1071C67.306 18.8849 67.306 7.88464 66.978 6.63258C66.0837 3.21923 63.1026 0.655493 59.3912 0.148708C58.0646 -0.045063 37.346 -0.045063 36.1237 0.133801ZM58.5266 7.43748C58.9589 7.83992 58.9589 8.57029 58.5266 9.03236L58.2136 9.37519H48.4655C38.926 9.37519 38.7322 9.37519 38.4341 9.07708C38.2552 8.89821 38.136 8.5852 38.136 8.25728C38.136 7.92936 38.2552 7.61634 38.4341 7.43748C38.7322 7.13937 38.926 7.13937 48.4655 7.13937C58.0646 7.13937 58.2136 7.13937 58.5266 7.43748ZM58.5117 12.5351C58.944 12.9376 58.9738 13.7276 58.5565 14.1449C58.2583 14.443 58.0646 14.443 48.54 14.443H38.8216L38.4788 14.1151C38.0316 13.6978 38.0167 12.9227 38.4639 12.5053C38.7769 12.2072 38.926 12.2072 48.4804 12.2072H58.1689L58.5117 12.5351ZM58.5117 17.7521C58.9589 18.1694 58.9589 18.8551 58.5266 19.3171L58.2136 19.66H48.5102H38.8216L38.4788 19.332C38.0465 18.9296 38.0167 18.1396 38.4341 17.7222C38.7322 17.4241 38.926 17.4241 48.4506 17.4241H58.1689L58.5117 17.7521Z" />
      <path d="M13.6762 35.1614C10.2033 35.7576 7.16256 37.3674 4.68825 39.9311C1.67735 43.0464 0.171896 46.579 0.0228417 50.8568C-0.0665911 53.1523 0.097369 54.5385 0.678682 56.4017C1.36433 58.563 2.16923 59.9641 4.18146 62.4831C6.16389 64.9723 7.22218 67.551 7.37123 70.3383C7.46066 71.9779 7.60972 72.3505 8.22084 72.5741C8.51895 72.6635 11.8876 72.7231 18.0435 72.7231C27.9557 72.7231 28.1047 72.7082 28.3581 72.0524C28.4028 71.9182 28.4475 70.5171 28.4475 68.9223V66.0455L29.9083 65.9859C32.4422 65.8964 32.9192 65.3449 32.9192 62.5576V60.7988H33.8731C34.9314 60.7988 35.4233 60.5305 35.8407 59.7256C36.1984 59.025 36.0344 58.4586 34.7824 56.2377C32.9937 53.0778 32.9192 52.884 32.7552 50.7078C32.6062 48.6359 32.3826 47.518 31.8013 45.8784C29.9232 40.4826 24.9001 36.2197 19.2509 35.2359C17.9094 34.9974 14.8687 34.9676 13.6762 35.1614Z" />
      <path d="M54.1591 35.3105C49.8514 36.2346 46.125 38.7536 43.77 42.316C41.9813 45.0139 41.236 47.3243 40.9826 51.0804C40.8336 53.1225 40.7441 53.361 39.0598 56.3272C38.0612 58.0711 37.9121 58.4288 37.9121 59.0549C37.9121 59.6958 37.9717 59.8299 38.4636 60.292C38.9853 60.7541 39.1045 60.7988 39.9989 60.7988H40.9677V62.5576C40.9677 65.345 41.4447 65.8965 43.9786 65.9859L45.4394 66.0455V69.0713C45.4394 71.9481 45.4543 72.0971 45.7524 72.3654C46.0505 72.6337 46.4678 72.6486 55.5602 72.6933C61.895 72.7232 65.2189 72.6933 65.5766 72.5741C66.2623 72.3803 66.4113 72.0226 66.5157 70.2638C66.6498 67.7447 67.6932 65.0767 69.3626 62.9601C72.135 59.4275 73.1188 57.5047 73.7001 54.4342C74.0578 52.5114 73.9237 49.3067 73.4169 47.3541C72.6716 44.5518 71.2705 42.0924 69.1838 39.9162C67.0672 37.7251 64.5333 36.2495 61.5373 35.4595C59.689 34.9825 56.0222 34.8931 54.1591 35.3105Z" />
      <path
        d="M37.8379 8.03369H58.1093"
        stroke="url(#paint0_linear_5538_2443)"
        strokeWidth="2.68298"
        strokeLinecap="round"
      />
      <path
        d="M37.8379 13.3994H58.1093"
        stroke="url(#paint1_linear_5538_2443)"
        strokeWidth="2.68298"
        strokeLinecap="round"
      />
      <path
        d="M37.8379 18.7656H58.1093"
        stroke="url(#paint2_linear_5538_2443)"
        strokeWidth="2.68298"
        strokeLinecap="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_5538_2443"
          x1="37.8379"
          y1="9.03369"
          x2="58.1093"
          y2="9.03369"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#40730E" />
          <stop offset="1" stopColor="#002B14" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_5538_2443"
          x1="37.8379"
          y1="14.3994"
          x2="58.1093"
          y2="14.3994"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#40730E" />
          <stop offset="1" stopColor="#002B14" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_5538_2443"
          x1="37.8379"
          y1="19.7656"
          x2="58.1093"
          y2="19.7656"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#40730E" />
          <stop offset="1" stopColor="#002B14" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default SvgCommunication;
