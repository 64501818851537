import useFetchPartners from "../_hooks";

const PartnersGrid = () => {
  const { data, isLoading } = useFetchPartners();
  return (
    <section
      id="partners-logos"
      className="section-margin container text-white"
    >
      {isLoading ? (
        <p>Laoding Partners...</p>
      ) : (
        <div className="grid auto-rows-fr gap-20 md:grid-cols-2 xl:grid-cols-3">
          {data?.map(({ id, logo_url, description }) => {
            return (
              <article
                key={`partners_logo_${id}`}
                className="grid grid-rows-[5fr_2fr] rounded-xl bg-primary-green bg-opacity-70"
              >
                <div className="flex justify-center">
                  <img
                    src={logo_url}
                    alt={`logo_${id}`}
                    className="my-auto box-content h-32 w-32 rounded-full object-cover"
                  />
                </div>
                <p className="rounded-xl bg-[#B1BEB1] p-4 text-center">
                  {description}
                </p>
              </article>
            );
          })}
        </div>
      )}
    </section>
  );
};

export default PartnersGrid;
