import { useAtomValue } from "jotai";
import userAtom from "../../../authentication/_atom";
import { Link } from "react-router-dom";
import { useUpdateUser } from "../../account/_hooks";

const Overview = () => {
  useUpdateUser();
  const user = useAtomValue(userAtom);
  return (
    <section id="sell-overview" className="container my-10">
      <h2 className="mb-3 text-primary-green section-heading">
        Overview <span className="text-base">Track my data</span>
      </h2>
      <div className="mb-10 grid lg:grid-cols-4 gap-10">
        <article className="flex flex-col justify-between rounded-lg border-2 border-tertiary-green p-4 capitalize text-tertiary-green">
          <p className="mb-4 text-base ">Total Sales</p>
          <div>
            <h3 className="text-2xl font-extrabold">
              ${user?.user.total_sales}
            </h3>
            <p className="text-lg">Total items</p>
          </div>
        </article>
        {/* <article className="flex flex-col justify-between rounded-lg border-2 border-tertiary-green p-4 capitalize text-tertiary-green">
          <p className="mb-4 text-base">Total views</p>
          <div>
            <h3 className="text-2xl font-extrabold">Number</h3>
            <p className="text-lg">On {user?.user.listings_count} listings</p>
          </div>
        </article>
        <article className="flex flex-col justify-between rounded-lg border-2 border-tertiary-green p-4 capitalize text-tertiary-green">
          <p className="mb-4 text-base">Inventory</p>
          <div>
            <h3 className="text-2xl font-extrabold">Number</h3>
            <p className="text-lg">Available total items</p>
          </div>
        </article> */}
        <article className="flex flex-col justify-between rounded-lg border-2 border-tertiary-green p-4 capitalize text-tertiary-green">
          <p className="mb-4 text-base">Total profit</p>
          <div>
            <h3 className="text-2xl font-extrabold">
              {user?.user.total_profit && user?.user.total_profit < 0
                ? `-$${Math.abs(user?.user.total_profit)}`
                : `$${user?.user.total_profit}`}
            </h3>
            <p className="text-lg">Available total items</p>
          </div>
        </article>
      </div>
      {/* <article className="mb-10 rounded-lg bg-gray-100 p-4">
        <div className="flex items-center justify-between gap-4">
          <p>Quantity Purchased of all produce:</p>
          <p>Unit</p>
        </div>
        <div className="flex items-center justify-between gap-4">
          <p>Quantity Purchased of all produce:</p>
          <p>Unit</p>
        </div>
        <div className="flex items-center justify-between gap-4">
          <p>Clients:</p>
          <p>Unit</p>
        </div>
      </article> */}
      <Link
        to="/user/offerings/offers"
        title="Offers"
        className="w-full rounded-lg bg-gradient-to-r from-primary-green to-tertiary-green p-4 text-center text-white hover-link"
      >
        Offers Made by Clients
      </Link>
    </section>
  );
};

export default Overview;
