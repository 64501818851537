import Banner from "./components/Banner";
import ContactUs from "./components/ContactUs";
import EmbeddedMap from "./components/EmbeddedMap";
import Main from "../../../components/layout/Main";

const PageContact = () => {
  return (
    <Main id="contact">
      <Banner />
      <ContactUs />
      <EmbeddedMap />
    </Main>
  );
};

export default PageContact;
