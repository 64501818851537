import overviewPageData from "../data";

const ExpertiseTransparencyReturns = () => {
  return (
    <section
      id="overview-expertise-transparency-returns"
      className="bg-[url(/public/assets/images/backgrounds/background-giant-blob.png)] bg-contain bg-left bg-no-repeat text-white"
    >
      <div className="container">
        <div className="-mt-10 mb-10 ml-4 h-40 w-1 rounded-xl bg-primary-green"></div>
        <div className="section-margin auto-rows-1fr grid gap-x-20 gap-y-40 md:grid-cols-2 xl:grid-cols-3">
          {overviewPageData.cards.map(({ title, content }, index) => {
            return (
              <article
                key={`overview_cards_${index}`}
                className="relative grid rounded-2xl bg-[#11340B] pb-6"
              >
                <div className="absolute grid aspect-square w-48 -translate-y-2/3 place-items-center justify-self-center rounded-full bg-primary-green">
                  <h2 className="text-2xl">{title}</h2>
                </div>
                <span className="absolute right-0 top-3 -translate-y-full text-6xl font-bold leading-none text-primary-green text-opacity-50 xl:text-8xl">
                  {index + 1}
                </span>
                <div className="flex flex-col justify-between gap-8 p-6 pt-20 text-center">
                  {content.map((text, pIndex) => {
                    return (
                      <p key={`overview_cards_${index}_text${pIndex}`}>
                        {text}
                      </p>
                    );
                  })}
                </div>
              </article>
            );
          })}
        </div>
      </div>
      <div className="absolute bottom-0 right-0 h-1 w-1/6 rounded-xl bg-primary-green"></div>
    </section>
  );
};

export default ExpertiseTransparencyReturns;
