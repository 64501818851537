import { Link } from "react-router-dom";
import { socialPlatformData } from "../../../constants/social";

const FooterSocials = () => {
  return (
    <div>
      <h4 className="mb-6 font-semibold uppercase text-light-green underline">
        Stay connected & follow us
      </h4>
      <ul>
        {socialPlatformData.map(({ id, platform, url }) => (
          <li key={`footer_${id}`} className="w-max cursor-pointer uppercase">
            <Link
              to={url}
              target="_blank"
              rel="noopener noreferrer"
              title={platform}
              className="hover-link"
            >
              {platform}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default FooterSocials;
