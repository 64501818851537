import { styled, TextField } from "@mui/material";
import { useState } from "react";

const CustomTextField = styled(TextField)({
  "& label": {
    color: "white",
    fontStyle: "italic",
  },
  "& label.Mui-focused": {
    color: "white",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "white",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "white",
    },
    "&:hover fieldset": {
      borderColor: "white",
    },
    "&.Mui-focused fieldset": {
      borderColor: "white",
    },
  },
});
const FooterNewsletter = () => {
  const [email, setEmail] = useState("");
  const onChangeEmail = (value: string) => {
    setEmail(value);
  };
  return (
    <div>
      <h4 className="mb-6 font-semibold uppercase text-light-green underline">
        Stay up to our newsletter
      </h4>
      <div className="relative mb-6 flex max-w-md">
        <CustomTextField
          sx={{ input: { color: "white", width: "13rem" } }}
          id="outlined-basic"
          fullWidth
          value={email}
          onChange={(event) => onChangeEmail(event.target.value)}
          label="Enter Your Email"
          variant="outlined"
        />
        <button
          type="submit"
          className="absolute bottom-0 right-0 top-0 rounded-md bg-light-green px-10 font-bold text-primary-green"
        >
          Join
        </button>
      </div>
    </div>
  );
};

export default FooterNewsletter;
