import { useQuery } from "@tanstack/react-query";
import Main from "../../../components/layout/Main";
import axios from "axios";
import { TLaravelResponse } from "../../../types/utils/laravel";
import { TOfferingBelonging } from "../../../types/offering";
import LoadingImage from "../../../components/common/LoadingImage";
import CardBelonging from "../../../components/common/cards/OfferingBelonging";

const PageWishlistMarketplace = () => {
  const { data, isLoading } = useQuery({
    queryKey: ["user-offerings", "wishlist"],
    queryFn: async () => {
      const res = await axios.get<TLaravelResponse<TOfferingBelonging[]>>(
        "marketplace-wishlist",
      );
      return res.data;
    },
  });
  return (
    <Main id="wishlist-marketplace">
      <section
        id="wishlist-marketplace-grid"
        className="container section-margin"
      >
        <ul className="grid-offerings">
          {isLoading ? (
            [...Array(9)].map((_, index) => {
              return (
                <li key={`offering_loading_${index}`}>
                  <LoadingImage />
                </li>
              );
            })
          ) : data?.success ? (
            data.data.map((offering) => {
              return (
                <li key={`offerings_belongings_${offering.id}`}>
                  <CardBelonging offeringBelonging={offering} />
                </li>
              );
            })
          ) : (
            <p>Something went wrong...</p>
          )}
        </ul>
        {data?.success && data.data.length === 0 && (
          <p>You have no offerings in your wishlist.</p>
        )}
      </section>
    </Main>
  );
};

export default PageWishlistMarketplace;
