import { TSvgComponent } from "../../../types/utils/components";

const SvgExpert: TSvgComponent = ({ ...props }) => {
  return (
    <svg
      viewBox="0 0 62 65"
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M0.00195312 54.8388C0.271334 53.4882 0.450294 52.113 0.823284 50.7906C3.08383 42.7826 10.0538 36.8864 18.3218 36.244C22.1515 35.9464 26.017 36.1065 29.8656 36.1065C31.075 36.1065 32.015 37.184 31.9962 38.3802C31.9773 39.6047 31.0015 40.5861 29.7224 40.5937C26.6236 40.6106 23.5229 40.5918 20.4241 40.6012C12.8117 40.6257 6.49348 45.6667 4.90168 53.1114C4.52492 54.8784 4.54564 56.7302 4.37422 58.6441C4.84893 58.6441 5.12208 58.6441 5.39523 58.6441C13.4503 58.6441 21.5035 58.6422 29.5585 58.646C31.2954 58.646 32.4162 60.1361 31.8586 61.6657C31.5365 62.5492 30.7321 63.1332 29.7507 63.1482C28.6242 63.1671 27.4958 63.1539 26.3693 63.1539C18.5742 63.1539 10.7772 63.1294 2.9821 63.1765C1.57491 63.184 0.580275 62.7583 0.00383691 61.4622V54.837L0.00195312 54.8388Z" />
      <path d="M41.2383 54.8161C41.2383 52.5857 41.2571 50.3553 41.2213 48.1249C41.2138 47.7105 41.0744 47.2414 40.8484 46.8948C36.316 39.9531 40.53 30.6377 48.7452 29.6582C53.3755 29.1062 57.1356 30.8638 59.6052 34.8386C62.056 38.7813 61.8997 42.8371 59.5129 46.8213C59.2661 47.2339 59.0928 47.7632 59.0891 48.2398C59.057 52.6309 59.0589 57.022 59.0796 61.4131C59.0834 62.4323 58.8234 63.2856 57.8891 63.7848C56.9302 64.2953 56.0486 64.0429 55.1934 63.4439C53.6958 62.3927 52.1605 61.3943 50.6591 60.3469C50.2993 60.0964 50.0507 60.0756 49.6777 60.3356C48.1405 61.4113 46.5675 62.4379 45.0247 63.506C44.2053 64.0749 43.3519 64.2633 42.4477 63.7961C41.5322 63.3233 41.2289 62.502 41.2364 61.5073C41.2515 59.2769 41.2402 57.0465 41.2402 54.8161H41.2383ZM50.2371 34.0794C46.5581 34.0323 43.5817 36.9277 43.5252 40.6105C43.4668 44.2707 46.4394 47.3149 50.1147 47.362C53.6751 47.4072 56.7494 44.4101 56.8059 40.8384C56.8643 37.1368 53.9388 34.1246 50.2371 34.0775V34.0794ZM45.7971 57.5194C46.7164 56.9052 47.5471 56.3514 48.376 55.7919C49.8434 54.8011 50.482 54.8011 51.9457 55.7882C52.7878 56.3571 53.6336 56.9184 54.5322 57.5212V51.0316C51.6104 52.1845 48.715 52.1826 45.7989 51.0391V57.5212L45.7971 57.5194Z" />
      <path d="M47.3692 15.7748C47.3748 24.4854 40.3182 31.5666 31.6208 31.5779C22.8932 31.5892 15.7875 24.4892 15.7988 15.7673C15.8083 7.07173 22.8951 0 31.5925 0C40.2975 0 47.3635 7.06608 47.3692 15.7748ZM42.8594 15.8181C42.8688 9.57528 37.8316 4.51543 31.6019 4.51167C25.4024 4.5079 20.3331 9.54137 20.3086 15.7221C20.2841 21.9536 25.327 27.0455 31.5416 27.0662C37.7788 27.0851 42.85 22.0459 42.8594 15.8181Z" />
    </svg>
  );
};

export default SvgExpert;
