import ThreeNeedlesHeading from "../../../components/common/ThreeNeedlesHeading";
import SvgAradinaIcon from "../../../components/svgs/logos/AradinaIcon";
import SvgArrowCurved from "../../../components/svgs/arrows/ArrowCurved";
import { homeData } from "../data";
export const HighProfit = () => {
  return (
    <section
      id="home-high-profit"
      className="relative overflow-x-clip bg-primary-yellow bg-[url(/public/assets/images/backgrounds/background-blobs.png)] bg-cover bg-no-repeat text-white"
    >
      <div className="absolute flex -translate-y-1/2 items-center gap-5">
        <div className="h-1 w-32 rounded-lg bg-white"></div>
        <h2 className="gradient-text bg-gradient-to-r from-white to-[#FFF647] text-center text-section-heading font-bold">
          High Profit <br />
          Potential
        </h2>
      </div>
      <div className="section-padding xl:single-grid">
        <div className="xl:single-grid-item grid max-xl:container xl:grid-cols-2">
          <div className="col-start-2 flex flex-col justify-between gap-4">
            <div className="flex flex-wrap gap-x-2 text-section-heading font-bold max-xl:justify-end max-xl:self-end">
              <SvgArrowCurved className="w-20 -scale-x-100 fill-dark-yellow max-md:hidden" />
              <h2 className="py-1.5">Why should you use</h2>
              <ThreeNeedlesHeading
                backgroundColor="white"
                needlesColor="rgb(var(204,153,0))"
                className="text-primary-yellow"
              >
                Aradina
              </ThreeNeedlesHeading>
            </div>
            <img
              loading="lazy"
              src="/assets/images/pages/home/high-profit.png"
              alt=""
            />
          </div>
        </div>
        <div className="single-grid-item container grid xl:grid-cols-2">
          <div>
            <SvgAradinaIcon className="w-12" />
            <p className="my-4">
              <b>Aradina</b> offers an alternative funding option to the
              traditional bank loan route by connecting farmers and cattle
              breeders to experienced or passive investors with a passion for
              agriculture. You keep control of the operation while raising the
              capital needed to fund growth.
            </p>
            <div className="flex-start flex">
              <div className="flex flex-col">
                {homeData.highProfit.map(({ id, title, Icon }) => {
                  return (
                    <div
                      key={`home_high_profit_${id}`}
                      className="grid grid-cols-[auto_1fr] place-items-center gap-x-4"
                    >
                      <div className="h-6 w-px bg-white"></div>
                      <div className="col-start-1 grid place-items-center rounded-full border border-white p-2">
                        <Icon className="h-7 w-7" />
                      </div>
                      <h4 className="col-start-2 row-start-2 w-max justify-self-start text-xl text-white">
                        {title}
                      </h4>
                    </div>
                  );
                })}
              </div>
            </div>{" "}
          </div>
        </div>
      </div>
    </section>
  );
};
export default HighProfit;
