import { FC } from "react";
import { Link } from "react-router-dom";

type TProps = {
  status: number;
  message: string;
  path: string;
  page: string;
};

const ErrorDisplay: FC<TProps> = ({ status, message, path, page }) => {
  return (
    <main
      id="page-not-found"
      className="min-w-screen grid min-h-screen place-items-center bg-primary-green bg-[url(/public/assets/images/backgrounds/background-giant-blob.png)] bg-right bg-no-repeat text-white"
    >
      <section className="container text-center" id="not-found-section">
        <h1 className="mb-4 text-6xl">{status}</h1>
        <h2 className="mb-4 text-4xl">{message}</h2>
        <p>
          Click&nbsp;
          <Link
            className="text-primary-yellow duration-500 hover:-translate-y-0.5 hover:translate-x-0.5"
            to={path}
          >
            Here
          </Link>
          &nbsp;to be redirected to the {page} page.
        </p>
      </section>
    </main>
  );
};

export default ErrorDisplay;
