import AddressLocation from "../../../../../components/common/AddressLocation";
import SvgAradinaIcon from "../../../../../components/svgs/logos/AradinaIcon";
import { TMyBelonging } from "../../../../../types/offering";

type TProps = {
  data: TMyBelonging;
};

const Banner = ({ data }: TProps) => {
  return (
    <section id="my-belonging-banner" className="container my-10">
      <div className="rounded-2xl bg-gradient-to-r from-tertiary-green to-secondary-green">
        <div className="h-80 w-full overflow-hidden rounded-2xl single-grid">
          <img
            src={data.purchased_offering.offering.thumbnail}
            alt={data.title}
            title={data.title}
            className="size-full rounded-2xl object-cover single-grid single-grid-item"
            height={320}
            width="100%"
          />
          <div className="grid size-full place-items-center bg-black/50 single-grid-item">
            <div className="text-center text-white">
              <SvgAradinaIcon className="mx-auto mb-4 w-20" />
              <h1 className="mb-4 text-3xl font-extrabold">{data.title}</h1>
              <div className="mx-auto flex w-fit items-center justify-center gap-4 rounded-lg bg-tertiary-green p-2.5 text-white">
                <img
                  src={data.main_type.logo_path}
                  alt={data.main_type.type_name}
                  title={data.main_type.type_name}
                  className="size-10"
                  height={40}
                  width={40}
                />
                <p>{data.main_type.type_name}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="container py-4">
          <AddressLocation
            className="justify-center text-xl text-white"
            svgClassName="fill-white size-5"
            location={data.purchased_offering.offering.location}
          />
        </div>
      </div>
    </section>
  );
};

export default Banner;
