import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import {
  TLaravelResponse,
  TLaravelSuccess,
} from "../../../types/utils/laravel";
import { TOfferingBelongingPurchased } from "../../../types/offering";
import moment from "moment";
import { useSetAtom } from "jotai";
import userAtom from "../../authentication/_atom";
import { TResponseUser } from "../../../types/user";
import { useLaravelQuery } from "../../../utils/hooks/Laravel";

export const useUpdateUser = () => {
  const setUser = useSetAtom(userAtom);
  useQuery({
    queryKey: ["user"],
    queryFn: async () => {
      const res =
        await axios.get<TLaravelResponse<TResponseUser["user"]>>("me");
      if (res.data.success && res.data.data) {
        setUser(
          (_prev) =>
            (_prev = {
              ...(_prev as TResponseUser),
              user: (res.data as TLaravelSuccess<TResponseUser["user"]>).data,
            }),
        );
      }
      return res.data;
    },
  });
};

export const useMyPurchases = () => {
  const { Display, data } = useLaravelQuery<TOfferingBelongingPurchased[]>({
    queryKey: ["my-purchases"],
    endPoint: "marketplace-purchased-items",
  });
  const purchases: {
    date: string;
    purchases: TOfferingBelongingPurchased[];
  }[] = [];
  if (data?.success) {
    const dates: string[] = [];
    data.data.forEach((item) => {
      const date = moment(item.created_at).format("MMMM Do, YYYY");
      if (dates.includes(date)) {
        const index = purchases.findIndex((item) => item.date === date);
        purchases[index].purchases =
          purchases[index].purchases.length > 0
            ? [...purchases[index].purchases, item]
            : [item];
        return;
      }
      dates.push(date);
      purchases.push({
        date,
        purchases: [item],
      });
    });
  }
  return { Display, purchases };
};
