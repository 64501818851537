import { TSvgComponent } from "../../../types/utils/components";

const SvgAradinaIcon: TSvgComponent = ({ ...props }) => {
  return (
    <svg
      {...props}
      viewBox="0 0 60 25"
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M37.1223 16.1386H35.009L33.656 13.4893L29.5748 5.45203L25.4936 13.4893L24.1406 16.1386H22.0273L23.3803 13.4893L29.5636 1.33838L35.7693 13.5005L37.1223 16.1497V16.1386Z" />
      <path d="M37.6701 16.4736L35.8923 19.4918L29.5636 6.18948L22.9442 19.4918L21.457 16.4736L29.5747 0.589111L37.6813 16.4736H37.6701ZM35.2102 15.8029H36.5743L29.5636 2.06466L22.5528 15.8029H23.9169L29.5636 4.71394L35.2102 15.8029Z" />
      <path d="M24.4873 24.4551H21.0546L18.8631 20.1738L12.2437 7.13975L5.62426 20.1738L3.4327 24.4551H0L2.19156 20.1738L12.2437 0.455078L22.2958 20.1626L24.4873 24.4439V24.4551Z" />
      <path d="M59.1397 24.4551H55.707L53.5154 20.1738L46.896 7.13975L40.2766 20.1738L38.085 24.4551H34.6523L36.8439 20.1738L46.896 0.455078L56.9481 20.1626L59.1397 24.4439V24.4551Z" />
    </svg>
  );
};

export default SvgAradinaIcon;
