import SvgQuotes from "../../svgs/icons/Quotes";
import useTestimonials from "./hooks";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

export const Testimonials = ({
  type,
}: {
  type: "investors" | "farmers" | "partners";
}) => {
  const { activeStep, setActiveStep, data, isLoading } = useTestimonials(type);
  return (
    <div className="relative h-full rounded-tl-lg rounded-tr-lg">
      {isLoading ? (
        <p>Loading {type} testimonials</p>
      ) : (
        <>
          <SvgQuotes className="absolute -top-5 left-5 z-10 w-12 rotate-180" />
          <Swiper slidesPerView={1}>
            {data?.data.map(({ id, name, photo_url, message }, index) => {
              return (
                <SwiperSlide
                  className="h-full"
                  key={`testimonial_${type}_${id}`}
                >
                  {Math.abs(activeStep - index) <= 2 ? (
                    <div className="z-0 grid h-full select-none gap-4 rounded-tl-lg rounded-tr-lg bg-[#F6FFED] px-12 py-8 text-xl text-primary-green md:grid-cols-[4fr_1fr] xl:grid-cols-[9fr_1fr]">
                      <div
                        className={`flex flex-col justify-around gap-4 ${
                          !photo_url && "col-span-2"
                        }`}
                      >
                        <p className="italic">"{message}"</p>
                        <span className="font-extrabold">- {name}</span>
                      </div>
                      {photo_url ? (
                        <img
                          className="aspect-square w-full max-w-[100px] self-end justify-self-end rounded-full object-cover max-md:col-start-1 max-md:row-start-1 max-md:self-start max-md:justify-self-start"
                          src={photo_url}
                          alt={name}
                        />
                      ) : (
                        <p className="aspect-square w-full rounded-full bg-[#F6FFED]" />
                      )}
                    </div>
                  ) : null}
                </SwiperSlide>
              );
            })}
          </Swiper>
          <div>
            <ul className="flex justify-center gap-1.5 rounded-b-lg bg-primary-green py-3">
              {[...Array(data?.data.length)].map((_, index) => {
                return (
                  <li
                    key={`testimonials_${type}_${index}`}
                    className={`${
                      activeStep === index
                        ? "bg-white"
                        : "bg-black bg-opacity-30"
                    } h-2.5 w-2.5 cursor-pointer rounded-full`}
                    onClick={() => setActiveStep((prev) => (prev = index))}
                  ></li>
                );
              })}
            </ul>
          </div>
          <SvgQuotes className="absolute -bottom-3 right-5 z-10 w-12 fill-white" />
        </>
      )}
    </div>
  );
};
export default Testimonials;
