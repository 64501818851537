import SvgAradinaIcon from "../../../../components/svgs/logos/AradinaIcon";

type ITeamMemberProps = {
  image: string;
  full_name: string;
  job_title: string;
  small_description: string;
  quotes?: string[];
};

const TeamMember = (props: ITeamMemberProps) => {
  const { image, full_name, job_title, small_description } = props;

  return (
    <article className="flex w-full max-w-sm flex-col items-center gap-8 rounded-2xl bg-gradient-to-t from-[rgba(88,131,44,0.1)] to-white to-70% p-4 text-center">
      <div className="relative grid w-full place-items-center">
        <img
          className="aspect-square w-3/4 rounded-full bg-gradient-to-b from-[rgba(88,131,44,0.1)] to-50% object-cover p-4"
          src={image}
          alt={full_name}
        />
        <div className="absolute right-3 top-3 box-content grid h-[80px] w-[80px] place-items-center rounded-full bg-primary-green p-2 leading-none text-white">
          <span className="whitespace-nowrap text-base">{job_title}</span>
        </div>
      </div>
      <div className="flex flex-col items-center py-4">
        <SvgAradinaIcon className="mb-4 w-10 fill-primary-green" />
        <h4 className="text-2xl font-bold uppercase text-primary-gray">
          {full_name}
        </h4>
        <h6 className="mt-2 text-xl font-bold italic text-primary-green">
          {job_title}
        </h6>
        <p className="font-bold text-primary-gray">{small_description}</p>
      </div>
    </article>
  );
};

export default TeamMember;
