import CustomInput from "../../../components/common/inputs/Input";
import CustomDropdown from "../../../components/common/inputs/Dropdown";
import ButtonPrimary from "../../../components/common/buttons/Button";
import { Link } from "react-router-dom";
import useSignup from "./hooks";
import CustomCheckbox from "../../../components/common/inputs/Checkbox";

const SignUpForm = () => {
  const {
    registerUser,
    countries,
    agreed,
    setAgreed,
    validationErrors,
    signupError,
  } = useSignup();
  return (
    <form
      onSubmit={(e) => {
        registerUser(e);
      }}
    >
      <div className="grid items-start justify-center gap-y-4 gap-x-6 md:grid-cols-2">
        <div>
          <CustomInput
            autoComplete="username"
            className={validationErrors?.username && "border-red-600"}
            type="text"
            name="username"
            label="Username*"
            error={
              validationErrors?.username?._errors[0] ||
              signupError?.response?.data.errors?.username?.[0]
            }
          />
        </div>
        <div>
          <CustomInput
            autoComplete="email"
            className={validationErrors?.email && "border-red-600"}
            type="email"
            name="email"
            label="Email*"
            error={
              validationErrors?.email?._errors[0] ||
              signupError?.response?.data.errors?.email?.[0]
            }
          />
        </div>
        <div>
          <CustomInput
            autoComplete="new-password"
            className={validationErrors?.password && "border-red-600"}
            type="password"
            name="password"
            label="Password*"
            error={
              validationErrors?.password?._errors[0] ||
              signupError?.response?.data.errors?.password?.[0]
            }
          />
        </div>
        <div>
          <CustomInput
            autoComplete="new-password"
            className={
              validationErrors?.password_confirmation && "border-red-600"
            }
            type="password"
            name="password_confirmation"
            label="Confirm Password*"
            error={validationErrors?.password_confirmation?._errors?.[0]}
          />
        </div>
        <div className="mb-6 mt-3 md:col-span-2">
          <CustomDropdown
            label="Country*"
            name="country"
            error={!!validationErrors?.country?._errors[0]}
            options={
              countries?.map(({ name }) => name) ?? ["Loading countries..."]
            }
          />
          <p className="text-base text-red-600">
            {validationErrors?.country?._errors[0]}
            {signupError?.response?.data.errors?.country?.[0]}
          </p>
          <div className="mt-6 flex items-start">
            <CustomCheckbox
              labelClass="grid grid-cols-[1rem_1fr] gap-2"
              label={
                <p>
                  I agree to receive electronic communications regarding my
                  activity on this portal and agree to the{" "}
                  <Link className="inline font-extrabold" to="/resources">
                    Terms of Use & Privacy Policy.*
                  </Link>
                </p>
              }
              className="mt-1"
              name="Agreed"
              checked={agreed}
              onChange={() => setAgreed((_prev) => (_prev = !agreed))}
            />
          </div>
        </div>
      </div>
      <div className="flex justify-center">
        <ButtonPrimary disabled={!agreed} type="submit">
          Sign Up
        </ButtonPrimary>
      </div>
    </form>
  );
};

export default SignUpForm;
