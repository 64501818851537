import { TSvgComponent } from "../../../types/utils/components";

const SvgCalendar: TSvgComponent = (props) => {
  return (
    <svg
      viewBox="0 0 13 14"
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M11.8462 1.07692H10.2308V0.538462C10.2308 0.395653 10.174 0.258693 10.0731 0.157712C9.97208 0.0567306 9.83512 0 9.69231 0C9.5495 0 9.41254 0.0567306 9.31156 0.157712C9.21058 0.258693 9.15384 0.395653 9.15384 0.538462V1.07692H3.76923V0.538462C3.76923 0.395653 3.7125 0.258693 3.61152 0.157712C3.51054 0.0567306 3.37358 0 3.23077 0C3.08796 0 2.951 0.0567306 2.85002 0.157712C2.74904 0.258693 2.69231 0.395653 2.69231 0.538462V1.07692H1.07692C0.791305 1.07692 0.517386 1.19038 0.315423 1.39235C0.113461 1.59431 0 1.86823 0 2.15385V12.9231C0 13.2087 0.113461 13.4826 0.315423 13.6846C0.517386 13.8865 0.791305 14 1.07692 14H11.8462C12.1318 14 12.4057 13.8865 12.6077 13.6846C12.8096 13.4826 12.9231 13.2087 12.9231 12.9231V2.15385C12.9231 1.86823 12.8096 1.59431 12.6077 1.39235C12.4057 1.19038 12.1318 1.07692 11.8462 1.07692ZM2.69231 2.15385V2.69231C2.69231 2.83512 2.74904 2.97208 2.85002 3.07306C2.951 3.17404 3.08796 3.23077 3.23077 3.23077C3.37358 3.23077 3.51054 3.17404 3.61152 3.07306C3.7125 2.97208 3.76923 2.83512 3.76923 2.69231V2.15385H9.15384V2.69231C9.15384 2.83512 9.21058 2.97208 9.31156 3.07306C9.41254 3.17404 9.5495 3.23077 9.69231 3.23077C9.83512 3.23077 9.97208 3.17404 10.0731 3.07306C10.174 2.97208 10.2308 2.83512 10.2308 2.69231V2.15385H11.8462V4.30769H1.07692V2.15385H2.69231ZM11.8462 12.9231H1.07692V5.38462H11.8462V12.9231Z" />
    </svg>
  );
};

export default SvgCalendar;
