import { useMutation } from "@tanstack/react-query";
import { FormEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ZodFormattedError, z } from "zod";
import { TLaravelError, TLaravelResponse } from "../../../types/utils/laravel";
import axios, { AxiosError } from "axios";
import { useAtom } from "jotai";
import userAtom from "../_atom";
import { TResponseUser, TUserSignupResponse } from "../../../types/user";
import { toast } from "sonner";
import { useCountries } from "../../../utils/hooks";

const useSignup = () => {
  const navigate = useNavigate();
  const [agreed, setAgreed] = useState(false);
  const [, setUser] = useAtom(userAtom);
  const countries = useCountries();
  const objectSchema = {
    username: z.string().min(1, { message: "Username is required" }),
    email: z
      .string()
      .min(1, "Email is required")
      .email("Must be a valid email"),
    password: z
      .string()
      .min(8, "Password should be 8 characters long")
      .max(30, "Password must not exceed 30 characters")
      .regex(
        new RegExp(".*[A-Z].*"),
        "Password requires one uppercase character",
      )
      .regex(
        new RegExp(".*[a-z].*"),
        "Password requires one lowercase character",
      )
      .regex(new RegExp(".*\\d.*"), "Password requires one number")
      .regex(
        new RegExp(".*[`~<>?,./!@#$%^&*()\\-_+=\"'|{}\\[\\];:\\\\].*"),
        "Password requires one special character",
      ),
    password_confirmation: z.string().min(1, "Please confirm your password"),
    country: z.string().min(1, "Please select a country"),
  };
  const userSchema = z
    .object(objectSchema)
    .superRefine(({ password_confirmation, password }, ctx) => {
      if (password_confirmation !== password) {
        ctx.addIssue({
          code: "custom",
          message: "Passwords did not match",
          path: ["password_confirmation"],
        });
      }
    });
  type TUserRegistration = z.infer<typeof userSchema>;
  const [validationErrors, setValidationErrors] = useState<ZodFormattedError<
    TUserRegistration,
    string
  > | null>();

  const registerUser = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setValidationErrors(null);
    const formData = new FormData(e.target as HTMLFormElement);
    const newUser = userSchema.safeParse(Object.fromEntries(formData));
    if (newUser.success) {
      mutate(formData);
    } else {
      setValidationErrors(newUser.error.format());
    }
  };

  const { mutate, error: signupError } = useMutation({
    mutationFn: async (data: FormData) => {
      const response = await axios.post<TLaravelResponse<TUserSignupResponse>>(
        "register",
        data,
      );
      return response.data;
    },
    onSuccess: (res) => {
      if (res.success) {
        setUser(res.data as TResponseUser);
        localStorage.setItem("token", res.data.token);
        navigate("/verify-email");
      }
    },
    onError: (e: AxiosError<TLaravelError<TUserRegistration>>) =>
      toast.error(e.response?.data.message),
  });

  return {
    registerUser,
    countries,
    agreed,
    setAgreed,
    validationErrors,
    signupError,
  };
};

export default useSignup;
