import useTeam from "../hooks";
import TeamMember from "./Members";

const TeamSection = () => {
  const { isLoading, members } = useTeam();
  return (
    <section id="team-members">
      <div className="container relative flex flex-col gap-10 overflow-x-clip section-margin">
        <h2 className="text-center text-2xl text-primary-green">
          To be the company our clients <b>want us</b> to be, it takes an
          eclectic group of passionate Leadership team.
          <br />
          Get to know the people paving the way at Aradina.
        </h2>
        <div className="h-40 w-1 self-center rounded-lg bg-primary-green"></div>
        {isLoading ? (
          <p className="text-center">Loading team members...</p>
        ) : (
          <div className="grid place-items-center gap-x-32 gap-y-10 md:grid-cols-2 xl:grid-cols-3">
            {members?.map(
              ({ id, image, full_name, job_title, small_description }) => (
                <TeamMember
                  key={`team_member_${id}`}
                  image={image}
                  full_name={full_name}
                  job_title={job_title}
                  small_description={small_description}
                />
              ),
            )}
          </div>
        )}
        <div className="mt-10 h-1 w-60 -translate-x-1/4 self-start rounded-lg bg-primary-green"></div>
      </div>
    </section>
  );
};

export default TeamSection;
