import ButtonPrimary from "../../common/buttons/Button";
import CustomInput from "../../common/inputs/Input";
import CustomTextArea from "../../common/inputs/TextArea";
import { useContactUs } from "./hooks";

const ContactUsForm = () => {
  const {
    form,
    handleSubmit,
    validationErrors,
    responseData,
    responseError,
    isResponseSuccess,
    isPending,
  } = useContactUs();
  return (
    <div>
      <p>
        We're happy to answer any question you have or provide you with an
        estimate. Just send us a message in the form below we endeavour to
        answer you within 48 hours.
      </p>
      <form
        onSubmit={handleSubmit}
        ref={form}
        id="form-contact-us"
        className="grid gap-2 py-6"
      >
        <CustomInput
          label="Full Name"
          name="full_name"
          type="text"
          autoComplete="name"
          required
          className={
            validationErrors?.full_name?._errors[0] && "border-red-600"
          }
          error={
            responseError?.response?.data.errors?.full_name?.[0] ||
            validationErrors?.full_name?._errors[0]
          }
        />
        <CustomInput
          label="Email"
          name="email"
          type="email"
          autoComplete="email"
          required
          className={validationErrors?.email?._errors[0] && "border-red-600"}
          error={
            responseError?.response?.data.errors?.email?.[0] ||
            validationErrors?.email?._errors[0]
          }
        />
        <CustomInput
          label="Subject"
          name="subject"
          type="text"
          autoComplete="off"
          required
          className={validationErrors?.subject?._errors[0] && "border-red-600"}
          error={
            responseError?.response?.data.errors?.subject?.[0] ||
            validationErrors?.subject?._errors[0]
          }
          />
        <CustomTextArea
          label="Message"
          name="message"
          autoComplete="off"
          required
          className={validationErrors?.message?._errors[0] && "border-red-600"}
          error={
            responseError?.response?.data.errors?.message?.[0] ||
            validationErrors?.message?._errors[0]
          }
        />
        {isResponseSuccess && (
          <>
            <p className="text-xl text-primary-green">
              Your message is well recieved, our team will contact you as soon
              as possible.
            </p>
            <p className="text-sm text-primary-gray">
              We have recieved {responseData?.id} messages, and still counting!
            </p>
          </>
        )}
        <ButtonPrimary
          className="w-40 justify-self-end uppercase"
          type="submit"
          isLoading={isPending}
        >
          Submit
        </ButtonPrimary>
      </form>
    </div>
  );
};

export default ContactUsForm;
