import ButtonPrimary from "../../../../components/common/buttons/Button";
import ThreeNeedlesHeading from "../../../../components/common/ThreeNeedlesHeading";
import SvgArrowCurved from "../../../../components/svgs/arrows/ArrowCurved";

const BestPartners = () => {
  return (
    <section
      id="partners-best-partners"
      className="section-padding bg-primary-yellow bg-[url('/public/assets/images/backgrounds/background-giant-blob-green.png')] bg-[length:60%] bg-right-bottom bg-no-repeat text-white"
    >
      <div className="container mb-10 flex flex-wrap gap-x-2 text-section-heading">
        <h2 className="py-1.5">We work with the</h2>
        <ThreeNeedlesHeading
          backgroundColor="white"
          needlesColor="#CC9900"
          className="text-primary-yellow"
        >
          Best Partners
        </ThreeNeedlesHeading>
        <SvgArrowCurved className="z-10 -ml-4 mt-2 w-24 fill-dark-yellow max-md:hidden" />
      </div>
      <div className="single-grid">
        <div className="single-grid-item grid grid-cols-2 items-center justify-items-end max-md:hidden">
          <img
            className="w-max origin-right -translate-x-10 scale-110"
            src="/assets/images/pages/about/partners/best-partners.png"
            alt="Best Partners"
          />
        </div>
        <div className="single-grid-item container grid-cols-2 items-center md:grid">
          <div className="md:col-start-2">
            <p className="mb-10">
              Associating with our virtuous partners Cooperation is the
              principle of accomplishments, and success cannot be achieved
              without our beloved partners.
            </p>
            <ButtonPrimary onClick={() => alert("Read more about partners.")}>
              Read More
            </ButtonPrimary>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BestPartners;
