import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

const Payment = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    if (searchParams.get("redirect_status")) {
      if (searchParams.get("redirect_status") === "succeeded")
        alert("Payment Successful");
      else alert("Payment Failed");
    }
    setSearchParams("");
    //eslint-disable-next-line
  }, [searchParams]);
  return null;
};

export default Payment;
