import { TSvgComponent } from "../../../types/utils/components";

const SvgTikTok: TSvgComponent = ({ ...props }) => {
  return (
    <svg
      viewBox="0 0 10 11"
      xmlns="http://www.w3.org/2000/svg"
      fill="white"
      {...props}
    >
      <path
        opacity="0.8"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.94593 4.03464C7.61488 4.52223 8.42903 4.80843 9.3106 4.80843V3.07534C9.14466 3.07534 8.97871 3.05944 8.81277 3.02234V4.38443C7.9312 4.38443 7.11705 4.09824 6.4481 3.61064V7.02912C6.4481 8.60322 5.39022 10.0289 3.876 10.2992C3.02036 10.4529 2.21139 10.2462 1.56836 9.80631C2.24768 10.5165 3.25372 10.9087 4.33752 10.7285C5.87249 10.4741 6.95111 9.03252 6.95111 7.44782V4.03464H6.94593ZM7.55783 2.28564C7.21558 1.90404 6.9926 1.41645 6.94593 0.870548V0.647949H6.47403C6.5933 1.33695 6.99778 1.92524 7.55783 2.28564ZM2.66254 8.45482C2.47067 8.20042 2.36696 7.88772 2.37214 7.56972C2.37214 6.75883 3.01517 6.10163 3.80858 6.10163C3.95378 6.10163 4.10416 6.12283 4.24418 6.17053V4.40033C4.07824 4.37913 3.9123 4.36853 3.74636 4.37383V5.75183C3.60634 5.70413 3.46114 5.68293 3.31075 5.68293C2.51734 5.68293 1.87432 6.34013 1.87432 7.15102C1.87432 7.72342 2.19583 8.21632 2.66254 8.46012V8.45482Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.45332 3.61064C7.12227 4.09824 7.93642 4.38443 8.81799 4.38443V3.02234C8.32535 2.91634 7.88975 2.65134 7.56306 2.28564C7.003 1.93054 6.59851 1.33695 6.47924 0.647949H5.24505V7.56972C5.24505 8.37532 4.60202 9.03252 3.80861 9.03252C3.3419 9.03252 2.92705 8.80462 2.66776 8.45482C2.08178 8.15272 1.72915 7.45842 1.94176 6.71113C2.0714 6.25003 2.43959 5.87903 2.89074 5.74653C3.19151 5.65643 3.4871 5.66703 3.75157 5.75183V4.37383C2.05066 4.41093 0.681641 5.83133 0.681641 7.57502C0.681641 8.44952 1.0239 9.23922 1.57358 9.81691C2.21661 10.2568 3.02557 10.4635 3.88121 10.3098C5.39543 10.0395 6.45332 8.61382 6.45332 7.03972V3.62124V3.61064Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.81803 3.02251V2.65151C8.37206 2.65151 7.94165 2.52431 7.56309 2.28581C7.89498 2.65681 8.33576 2.91651 8.81803 3.02251ZM6.47928 0.64812C6.46891 0.58452 6.45854 0.51562 6.45336 0.44672V0.224121H4.74726V7.14589C4.74726 7.95149 4.10423 8.60869 3.31082 8.60869C3.07747 8.60869 2.85967 8.55039 2.6678 8.45499C2.93227 8.80479 3.34194 9.03269 3.80865 9.03269C4.59688 9.03269 5.2399 8.38079 5.24509 7.56989V0.64812H6.47928ZM3.74643 4.3687V3.97651C3.11896 3.88641 2.47074 3.99241 1.8692 4.3051C1.01356 4.7503 0.396469 5.6089 0.230527 6.5735C0.00235695 7.91439 0.593524 9.13868 1.57362 9.80648C1.01875 9.22878 0.68168 8.43909 0.68168 7.56459C0.68168 5.8156 2.0507 4.3952 3.75161 4.3634L3.74643 4.3687Z"
      />
    </svg>
  );
};

export default SvgTikTok;
