import BannerGradientHeading from "../../components/common/BannerGradientHeading";
import Main from "../../components/layout/Main";

const PagePrivacyPolicy = () => {
  return (
    <Main id="privacy-policy">
      <section
        className="container section-margin"
        id="privacy-policy-information"
      >
        <BannerGradientHeading
          title="Privacy Policy"
          text="Privacy is about the safeguarding of user identity"
          mode={2}
        />
        <div className="mt-10 text-primary-green">
          <p className="break-words">
            Purpose of our Privacy Policy Aradina is aware of the trust you
            place in us and our responsibility to protect your privacy. This
            privacy policy and agreement (our “Privacy Policy”) specifies the
            personal information that we ask you to provide in order to
            participate in the Services we offer and governs how we treat this
            personal information. We operate our Website and offer our Services
            pursuant to the statutes and regulations that apply to this Website
            with respect to the Services provided through this Site (“Applicable
            Law”). PLEASE READ THIS PRIVACY POLICY CAREFULLY. YOUR CONSENT TO
            THE TERMS OF THIS PRIVACY POLICY CREATES LEGAL OBLIGATIONS FOR YOU
            AND FOR US. IF YOU DO NOT AGREE WITH THE TERMS OF THIS PRIVACY
            POLICY, PLEASE DO NOT USE OUR WEBSITE. You indicate your consent to
            the terms of this Privacy Policy in various ways, depending on your
            use of the services offered by the Website. By accessing and using
            the Website as a site visitor, you are acknowledging that you have
            read and understood this Privacy Policy and agree to be legally
            bound by it. If you register as an Investor on our Website, during
            the registration process you will be asked to confirm that you have
            reviewed this Privacy Policy and that you agree to its terms. Such
            confirmation constitutes your agreement to be bound by this Privacy
            Policy. You are permitted to register only in your capacity as an
            individual, and we do not allow Investors to register on behalf of a
            group or organization. Information you may provide while visiting
            our Website or while participating in our Services falls into two
            broad categories: (i) Personally Identifiable Information, and (ii)
            Anonymous Information. “Personally Identifiable Information” means
            any information that could reasonably be used to identify you,
            including your name, address, e-mail address, birth date, financial
            information, cell or land-line phone number, or any combination of
            information that could be used to identify you. “Anonymous
            Information” is information that does not identify you and may
            include statistical information concerning, for example, your use of
            our Services or the pages on our Website that you visit most
            frequently. Please keep in mind that our Privacy Policy does not
            govern information you choose to share with other Users, as we have
            no direct control over the collection or use of this information.
            Please use appropriate precautions in sharing your information with
            other Users. A User of our Website who is not an Investor may review
            selected information about our the services offered by our Website
            without revealing his or her identity or providing any Personally
            Identifiable Information. It is only when a User wishes to become an
            Investor and participate in the Investor-only accessed portions of
            the Website and utilize investment tools that a User is required to
            provide Personally Identifiable Information. Investors who wish to
            invest in the pooled investment vehicles and other investment
            opportunities (“Investment Opportunities”) presented on our Website
            must qualify as Accredited Investors and provide additional
            Personally Identifiable Information as described below. Personally
            Identifiable Information The Personal Information that you are asked
            to provide, and the reasons why you are asked to provide it, will be
            made clear to you at the point we ask you to provide your Personal
            Information. We will also let you know prior to collection whether
            the provision of the Personal Information we are collecting is
            compulsory or may be provided on a voluntary basis and the
            consequences, if any, of not providing the information. We obtain
            Personally Identifiable Information from Investors or other persons
            who have signed up to participate as an Investor through the
            Website. In order to access our services as an Investor, you must
            complete a registration form which specifies the information you
            must provide in order to enjoy the particular features to which you
            wish to subscribe. The information required may include such items
            as name; email address; cell and land-line phone number; mailing
            address; social security number (for IRS tax reporting purposes);
            and birth date (for COPPA and eligibility purposes) (collectively
            "Contact Information"). Our registration forms may additionally
            request information which you may, at your option, choose to provide
            or withhold. To the extent this additional information constitutes
            Personally Identifiable Information, we will treat it in the manner
            specified in this Privacy Policy. To qualify to invest in Investment
            Opportunities presented on our Website, you must be an Accredited
            Investor and provide identity verification information and Investor
            financial information described below. Identity Verification
            Information. Each Investor must provide information designed to
            verify his or her identity. Such information may include (i)
            information from a successfully completed electronic check
            transaction, (ii) a credit report, (iii) tax documents or (iv) other
            similar information designed to authenticate and confirm your
            identity and status as an Accredited Investor or a Non-U.S. Person.
            Specific-Qualifications Information. Investors must also provide
            other information sufficient to meet regulatory "suitability"
            requirements for investing in an Investment Opportunity presented on
            our Website. Depending on the nature of the offering, this
            information will include: (i) information concerning an Investor's
            net worth and income; (ii) information concerning his or her
            residency; and (iii) other information we might request to determine
            whether an Investor qualifies for a particular investment. Payment
            Information. In order to make an investment through our Website, an
            Investor must provide us with certain account and other payment
            information, such as information needed to make payment via
            electronic checks, ACH or wire. Our registration process for
            Investors provides additional details on the types of Payment
            Information needed. How We Protect Your Personally Identifiable
            Information We have security systems in place designed to prevent
            unauthorized access to or disclosure of Personally Identifiable
            Information, and we take all reasonable steps to secure and
            safeguard this Information, including: Our Website's
            password-protected section requires Users to give us unique
            identifiers such as their user name and password; We employ a secure
            channel using Secure Sockets Layer encryption to protect information
            exchanged over the Internet between your web browser and our
            servers; Our employees (and employees of our Service Providers) are
            required to acknowledge that they understand and will abide by this
            Privacy Policy with respect to the confidentiality of Personally
            Identifiable Information; We provide access to our databases
            containing Personally Identifiable Information on a need-to-know
            basis only; and We use automated tools to monitor network traffic to
            identify unauthorized attempts to upload information, change
            information or otherwise seek to "hack into" our systems. We
            recognize the sensitivity of information provided in response to our
            Investor Qualifying Questions and take the following additional
            precautions with respect to such information: We store investor
            questionnaire information that we receive on a secure system
            isolated from direct connection to the Internet. We encrypt investor
            questionnaire Information (including account and social security
            number information) using the same encryption standard used by banks
            to secure sensitive customer data. Our security systems are
            structured to deter and prevent hackers and others from accessing
            information you provide to us. We make no warranty that our security
            systems are fail proof. Due to the nature of Internet communications
            and evolving technologies, we cannot provide and we disclaim
            assurance that the information you provide us will remain free from
            loss, misuse, or alteration by third parties who, despite our
            efforts, obtain unauthorized access. Changing or Removing
            Information; Opting Out Applicable Law places certain obligations on
            Investors to keep information current, including, for example, Form
            W-9 Information. It is particularly important for you to keep your
            Investor Financial Information current and to report to us any
            material change in this Information. We use this information to
            provide you with investment opportunities and financial
            distributions due to you as an equity owner of a Investment
            Opportunity in which you have invested through our Website. Without
            current information, you receive information in error or that you
            are not eligible to receive or act on. To allow appropriate control
            over Personally Identifiable Information, you can access your
            account (after supplying your user name and password) to change or
            update discretionary information that you have previously submitted.
            You are entitled to decline to participate in certain Services and
            to decline to give us permission (or to revoke that permission in
            your discretion) to use your Personally Identifiable Information for
            certain purposes. We provide you with the ability to opt-out of
            receiving communications concerning, or being included in, these
            activities. To opt-out of Services for which an opt-out right is
            available, please email us at info@harvestreturns.com. We can also
            answer questions or assist if you contact us as provided herein. You
            will not be allowed to opt-out of required notices or other legal
            and related notices concerning your relationship to the Website. If
            you request, we will remove your name and all other Personally
            Identifiable Information from our databases. It may be impossible to
            remove this information completely, due to IRS and regulatory
            reporting requirements, ownership in a Investment Opportunity in
            which you have invested through our Website and the rights thereof,
            data backups and records of deletions. If you request deletion of
            your information, you will be unable to use certain features of the
            Website and any associated Services. You may not remove
            de-identified, anonymous, or aggregate data from our databases.
            Third Party Access to Personally Identifiable Information We may
            employ or retain other companies (“Service Providers”) to perform
            functions on our behalf, such as maintaining the Website, providing
            services related to the Website, collecting information, responding
            to and sending e-mail or other functions to facilitate our business,
            broker-dealer services, and escrow services. We may need to share
            your Personally Identifiable Information with Service Providers. We
            will share with our Service Providers only that information
            necessary for them to perform their functions, and we require them
            to commit to refrain from using your Personally Identifiable
            Information for any other purpose. We may reveal your Personally
            Identifiable Information to attorneys, private investigator
            organizations or law enforcement agencies if we believe (i) that you
            are in risk of harm from another, (ii) that you are harming or
            interfering (or will harm or interfere) with others, or (iii) that
            you are violating (either intentionally or unintentionally) our
            Terms or are otherwise violating legal rights. We will take all
            reasonable steps to disclose only so much of this Information as is
            necessary, limiting the disclosure, for example (and where
            applicable), to Contact Information only. We will reveal your
            Personally Identifiable Information to the extent we reasonably
            believe we are required to do so by Applicable Law. If we receive
            legal process calling for the disclosure of your Personally
            Identifiable Information, we will attempt to notify you at the email
            address you supplied during registration within a reasonable amount
            of time before we respond to the request, unless such notification
            is not permitted. We are entitled to transfer to a third party
            information we collect, including any Personally Identifiable
            Information, in connection with a sale of all or substantially all
            of our assets, provided the acquiring third party has agreed to
            safeguard your Personally Identifiable Information with protections
            that in all material respects are the same as, or more protective
            than, those set out in this Privacy Policy. Our Websites include
            social media features, such as the Facebook Like button. These
            features may collect information about your IP address and which
            page you are visiting on our Website, and they may set a cookie to
            make sure the feature functions properly. Anonymous, Aggregate
            Information We use Anonymous Information to analyze the
            effectiveness of our Website, to improve our Services and for other
            similar purposes. In addition, from time to time, we may undertake
            or commission statistical and other summary analyses of the general
            behavior and characteristics of Users participating in our Services
            and the characteristics of visitors to our Website and may share
            Anonymous Information with third parties, including advertisers.
            Anonymous Information provided to third parties will not allow
            anyone to identify you or determine anything personal about you. We
            may collect Anonymous Information through features of the software
            that supports our Services, through cookies, and through other means
            . We may automatically receive and record information in our server
            logs from your browser, including your IP address (the Internet
            address of your computer), your computer's name, the type and
            version of your web browser, referrer addresses, and other
            generally-accepted log information. We may also record page views
            and other general statistical and tracking information, which will
            be aggregated with that of other Users in order to understand how
            our Website is being used, and for security and monitoring purposes.
            None of this data contains Personally Identifiable Information. A
            cookie is a small amount of data, often including an anonymous
            unique identifier, which is sent to your browser from a website's
            computers and stored on your computer's hard drive. Cookies can be
            used to provide you with a tailored user experience and to make it
            easier for you to use a website upon a future visit. We may include
            cookies on our Website and use them to recognize you when you return
            to our Website. You may set your browser so that it does not accept
            cookies. However, cookies must be enabled on your web browser if you
            wish to access certain personalized features of our Services. We may
            compile records of visits with respect to certain pages or
            information that are available on our Website. We might also compile
            information and other metrics concerning Investors' interactions
            with respect to our Services and other features of our Website. We
            might compile site metrics of a page of interest or other data. Such
            records do not contain any Personally Identifiable Information, and
            all such data and information is subject to our Privacy Policy. We
            may use "pixel tags" to tell us what parts of our Website have been
            visited or to measure the effectiveness of searches Users perform on
            our Website. Pixel tags also enable us to send email messages in a
            format Users can read, and they inform us whether emails have been
            opened, to help ensure that our messages are of interest to our
            Investors. None of this data includes Personally Identifiable
            Information, and you can "opt-out" of receiving these types of
            emails from us. If any Personally Identifiable Information is
            collected using such tools, it will be subject to the terms of this
            Privacy Policy. Our Services may include the use of ad banner
            partners for the serving and/or targeting of ads, promotions, and
            other marketing messages. These ads may be provided, in some cases,
            by a third party ad service provider or advertiser. These
            advertisers may use traditional banner ads, or other advertising
            methods, such as advertising using profiles and related Content. An
            advertiser may place or utilize its own cookie on your browser and
            may use Anonymous Information about your visit to our Website, such
            as the number of times you have viewed the ad. If the advertiser
            requests that you provide to it Personally Identifiable Information,
            such advertiser's use and collection of such information will be
            governed by its own privacy policy, and not our Privacy Policy. We
            recommend that you review the advertiser's privacy policy before
            providing Personally Identifiable Information. In order to determine
            whether your computer is supported by our system, we may collect
            certain Anonymous Information. This information includes, but may
            not be limited to, your operating system and browser, as well as the
            presence of any software that our Website may require to operate
            with your computer, or other third party software on your computer.
            This information is kept strictly confidential and is not shared
            with third parties, except as provided herein. Anonymous Information
            Linked With Your Personally Identifiable Information Is Protected As
            “Personally Identifiable Information” To enable us to better
            understand the characteristics of our Investors and/or to provide
            Services tailored to your needs, we may link (i) the Personally
            Identifiable Information an Investor provides with (ii) Anonymous
            Information. If we combine or link any Anonymous Information or
            other information with your Personally Identifiable Information, the
            resulting combination will be treated and protected as Personally
            Identifiable Information under this Privacy Policy. Relationship to
            Terms of Use; Incorporation of Terms of Use This Privacy Policy must
            be read in conjunction with our Terms of Use, and the provisions of
            our Terms of Use are incorporated herein. To the extent the Terms of
            Use conflict with the terms of this Privacy Policy, the terms of
            this Privacy Policy will control. Contact Us If you have any
            questions about this Privacy Policy, the practices of this Website
            or your dealings with this Website, please contact us by email at:
            info@aradina.com, or send mail to: Aradina - Ghazir, Jounieh
            Highway, GAF & CO bld. 3rd fl. Kesrouan, Lebanon. We respond to all
            requests we receive from individuals wishing to exercise their data
            protection rights in accordance with applicable data protection
            laws. We may ask you to verify your identity in order to help us
            respond efficiently to your request. Updated January 20, 2023.
          </p>
        </div>
      </section>
    </Main>
  );
};

export default PagePrivacyPolicy;
