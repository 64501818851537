import Banner from "./components/Banner";
import WhyInvestment from "./components/WhyInvestment/index";
import HighProfit from "./components/HighProfit";
import TestimonialsSection from "../../components/section/testimonials/TestimonialsSection";
import HowItWorks from "./components/HowItWorks";
import Statistics from "./components/Statistics";
import OurOfferings from "../../components/section/ourOfferings";
import InNeedOfCapital from "./components/InNeedOfCapital";
import DownloadAradinaMobileApp from "../../components/section/DownloadAradinaMobileApp";
import Main from "../../components/layout/Main";
import Payment from "./components/Payment";

export const PageHome = () => {
  return (
    <Main id="home">
      <h1 hidden>Homepage</h1>
      <Banner />
      <WhyInvestment />
      <HighProfit />
      <TestimonialsSection page="home" type="investors" />
      <HowItWorks />
      <Statistics />
      <OurOfferings id="home" decoration />
      <TestimonialsSection page="home" type="farmers" />
      <InNeedOfCapital />
      <DownloadAradinaMobileApp />
      <Payment />
    </Main>
  );
};
export default PageHome;
