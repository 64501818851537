import { FC, ReactNode } from "react";
import SvgArrow from "../svgs/arrows/Arrow";
import SvgAradinaFull from "../svgs/logos/AradinaFull";
import SvgCrop from "../svgs/icons/Crop";
import { useNavigate } from "react-router-dom";

type TProps = {
  id: string;
  background: string;
  title: string;
  children: ReactNode;
};

const AuthModal: FC<TProps> = ({ id, background, title, children }) => {
  const navigate = useNavigate();
  return (
    <section
      id={id}
      style={{ backgroundImage: `url(${background})` }}
      className="relative overflow-x-clip bg-cover bg-center bg-no-repeat"
    >
      <SvgCrop className="absolute top-1/3 h-32 w-32 -translate-x-1/4 rounded-full bg-secondary-green p-4 max-md:hidden" />
      <img
        className="absolute right-0 -translate-y-6 translate-x-1/4 max-2xl:hidden"
        src="/assets/images/common/safe-investment.png"
        alt=""
      />
      <div className="grid min-h-screen place-items-center bg-primary-green bg-opacity-50 bg-[url(/public/assets/images/backgrounds/background-giant-blob.png)] bg-[length:60%_120%] bg-left-bottom bg-no-repeat">
        <div className="container rounded-xl bg-white px-14 py-10 md:px-20">
          <div className="grid grid-cols-3">
            <button
              title="Back"
              type="button"
              className="w-max"
              onClick={() => navigate(-1)}
            >
              <SvgArrow className="w-2.5 md:w-8" />
            </button>
            <SvgAradinaFull className="w-24 justify-self-center fill-dark-green md:w-52" />
          </div>
          <div className="text-primary-green">
            <h4 className="my-10 text-center text-2xl">{title}</h4>
            <div className="mx-auto max-w-3xl">{children}</div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AuthModal;
