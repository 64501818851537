import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { TLaravelResponse } from "../../../types/utils/laravel";
import { TOfferingCart } from "../../../types/offering";
import { toast } from "sonner";
import { useAtom } from "jotai";
import { atomCartUser } from "./_atom";
import { FormEvent, useState } from "react";
import { TLabelOption } from "../../../types/utils/components";
import { loadStripe } from "@stripe/stripe-js";
import { TResponseCheckout } from "../../../types/utils/response";
import { useElements, useStripe } from "@stripe/react-stripe-js";

export const useStripeForm = (client_secret: string) => {
  const stripe = useStripe();
  const elements = useElements();
  const queryClient = useQueryClient();
  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (elements == null || !client_secret) {
      return;
    }
    const { error: submitError } = await elements.submit();
    if (submitError) {
      toast.error(submitError.message ?? "Something went wrong");
      return;
    }
    const { error } = stripe
      ? await stripe.confirmPayment({
          elements,
          clientSecret: client_secret,
          confirmParams: {
            return_url: process.env.REACT_APP_URL ?? "",
          },
        })
      : { error: null };

    if (error) {
      toast.error(error.message ?? "Something went wrong");
      return;
    }
    queryClient.invalidateQueries({ queryKey: ["cart-user"] });
    queryClient.invalidateQueries({ queryKey: ["cart-marketplace"] });
  };
  return { handleSubmit, elements, stripe };
};

export const useCartUser = () => {
  const [cart, setCart] = useAtom(atomCartUser);
  const { data, isLoading } = useQuery({
    queryKey: ["cart-user"],
    queryFn: async () => {
      const res =
        await axios.get<TLaravelResponse<TOfferingCart[]>>("user-cart");
      if (res.data.success) setCart(res.data.data);
      return res.data;
    },
  });
  let total = 0;
  if (data?.success)
    total = data.data.reduce(
      (acc, item) => acc + +item.total_price_insurance,
      0,
    );
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY ?? "");
  const { data: checkout, mutate } = useMutation({
    mutationFn: async () => {
      const res = await axios.post<TLaravelResponse<TResponseCheckout>>(
        "checkout-information",
      );
      return res.data;
    },
  });
  return {
    cart,
    isLoading,
    total,
    stripePromise,
    checkout,
    mutate,
  };
};

export const useCartUserItem = (index: number) => {
  const [edit, setEdit] = useState(false);
  const [cart, setCart] = useAtom(atomCartUser);
  const [item, setItem] = useState(cart[index]);
  const handleDecrement = () => {
    const quantity = item.quantity > 1 ? item.quantity - 1 : 1;
    setItem(
      (_prev) =>
        (_prev = {
          ..._prev,
          quantity,
          total_price: quantity * +item.offering.unit_price,
          total_price_insurance: (
            quantity *
            (+item.offering.unit_price +
              (item.insurance?.price ? +item.insurance?.price : 0))
          ).toFixed(2),
        }),
    );
  };
  const handleIncrement = () => {
    const quantity =
      item.quantity < +item.offering.max_qty_investment
        ? item.quantity + 1
        : +item.offering.max_qty_investment;
    setItem(
      (_prev) =>
        (_prev = {
          ..._prev,
          quantity,
          total_price: quantity * +item.offering.unit_price,
          total_price_insurance: (
            quantity *
            (+item.offering.unit_price +
              (item.insurance?.price ? +item.insurance?.price : 0))
          ).toFixed(2),
        }),
    );
  };
  const handleEdit = () => {
    if (!edit) {
      setEdit(true);
    } else {
      if (item.total_price >= +item.offering.minimum_investment) mutate();
      else
        toast.warning(
          `Minimum investment is $${item.offering.minimum_investment}`,
        );
    }
  };
  const queryClient = useQueryClient();
  const { mutate } = useMutation({
    mutationFn: async () => {
      const res = await axios.post<TLaravelResponse<true>>(
        `update-cart/offerings/${item.offering.id}`,
        {
          quantity: item.quantity,
          total_price: item.total_price_insurance,
          insurance_id: item.insurance_id ?? null,
        },
        {
          params: {
            "inv-numb": item.investment_number,
          },
        },
      );
      return res;
    },
    onSuccess: () => {
      setEdit(false);
      toast.success("Saved successfully");
      queryClient.invalidateQueries({ queryKey: ["cart-user"] });
    },
    onError: () => {
      toast.error("Something went wrong...");
    },
  });
  const handleInsuranceChange = (value: TLabelOption) => {
    if (value === null) {
      setItem(
        (_prev) =>
          (_prev = {
            ..._prev,
            insurance_id: null,
            insurance: null,
            total_price: item.quantity * +item.offering.unit_price,
            total_price_insurance: (
              item.quantity * +item.offering.unit_price
            ).toFixed(2),
          }),
      );
    } else {
      const ins =
        item.offering.insurances.find(
          (item) => item.id === (value as TLabelOption).id,
        ) ?? null;
      setItem(
        (_prev) =>
          (_prev = {
            ..._prev,
            insurance_id: (value as TLabelOption | null)?.id ?? null,
            insurance: (value as TLabelOption).id !== null ? ins : null,
            total_price: item.quantity * +item.offering.unit_price,
            total_price_insurance: (
              item.quantity *
              (+item.offering.unit_price + (ins ? +ins?.price : 0))
            ).toFixed(2),
          }),
      );
      const arr = cart;
      arr[index] = item;
      setCart(arr);
    }
  };

  const { mutate: removeFromCart } = useMutation({
    mutationFn: async () => {
      const res = await axios.delete<TLaravelResponse<string>>(
        `remove-from-cart`,
        {
          params: { "inv-numb": item.investment_number },
        },
      );
      return res.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["cart-user"] });
      toast.success("Successfully removed from cart");
    },
    onError: () => toast.error("Something went wrong..."),
  });
  return {
    item,
    removeFromCart,
    handleDecrement,
    handleIncrement,
    handleEdit,
    handleInsuranceChange,
    edit,
  };
};
