import Main from "../../../components/layout/Main";

const PageProfile = () => {
  return (
    <Main id="profile">
      <section id="profile-description" className="container section-margin">
        <p>Profile</p>
      </section>
    </Main>
  );
};

export default PageProfile;
