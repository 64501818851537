import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { TLaravelSuccess } from "../../../types/utils/laravel";

type TMediaResource = {
  id: number;
  title: string;
  description: string;
  file: string;
};

type TMediaArticle = {
  id: number;
  thumbnail: string;
  logo: string;
  title: string;
  description: string;
  author: string;
  release_date: string;
  url: string;
  type: string | null;
};

const useMedia = () => {
  const { data, isLoading } = useQuery({
    queryKey: ["aradina-media"],
    queryFn: async () => {
      const response = await axios.get<
        TLaravelSuccess<{
          resources: TMediaResource[];
          articles: TMediaArticle[];
        }>
      >("aradina-media");
      return response.data;
    },
  });
  return {
    resources: data?.data.resources,
    articles: data?.data.articles,
    isLoading,
  };
};

export default useMedia;
