import PageBanner from "../../../../components/section/PageBanner";
import BannerGradientHeading from "../../../../components/common/BannerGradientHeading";

const Banner = () => {
  return (
    <PageBanner
      id="farmers"
      imageUrl="/assets/images/pages/farmers/empowerment/banner-background.jpg"
    >
      <div className="container flex flex-col items-center justify-center">
        <BannerGradientHeading title="Farmers Empowerment" />
        <div className="mt-4 h-32 w-0.5 bg-white"></div>
        <button
          onClick={() => alert("Start Now")}
          className="rounded-3xl bg-gradient-to-r from-[#7FE11D] to-[#77AC33] px-5 py-2 font-bold text-white"
        >
          Start Now
        </button>
      </div>
      <img
        className="absolute right-0 top-0 -translate-y-10 translate-x-1/3 max-lg:hidden"
        src="/assets/images/pages/farmers/empowerment/banner-1.png"
        alt=""
      />
      <img
        className="absolute bottom-0 left-0 -translate-x-10 translate-y-1/3 max-lg:w-52"
        src="/assets/images/pages/farmers/empowerment/banner-2.png"
        alt=""
      />
    </PageBanner>
  );
};

export default Banner;
