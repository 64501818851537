import { Outlet } from "react-router-dom";
import Header from "../section/header";

const LayoutUser = () => {
  return (
    <>
      <Header loggedIn />
      <Outlet />
    </>
  );
};

export default LayoutUser;
