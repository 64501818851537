import { Link } from "react-router-dom";
import ButtonPrimary from "../../../../components/common/buttons/Button";
import ThreeNeedlesHeading from "../../../../components/common/ThreeNeedlesHeading";
import SvgArrowCurved from "../../../../components/svgs/arrows/ArrowCurved";
import { howItWorks } from "../data";

const HowItWorks = () => {
  return (
    <section
      id="farmer-how-it-works"
      className="bg-[url('/public/assets/images/pages/farmers/empowerment/how-it-works-background.jpg'),url('/public/assets/images/backgrounds/background-dots.png')] bg-cover bg-no-repeat"
    >
      <div className="relative bg-gradient-to-b from-[#fffd] to-[#fff6]">
        <div className="container section-padding">
          <div className="flex flex-wrap justify-end gap-x-2 text-right text-section-heading text-primary-green">
            <SvgArrowCurved className="w-20 -scale-x-100" />
            <h2 className="py-1.5">How it</h2>
            <ThreeNeedlesHeading
              backgroundColor="primary-green"
              className="text-white"
              needlesColor="black"
            >
              Works
            </ThreeNeedlesHeading>
          </div>
          <h3 className="mb-6 w-max rounded bg-white px-4 py-2 text-2xl text-primary-green">
            Capital Raise Process
          </h3>
          <ul className="grid gap-y-4 text-white md:grid-cols-2">
            {howItWorks.map(({ title, text }, index) => {
              return (
                <li
                  key={`farmer_how_it_works_${index}`}
                  style={{ gridRowStart: index + 2 }}
                  className={`${
                    index % 2 === 0 ? "md:col-start-1" : "md:col-start-2"
                  } grid grid-cols-[40px_auto]`}
                >
                  <span className="box-content grid h-7 w-7 place-items-center rounded-full bg-white p-0.5 text-xl text-primary-green">
                    {index + 1}
                  </span>
                  <div className="rounded-lg bg-primary-green p-5">
                    <h3>{title}</h3>
                    <p dangerouslySetInnerHTML={{ __html: text }}></p>
                  </div>
                </li>
              );
            })}
          </ul>
          <ButtonPrimary className="ml-10 mt-6">
            <Link to="/farmers/fundraising">Apply For Funding</Link>
          </ButtonPrimary>
        </div>
      </div>
      <img
        className="absolute right-0 z-10 -translate-y-3/4"
        src="/assets/images/pages/farmers/how-it-works.png"
        alt=""
      />
      <img
        className="absolute left-0 z-10 -translate-x-1/4 -translate-y-1/2"
        src="/assets/images/decorations/plants.png"
        alt=""
      />
    </section>
  );
};

export default HowItWorks;
