import ButtonPrimary from "../../../../../components/common/buttons/Button";
import CustomDropdown from "../../../../../components/common/inputs/Dropdown";
import CustomInput from "../../../../../components/common/inputs/Input";
import CustomPhoneInput from "../../../../../components/common/inputs/Phone";
import { GENDERS, TITLES } from "../../../../../constants/account";
import { useNextOfKin } from "../_hooks";

const NextOfKin = () => {
  const { handleSubmit, user, isPending } = useNextOfKin();
  return (
    <section id="account-next-of-kin" className="container section-margin">
      <h2 className="mb-4 text-3xl font-bold uppercase text-dark-green">
        Next of kin
      </h2>
      <form
        onSubmit={handleSubmit}
        className="mb-4 grid items-center gap-x-6 gap-y-3 sm:grid-cols-2 md:grid-cols-3"
      >
        <CustomInput
          type="text"
          title="First Name"
          name="first_name"
          id="account-next-of-kin-first-name"
          autoComplete="given-name"
          label
          required
          defaultValue={user?.user.next_of_kin?.first_name ?? ""}
        />
        <CustomInput
          type="text"
          title="Middle Name"
          name="middle_name"
          id="account-next-of-kin-middle-name"
          autoComplete="additional-name"
          label
          required
          defaultValue={user?.user.next_of_kin?.middle_name ?? ""}
        />
        <CustomInput
          type="text"
          title="Last Name"
          name="last_name"
          id="account-next-of-kin-last-name"
          autoComplete="family-name"
          label
          required
          defaultValue={user?.user.next_of_kin?.last_name ?? ""}
        />
        <CustomDropdown
          title="Title"
          name="title"
          id="account-next-of-kin-title"
          options={TITLES}
          label
          defaultValue={TITLES.find(
            (title) => title === user?.user.next_of_kin?.title,
          )}
        />
        <CustomPhoneInput
          required
          label
          title="Phone Number"
          name="phone_number"
          id="account-next-of-kin-phone-number"
          defaultValue={user?.user.next_of_kin?.phone_number ?? ""}
        />
        <CustomInput
          type="text"
          title="Home Location"
          name="home_location"
          id="account-next-of-kin-home-location"
          label
          required
          defaultValue={user?.user.next_of_kin?.home_location ?? ""}
        />
        <CustomDropdown
          title="Gender"
          name="gender"
          id="account-next-of-kin-gender"
          options={GENDERS}
          label
          defaultValue={GENDERS.find(
            (gender) => gender === user?.user.next_of_kin?.gender,
          )}
        />
        <CustomInput
          type="email"
          title="Email"
          name="email"
          id="account-next-of-kin-email"
          autoComplete="email"
          label
          required
          defaultValue={user?.user.next_of_kin?.email ?? ""}
        />
        <CustomInput
          type="text"
          title="Relation"
          name="relation"
          id="account-next-of-kin-relation"
          label
          required
          defaultValue={user?.user.next_of_kin?.relation ?? ""}
        />
        <ButtonPrimary
          isLoading={isPending}
          type="submit"
          title="Save"
          className="h-max w-max justify-self-end px-12 md:col-start-3"
        >
          Save
        </ButtonPrimary>
      </form>
    </section>
  );
};

export default NextOfKin;
