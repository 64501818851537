import ContactUsForm from "../../../../components/section/contact";

const ContactUs = () => {
  return (
    <section id="contact-form" className="container">
      <ContactUsForm card />
    </section>
  );
};

export default ContactUs;
