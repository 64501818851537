import ThreeNeedlesHeading from "../../../../components/common/ThreeNeedlesHeading";
import SvgArrowCurved from "../../../../components/svgs/arrows/ArrowCurved";
import overviewPageData from "../data";

const AradinaAbout = () => {
  return (
    <section
      id="overview-aradina-about"
      className="relative bg-primary-green bg-opacity-60"
    >
      <div className="py-10">
        <div className="container">
          <div className="flex flex-wrap gap-x-2 text-section-heading text-white">
            <ThreeNeedlesHeading
              backgroundColor="primary-green"
              needlesColor="black"
            >
              Aradina
            </ThreeNeedlesHeading>
            <h2 className="py-1.5">is about</h2>
            <SvgArrowCurved className="mt-2 w-20 max-md:hidden" />
          </div>
        </div>
        <ul className="mt-6 grid gap-4 text-primary-green">
          {overviewPageData.about.map((text, index) => {
            return (
              <li key={`overview_about_${index}`} className="single-grid">
                <div className="single-grid-item w-3/4 rounded-r-lg bg-white lg:w-3/5"></div>
                <div className="single-grid-item container">
                  <p className="my-1.5 w-2/3 font-semibold lg:w-1/2">{text}</p>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
      <img
        loading="lazy"
        className="absolute -top-4 right-0 h-full max-lg:hidden"
        src="/assets/images/pages/about/overview/aradina-about.png"
        alt=""
      />
    </section>
  );
};

export default AradinaAbout;
