import { atom } from "jotai";
import { TOfferingDetails } from "../../types/offering";

export const offeringDetailsAtom = atom<TOfferingDetails>(
  {} as TOfferingDetails,
);

export const offeringDetailsQuantityAtom = atom<number>(1);

export const offeringDetailsInsuranceAtom = atom<number | null>(null);
