import SliderComponent from "../../../../components/common/InputFields/SliderComponent";
import CustomDropdown from "../../../../components/common/inputs/Dropdown";
import SvgFilters from "../../../../components/svgs/icons/Filters";
import { useOfferingBelongingsFilters } from "./_hooks";

const OfferingsFilters = () => {
  const {
    filters,
    setFilters,
    options,
    maxPrice,
    sliderValue,
    setSliderValue,
  } = useOfferingBelongingsFilters();
  return (
    <>
      <section id="all-offerings-filters">
        <div className="container section-margin">
          <div className="flex items-center gap-2">
            <SvgFilters className="size-9" />
            <h2 className="text-section-heading font-bold text-dark-green">
              Filters
            </h2>
          </div>
          <div className="mb-12 mt-4 grid items-end gap-4 lg:grid-cols-2">
            <CustomDropdown
              options={options}
              value={filters.filter}
              onChange={(_, value) =>
                setFilters(
                  (_prev) =>
                    (_prev = {
                      ..._prev,
                      filter: value as typeof filters.filter,
                    }),
                )
              }
              name={filters.filter?.type ?? "type"}
              className="max-sm:col-span-3"
            />
            <div>
              {maxPrice !== 0 ? (
                <SliderComponent
                  changeInstantly={(price) => setSliderValue(price as number[])}
                  onChangeMouseUp={(price) => {
                    setFilters((prev) => ({
                      ...prev,
                      minPrice: (price as number[])[0],
                      maxPrice: (price as number[])[1],
                      page: 1,
                    }));
                  }}
                  maxNumber={maxPrice}
                  value={sliderValue}
                />
              ) : (
                <p className="text-center">Loading the price ranges...</p>
              )}
              <div className="col-span-3 flex items-center justify-between self-center text-primary-green max-lg:row-start-3 max-sm:row-start-5 lg:col-span-2">
                <div className="flex h-full items-center rounded-lg border border-primary-green border-opacity-50 px-2">
                  <p>$ {filters.minPrice}</p>
                </div>
                <div className="mx-4 h-px flex-grow rounded-lg bg-primary-green bg-opacity-50"></div>
                <p>to</p>
                <div className="mx-4 h-px flex-grow rounded-lg bg-primary-green bg-opacity-50"></div>
                <div className="flex h-full items-center rounded-lg border border-primary-green border-opacity-50 px-2">
                  <p>$ {filters.maxPrice}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default OfferingsFilters;
