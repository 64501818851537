import { FormEvent, useState } from "react";
import LaravelResponse from "../../../../../components/common/LaravelResponse";
import Main from "../../../../../components/layout/Main";
import { TOfferingBelongingUser } from "../../../../../types/offering";
import { useOfferingBelongingsSingle } from "../_hooks";
import ButtonPrimary from "../../../../../components/common/buttons/Button";
import SvgCartAdd from "../../../../../components/svgs/icons/CartAdd";
import LinkPrimary from "../../../../../components/common/buttons/Link";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { toast } from "sonner";
import SvgDollar from "../../../../../components/svgs/icons/Dollar";

const PageUserOfferingsBelongingsSingle = () => {
  const { query } = useOfferingBelongingsSingle();
  return (
    <Main
      id="belonging"
      className="container grid gap-20 section-margin lg:grid-cols-2"
    >
      <LaravelResponse<TOfferingBelongingUser>
        query={query}
        success={(data) => {
          const unit =
            data.offering_item.purchased_offering.offering.unit?.unit !==
              "unit" &&
            data.offering_item.purchased_offering.offering.unit?.unit;
          return (
            <>
              <section id="belonging-gallery">
                <img
                  src={data.offering_item.purchased_offering.offering.thumbnail}
                  alt={data.offering_item.title}
                  title={data.offering_item.title}
                  className="size-full rounded-2xl object-cover"
                  height={620}
                  width={620}
                />
              </section>
              <div className="flex flex-col justify-between gap-10">
                <section id="belonging-overview" className="text-primary-green">
                  <h2 className="mb-4 font-extrabold text-tertiary-green section-heading">
                    Overview
                  </h2>
                  <p className="mb-6">
                    {
                      data.offering_item.purchased_offering.offering
                        .long_description
                    }
                  </p>
                  <div className="grid w-fit grid-cols-2 gap-x-3 gap-y-0.5 text-lg">
                    <p className="mt-1 font-extrabold">Location:</p>
                    <p>
                      {data.offering_item.purchased_offering.offering.location}
                    </p>
                    <p className="mt-1 font-extrabold">Type:</p>
                    <p>{data.offering_item.main_type.type_name}</p>
                    <p className="mt-1 font-extrabold">Date Posted:</p>
                    <p>
                      {new Date(
                        data.offering_item.created_at,
                      ).toLocaleDateString()}
                    </p>
                  </div>
                </section>
                <div className="h-px w-full rounded-full bg-primary-gray/50"></div>
                <section
                  id="belonging-details"
                  className="font-extrabold text-dark-green"
                >
                  <h2 className="mb-4 font-extrabold text-tertiary-green section-heading">
                    Details
                  </h2>
                  <div className="mb-2 flex flex-wrap items-center justify-between gap-4">
                    <p className="text-xl">Price</p>
                    <p className="text-xl">${data.price}</p>
                  </div>
                  <div className="mb-2 flex flex-wrap items-center justify-between gap-4">
                    <p className="text-xl">Quantity</p>
                    <p className="text-xl">
                      {data.quantity}
                      {unit}
                    </p>
                  </div>
                </section>
                <Cart
                  id={data.id}
                  price={data.price}
                  max={data.quantity}
                  unit={unit}
                />
                <div className="h-px w-full rounded-full bg-primary-gray/50"></div>
              </div>
            </>
          );
        }}
      />
    </Main>
  );
};

export default PageUserOfferingsBelongingsSingle;

const Cart = ({
  id,
  max,
  price,
  unit,
}: {
  id: TOfferingBelongingUser["id"];
  max: TOfferingBelongingUser["quantity"];
  price: TOfferingBelongingUser["price"];
  unit: string | false | undefined;
}) => {
  const queryClient = useQueryClient();
  const [quantity, setQuantity] = useState(1);
  const handleDecrement = () => {
    setQuantity((_prev) => (_prev > 1 ? _prev - 1 : 1));
  };
  const handleIncrement = () => {
    setQuantity((_prev) => (_prev < +max ? _prev + 1 : +max));
  };
  const { mutate } = useMutation({
    mutationFn: async (data: FormData) => {
      const res = await axios.post(
        `/user-belongings/${id}/marketplace-cart-items`,
        data,
      );
      return res.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["cart-marketplace"],
      });
      toast.success("Added to cart");
    },
    onError: () => toast.error("Something went wrong"),
  });
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    mutate(new FormData(e.currentTarget));
  };
  return (
    <section id="belonging-cart" className="text-dark-green">
      <form onSubmit={handleSubmit}>
        <div className="mb-6 flex flex-wrap items-center justify-between gap-4">
          <h4 className="text-2xl">Unit{unit && `(${unit})`}</h4>
          <div className="flex flex-wrap items-center justify-center gap-6">
            <div className="grid grid-cols-3 rounded-md bg-secondary-green/25">
              <button
                type="button"
                title="Increment"
                className="px-4 py-2 hover-link"
                onClick={handleDecrement}
              >
                -
              </button>
              <input
                id="quantity-input"
                type="number"
                className="w-12 bg-transparent text-center"
                readOnly
                value={quantity}
                tabIndex={-1}
                name="quantity"
              />
              <button
                type="button"
                title="Increment"
                className="px-4 py-2 hover-link"
                onClick={handleIncrement}
              >
                +
              </button>
            </div>
          </div>
        </div>
        <h5 className="text-xl">Total</h5>
        <h4 className="mb-4 text-3xl">${quantity * +price}</h4>
        <div className="grid grid-cols-2 gap-4">
          <ButtonPrimary
            secondary
            type="submit"
            className="flex items-center justify-center gap-2 py-2.5"
            title="Add to cart"
          >
            <SvgCartAdd className="size-5" />
            Add to cart
          </ButtonPrimary>
          <LinkPrimary
            to={`/user/offerings/belongings/offer/${id}`}
            className="flex items-center justify-center gap-2 py-2.5"
            title="Send offer"
          >
            <SvgDollar className="size-6" />
            Send Offer
          </LinkPrimary>
        </div>
      </form>
    </section>
  );
};
