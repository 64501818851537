import { TSvgComponent } from "../../../types/utils/components";

const SvgAradinaFull: TSvgComponent = ({ ...props }) => {
  return (
    <svg
      {...props}
      viewBox="0 0 154 82"
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M3.71944 77.3253V79.876H2.94834V77.3253L0 72.5072H0.827803L3.35657 76.6451L5.88534 72.5072H6.66779L3.71944 77.3253Z" />
      <path d="M6.51953 77.0985C6.51953 75.4547 7.72155 74.287 9.34313 74.287C10.9647 74.287 12.1554 75.4547 12.1554 77.0985C12.1554 78.7423 10.9647 79.9213 9.34313 79.9213C7.72155 79.9213 6.51953 78.7423 6.51953 77.0985ZM11.3956 77.0985C11.3956 75.8061 10.5225 74.9445 9.34313 74.9445C8.1638 74.9445 7.2793 75.8061 7.2793 77.0985C7.2793 78.3909 8.1638 79.2525 9.34313 79.2525C10.5225 79.2525 11.3956 78.3909 11.3956 77.0985Z" />
      <path d="M18.6755 74.3323V79.8646H17.9611V78.8556C17.5755 79.5245 16.8611 79.9213 15.9766 79.9213C14.5705 79.9213 13.6406 79.1277 13.6406 77.5406V74.3323H14.389V77.4725C14.389 78.6516 15.0014 79.2524 16.056 79.2524C17.2127 79.2524 17.9384 78.5042 17.9384 77.2458V74.3436H18.6868L18.6755 74.3323Z" />
      <path d="M23.5533 74.287V75.0125C23.4852 75.0125 23.4285 75.0125 23.3718 75.0125C22.2152 75.0125 21.5121 75.7494 21.5121 77.0531V79.876H20.7637V74.3437H21.4781V75.432C21.8296 74.6951 22.5327 74.3097 23.5533 74.3097V74.287Z" />
      <path d="M33.0896 76.1917H33.838V79.0032C33.1463 79.6267 32.1484 79.9441 31.1278 79.9441C28.8825 79.9441 27.2383 78.357 27.2383 76.203C27.2383 74.049 28.8825 72.4506 31.1392 72.4506C32.2505 72.4506 33.2257 72.8133 33.872 73.5162L33.3844 74.015C32.7608 73.4028 32.0237 73.1534 31.1618 73.1534C29.3361 73.1534 27.998 74.4458 27.998 76.203C27.998 77.9602 29.3361 79.2526 31.1505 79.2526C31.8536 79.2526 32.5113 79.0939 33.0783 78.6857V76.203L33.0896 76.1917Z" />
      <path d="M40.0638 76.4409V79.8759H39.3493V79.0143C39.0092 79.5811 38.3628 79.9326 37.4443 79.9326C36.1969 79.9326 35.4258 79.275 35.4258 78.3228C35.4258 77.4725 35.9701 76.7583 37.5577 76.7583H39.3153V76.4182C39.3153 75.4659 38.7824 74.9558 37.7504 74.9558C37.036 74.9558 36.3556 75.2052 35.9021 75.6133L35.5619 75.0578C36.1175 74.5816 36.9453 74.3096 37.8185 74.3096C39.2473 74.3096 40.0638 75.0238 40.0638 76.4522V76.4409ZM39.3153 78.2094V77.3025H37.5803C36.5031 77.3025 36.1629 77.7219 36.1629 78.2887C36.1629 78.9349 36.6845 79.3317 37.5577 79.3317C38.4308 79.3317 39.0205 78.9349 39.3153 78.2094Z" />
      <path d="M45.0066 79.5358C44.7118 79.7966 44.2695 79.9213 43.8386 79.9213C42.7954 79.9213 42.217 79.3318 42.217 78.3115V74.9671H41.2305V74.3323H42.217V73.1193H42.9655V74.3323H44.6551V74.9671H42.9655V78.2775C42.9655 78.935 43.3056 79.2864 43.918 79.2864C44.2242 79.2864 44.5303 79.1957 44.7345 79.003L44.9953 79.5358H45.0066Z" />
      <path d="M50.9844 77.3366H46.3465C46.4258 78.4929 47.3103 79.2638 48.5237 79.2638C49.1927 79.2638 49.7938 79.0257 50.2247 78.5383L50.6442 79.0257C50.1453 79.6152 49.3855 79.9327 48.5124 79.9327C46.7887 79.9327 45.6094 78.7537 45.6094 77.1098C45.6094 75.466 46.7547 74.2983 48.3196 74.2983C49.8845 74.2983 51.0071 75.4433 51.0071 77.1098C51.0071 77.1779 51.0071 77.2572 51.0071 77.3366H50.9844ZM46.3465 76.7811H50.27C50.1793 75.6928 49.3855 74.9332 48.2969 74.9332C47.2083 74.9332 46.4372 75.6928 46.3351 76.7811H46.3465Z" />
      <path d="M60.5441 74.3323L58.4462 79.8646H57.7432L55.9968 75.3299L54.2505 79.8646H53.5475L51.4609 74.3323H52.1753L53.9103 79.037L55.6907 74.3323H56.337L58.106 79.037L59.8637 74.3323H60.5441Z" />
      <path d="M65.6809 76.4409V79.8759H64.9665V79.0143C64.6263 79.5811 63.98 79.9326 63.0614 79.9326C61.8141 79.9326 61.043 79.275 61.043 78.3228C61.043 77.4725 61.5873 76.7583 63.1748 76.7583H64.9325V76.4182C64.9325 75.4659 64.3995 74.9558 63.3676 74.9558C62.6532 74.9558 61.9728 75.2052 61.5192 75.6133L61.179 75.0578C61.7347 74.5816 62.5625 74.3096 63.4357 74.3096C64.8645 74.3096 65.6809 75.0238 65.6809 76.4522V76.4409ZM64.9325 78.2094V77.3025H63.1975C62.1202 77.3025 61.7801 77.7219 61.7801 78.2887C61.7801 78.9349 62.3017 79.3317 63.1748 79.3317C64.048 79.3317 64.6377 78.9349 64.9325 78.2094Z" />
      <path d="M72.2577 74.3323L69.4908 80.5334C69.0259 81.6218 68.4362 81.9619 67.6424 81.9619C67.1208 81.9619 66.6332 81.7918 66.293 81.4517L66.6445 80.8962C66.9167 81.1683 67.2569 81.3157 67.6537 81.3157C68.1527 81.3157 68.4815 81.0889 68.7991 80.3861L69.0372 79.8532L66.5651 74.3323H67.3476L69.4341 79.037L71.5206 74.3323H72.2577Z" />
      <path d="M78.9832 79.5359C78.6883 79.7967 78.2461 79.9214 77.8152 79.9214C76.7719 79.9214 76.1936 79.3319 76.1936 78.3116V74.9673H75.207V74.3324H76.1936V73.1194H76.942V74.3324H78.6316V74.9673H76.942V78.2776C76.942 78.9351 77.2822 79.2865 77.8946 79.2865C78.2007 79.2865 78.5069 79.1958 78.711 79.0031L78.9718 79.5359H78.9832Z" />
      <path d="M79.582 77.0985C79.582 75.4547 80.784 74.287 82.4056 74.287C84.0272 74.287 85.2179 75.4547 85.2179 77.0985C85.2179 78.7423 84.0272 79.9213 82.4056 79.9213C80.784 79.9213 79.582 78.7423 79.582 77.0985ZM84.4581 77.0985C84.4581 75.8061 83.585 74.9445 82.4056 74.9445C81.2263 74.9445 80.3418 75.8061 80.3418 77.0985C80.3418 78.3909 81.2263 79.2525 82.4056 79.2525C83.585 79.2525 84.4581 78.3909 84.4581 77.0985Z" />
      <path d="M89.0059 76.1916C89.0059 74.049 90.6501 72.4392 92.8841 72.4392C95.118 72.4392 96.7509 74.0263 96.7509 76.1916C96.7509 78.357 95.0953 79.9328 92.8841 79.9328C90.6728 79.9328 89.0059 78.3343 89.0059 76.1916ZM95.9685 76.1916C95.9685 74.4345 94.6531 73.1421 92.8841 73.1421C91.1151 73.1421 89.777 74.4345 89.777 76.1916C89.777 77.9488 91.1037 79.2412 92.8841 79.2412C94.6644 79.2412 95.9685 77.9488 95.9685 76.1916Z" />
      <path d="M103.941 77.0985C103.941 78.7877 102.762 79.9213 101.186 79.9213C100.301 79.9213 99.5415 79.5472 99.0766 78.8217V81.9053H98.3281V74.3323H99.0425V75.432C99.4961 74.6951 100.267 74.2983 101.174 74.2983C102.751 74.2983 103.93 75.432 103.93 77.1098L103.941 77.0985ZM103.193 77.0985C103.193 75.8175 102.297 74.9445 101.129 74.9445C99.9611 74.9445 99.0652 75.8175 99.0652 77.0985C99.0652 78.3795 99.9497 79.2525 101.129 79.2525C102.308 79.2525 103.193 78.3909 103.193 77.0985Z" />
      <path d="M111.072 77.0985C111.072 78.7877 109.893 79.9213 108.317 79.9213C107.432 79.9213 106.672 79.5472 106.207 78.8217V81.9053H105.459V74.3323H106.173V75.432C106.627 74.6951 107.398 74.2983 108.305 74.2983C109.881 74.2983 111.061 75.432 111.061 77.1098L111.072 77.0985ZM110.324 77.0985C110.324 75.8175 109.428 74.9445 108.26 74.9445C107.092 74.9445 106.196 75.8175 106.196 77.0985C106.196 78.3795 107.081 79.2525 108.26 79.2525C109.439 79.2525 110.324 78.3909 110.324 77.0985Z" />
      <path d="M112.037 77.0985C112.037 75.4547 113.239 74.287 114.861 74.287C116.482 74.287 117.673 75.4547 117.673 77.0985C117.673 78.7423 116.482 79.9213 114.861 79.9213C113.239 79.9213 112.037 78.7423 112.037 77.0985ZM116.913 77.0985C116.913 75.8061 116.04 74.9445 114.861 74.9445C113.681 74.9445 112.797 75.8061 112.797 77.0985C112.797 78.3909 113.681 79.2525 114.861 79.2525C116.04 79.2525 116.913 78.3909 116.913 77.0985Z" />
      <path d="M121.981 74.287V75.0125C121.913 75.0125 121.856 75.0125 121.8 75.0125C120.643 75.0125 119.94 75.7494 119.94 77.0531V79.876H119.191V74.3437H119.906V75.432C120.257 74.6951 120.96 74.3097 121.981 74.3097V74.287Z" />
      <path d="M126.495 79.5358C126.2 79.7966 125.758 79.9213 125.327 79.9213C124.284 79.9213 123.705 79.3318 123.705 78.3115V74.9671H122.719V74.3323H123.705V73.1193H124.454V74.3323H126.143V74.9671H124.454V78.2775C124.454 78.935 124.794 79.2864 125.406 79.2864C125.712 79.2864 126.019 79.1957 126.223 79.003L126.484 79.5358H126.495Z" />
      <path d="M132.847 74.3323V79.8646H132.133V78.8556C131.747 79.5245 131.033 79.9213 130.148 79.9213C128.742 79.9213 127.812 79.1277 127.812 77.5406V74.3323H128.561V77.4725C128.561 78.6516 129.173 79.2524 130.228 79.2524C131.385 79.2524 132.11 78.5042 132.11 77.2458V74.3436H132.859L132.847 74.3323Z" />
      <path d="M140.025 76.6563V79.8646H139.276V76.7244C139.276 75.5567 138.664 74.9558 137.609 74.9558C136.419 74.9558 135.67 75.6927 135.67 76.9511V79.8533H134.922V74.321H135.636V75.3413C136.033 74.6724 136.782 74.2756 137.745 74.2756C139.095 74.2756 140.025 75.0579 140.025 76.645V76.6563Z" />
      <path d="M141.896 72.5864C141.896 72.303 142.135 72.0649 142.441 72.0649C142.747 72.0649 142.985 72.2917 142.985 72.5864C142.985 72.8925 142.758 73.1306 142.441 73.1306C142.123 73.1306 141.896 72.8925 141.896 72.5978V72.5864ZM142.067 74.3323H142.815V79.8646H142.067V74.3323Z" />
      <path d="M147.802 79.5359C147.507 79.7967 147.064 79.9214 146.634 79.9214C145.59 79.9214 145.012 79.3319 145.012 78.3116V74.9673H144.025V74.3324H145.012V73.1194H145.76V74.3324H147.45V74.9673H145.76V78.2776C145.76 78.9351 146.101 79.2865 146.713 79.2865C147.019 79.2865 147.325 79.1958 147.529 79.0031L147.79 79.5359H147.802Z" />
      <path d="M153.709 74.3323L150.942 80.5334C150.477 81.6218 149.887 81.9619 149.094 81.9619C148.572 81.9619 148.084 81.7918 147.744 81.4517L148.096 80.8962C148.368 81.1683 148.708 81.3157 149.105 81.3157C149.604 81.3157 149.933 81.0889 150.25 80.3861L150.488 79.8532L148.016 74.3323H148.799L150.885 79.037L152.972 74.3323H153.709Z" />
      <path d="M84.5138 16.8895H82.3706L80.9984 14.2027L76.8594 6.05166L72.7204 14.2027L71.3483 16.8895H69.2051L70.5772 14.2027L76.8481 1.87976L83.1417 14.2141L84.5138 16.9009V16.8895Z" />
      <path d="M85.0696 17.2294L83.2666 20.2903L76.8483 6.79967L70.1351 20.2903L68.627 17.2294L76.8596 1.12L85.081 17.2294H85.0696ZM82.5749 16.5492H83.9583L76.8483 2.61644L69.7383 16.5492H71.1217L76.8483 5.30323L82.5749 16.5492Z" />
      <path d="M71.7013 25.3238H68.22L65.9974 20.9818L59.2842 7.76323L52.5711 20.9818L50.3485 25.3238H46.8672L49.0898 20.9818L59.2842 0.983887L69.4787 20.9705L71.7013 25.3124V25.3238Z" />
      <path d="M106.844 25.3238H103.363L101.14 20.9818L94.4268 7.76323L87.7137 20.9818L85.4911 25.3238H82.0098L84.2324 20.9818L94.4268 0.983887L104.621 20.9705L106.844 25.3124V25.3238Z" />
      <path d="M26.0142 67.4168H22.5329L20.3103 63.0748L13.5971 49.8563L6.88397 63.0748L4.66138 67.4168H1.19141L3.414 63.0748L13.5971 43.0883L23.7916 63.0748L26.0142 67.4168Z" />
      <path d="M72.5978 67.4168H69.1165L66.8939 63.0748L60.1807 49.8563L53.4676 63.0748L51.245 67.4168H47.7637L49.9863 63.0748L60.1694 43.0883L70.3638 63.0748L72.5864 67.4168H72.5978Z" />
      <path d="M152.522 67.4168H149.04L146.818 63.0748L140.105 49.8563L133.391 63.0748L131.169 67.4168H127.688L129.91 63.0748L140.093 43.0883L150.288 63.0748L152.51 67.4168H152.522Z" />
      <path d="M31.6717 67.3374C31.5129 67.3941 31.3088 67.4508 31.048 67.5188C30.7872 67.5868 30.4923 67.6208 30.1862 67.6208C29.0295 67.6208 28.4512 67.1334 28.4512 66.1698V45.2536C28.4512 44.8341 28.5532 44.5167 28.7687 44.2899C28.9728 44.0632 29.3017 43.8932 29.7552 43.7571C30.515 43.5191 31.4562 43.349 32.5675 43.247C33.6788 43.1449 34.7674 43.0883 35.8107 43.0883C39.1106 43.0883 41.5713 43.7458 43.1815 45.0722C44.7918 46.3986 45.5969 48.2805 45.5969 50.7178C45.5969 52.5317 45.132 54.0508 44.2021 55.2752C43.2723 56.5109 41.8661 57.3838 39.9724 57.9053C40.6301 58.7442 41.2878 59.5831 41.9342 60.4221C42.5919 61.261 43.2156 62.0545 43.8052 62.8028C44.3949 63.551 44.9165 64.2198 45.3815 64.832C45.835 65.4329 46.1979 65.909 46.4587 66.2491C46.3567 66.6912 46.1299 67.054 45.767 67.3148C45.4155 67.5755 45.0526 67.7115 44.6897 67.7115C44.2475 67.7115 43.8733 67.6095 43.5898 67.3941C43.3063 67.1901 43.0001 66.8613 42.6826 66.4078L36.9447 58.3928H31.6717V67.3601V67.3374ZM36.1509 55.6947C38.09 55.6947 39.6095 55.2752 40.7095 54.4363C41.8094 53.5974 42.3651 52.3503 42.3651 50.7065C42.3651 49.0627 41.7867 47.8157 40.6301 46.9881C39.4734 46.1605 37.8292 45.7524 35.6746 45.7524C34.9715 45.7524 34.2571 45.7864 33.5314 45.8544C32.8056 45.9224 32.1706 46.0018 31.6263 46.1152V55.706H36.1509V55.6947Z" />
      <path d="M93.305 55.1845C93.305 57.3384 93.0101 59.1977 92.4205 60.7848C91.8308 62.3719 90.9916 63.687 89.903 64.7413C88.8144 65.7956 87.499 66.5665 85.9454 67.0767C84.4032 67.5868 82.6682 67.8475 80.7518 67.8475C79.9127 67.8475 78.9715 67.8135 77.9169 67.7455C76.8736 67.6775 75.8757 67.5074 74.9232 67.2127C73.8232 66.8726 73.2676 66.2717 73.2676 65.3988V45.1855C73.2676 44.7661 73.3696 44.4486 73.5851 44.2219C73.7892 43.9952 74.1181 43.8251 74.5717 43.6891C75.3881 43.4283 76.3066 43.2583 77.3272 43.1789C78.3478 43.0996 79.3684 43.0656 80.4003 43.0656C82.3167 43.0656 84.063 43.3263 85.6506 43.8364C87.2382 44.3466 88.5876 45.1062 89.7216 46.1151C90.8442 47.1241 91.7287 48.3825 92.3524 49.8902C92.9761 51.398 93.2936 53.1552 93.2936 55.1731L93.305 55.1845ZM90.0391 55.1845C90.0391 53.5066 89.7783 52.0669 89.268 50.8765C88.7577 49.6862 88.066 48.7112 87.1815 47.9517C86.297 47.1921 85.2764 46.6366 84.1084 46.2852C82.9404 45.9337 81.693 45.7523 80.3549 45.7523C79.5158 45.7523 78.79 45.775 78.1777 45.8317C77.5654 45.8884 76.987 45.9677 76.4654 46.0698V64.7866C77.1004 64.9454 77.7695 65.0474 78.4952 65.1041C79.221 65.1608 79.958 65.1834 80.7178 65.1834C83.8136 65.1834 86.1382 64.3445 87.7031 62.6667C89.268 60.9889 90.0391 58.4948 90.0391 55.1958V55.1845Z" />
      <path d="M100.255 67.5075C99.9942 67.5756 99.6994 67.6096 99.3932 67.6096C98.2365 67.6096 97.6582 67.1221 97.6582 66.1585V43.5871C97.7943 43.5304 97.9871 43.4738 98.2706 43.4057C98.5427 43.3377 98.8375 43.3037 99.1551 43.3037C100.278 43.3037 100.845 43.7912 100.845 44.7548V67.3261C100.709 67.3828 100.516 67.4395 100.255 67.5075Z" />
      <path d="M108.477 67.6095C107.321 67.6095 106.742 67.1334 106.742 66.1924V43.9839C107.218 43.5077 107.922 43.281 108.863 43.281C109.486 43.281 110.031 43.4057 110.473 43.6551C110.915 43.9045 111.323 44.358 111.698 45.0155L119.205 57.5992C119.522 58.1547 119.851 58.7329 120.191 59.3451C120.531 59.9573 120.86 60.5808 121.178 61.193C121.495 61.8052 121.79 62.3833 122.062 62.9275C122.334 63.4603 122.573 63.9138 122.754 64.2879L122.913 64.2539C122.697 62.1339 122.584 59.9913 122.538 57.8486C122.504 55.6947 122.482 53.5974 122.482 51.5568V43.6098C122.641 43.5531 122.845 43.4964 123.117 43.4284C123.378 43.3604 123.672 43.3263 123.979 43.3263C125.135 43.3263 125.714 43.8025 125.714 44.7434V66.9974C125.475 67.2127 125.181 67.3715 124.806 67.4848C124.432 67.5982 124.035 67.6662 123.582 67.6662C122.958 67.6662 122.414 67.5415 121.972 67.2921C121.529 67.0427 121.121 66.5892 120.747 65.9317L113.274 53.348C112.956 52.8265 112.628 52.2483 112.276 51.6135C111.924 50.9786 111.584 50.3664 111.255 49.7656C110.927 49.1647 110.632 48.5866 110.348 48.0311C110.076 47.4756 109.838 47.0221 109.657 46.6593L109.498 46.6933C109.634 47.9517 109.736 49.3915 109.815 51.0013C109.895 52.6111 109.929 54.1416 109.929 55.5813V67.3375C109.793 67.3941 109.6 67.4508 109.316 67.5188C109.044 67.5869 108.761 67.6209 108.466 67.6209L108.477 67.6095Z" />
    </svg>
  );
};

export default SvgAradinaFull;
