import { Checkbox, styled } from "@mui/material";

const CheckBoxStyle = {
  "&.Mui-checked": {
    color: "#547C2C",
  },
};

interface CheckBoxTypes {
  label?: string;
  name: string;
  value?: boolean;
  limit?: number;
  onChange: (name: string, value: boolean) => void;
}

const CustomCheckBox = styled(Checkbox)({
  ...CheckBoxStyle,
});
export const CheckBox = (Props: CheckBoxTypes) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    Props.onChange(Props.name, event.target.checked);
  };
  return (
    <CustomCheckBox
      checked={Props.value}
      onChange={handleChange}
      inputProps={{ "aria-label": "controlled" }}
    />
  );
};
export default CheckBox;
