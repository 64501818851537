import Main from "../../../components/layout/Main";
import Cart from "./Cart";

const PageCartMarketplace = () => {
  return (
    <Main id="cart-marketplace">
      <Cart />
    </Main>
  );
};

export default PageCartMarketplace;
