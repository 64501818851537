import CustomInput from "../../../../components/common/inputs/Input";

const AdditionalInformation = () => {
  return (
    <section id="fundraising-additional-information" className="section-margin container">
      <h3 className="mb-6 text-3xl font-bold text-primary-green">
        Additional information
      </h3>
      <div className="grid gap-6">
        <CustomInput
          type="text"
          name="share_your_work"
          label="Is there anything else you'd like to share with us about your work?"
        />
        <div>
          <p className="text-primary-green">
            If you have supporting documentation, please upload here.
          </p>
          <label htmlFor="file_url">
            <input
              type="file"
              id="file_url"
              name="file_url"
              className="my-2 w-max text-lg before:hidden after:hidden [&>input]:h-auto"
            />
          </label>
        </div>
        <CustomInput
          type="text"
          name="exposure_type"
          label="How did you hear about Aradina?"
        />
      </div>
    </section>
  );
};

export default AdditionalInformation;
