const Inquiries = () => {
  return (
    <section id="media-inquiries" className="bg-primary-yellow">
      <div className="container text-white text-2xl font-bold py-5 text-center">
        For inquiries, please contact{" "}
        <a href="mailto:media@aradina.com">media@aradina.com</a> or call{" "}
        <a href="tel:+961 9 942932">+961 9 942932, extension 4</a>.
      </div>
    </section>
  );
};

export default Inquiries;
