import { Outlet, useLocation, useNavigate } from "react-router-dom";
import TabsNavigation from "../section/TabsNavigation";
import { useEffect } from "react";

const LayoutResources = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  useEffect(() => {
    if (pathname === "/resources/" || pathname === "/resources")
      navigate("/resources/privacy-policy");
    //eslint-disable-next-line
  }, []);
  return (
    <>
      <TabsNavigation
        id="resources"
        data={[
          {
            path: "/resources/privacy-policy",
            title: "Privacy policy",
          },
          {
            path: "/resources/security-settings",
            title: "Security settings",
          },
        ]}
      />
      <Outlet />
    </>
  );
};

export default LayoutResources;
