import Main from "../../../components/layout/Main";
// import OurOfferings from "../../../components/section/ourOfferings";
import Cart from "./Cart";

const PageCartUser = () => {
  return (
    <Main id="cart-user">
      <Cart />
      {/* <OurOfferings id="cart-user" other /> */}
    </Main>
  );
};

export default PageCartUser;
