import { TSvgComponent } from "../../../types/utils/components";

const SvgArrowDouble: TSvgComponent = ({ ...props }) => {
  return (
    <svg
      viewBox="0 0 60 46"
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M5.29107 32.9059C6.02115 31.9861 6.76945 31.0764 7.48132 30.1465C11.8606 24.4409 16.7407 19.2327 22.7989 15.2509C30.1722 10.4119 38.1566 7.39958 47.0726 7.21663C47.3341 7.2093 47.5911 7.08984 48.2991 6.89662C47.144 5.99778 46.242 5.28712 45.3396 4.58578C44.4011 3.85489 43.757 3.00615 44.4623 1.79609C45.0361 0.82286 46.3901 0.573668 47.6399 1.4393C51.1442 3.87634 54.6279 6.35913 57.9935 8.98605C59.6898 10.3094 59.4557 11.8384 57.591 13.1102C54.0637 15.5133 50.5104 17.8686 46.9571 20.2239C45.5357 21.1698 44.2237 21.0943 43.5115 20.0934C42.7815 19.073 43.0189 17.8987 44.3938 16.7363C45.8362 15.5208 47.3852 14.4312 48.8896 13.2931C49.4202 12.887 49.9322 12.4801 50.9072 11.7201C48.4229 11.1461 46.3884 11.17 44.3794 11.4654C33.5462 13.0578 24.4664 18.1028 16.9463 25.9017C13.9874 28.9637 11.4762 32.4555 8.80439 35.7818C8.28192 36.4307 7.9307 37.2177 7.85399 38.3431C9.38235 37.5138 10.9026 36.6562 12.4484 35.8556C13.2405 35.4422 14.0585 34.8619 14.8814 34.8136C15.6388 34.7718 16.7795 35.1383 17.1096 35.703C17.44 36.2583 17.2022 37.4419 16.7833 38.0674C16.3244 38.7565 15.4198 39.1837 14.6451 39.6259C11.4686 41.4658 8.26534 43.2766 5.08034 45.0976C2.36404 46.6496 0.603825 45.7246 0.561168 42.6166C0.509891 38.8459 0.580055 35.0712 0.631595 31.2957C0.637968 30.7176 0.670619 29.9635 1.01261 29.6052C1.56059 29.0133 2.45101 28.2683 3.08129 28.3609C3.67433 28.4519 4.31125 29.4682 4.56843 30.2069C4.84262 30.9836 4.69058 31.9098 4.71866 32.7692C4.92148 32.8246 5.11538 32.8703 5.31819 32.9258L5.29107 32.9059Z" />
    </svg>
  );
};

export default SvgArrowDouble;
