import { useQuery } from "@tanstack/react-query";
import Main from "../../../../components/layout/Main";
import axios from "axios";
import {
  TLaravelPagination,
  TLaravelResponse,
} from "../../../../types/utils/laravel";
import LoadingImage from "../../../../components/common/LoadingImage";
import CardBelonging from "../../../../components/common/cards/OfferingBelonging";
import { TOfferingBelonging } from "../../../../types/offering";

const PageUserOfferingsTodaysPicks = () => {
  const { data, isLoading } = useQuery({
    queryKey: ["offerings", "todays-picks"],
    queryFn: async () => {
      const res = await axios.get<
        TLaravelResponse<{
          belongings: TLaravelPagination<TOfferingBelonging[]>;
          max_price: string;
        }>
      >("belongings?isToday=true");
      return res.data;
    },
  });
  return (
    <Main id="todays-picks">
      <section id="todays-picks-offerings" className="container section-margin">
        <ul className="grid-offerings">
          {isLoading
            ? [...Array(9)].map((_, index) => {
                return <LoadingImage key={`user_offering_loading_${index}`} />;
              })
            : data?.success
              ? data.data.belongings.data.map((offering, index) => {
                  return (
                    <li key={`user_offering_${index}`}>
                      <CardBelonging offeringBelonging={offering} />
                    </li>
                  );
                })
              : null}
        </ul>
      </section>
    </Main>
  );
};

export default PageUserOfferingsTodaysPicks;
