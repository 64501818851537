import { useEffect, useState } from "react";
import SvgBurger from "../../svgs/icons/SvgBurger";
import Navigation from "./Navigation";
import { useLocation } from "react-router-dom";
import UserButton from "./UserButton";
import SvgErrorX from "../../svgs/icons/ErrorX";

const MobileNavigation = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const handleClick = () => {
    if (isMobile) {
      document.body.classList.toggle("active-menu");
      setIsOpen((prev) => (prev = !isOpen));
    } else {
      document.body.classList.remove("active-menu");
      setIsOpen((prev) => (prev = false));
    }
  };
  useEffect(() => {
    document.body.classList.remove("active-menu");
    setIsOpen((prev) => (prev = false));
  }, [location]);
  useEffect(() => {
    const mobileNav = document.getElementById(
      "mobile-navigation",
    ) as HTMLDivElement;
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1280);
      if (window.innerWidth >= 1280) {
        document.body.classList.remove("active-menu");
      }
    };
    const preventScroll = (e: any) => {
      e.preventDefault();
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    mobileNav.addEventListener("wheel", preventScroll, { passive: false });
    mobileNav.addEventListener("touchmove", preventScroll, { passive: false });
    return () => {
      window.removeEventListener("resize", handleResize);
      mobileNav.removeEventListener("wheel", preventScroll);
      mobileNav.addEventListener("touchmove", preventScroll);
    };
  }, []);
  return (
    <>
      <div className="h-max">
        <SvgBurger
          onClick={() => handleClick()}
          className="w-10 fill-dark-green"
        />
      </div>
      <aside
        id="mobile-navigation"
        className="fixed inset-0 max-h-screen w-full translate-x-full overflow-y-hidden bg-primary-green py-10"
      >
        <div className="container flex flex-col gap-y-8 bg-primary-green">
          <SvgErrorX
            onClick={() => handleClick()}
            className="h-8 w-8 self-end fill-dark-green"
          />
          <UserButton mobile />
          <Navigation mobile />
        </div>
      </aside>
    </>
  );
};

export default MobileNavigation;
