const AradinaDestination = () => {
  return (
    <section
      id="farmers-aradina-destination"
      className="bg-primary-yellow py-10"
    >
      <div className="container">
        <h3 className="z-10 text-center text-[28px] text-white">
          Any farmer or rancher with a solid potential in need of capital{" "}
          <span className="relative uppercase">
            <img
              className="absolute z-0 -translate-y-1 scale-125"
              src="/assets/images/decorations/circled-text.png"
              alt=""
            />
            Aradina
          </span>{" "}
          WILL BE THE{" "}
          <span className="relative uppercase">
            <img
              className="absolute z-0 -translate-y-3 scale-125"
              src="/assets/images/decorations/circled-text.png"
              alt=""
            />
            Destination
          </span>
        </h3>
      </div>
    </section>
  );
};

export default AradinaDestination;
