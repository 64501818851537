import ContactCard from "./ContactCard";
import ContactSocials from "./ContactSocials";
import ContactUsForm from "./ContactUsForm";

const ContactUsSection = ({ card = false }) => {
  return (
    <div className="section-margin">
      <h3 className="mb-2 text-section-heading text-primary-green">
        Contact Us
      </h3>
      <div className="grid gap-x-20 gap-y-14 xl:grid-cols-[3fr_1fr]">
        <ContactUsForm />
        <div className="grid items-center gap-x-10 gap-y-6 max-xl:row-start-1 md:max-xl:grid-cols-[3fr_2fr]">
          {card && <ContactCard />}
          <ContactSocials />
        </div>
      </div>
    </div>
  );
};

export default ContactUsSection;
