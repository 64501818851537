import { useAtomValue } from "jotai";
import SvgPDF from "../../../../../../components/svgs/icons/PDF";
import { offeringDetailsAtom } from "../../../../atoms";

export const DocumentsTab = () => {
  const offering = useAtomValue(offeringDetailsAtom);
  return (
    <section
      id="offering-tab-documents"
      className="container grid gap-5 section-margin sm:grid-cols-2 lg:grid-cols-4"
    >
      {offering.documents?.map(({ id, path, name }) => (
        <a
          key={id}
          href={path}
          target="_blank"
          rel="noopener noreferrer"
          className="flex flex-col items-center justify-center rounded-xl bg-white py-4 text-center shadow-md"
        >
          <SvgPDF className="mb-1 w-6" />
          <p>{name}</p>
        </a>
      ))}
    </section>
  );
};
export default DocumentsTab;
