import { Link, useLocation } from "react-router-dom";
import {
  headerNavigation,
  userHeaderNavigation,
} from "../../../constants/navigation";
import SvgArrow from "../../svgs/arrows/Arrow";

const Navigation = ({
  loggedIn = false,
  mobile = false,
}: {
  loggedIn?: boolean;
  mobile?: boolean;
}) => {
  const location = useLocation();
  const getDefaultPath = (path: (typeof userHeaderNavigation)[0]["path"]) => {
    switch (path) {
      case "/user/offerings":
        return "/user/offerings/todays-picks";
      default:
        return path;
    }
  };
  return (
    <nav>
      <ul className={`flex gap-3.5 ${mobile ? "flex-col" : "items-center"}`}>
        {loggedIn
          ? userHeaderNavigation.map(({ id, title, path }) => {
              return (
                <li key={`header_${id}`}>
                  <Link
                    to={getDefaultPath(path)}
                    className={`hover-link ${
                      location.pathname.startsWith(path)
                        ? mobile
                          ? "font-extrabold text-white"
                          : "font-extrabold text-dark-green"
                        : mobile
                          ? "text-white"
                          : "text-dark-green"
                    }`}
                  >
                    {title}
                  </Link>
                </li>
              );
            })
          : headerNavigation.map(
              ({ id, title, path, parentPath, children }) => (
                <li key={`header_${id}`}>
                  {path ? (
                    <Link
                      to={path}
                      className={`hover-link ${
                        location.pathname === "/"
                          ? location.pathname === path
                            ? "font-extrabold text-white"
                            : "text-white"
                          : location.pathname === path
                            ? mobile
                              ? "font-extrabold text-white"
                              : "font-extrabold text-dark-green"
                            : mobile
                              ? "text-white"
                              : "text-dark-green"
                      }`}
                    >
                      {title}
                    </Link>
                  ) : (
                    <div className={`group ${mobile && "relative w-max"}`}>
                      <div className="flex cursor-pointer items-center gap-2">
                        <span
                          className={
                            location.pathname === "/"
                              ? "text-white"
                              : "/" + location.pathname.split("/")[1] ===
                                  parentPath
                                ? mobile
                                  ? "translate-y-0.5 font-extrabold text-white"
                                  : "translate-y-0.5 font-extrabold text-dark-green"
                                : mobile
                                  ? "text-white"
                                  : "text-dark-green"
                          }
                        >
                          {title}
                        </span>
                        <SvgArrow
                          className={`w-2.5 -rotate-180 duration-200 group-hover:-rotate-90 ${
                            location.pathname === "/"
                              ? "fill-white"
                              : "fill-dark-green"
                          }`}
                        />
                      </div>
                      <nav
                        className={`absolute z-20 origin-top scale-0 text-lg transition-transform duration-200 group-hover:scale-100 ${
                          mobile &&
                          "bottom-0 left-0 min-w-[150px] translate-y-full pt-2"
                        }`}
                      >
                        <ul
                          className={`rounded-lg ${
                            mobile
                              ? "bg-dark-green py-1"
                              : "bg-primary-green py-2.5"
                          }`}
                        >
                          {children?.map(({ id, title, path }, index) => (
                            <li
                              key={`header_${id}`}
                              className={
                                mobile
                                  ? `px-4 py-1 ${
                                      index !== children.length - 1 &&
                                      "border-b-2 "
                                    }`
                                  : "px-4"
                              }
                            >
                              <Link
                                to={path}
                                className={`cursor-pointer py-0.5 ${
                                  location.pathname === path
                                    ? "font-extrabold text-white"
                                    : "text-white"
                                }`}
                              >
                                {title}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </nav>
                    </div>
                  )}
                </li>
              ),
            )}
      </ul>
    </nav>
  );
};

export default Navigation;
