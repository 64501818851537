import { useAtomValue } from "jotai";
import userAtom from "../../pages/authentication/login/atom";
import { DetailedHTMLProps, HTMLAttributes } from "react";

type TProps = {
  id: string;
  className?: DetailedHTMLProps<
    HTMLAttributes<HTMLElement>,
    HTMLElement
  >["className"];
  listings?: boolean;
};

const AccountBanner = ({ id, listings, className }: TProps) => {
  const user = useAtomValue(userAtom);
  return (
    <section id={`${id}-banner`} className={className}>
      <div className="container grid grid-cols-[1fr_10fr] gap-10">
        <img
          src={user?.user.profile_picture}
          alt={user?.user.username}
          className="aspect-square rounded-full object-cover"
        />
        <div className="flex items-center justify-between text-3xl font-semibold text-primary-green">
          {!user?.user.first_name &&
          !user?.user.middle_name &&
          !user?.user.last_name ? (
            <h3>{user?.user.username}</h3>
          ) : (
            <h3>
              {user?.user.first_name ?? ""}&nbsp;
              {user?.user.middle_name ?? ""}&nbsp;
              {user?.user.last_name ?? ""}
            </h3>
          )}
          {listings && (
            <div className="rounded-full bg-tertiary-green px-10 py-4 capitalize text-white">
              <p className="-mb-1">{user?.user.listings_count} listings</p>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default AccountBanner;
