import { Link } from "react-router-dom";
import { footerNavigation } from "../../../constants/navigation";

const FooterNavigation = () => {
  return (
    <div>
      <h4 className="mb-6 font-semibold uppercase text-light-green underline">
        Get to know us
      </h4>
      <ul>
        {footerNavigation.map(({ id, title, path }) => (
          <li key={`footer_${id}`} className="w-max cursor-pointer">
            <Link className="uppercase hover-link" title={title} to={path}>
              {title}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default FooterNavigation;
