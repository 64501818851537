import Banner from "./components/Banner";
import VisionMissionMantra from "./components/VisionMissionMantra";
import AgtechCompany from "./components/AgtechCompany";
import AradinaEmpowers from "./components/AradinaEmpowers";
import ExpertiseTransparencyReturns from "./components/ExpertiseTransparencyReturns";
import InvestmentFramework from "./components/InvestmentFramework";
import AradinaAbout from "./components/AradinaAbout";
import Main from "../../../components/layout/Main";

const PageOverview = () => {
  return (
    <Main id="overview">
      <Banner />
      <VisionMissionMantra />
      <AgtechCompany />
      <AradinaEmpowers />
      <ExpertiseTransparencyReturns />
      <InvestmentFramework />
      <AradinaAbout />
    </Main>
  );
};

export default PageOverview;
