import { TSvgComponent } from "../../../types/utils/components";

const SvgTrash: TSvgComponent = (props) => {
  return (
    <svg
      viewBox="0 0 16 16"
      fill="#FF0000"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.7517 2.59438C15.0836 2.59438 15.36 2.85277 15.36 3.18157V3.48557C15.36 3.80636 15.0836 4.07276 14.7517 4.07276H0.609152C0.276361 4.07276 0 3.80636 0 3.48557V3.18157C0 2.85277 0.276361 2.59438 0.609152 2.59438H3.09723C3.60265 2.59438 4.0425 2.25758 4.15619 1.78238L4.28649 1.23678C4.48899 0.493592 5.15541 0 5.9181 0H9.44188C10.1963 0 10.8702 0.493592 11.0652 1.19759L11.2046 1.78158C11.3175 2.25758 11.7573 2.59438 12.2636 2.59438H14.7517ZM13.4876 13.7072C13.7474 11.4377 14.2022 6.0457 14.2022 5.9913C14.2188 5.8265 14.1615 5.6705 14.0478 5.5449C13.9258 5.4273 13.7714 5.3577 13.6014 5.3577H1.76514C1.59418 5.3577 1.43152 5.4273 1.31865 5.5449C1.20412 5.6705 1.14768 5.8265 1.15598 5.9913C1.15751 6.0013 1.17383 6.19122 1.20111 6.50872C1.32231 7.91936 1.65988 11.8482 1.87801 13.7072C2.03237 15.0768 2.99092 15.9376 4.37936 15.9688C5.45078 15.992 6.55454 16 7.68324 16C8.74633 16 9.82605 15.992 10.9307 15.9688C12.3673 15.9456 13.325 15.1 13.4876 13.7072Z"
      />
    </svg>
  );
};

export default SvgTrash;
