import SvgLocation from "../../svgs/icons/Location";

const FooterContact = () => {
  return (
    <address className="grid grid-cols-[auto_1fr] gap-4">
      <SvgLocation className="w-5 fill-white" />
      <p className="text-xl text-white">Dbayeh Al Abyad Center</p>
    </address>
  );
};

export default FooterContact;
