import { useQuery } from "@tanstack/react-query";
import CardOffering from "../../../components/common/cards/Offering";
import LoadingImage from "../../../components/common/LoadingImage";
import Main from "../../../components/layout/Main";
import axios from "axios";
import { TLaravelResponse } from "../../../types/utils/laravel";
import { TOfferingDetails } from "../../../types/offering";

const PageWishlistUser = () => {
  const { data, isLoading } = useQuery({
    queryKey: ["offerings", "wishlist"],
    queryFn: async () => {
      const res =
        await axios.get<TLaravelResponse<TOfferingDetails[]>>("wishlist");
      return res.data;
    },
  });
  return (
    <Main id="wishlist-user">
      <section id="wishlist-user-grid" className="container section-margin">
        <ul className="grid-offerings">
          {isLoading ? (
            [...Array(9)].map((_, index) => {
              return (
                <li key={`offering_loading_${index}`}>
                  <LoadingImage />
                </li>
              );
            })
          ) : data?.success ? (
            data.data.map((offering) => {
              return (
                <li key={`offerings_belongings_${offering.id}`}>
                  <CardOffering offering={offering} />
                </li>
              );
            })
          ) : (
            <p>Something went wrong...</p>
          )}
        </ul>
        {data?.success && data.data.length === 0 && (
          <p>You have no offerings in your wishlist.</p>
        )}
      </section>
    </Main>
  );
};

export default PageWishlistUser;
