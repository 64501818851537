import { TSvgComponent } from "../../../types/utils/components";

const SvgTwitter: TSvgComponent = ({ ...props }) => {
  return (
    <svg
      viewBox="0 0 11 9"
      xmlns="http://www.w3.org/2000/svg"
      fill="white"
      {...props}
    >
      <path d="M0.357422 7.85228C1.3064 8.47237 2.4317 8.83807 3.63997 8.83807C7.61739 8.83807 9.86279 5.40369 9.72797 2.3297C10.148 2.0223 10.511 1.6354 10.7962 1.1955C10.4125 1.3704 9.99762 1.487 9.56721 1.54C10.008 1.2697 10.3502 0.840405 10.511 0.331607C10.0961 0.580706 9.63981 0.766205 9.15235 0.861604C8.76343 0.437606 8.20337 0.172607 7.59146 0.172607C6.20688 0.172607 5.19567 1.4923 5.50681 2.8597C3.72812 2.7696 2.15167 1.8951 1.09379 0.575406C0.533734 1.5612 0.80339 2.8438 1.75756 3.49569C1.40493 3.48509 1.07823 3.38439 0.787835 3.2201C0.767092 4.23239 1.47753 5.18109 2.5043 5.39309C2.20353 5.47789 1.87164 5.49379 1.53976 5.43019C1.80941 6.29938 2.60283 6.93008 3.54144 6.95128C2.63913 7.67208 1.50864 7.99538 0.372978 7.85758L0.357422 7.85228Z" />
    </svg>
  );
};

export default SvgTwitter;
