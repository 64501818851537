import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useAtom } from "jotai";
import { offeringsAtom, offeringsFiltersAtom } from "./atoms";
import {
  TLaravelPagination,
  TLaravelSuccess,
} from "../../../types/utils/laravel";
import { TLabelItem } from "../../../types/utils/components";
import { TOfferingDisplay } from "../../../types/offering";
import { useState } from "react";
import { scrollToId } from "../../../utils/functions";

type TOfferingFiltersResponse = {
  type: { id: number; label: string; logo_path: string }[];
  status: TLabelItem[];
  location: TLabelItem[];
  max_price: string;
};

export const useOfferingsFilters = () => {
  const [offeringsFilters, setOfferingsFilters] = useAtom(offeringsFiltersAtom);
  const { data, isLoading } = useQuery({
    queryKey: ["offerings", "filters"],
    queryFn: async () => {
      const response =
        await axios.get<TLaravelSuccess<TOfferingFiltersResponse>>(
          "offerings/filters",
        );
      setOfferingsFilters((prev) => ({
        ...prev,
        pricetwo: parseInt(response.data.data.max_price),
      }));
      setSliderValue([0, +response.data.data.max_price]);
      return response.data.data;
    },
    staleTime: 60_000,
  });
  const [sliderValue, setSliderValue] = useState([0, 0]);
  return {
    allFiters: data,
    isLoading,
    offeringsFilters,
    setOfferingsFilters,
    sliderValue,
    setSliderValue,
  };
};

type TOfferingsResponse = TLaravelPagination<TOfferingDisplay[]>;

export const useOfferings = () => {
  const [offeringsFilters, setOfferingsFilters] = useAtom(offeringsFiltersAtom);
  const [offerings, setOfferings] = useAtom(offeringsAtom);
  const { data: offeringsResponse, isLoading: isOfferingsLoading } = useQuery({
    queryKey: ["offerings", offeringsFilters],
    queryFn: async () => {
      const response = await axios.get<TLaravelSuccess<TOfferingsResponse>>(
        "offerings/",
        {
          params:
            offeringsFilters.type.length > 0
              ? {
                  ...offeringsFilters,
                  type: `[${offeringsFilters.type}]`,
                }
              : offeringsFilters,
        },
      );
      setOfferings(response.data.data.data);
      return response.data.data;
    },
  });
  const handlePage = (value: "decrease" | "increase") => {
    scrollToId("investors-filters");
    setOfferingsFilters(
      (prev) =>
        (prev = {
          ...prev,
          page:
            value === "decrease"
              ? offeringsFilters.page - 1
              : offeringsFilters.page + 1,
        }),
    );
  };

  return {
    currentPage: offeringsResponse?.current_page,
    offeringsResponse,
    offerings,
    isOfferingsLoading,
    handlePage,
  };
};
