import Banner from "./components/Banner";
import CareerThatFits from "./components/CareerThatFits";
import CareersFilters from "./components/CareersFilters";
import CareersGrid from "./components/CareersGrid";
import Main from "../../../components/layout/Main";

const PageCareers = () => {
  return (
    <Main id="careers">
      <Banner />
      <CareerThatFits />
      <section id="careers-filter">
        <div className="container">
          <p className="my-6 text-3xl text-primary-green">
            Would you like to work with a dynamic company that innovatively
            combines finance, technology, and agriculture? We're looking for{" "}
            <b>adaptive</b>, motivated self-starters who thrive under pressure.
          </p>
        </div>
        <CareersFilters />
        <CareersGrid />
      </section>
    </Main>
  );
};

export default PageCareers;
