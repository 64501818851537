import { homeData } from "../../data";

const WhyInvestmentTimeLine = () => {
  return (
    <div className="flex flex-col items-center">
      <div className="h-36 w-px bg-white max-lg:hidden"></div>
      <ul className="mb-8 flex flex-col gap-6 lg:mb-0 lg:items-center lg:gap-0">
        {homeData.whyInvestment.timeline.map(
          ({ Icon, title, content }, index) => {
            return (
              <li
                key={`home_why_investment_timeline_${index}`}
                className="relative lg:flex lg:flex-col lg:items-center"
              >
                <div className="grid size-12 place-items-center rounded-full border border-white max-lg:mb-2.5 lg:size-14 lg:p-2">
                  <Icon className="size-6 lg:size-10" />
                </div>
                <div
                  className="hidden w-px bg-white lg:block"
                  style={{
                    height: title.length / 20 + content.length / 30 + "rem",
                  }}
                ></div>
                <div className="left-full text-white lg:absolute lg:ml-3 lg:w-[40vw]">
                  <h3 className="mb-2 w-auto text-3xl 2xl:w-[30ch]">{title}</h3>
                  <p className="w-auto max-2xl:text-base 2xl:w-[50ch]">
                    {content}
                  </p>
                </div>
              </li>
            );
          },
        )}
      </ul>
    </div>
  );
};

export default WhyInvestmentTimeLine;
