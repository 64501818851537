import LoginForm from "./LoginForm";
import AuthModal from "../../../components/section/AuthModal";
import Main from "../../../components/layout/Main";

const PageLogin = () => {
  return (
    <Main id="login">
      <AuthModal
        id="login-modal"
        background="/assets/images/pages/login/background.jpg"
        title="Log in"
      >
        <LoginForm />
      </AuthModal>
    </Main>
  );
};

export default PageLogin;
