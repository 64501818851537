import SvgAradinaIcon from "../../../../components/svgs/logos/AradinaIcon";
import ButtonPrimary from "../../../../components/common/buttons/Button";
import SvgHeart from "../../../../components/svgs/icons/Heart";
import SvgCartAdd from "../../../../components/svgs/icons/CartAdd";
import { useOfferingCart } from "../../hooks";

const Cart = () => {
  const {
    disabled,
    handleSubmit,
    insuranceTotal,
    offering,
    maximumInvestment,
    total,
    addToWishlist,
    whishlisted,
  } = useOfferingCart();
  return (
    <section
      id="offering-cart"
      className="container grid gap-4 text-dark-green section-margin lg:grid-cols-[4fr_3fr]"
    >
      <div className="grid grid-cols-2 gap-4 rounded-lg bg-primary-green/15 p-6">
        <div>
          <h4 className="text-lg">Minimum Investment:</h4>
          <span className="text-xl font-bold">
            ${offering.minimum_investment}
          </span>
        </div>
        <div>
          <h4 className="text-lg">Return on Investment:</h4>
          <span className="text-xl font-bold">
            %{offering.return_on_investment}
          </span>
        </div>
        <div>
          <h4 className="text-lg">Maximum Investment:</h4>
          <span className="text-xl font-bold">${maximumInvestment}</span>
        </div>
        <div>
          <h4 className="text-lg">Insurance:</h4>
          <span className="text-xl font-bold">${insuranceTotal}</span>
        </div>
      </div>
      <form
        onSubmit={handleSubmit}
        className="rounded-lg bg-secondary-green/30 p-6 lg:p-8"
      >
        <div className="mb-2 flex flex-wrap-reverse justify-between gap-2">
          <div>
            <h4 className="text-lg font-bold">Total</h4>
            <span className="text-xl font-extrabold">${total}</span>
          </div>
          <div className="flex items-center gap-2">
            <SvgAradinaIcon className="w-7 fill-primary-green" />
            <p className="text-sm">Aradina Investment</p>
          </div>
        </div>
        <div className="flex items-center justify-end gap-2">
          <ButtonPrimary
            title="Add to cart"
            disabled={disabled}
            type="submit"
            secondary
            className="flex h-full items-center gap-2"
          >
            <SvgCartAdd className="size-4" />
            Add to cart
          </ButtonPrimary>
          <button
            type="button"
            title="Add to wishlist"
            className="rounded-full bg-dark-green p-3 hover-link"
            onClick={() => addToWishlist()}
          >
            <SvgHeart
              className={`size-[18px] duration-200 ${whishlisted ? "fill-white" : ""}`}
            />
          </button>
        </div>
      </form>
    </section>
  );
};

export default Cart;
