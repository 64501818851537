import { useState } from "react";

type TProps = {
  title?: string;
  text: string;
};

const InnerCard = ({ data }: { data: TProps }) => {
  const { title, text } = data;
  const [showMore, setshowMore] = useState(false);
  return (
    <div
      className={`mb-8 ${
        title && "rounded-lg bg-gradient-to-b from-transparent to-[#FFF4] p-5"
      }`}
    >
      <div className="mb-2 flex justify-between gap-x-1">
        <h5 className="text-2xl">{title}</h5>
        {title && (
          <span
            onClick={() => setshowMore((prev) => (prev = !prev))}
            className="cursor-pointer text-white"
          >
            {showMore ? "Less" : "More"}
          </span>
        )}
      </div>
      {title ? (
        showMore && <p className="mr-10 text-base">{text}</p>
      ) : (
        <p className="mr-10 text-base">{text}</p>
      )}
    </div>
  );
};

export default InnerCard;
