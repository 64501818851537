import { useAtom } from "jotai";
import { Link, useNavigate } from "react-router-dom";
import userAtom from "../../../pages/authentication/_atom";
import { useState } from "react";
import SvgArrowFilled from "../../svgs/arrows/ArrowFilled";
import { RESET } from "jotai/utils";
import SvgPlus from "../../svgs/icons/Plus";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { TLaravelResponse } from "../../../types/utils/laravel";
import LoadingPage from "../../../pages/LoadingPage";
import { toast } from "sonner";
import { useEmailVerified } from "../../../utils/hooks";

const UserButton = ({ mobile = false }: { mobile?: boolean }) => {
  const emailVerified = useEmailVerified();
  const [openUserSettings, setOpenUserSettings] = useState(false);
  const [user, setUser] = useAtom(userAtom);
  const navigate = useNavigate();
  const { mutate, isPending } = useMutation({
    mutationFn: async () => {
      const response =
        await axios.post<TLaravelResponse<{ data: string }>>("logout");
      return response.data;
    },
    onSuccess: (res) => {
      if (res.success) toast.success("Successfully logged out!");
    },
    onError: () => toast.error("Something went wrong!"),
    onSettled: () => {
      setUser(RESET);
      localStorage.removeItem("token");
      navigate("/");
    },
  });
  if (isPending) {
    return <LoadingPage />;
  }
  return (
    <div
      className={`${
        user?.token && openUserSettings
          ? "bg-gray-300 ease-out"
          : "bg-transparent ease-in"
      } rounded-t-lg transition-colors duration-200`}
    >
      <div
        className={`min-w-[200px] rounded-md text-white ${
          mobile ? "bg-dark-green" : "bg-primary-green"
        }`}
      >
        {user?.token ? (
          <div className="relative z-20">
            <button
              type="button"
              title={`${user?.user.username} logged in`}
              className="flex h-max w-full items-center justify-between gap-2.5 px-3 py-2 text-center"
              onClick={() => {
                setOpenUserSettings((_prev) => (_prev = !_prev));
              }}
            >
              <div className="flex gap-2">
                <img
                  className="h-6 w-6 rounded-full object-cover"
                  src={user.user.profile_picture}
                  alt={user.user.username}
                />
                <span className="text-base">{user.user.username}</span>
              </div>
              <SvgArrowFilled className="w-3" />
            </button>
            <div
              className={`${
                openUserSettings ? "scale-y-100" : "scale-y-0"
              } absolute w-full origin-top rounded-b-lg bg-gray-200 text-primary-gray duration-300`}
            >
              <div className="border-b border-primary-green px-3 py-2">
                {!emailVerified && (
                  <Link
                    title="Verify email"
                    to="/verify-email"
                    className="text-center text-base text-red-500 underline underline-offset-2"
                  >
                    Verify email
                  </Link>
                )}
                <span
                  className={`${
                    emailVerified ? "text-primary-green" : "text-red-600"
                  } w-full overflow-hidden text-ellipsis text-base leading-none`}
                >
                  {user.user.email}
                </span>
              </div>
              <ul className="mt-2 flex flex-col gap-1.5 border-b border-primary-green pb-2 text-lg">
                <li className="px-3">
                  <Link
                    to="/user/account"
                    title="My account"
                    className="hover-link"
                  >
                    My Account
                  </Link>
                </li>
                {/* <li className="px-3">
                  <Link to="/user/gift" title="Gift" className="hover-link">
                    Gift
                  </Link>
                </li>
                <li className="px-3">
                  <Link
                    to="/user/invite"
                    title="Invite accounts"
                    className="hover-link"
                  >
                    Invite Accounts
                  </Link>
                </li> */}
                <li className="cursor-pointer px-3 duration-200 hover:-translate-y-0.5 hover:translate-x-0.5">
                  <button
                    type="button"
                    onClick={() => mutate()}
                    title="Log out"
                    className="hover-link"
                  >
                    Log Out
                  </button>
                </li>
              </ul>
              <Link to={`/user/new-account`} className="flex gap-2 p-2.5">
                <SvgPlus className="w-4" />
                <span className="text-base capitalize text-primary-green">
                  Add new account
                </span>
              </Link>
            </div>
          </div>
        ) : (
          <Link className="w-full py-2 text-center" title="Sign in" to="/login">
            Sign in
          </Link>
        )}
      </div>
    </div>
  );
};

export default UserButton;
