import { TSvgComponent } from "../../../types/utils/components";

const SvgArrowFilled: TSvgComponent = ({ ...props }) => {
  return (
    <svg
      viewBox="0 0 12 8"
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M7.01835 6.90712C6.48114 7.52743 5.51886 7.52743 4.98165 6.90712L0.930429 2.22917C0.174844 1.35669 0.794607 0.000102883 1.94878 0.000102984L10.0512 0.000103693C11.2054 0.000103794 11.8252 1.3567 11.0696 2.22917L7.01835 6.90712Z" />
    </svg>
  );
};

export default SvgArrowFilled;
