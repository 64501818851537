import ThreeNeedlesHeading from "../../../../components/common/ThreeNeedlesHeading";
import SvgArrowCurved from "../../../../components/svgs/arrows/ArrowCurved";
import { useAtomValue } from "jotai";
import { offeringDetailsAtom } from "../../atoms";

export const OfferingSummary = () => {
  const offering = useAtomValue(offeringDetailsAtom);
  return (
    <section
      id="offering-summary"
      className="relative bg-primary-green bg-[url(/public/assets/images/backgrounds/background-giant-blob.png)] bg-cover bg-left-top bg-no-repeat"
    >
      <div className="section-padding container text-white">
        <div className="mb-12 flex flex-wrap gap-x-2 text-section-heading font-bold">
          <h2 className="py-1.5">Offering</h2>
          <ThreeNeedlesHeading
            backgroundColor="white"
            needlesColor="black"
            className="text-primary-green"
          >
            Summary
          </ThreeNeedlesHeading>
          <SvgArrowCurved className="z-10 -ml-3 mt-1 w-24 max-md:hidden" />
        </div>
        <div className="grid items-center gap-x-20 xl:grid-cols-[1fr_3fr]">
          <img
            className="z-10 aspect-square origin-top-right scale-150 rounded-full object-cover max-xl:hidden"
            loading="lazy"
            src={offering.right_picture_offering_summary}
            alt="Offering Summary"
          />
          <p className="text-2xl">{offering.description_offering_summary}</p>
        </div>
        <img
          className="absolute right-0 top-0 aspect-square w-64 -translate-y-1/4 rounded-full object-cover max-md:hidden"
          loading="lazy"
          src={offering.right_picture_offering_summary}
          alt="Offering Summary"
        />
      </div>
    </section>
  );
};
export default OfferingSummary;
