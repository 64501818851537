import ThreeNeedlesHeading from "../../../../components/common/ThreeNeedlesHeading";
import SvgArrowCurved from "../../../../components/svgs/arrows/ArrowCurved";
import { useAtomValue } from "jotai";
import { offeringDetailsAtom } from "../../atoms";

export const FarmOperator = () => {
  const offering = useAtomValue(offeringDetailsAtom);
  return (
    <section
      id="offering-operator"
      className="overflow-y-clip bg-[url(/public/assets/images/pages/single-offering/background-farm-operator.jpg)] bg-cover bg-no-repeat text-white"
    >
      <div className="section-padding container">
        <div className="mb-10 flex flex-wrap gap-x-2 text-section-heading font-bold">
          <h2 className="py-1.5">Farm</h2>
          <ThreeNeedlesHeading
            className="text-primary-green"
            backgroundColor="white"
            needlesColor="black"
          >
            Operator
          </ThreeNeedlesHeading>
          <SvgArrowCurved className="z-10 -ml-4 mt-2 w-24 max-md:hidden" />
        </div>
        <div className="grid lg:grid-cols-[3fr_7fr]">
          <div className="relative max-lg:hidden">
            <img
              className="absolute z-0"
              src="/assets/images/circular-text/circular-gateway-opportunity-white.png"
              alt="Gateway Opportunity"
            />
          </div>
          <p className="lg:text-right">{offering.description_farm_operator}</p>
        </div>
      </div>
    </section>
  );
};
export default FarmOperator;
