import { useMutation } from "@tanstack/react-query";
import { FormEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  TLaravelError,
  TLaravelSuccess,
  TODO,
} from "../../../types/utils/laravel";
import axios, { AxiosError } from "axios";
import { ZodFormattedError, z } from "zod";
import { useGoogleLogin } from "@react-oauth/google";
import userAtom from "../_atom";
import { useAtom } from "jotai";
import { RESET } from "jotai/utils";
import { TResponseUser } from "../../../types/user";
import { toast } from "sonner";

type TGoogleResponse = {
  email: string;
  email_verified: boolean;
  family_name: string;
  given_name: string;
  locale: string;
  name: string;
  picture: URL;
  sub: string;
};

export const useLogin = () => {
  const navigate = useNavigate();
  const [togglePass, setTogglePass] = useState(true);
  const [, setUser] = useAtom(userAtom);

  const signinSchema = z.object({
    email: z.string().min(1, { message: "Email is required" }).email({
      message: "Must be a valid email",
    }),
    password: z.string().min(1, { message: "Password is required" }),
  });
  type TUserLogin = z.infer<typeof signinSchema>;
  const [validationErrors, setValidationErrors] = useState<ZodFormattedError<
    TUserLogin,
    string
  > | null>();

  const handleLogin = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.target as HTMLFormElement);
    const data = Object.fromEntries(formData) as TUserLogin;
    const isValidated = signinSchema.safeParse(data);
    if (isValidated.success) {
      setValidationErrors(null);
      mutate(formData);
    } else {
      setValidationErrors(isValidated.error.format());
    }
  };

  const { error, mutate, isPending } = useMutation({
    mutationFn: async (params: FormData) => {
      const response = await axios.post<TLaravelSuccess<TResponseUser>>(
        "login",
        params,
      );
      return response.data;
    },
    onSuccess: (e) => {
      localStorage.setItem("token", e.data.token);
      setUser(e.data);
      toast.success(`Welcome back ${e.data.user.username}!`);
      if (!e.data.email_verified) {
        navigate("/verify-email");
      } else {
        navigate(-1);
      }
    },
    onError: (e: AxiosError<TLaravelError>) => {
      toast.error(e.response?.data.message ?? "Something went wrong!");
    },
  });

  const errorData = error?.response?.data as TLaravelError;

  const { mutate: googleMutate } = useMutation({
    mutationFn: async (googleData: TGoogleResponse) => {
      const response = await axios.post<TLaravelSuccess<TODO>>(
        "auth/google/call-back",
        {
          username: googleData.name,
          email: googleData.email,
          id: googleData.sub,
          profile_picture: googleData.picture,
        },
      );
      return response.data;
    },
    onSuccess: (res) => {
      localStorage.setItem("token", res.data.token);
      setUser(res.data);
      navigate(-1);
    },
    onError: (e: AxiosError<TLaravelError>) =>
      console.error("Laravel error", e),
  });

  const googleLogin = useGoogleLogin({
    onSuccess: async (credentialResponse) => {
      const googleApi = axios.create({
        baseURL: "https://www.googleapis.com/oauth2/v3/userinfo",
      });
      const userInfo = await googleApi<TGoogleResponse>({
        headers: { Authorization: `Bearer ${credentialResponse.access_token}` },
      });
      googleMutate(userInfo.data);
    },
    onError: () => console.error("Error in google login"),
  });

  useEffect(() => {
    setUser(RESET);
    localStorage.removeItem("token");
    // eslint-disable-next-line
  }, []);

  return {
    togglePass,
    setTogglePass,
    handleLogin,
    errorData,
    googleLogin,
    isPending,
    validationErrors,
  };
};

export default useLogin;
