import Main from "../../../components/layout/Main";
import OurOfferings from "../../../components/section/ourOfferings";
import Banner from "./components/Banner";
import HowItWorks from "./components/HowItWorks";

const PageHowInvestmentWorks = () => {
  return (
    <Main id="farmers-investment">
      <Banner />
      <HowItWorks />
      <OurOfferings id="farmers" />
    </Main>
  );
};

export default PageHowInvestmentWorks;
