import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { TLaravelResponse } from "../../types/utils/laravel";
import { useAtom, useAtomValue } from "jotai";
import {
  offeringDetailsAtom,
  offeringDetailsInsuranceAtom,
  offeringDetailsQuantityAtom,
} from "./atoms";
import { TOfferingCartResponse, TOfferingDetails } from "../../types/offering";
import { useParams } from "react-router-dom";
import { FormEvent, useEffect, useState } from "react";
import { toast } from "sonner";

export const useOfferingDetails = () => {
  const { offeringId } = useParams();
  const [, setOfferingDetails] = useAtom(offeringDetailsAtom);
  const { isLoading } = useQuery({
    queryKey: ["offering-details", offeringId],
    queryFn: async () => {
      const res = await axios.get<TLaravelResponse<TOfferingDetails>>(
        `offerings/${offeringId}`,
      );
      if (res.data.success) setOfferingDetails(res.data.data);
      return res.data;
    },
  });
  return { isLoading };
};

export const useOfferingQuantity = () => {
  const offering = useAtomValue(offeringDetailsAtom);
  const [quantity, setQuantity] = useAtom(offeringDetailsQuantityAtom);
  const handleDecrement = () => {
    setQuantity((_prev) => (_prev > 1 ? _prev - 1 : 1));
  };
  const handleIncrement = () => {
    setQuantity((_prev) =>
      _prev < +offering.max_qty_investment
        ? _prev + 1
        : +offering.max_qty_investment,
    );
  };
  return {
    offering,
    handleDecrement,
    handleIncrement,
    quantity,
  };
};

export const useOfferingCart = () => {
  const offering = useAtomValue(offeringDetailsAtom);
  const [quantity, setQuantity] = useAtom(offeringDetailsQuantityAtom);
  const insurance = useAtomValue(offeringDetailsInsuranceAtom);
  const selectedInsurance = offering.insurances?.find(
    (i) => i.id === insurance,
  )?.price;
  useEffect(() => {
    setQuantity(1);
    //eslint-disable-next-line
  }, []);

  const insuranceTotal = selectedInsurance ? +selectedInsurance * quantity : 0;
  const total = quantity * +offering.unit_price + insuranceTotal;
  const maximumInvestment = (
    +offering.max_qty_investment * +offering.unit_price
  ).toFixed(2);
  const disabled =
    quantity * +offering.unit_price < +offering.minimum_investment ||
    quantity > +offering.max_qty_investment;
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    mutate();
  };
  const [whishlisted, setWishlisted] = useState<boolean>(offering.isWishListed);

  const queryClient = useQueryClient();
  const { mutate } = useMutation({
    mutationFn: async () => {
      const res = await axios.post<TLaravelResponse<TOfferingCartResponse>>(
        `add-to-cart/offerings/${offering.id}`,
        {
          quantity,
          insurance_id: insurance,
          total_price: total,
        },
      );
      return res.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["cart-user"] });
      toast.success("Successfully added to cart");
    },
    onError: () => {
      toast.error("Error adding to cart");
    },
  });
  const { mutate: addToWishlist } = useMutation({
    mutationFn: async () => {
      const res = await axios.post<
        TLaravelResponse<
          | `added offering ${string} to wishlist`
          | `removed offering ${string} from wishlist`
        >
      >(`${offering.id}/wishlist`);
      return res.data;
    },
    onSuccess: (res) => {
      if (res.success) {
        queryClient.invalidateQueries({ queryKey: ["offerings", "wishlist"] });
        setWishlisted(res.data.slice(0, 5) === "added");
        toast.success(
          res.data.slice(0, 5) === "added"
            ? "Successfully added to wishlist"
            : "Successfully removed from wishlist",
        );
      }
    },
    onError: () => {
      toast.error("Oops! Something went wrong...");
    },
  });
  return {
    disabled,
    handleSubmit,
    insuranceTotal,
    offering,
    maximumInvestment,
    total,
    addToWishlist,
    whishlisted,
  };
};
