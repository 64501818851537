import SvgAccessibility from "../../components/svgs/icons/Accessibility";
import SvgAdjustment from "../../components/svgs/icons/Adjustment";
import SvgCart from "../../components/svgs/icons/Cart";
import SvgChart from "../../components/svgs/icons/Chart";
import SvgChoices from "../../components/svgs/icons/Choices";
import SvgDollar from "../../components/svgs/icons/Dollar";
import SvgExpert from "../../components/svgs/icons/Expert";
import SvgGroup from "../../components/svgs/icons/Group";
import SvgInfinity from "../../components/svgs/icons/Infinity";
import SvgMagnifyingGlass from "../../components/svgs/icons/MagnifyingGlass";
import SvgRadish from "../../components/svgs/icons/Radish";
import SvgRocket from "../../components/svgs/icons/Rocket";
import SvgSaving from "../../components/svgs/icons/Saving";
import SvgTarget from "../../components/svgs/icons/Target";
import SvgTechnology from "../../components/svgs/icons/Technology";

export const homeData = {
  whyInvestment: {
    timeline: [
      {
        Icon: SvgDollar,
        title: "Your Gateway to Opportunity",
        // "Inflation-proof Your Portfolio"
        content: "Grow your wealth confidently in farming and agribusiness.",
        // "Investments in farming and agribusiness are non-correlated with the stock market and appreciate with the underlying price of land.",
      },
      {
        Icon: SvgGroup,
        title: "Why Agriculture is the Safest option?",
        // "Feed a Growing Population & Enhance Food Security"
        content:
          "Inflation-Proof Your Portfolio. Farming and agribusiness are not tied to the stock market and increase in value with land prices.",
        // "With the world population projected to hit 10 billion by the year 2050, agricultural production will be depended on more than ever to reach increased food, fuel, and fiber demand.",
      },
      {
        Icon: SvgTarget,
        title: "Positive Social and Economic Impact ",
        content:
          "Agriculture provides jobs for about 1 billion people worldwide as it supports all of the rural communities and empowers farmers in a direct way.",
        //     "The agriculture sector provides jobs for approximately 1 billion people globally. Your passive investment will help keep farmers in the business of farming, create employment opportunities, and encourage small business growth.",
      },
      {
        Icon: SvgRadish,
        title: "Food Systems for Zero Hunger",
        content:
          "Food systems are the networks needed to produce, transform food, and ensure it reaches consumers. Improving the performance and resilience of global food systems, and their ability to cater for all who rely on them, will be key to reaching zero hunger.",
        //     "Investing is a great way to improve agriculture production and create a more resilient food system. Grow your wealth by connecting with an AgTech, sustainable livestock, specialty crop, or controlled environment investment opportunity.",
      },
    ],
    table: {
      heading: ["Benefits", "Agriculture", "Stocks", "Cash", "Gold"],
      data: [
        { title: "Fixed Asset", checked: [true, false, true, true] },
        { title: "Low Volatilly", checked: [true, false, true, false] },
        {
          title: "Hedging From Inflation",
          checked: [true, false, false, true],
        },
        { title: "Equity Building", checked: [true, false, true, true] },
      ],
    },
  },
  highProfit: [
    {
      id: "high_profit_1",
      title: "Affordable",
      Icon: SvgSaving,
    },
    {
      id: "high_profit_2",
      title: "A Marketplace",
      Icon: SvgCart,
    },
    {
      id: "high_profit_3",
      title: "Choices",
      Icon: SvgChoices,
    },
    {
      id: "high_profit_4",
      title: "Individualized ",
      Icon: SvgAdjustment,
    },
    {
      id: "high_profit_5",
      title: "Evolutionary ",
      Icon: SvgRocket,
    },
    {
      id: "high_profit_6",
      title: "No Bounds ",
      Icon: SvgInfinity,
    },
    {
      id: "high_profit_7",
      title: "Insightful",
      Icon: SvgMagnifyingGlass,
    },
  ],
  howItWorks: [
    {
      number: 1,
      title: "Sign Up",
      content: "Discover high-profit farmland opportunities.",
      // "Sign up for a complimentary Aradina Account to gain full access to high profit farmland investment opportunities in our easy-to-use portal.",
    },
    {
      number: 2,
      title: "Monitor",
      content:
        "Congratulations! You can now start growing your farm on Aradina's app.",
      // "Determine what best fits your investment portfolio and invest. All opportunities have been carefully selected and offer high return possibilities. ",
    },
    {
      number: 3,
      title: "Assess",
      content:
        "Determin what fits you best. All opportunities have been carefully selected and offer high return possibilities.",
      // "Congratulations, you're a smart Investor! Aradina manages all aspects of the farmland, from planting to harvesting to selling while you simply monitor your farm’s progress online.",
    },
    {
      number: 4,
      title: "Collect",
      content:
        "Receive income after every harvest through selling them on Aradina's market place or choose to keep them for yourself.",
      // "Profits from crop sales are distributed to investors after crops are harvested and sold each year. At the end of the holding period, they will receive their share from the sale of the the land.",
    },
  ],
  statistics: [
    {
      text: "Farms & Ranches Funded",
      sign: "",
      number: 43,
      size: "",
    },
    {
      text: "Raised",
      sign: "$",
      number: 27,
      size: "M",
    },
    {
      text: "Distributed to Investors",
      sign: "$",
      number: 6.1,
      size: "M",
    },
  ],
  benefits: [
    {
      label: "Access to Funds",
      icon: SvgAccessibility,
    },
    {
      label: "Access to Expert Advise and Business Plans",
      icon: SvgExpert,
    },
    {
      label: "Advanced Technology to Optimize Production",
      icon: SvgTechnology,
    },
    {
      label: "More Profitability",
      icon: SvgChart,
    },
  ],
};
