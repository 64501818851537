import { useFundaising } from "../hooks";
import CustomInput from "../../../../components/common/inputs/Input";

const ContactInformation = () => {
  const { validationErrors } = useFundaising();
  return (
    <section
      id="fundraising-contact-information"
      className="section-margin container"
    >
      <h3 className="mb-6 text-3xl font-bold text-dark-green">
        Contact Information
      </h3>
      <div className="flex flex-col gap-x-8 gap-y-4 sm:grid sm:grid-cols-2">
        <div>
          <CustomInput type="text" name="first_name" label="First Name*" />
          <p className="text-red-600">
            {validationErrors?.first_name?._errors[0]}
          </p>
        </div>
        <div>
          <CustomInput type="text" name="last_name" label="Last Name*" />
          <p className="text-red-600">
            {validationErrors?.last_name?._errors[0]}
          </p>
        </div>
        <CustomInput type="email" name="email" label="Email*" />
        <CustomInput type="tel" name="phone_number" label="Phone Number*" />
        <CustomInput
          type="text"
          name="company_name"
          label="Company Name*"
          containerClass="col-span-2"
        />
      </div>
    </section>
  );
};

export default ContactInformation;
