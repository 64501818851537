import { Link, Outlet, useLocation } from "react-router-dom";
import { userOfferingsNavigation } from "../../constants/navigation";
import ThreeNeedlesHeading from "../common/ThreeNeedlesHeading";

const LayoutUserOfferings = () => {
  const { pathname } = useLocation();
  return (
    <>
      <section
        id="offerings-banner"
        className="container flex justify-center text-center section-margin"
      >
        <div>
          <h2 className="mb-4 flex flex-wrap items-center justify-center gap-4 py-1.5 text-section-heading text-primary-green">
            Aradina
            <ThreeNeedlesHeading
              backgroundColor="primary-green"
              needlesColor="black"
              className="text-white"
            >
              Marketplace
            </ThreeNeedlesHeading>
          </h2>
          <p className="text-primary-green">
            Aradina is a farmer-owned, equity crowdfunding platform focused on
            specialty crop operations in the Lebanon.
            <br />
            We offer fractional farmland ownership opportunities to our members.
          </p>
        </div>
      </section>
      <section id="offerings-navigations" className="bg-gray-200 pt-8">
        <nav className="container">
          <ul className="flex flex-wrap gap-x-8 gap-y-2">
            {userOfferingsNavigation.map(({ path, title }, index) => {
              return (
                <li
                  key={`user_offering_${index}`}
                  className={`grid text-lg uppercase text-primary-green after:h-[3px] after:rounded-full after:bg-primary-green after:duration-200 ${pathname === path ? "font-extrabold after:w-full" : "after:w-0"}`}
                >
                  <Link className="pb-2" title={title} to={path}>
                    {title}
                  </Link>
                </li>
              );
            })}
          </ul>
        </nav>
      </section>
      <Outlet />
    </>
  );
};

export default LayoutUserOfferings;
