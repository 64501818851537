import { useAtomValue } from "jotai";
import { offeringDetailsAtom } from "../../../../../atoms";

const QuestionsAnswers = () => {
  const offering = useAtomValue(offeringDetailsAtom);
  return (
    <>
      {offering.questions_and_answers?.length !== 0 && (
        <>
          <h3 className="mb-4 text-section-heading text-primary-green">
            Questions & Answers
          </h3>
          <div>
            {offering.questions_and_answers?.map(
              ({ id, question, answer, answered_by }) => {
                return (
                  <div key={id} className="mb-6">
                    <h6 className="font-bold text-primary-green">Investor</h6>
                    <p className="text-primary-gray">{question}</p>
                    {answer ? (
                      <div className="mt-4 rounded-lg border border-primary-green p-4">
                        {answered_by && (
                          <h6 className="mb-2 font-bold text-primary-green">
                            {answered_by}
                          </h6>
                        )}
                        <p className="text-primary-gray">{answer}</p>
                      </div>
                    ) : (
                      <span>No answers yet...</span>
                    )}
                  </div>
                );
              },
            )}
          </div>
        </>
      )}
    </>
  );
};

export default QuestionsAnswers;
