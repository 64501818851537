import {
  DetailedHTMLProps,
  forwardRef,
  InputHTMLAttributes,
  LabelHTMLAttributes,
  ReactNode,
  useState,
} from "react";
import { twMerge } from "tailwind-merge";

type TProps = DetailedHTMLProps<
  InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  label?: LabelHTMLAttributes<HTMLLabelElement>["children"] | boolean;
  labelClass?: LabelHTMLAttributes<HTMLLabelElement>["className"];
  defaultFile?: string;
  children?: ReactNode;
};

const CustomFile = forwardRef<HTMLInputElement, TProps>(
  ({ label = false, labelClass, children, defaultFile, ...props }, ref) => {
    const [files, setFiles] = useState<FileList | null>(null);
    return (
      <label
        htmlFor={
          props.id ? props.id : props.name ? `${props.name}-input` : undefined
        }
        className={twMerge(
          "block text-xl font-bold text-primary-green",
          labelClass,
        )}
      >
        {label === true ? props.title : label}
        {props.required && label && (
          <span className="text-red-600">&nbsp;*</span>
        )}
        <div className="flex min-h-40 w-full flex-col items-center justify-center rounded-lg border-2 border-dashed border-primary-green p-4 text-center">
          {files ? (
            Array.from(files).length > 0 ? (
              <>
                {Array.from(files).map((file, index) => {
                  return (
                    <img
                      key={index}
                      src={URL.createObjectURL(file)}
                      alt={file.name}
                      title={file.name}
                      className="size-28 rounded-md border border-primary-gray object-contain"
                      width={112}
                      height={112}
                    />
                  );
                })}
              </>
            ) : (
              children
            )
          ) : (
            <img
              src={defaultFile}
              alt=""
              className="size-28 rounded-md border border-primary-gray object-contain"
              width={112}
              height={112}
            />
          )}
          <input
            id={props.name ? `${props.name}-input` : undefined}
            hidden
            {...props}
            onChange={(e) => {
              setFiles(e.target.files);
              props.onChange && props.onChange(e);
            }}
            type="file"
            ref={ref}
          />
        </div>
      </label>
    );
  },
);

export default CustomFile;
