import ThreeNeedlesHeading from "../../../../components/common/ThreeNeedlesHeading";
import SvgArrowCurved from "../../../../components/svgs/arrows/ArrowCurved";
import { offeringDetailsAtom } from "../../atoms";
import { useAtomValue } from "jotai";

export const InvestmentTimeline = () => {
  const offering = useAtomValue(offeringDetailsAtom);
  return (
    <section
      id="offering-investment-timeline"
      className="bg-[rgba(242,242,242)]"
    >
      <div className="section-padding container bg-[url(/public/assets/images/backgrounds/logo-aradina-icon-semi-transparent-green.png)] bg-contain bg-center bg-no-repeat text-primary-green">
        <div className="mb-6 flex flex-wrap gap-x-2 text-section-heading font-bold">
          <h2 className="py-1.5">Investment</h2>
          <ThreeNeedlesHeading
            backgroundColor="primary-green"
            needlesColor="black"
            className="text-white"
          >
            Timeline
          </ThreeNeedlesHeading>
          <SvgArrowCurved className="z-10 -ml-4 mt-2 w-24 max-md:hidden" />
        </div>
        <div className="mb-6 text-right">
          <p>
            <span className="font-extrabold">Aradina</span>
            <br />
            Investment Timeline
          </p>
        </div>
        <div className="relative grid auto-cols-fr auto-rows-fr place-items-center gap-10 md:grid-cols-2 xl:grid-cols-3">
          {offering.investment_timeline?.map(
            ({ id, order, date, title, description, logo }) => (
              <article
                key={`investment_${id}`}
                className="z-10 flex h-full w-full flex-col gap-2 rounded-2xl bg-[#F7F5F5] p-12 shadow"
              >
                <div className="flex flex-col items-center gap-4">
                  <div className="relative">
                    <img
                      className="aspect-square w-44 rounded-full object-cover"
                      loading="lazy"
                      src={logo}
                      alt={title}
                    />
                    <span className="absolute bottom-0 right-0 text-6xl font-bold text-primary-green text-opacity-50">
                      {order}
                    </span>
                  </div>
                  <h4 className="text-center text-3xl font-extrabold leading-tight text-primary-green">
                    {date}
                  </h4>
                </div>
                <div className="flex flex-col items-center gap-2">
                  <h4 className="text-center text-3xl leading-none text-primary-green">
                    {title}
                  </h4>
                  <p className="text-center leading-none text-primary-gray">
                    {description}
                  </p>
                </div>
              </article>
            ),
          )}
          {/* <div className="absolute z-0 h-1/3 w-full border-t-4 border-dashed border-primary-green border-opacity-50"></div> */}
        </div>
      </div>
    </section>
  );
};
export default InvestmentTimeline;
