import { TSvgComponent } from "../../../types/utils/components";

const SvgFacebook: TSvgComponent = ({ ...props }) => {
  return (
    <svg
      viewBox="0 0 6 13"
      xmlns="http://www.w3.org/2000/svg"
      fill="white"
      {...props}
    >
      <path d="M1.74005 12.55H4.12547V6.4497H5.79008L5.96639 4.40391H4.12547V3.23791C4.12547 2.75562 4.21882 2.56482 4.67516 2.56482H5.96639V0.444824H4.31734C2.54384 0.444824 1.74524 1.24512 1.74524 2.77151V4.39861H0.505859V6.4656H1.74524V12.5447L1.74005 12.55Z" />
    </svg>
  );
};

export default SvgFacebook;
