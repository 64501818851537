import CareerCard from "./CareerCard";
import { useCareers } from "../hooks";

const CareersGrid = () => {
  const { careers, isLoading } = useCareers();
  return (
    <div className="section-padding bg-primary-green bg-[url('/public/assets/images/backgrounds/background-giant-blob.png')] bg-[length:50%_200%] bg-right-bottom bg-no-repeat">
      <div className="container grid gap-x-10 gap-y-6 xl:grid-cols-2">
        {isLoading ? (
          <p className="text-white">Loading...</p>
        ) : careers?.length !== 0 ? (
          careers?.map((career) => {
            return <CareerCard key={`career_${career.id}`} data={career} />;
          })
        ) : (
          <p className="text-white">
            No current opportunites matches your demands
          </p>
        )}
      </div>
    </div>
  );
};

export default CareersGrid;
