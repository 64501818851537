import { useAtom } from "jotai";
import CustomCheckbox from "../../../../components/common/inputs/Checkbox";
import { TOfferingBelongingUser } from "../../../../types/offering";
import { useLaravelQuery } from "../../../../utils/hooks/Laravel";
import { atomSelectedBelongings } from "./_atom";
import { ChangeEvent } from "react";

const Belongings = () => {
  const { Display } = useLaravelQuery<TOfferingBelongingUser[]>({
    queryKey: ["sell-aradina-belongings"],
    endPoint: "user-belongings",
  });
  const [selectedBelongings, setSelectedBelongings] = useAtom(
    atomSelectedBelongings,
  );
  const handleSelectBelonging = (
    e: ChangeEvent<HTMLInputElement>,
    belonging: TOfferingBelongingUser,
  ) => {
    setSelectedBelongings((prev) =>
      prev.includes(belonging)
        ? prev.filter((item) => item.id !== +e.target.value)
        : [...prev, belonging],
    );
  };
  return (
    <section id="sell-to-aradina-belongings" className="container my-10">
      <h2 className="mb-2 text-tertiary-green section-heading">
        Sell to Aradina
      </h2>
      <p>
        Select the produce of choice and specify the quantity you wish to offer
        for sale to Aradina!
      </p>
      <Display
        success={(data) =>
          data.length > 0 ? (
            <ul className="my-10 grid gap-4 rounded-lg bg-gray-200 px-8 py-6 sm:grid-cols-2 lg:grid-cols-4">
              {data.map((item) => (
                <li key={item.id}>
                  <CustomCheckbox
                    checked={selectedBelongings.includes(item)}
                    onChange={(e) => handleSelectBelonging(e, item)}
                    label={item.offering_item.title}
                    title={item.offering_item.title}
                    value={item.id}
                    labelClass="flex items-center gap-2"
                    id={`belonging-${item.id}`}
                  />
                </li>
              ))}
            </ul>
          ) : (
            <p>No belongings found</p>
          )
        }
      />
    </section>
  );
};

export default Belongings;
