import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import {
  ComponentProps,
  DetailedHTMLProps,
  LabelHTMLAttributes,
  useRef,
} from "react";
import { twMerge } from "tailwind-merge";

type TProps = Omit<ComponentProps<typeof Autocomplete>, "renderInput"> & {
  label?:
    | DetailedHTMLProps<
        LabelHTMLAttributes<HTMLLabelElement>,
        HTMLLabelElement
      >["children"]
    | boolean;
  name?: string;
  labelClass?: DetailedHTMLProps<
    LabelHTMLAttributes<HTMLLabelElement>,
    HTMLLabelElement
  >["className"];
  required?: boolean;
  error?: boolean;
};

export const CustomDropdown = ({
  label = false,
  name,
  labelClass,
  error,
  required,
  ...props
}: TProps) => {
  const ref = useRef(null);
  return (
    <label
      hidden={!label}
      htmlFor={props.id ? props.id : name ? `${name}-input` : undefined}
      className={twMerge(
        "block text-xl font-bold text-primary-green",
        labelClass,
      )}
    >
      {label === true ? props.title : label}
      {required && label && <span className="text-red-600">&nbsp;*</span>}{" "}
      <Autocomplete
        ref={ref}
        disablePortal
        id={name ? `${name}-input` : undefined}
        loadingText={"Loading ..."}
        {...props}
        className={twMerge(
          `w-full [&_.MuiOutlinedInput-root]:rounded-xl [&_.MuiOutlinedInput-root]:!py-1 [&_input]:!text-lg [&_input]:!text-black ${
            error
              ? "[&_fieldset]:!border-red-600 [&_input]:text-red-600"
              : "[&_fieldset]:!border-primary-green [&_input]:text-primary-green"
          }`,
          props.className,
        )}
        renderInput={(params) => (
          <TextField {...params} name={name} value={props.value} />
        )}
      />
    </label>
  );
};
export default CustomDropdown;
