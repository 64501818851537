import axios from "axios";
export class AxiosMiddleware {
  static boot() {
    axios.defaults.baseURL = process.env.REACT_APP_BACKEND_URL + "api/";
    axios.defaults.headers.common["Content-Type"] = `application/json`;
    axios.interceptors.request.use(
      (config) => {
        const token = localStorage.getItem("token");
        if (token) {
          config.headers["Authorization"] = `Bearer ${token}`;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      },
    );
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        switch (error?.response?.status) {
          case 401:
            window.location.href = "/login";
            return;
          case 403:
            console.error("Forbidden error.");
            throw error;
          case 404:
            console.error("Not found error.");
            return;
          default:
            throw error;
        }
      },
    );
  }
}
