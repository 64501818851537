import BannerGradientHeading from "../../../../components/common/BannerGradientHeading";
import SvgTeam from "../../../../components/svgs/icons/Group";
import SvgArrowStraight from "../../../../components/svgs/arrows/ArrowStraight";
import SvgArrowDouble from "../../../../components/svgs/arrows/ArrowDouble";
import SvgCrop from "../../../../components/svgs/icons/Crop";
import PageBanner from "../../../../components/section/PageBanner";

const Banner = () => {
  return (
    <PageBanner
      id="team"
      imageUrl="/assets/images/pages/about/team/banner-background.jpg"
    >
      <div className="container z-10 grid items-end justify-center pt-20">
        <div className="flex flex-col items-center gap-2">
          <BannerGradientHeading title="Team" text="Behind Aradina" />
          <p className="rounded-3xl bg-gradient-to-r from-[#7FE11D] to-primary-green p-3 leading-none text-white">
            Meet our team
          </p>
          <div className="mt-2 flex flex-col items-center">
            <SvgTeam className="box-content h-6 w-6 rounded-full border-2 p-2" />
            <div className="h-10 w-0.5 bg-white"></div>
            <SvgArrowStraight className="box-content aspect-square max-h-[25px] max-w-[25px] rounded-full border-2 object-contain p-2" />
            <div className="h-10 w-0.5 bg-white"></div>
          </div>
        </div>
        <div className="container absolute inset-0 -bottom-2 z-10 grid items-end justify-center max-xl:hidden">
          <div className="relative w-max -translate-x-full rounded-lg px-10 py-8 text-lg shadow-[inset_0_250px_2500px_#fff,5px_5px_1px_1px_#ccc8]">
            <h3 className="m text-2xl font-bold text-primary-green">
              Who are we?
            </h3>
            <div className="grid max-w-xs grid-cols-3 gap-2">
              <span className="col-span-2 rounded-lg bg-light-green px-2 py-1 text-center text-white">
                Financial Analysts
              </span>
              <span className="dark-circled rounded-lg bg-primary-green px-2 py-1 text-center text-white">
                Innovators
              </span>
              <span className="dark-circled rounded-lg bg-primary-green px-2 py-1 text-center text-white">
                Farmers
              </span>
              <span className="col-span-2 rounded-lg bg-light-green px-2 py-1 text-center text-white">
                Advisors
              </span>
              <span className="dark-circled col-span-3 rounded-lg bg-primary-green px-2 py-1 text-center uppercase text-white">
                Aradina
              </span>
              <SvgArrowDouble className="absolute right-0 top-0 h-24 w-24 -translate-y-full translate-x-full p-1.5" />
            </div>
          </div>
        </div>
      </div>
      <img
        className="absolute bottom-0 left-0 top-0 z-0 h-3/4 origin-top-left -translate-x-1/4 max-xl:hidden"
        src="/assets/images/pages/about/team/banner-image.png"
        alt=""
      />
      <div className="absolute right-0 top-0 flex translate-x-1/4 flex-col">
        <img
          className="w-3/4 self-end"
          src="/assets/images/common/logo-green-circle-waves.png"
          alt="Logo Green"
        />
        <SvgCrop className="absolute bottom-0 w-1/3 translate-x-1/2 translate-y-1/2 max-xl:hidden" />
      </div>
    </PageBanner>
  );
};

export default Banner;
