import ThreeNeedlesHeading from "../../../../components/common/ThreeNeedlesHeading";

const RaiseCapital = () => {
  return (
    <section id="farmer-raise-capital" className="bg-primary-yellow text-white">
      <div className="container grid gap-10 lg:grid-cols-[4fr_1fr]">
        <div className="my-10">
          <div className="mb-6 flex flex-wrap gap-x-2 text-section-heading">
            <h2 className="py-1.5">A New Way to</h2>
            <ThreeNeedlesHeading
              backgroundColor="white"
              className="text-primary-yellow"
            >
              Raise Capital
            </ThreeNeedlesHeading>
            <h2 className="py-1.5">for</h2>
            <ThreeNeedlesHeading
              backgroundColor="white"
              className="text-primary-yellow"
            >
              Agriculture
            </ThreeNeedlesHeading>
          </div>
          <p>
            Farmers, ranchers, and agribusinesses often need capital to expand
            their operations or acquire new land. Most farmers automatically
            seek ag credit lenders for capital, because it's what they all know as
            a traditional funding source. There are other ways besides bank
            loans to receive the needed capital. The use of equity partners in
            crowdfunding is an alternate way to finance land acquisition.
          </p>
          <div className="mt-8 text-3xl font-extrabold">
            What's an equity partnership?
          </div>
          <p>
            In crowdfunding, an equity partnership generally consists of a
            general partner (GP), or an active investor, and limited partners
            (LP), or passive investors. The GP makes all the decisions on
            operating an investment while the LPs simply invest money passively
            and collect returns from harvests or rents. Simply put, the farmers
            and ranchers are the GP who keeps the operation running however they
            choose, while the investors are the LPs who have no say in the
            operation, and help bring capital to the farm and receive returns
            from harvests. For more information on how Aradina platform can help
            you, please contact us.
          </p>
        </div>
        <img
          className="origin-bottom-left scale-[2] self-end"
          src="/assets/images/pages/farmers/raise-capital.png"
          alt=""
        />
      </div>
    </section>
  );
};

export default RaiseCapital;
