import { TSvgComponent } from "../../../types/utils/components";

const SvgErrorX: TSvgComponent = ({ ...props }) => {
  return (
    <svg
      viewBox="0 0 18 19"
      fill="#E01C1C"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M15.5122 18.0626C15.0493 18.0626 14.5845 17.8852 14.2316 17.5323L0.530847 3.8315C-0.176949 3.12371 -0.176949 1.97619 0.530847 1.2684C1.23864 0.5606 2.38616 0.5606 3.09396 1.2684L16.7947 14.9692C17.5025 15.677 17.5025 16.8245 16.7947 17.5323C16.4418 17.8852 15.977 18.0626 15.5141 18.0626H15.5122Z" />
      <path d="M1.81144 18.0626C1.34857 18.0626 0.883781 17.8852 0.530847 17.5323C-0.176949 16.8245 -0.176949 15.677 0.530847 14.9692L14.2316 1.2684C14.9394 0.5606 16.0869 0.5606 16.7947 1.2684C17.5025 1.97619 17.5025 3.12371 16.7947 3.8315L3.09203 17.5323C2.73909 17.8852 2.2743 18.0626 1.81144 18.0626Z" />
    </svg>
  );
};

export default SvgErrorX;
