import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { TLaravelSuccess } from "../../../types/utils/laravel";
import { SyntheticEvent, useState } from "react";

type TFAQ = {
  id: number;
  question: string;
  answer: string;
};

const useFAQs = () => {
  const [expanded, setExpanded] = useState<string | false>(false);
  const handleChange =
    (panel: string) => (_: SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const { data, isLoading } = useQuery({
    queryKey: ["faqs"],
    queryFn: async () => {
      const response = await axios.get<TLaravelSuccess<TFAQ[]>>("faqs");
      return response.data;
    },
  });
  return {
    expanded,
    handleChange,
    faqs: data?.data,
    isLoading,
  };
};

export default useFAQs;
