import SvgCow from "../../../components/svgs/icons/Cow";
import SvgCrop from "../../../components/svgs/icons/Crop";
import SvgLeaf from "../../../components/svgs/icons/Leaf";
import SvgPlant from "../../../components/svgs/icons/Plant";
import SvgTimber from "../../../components/svgs/icons/Timber";
import SvgTree from "../../../components/svgs/icons/Tree";

export const passiveInvestment = {
  firstText:
    "Real assets, like income-producing agriculture, provide a hedge against inflation, and are an attractive investment for a diversified portfolio. There are wide variety of agricultural products, geographies, and methods in which to invest each with their own risk/return profile, such as:",
  secondText:
    "Aradina provides access to investments with little to no correlation to the overall stock market that produce solid returns compared to other asset classes. The platform allows qualified investors to choose from a variety of carefully screened and selected private agriculture investments. Many of these investments feature sustainable or organic production methods. Institutional investors and the ultra-wealthy have long understood the value of private investments in natural resources. Now you too can access these deals with as little as $500. And yes, you can invest using money in your IRA to get tax-free or tax-deferred gains.",
  data: [
    {
      label: "Timber",
      icon: SvgTimber,
    },
    {
      label: "Row Crops",
      icon: SvgCrop,
    },
    {
      label: "Tree Crops",
      icon: SvgTree,
    },
    {
      label: "Livestock",
      icon: SvgCow,
    },
    {
      label: "Indoor Agriculture",
      icon: SvgPlant,
    },
    {
      label: "AgTech",
      icon: SvgLeaf,
    },
  ],
};
