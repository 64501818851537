import { useFundaising } from "./hooks";
import ContactInformation from "./components/ContactInformation";
import ProjectInformation from "./components/ProjectInformation";
import ProjectFinancials from "./components/ProjectFinancials";
import SubmitFundraising from "./components/SubmitFundraising";
import AdditionalInformation from "./components/AdditionalInformation";
import Main from "../../../components/layout/Main";

const PageFundRaising = () => {
  const { handleSubmit } = useFundaising();
  return (
    <Main id="fundraising">
      <section id="fundraising-heading" className="section-margin text-center">
        <h2 className="mb-2 text-3xl font-bold text-dark-green">
          Initial Fundraising Request Submission
        </h2>
        <h5 className="text-primary-gray">
          Our team will review your information and will contact you
        </h5>
      </section>
      <form method="post" onSubmit={handleSubmit}>
        <ContactInformation />
        <ProjectInformation />
        <ProjectFinancials />
        <AdditionalInformation />
        <SubmitFundraising />
      </form>
    </Main>
  );
};

export default PageFundRaising;
