import Main from "../../../../components/layout/Main";
import ChangePassword from "./components/ChangePassword";
import ContactInformation from "./components/ContactInformation";
import Description from "./components/Description";
import IdentityVerification from "./components/IdentityVerification";
import NextOfKin from "./components/NextOfKin";
import PersonalAddress from "./components/PersonalAddress";

const PageAccount = () => {
  return (
    <Main id="account">
      <Description />
      <ContactInformation />
      <ChangePassword />
      <PersonalAddress />
      <IdentityVerification />
      <NextOfKin />
    </Main>
  );
};

export default PageAccount;
