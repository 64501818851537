import { createRoot } from "react-dom/client";
import { App } from "./App";
import { AxiosMiddleware } from "./middleware";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import ReactGA from "react-ga4";
import { Toaster } from "sonner";
import { StrictMode } from "react";

ReactGA.initialize("G-9ZKWQH51YZ");
ReactGA.send({
  hitType: "pageview",
  page: window.location.pathname,
});

AxiosMiddleware.boot();
const container = document.getElementById("root");
const root = createRoot(container!);
const queryClient = new QueryClient({
  defaultOptions: {
    queries: { retry: false, refetchOnWindowFocus: false, staleTime: 120_000 },
  },
});

root.render(
  <StrictMode>
    <GoogleOAuthProvider
      clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID ?? ""}
    >
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <App />
        <Toaster richColors />
      </QueryClientProvider>
    </GoogleOAuthProvider>
  </StrictMode>,
);
