import BannerGradientHeading from "../../../../components/common/BannerGradientHeading";

const Banner = () => {
  return (
    <section id="contact-banner">
      <div className="container">
        <BannerGradientHeading
          title="Get in Touch"
          text="Let's explore how Aradina can work for you"
          mode={2}
        />
      </div>
    </section>
  );
};

export default Banner;
