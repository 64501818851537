import Banner from "./components/Banner";
import TeamSection from "./components/TeamSection";
import QuotesSlider from "./components/QuotesSlider";
import Main from "../../../components/layout/Main";

const PageTeam = () => {
  return (
    <Main id="team">
      <Banner />
      <TeamSection />
      <QuotesSlider />
    </Main>
  );
};

export default PageTeam;
