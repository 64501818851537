import BannerGradientHeading from "../../../../components/common/BannerGradientHeading";
import PageBanner from "../../../../components/section/PageBanner";
import SvgArrowStraight from "../../../../components/svgs/arrows/ArrowStraight";

const Banner = () => {
  return (
    <PageBanner
      id="careers"
      imageUrl="/assets/images/pages/about/careers/banner-background.jpg"
    >
      <img
        className="absolute right-0 top-0 h-[200%] w-1/2 object-contain"
        src="/assets/images/backgrounds/background-giant-blob.png"
        alt="Blob"
      />
      <div className="container grid h-full auto-rows-max place-items-center content-end justify-center text-center">
        <BannerGradientHeading
          title="Careers"
          text="Are you ready to join the team?"
        />
        <SvgArrowStraight className="mt-4 box-content aspect-square max-h-[25px] max-w-[25px] rounded-full border-2 p-2" />
        <div className="h-32 w-0.5 bg-white"></div>
      </div>
    </PageBanner>
  );
};

export default Banner;
