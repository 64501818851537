const EmbeddedMap = () => {
  return (
    <section id="contact-map">
      <iframe
        title="Aradina Location"
        className="h-[600px]"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d826.8663509469014!2d35.648180969643796!3d34.00626281006779!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x151f41bda779a853%3A0x20993eb4096fda69!2sKLEV!5e0!3m2!1sen!2slb!4v1690965906407!5m2!1sen!2slb"
        allowFullScreen
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
    </section>
  );
};

export default EmbeddedMap;
