import { Navigate, Outlet } from "react-router-dom";

export const RequiresAuthentication = () => {
  const isLoggedIn = localStorage.getItem("token")
    ? !!localStorage.getItem("token")
    : false;
  const isVerified = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user") ?? "").email_verified !== false
    : false;
  const handleNavigateUser = () => {
    return <Navigate to="/401" replace />;
  };
  return isLoggedIn && isVerified ? <Outlet /> : handleNavigateUser();
};
