import CountUp from "react-countup";
import { homeData } from "../data";
import SvgAradinaIcon from "../../../components/svgs/logos/AradinaIcon";
export const Statistics = () => {
  return (
    <section
      id="home-statistics"
      className="section-padding relative overflow-x-clip bg-[url('/public/assets/images/pages/home/background-statistics.png')] xl:bg-cover xl:bg-no-repeat"
    >
      <div className="container flex flex-wrap justify-center gap-20 xl:gap-40">
        {homeData.statistics.map(({ text, number, sign, size }, index) => (
          <article
            key={`statistics_counter_${index}`}
            className="flex h-56 w-56 flex-col items-center justify-end gap-4 rounded-full bg-[#FCFFF9] px-4 py-12 shadow-2xl"
          >
            <div className="flex flex-col items-center">
              <h3 className="gradient-text w-full bg-gradient-to-r from-[#CB9C31] to-[#FFD87E] text-center text-section-heading font-bold">
                {sign}
                <CountUp end={number} enableScrollSpy={true} />
                {size}
              </h3>
              <p className="gradient-text w-full bg-gradient-to-r from-[#CB9C31] to-[#FFD87E] text-center font-bold">
                {text}
              </p>
            </div>
            <SvgAradinaIcon className="w-14 fill-dark-yellow" />
          </article>
        ))}
      </div>
      <img
        className="absolute right-0 z-10 translate-x-2/3 max-xl:bottom-0 max-md:hidden xl:top-0 xl:scale-75"
        src="assets/images/circular-text/circular-gateway-opportunity-green.png"
        alt=""
      />
    </section>
  );
};
export default Statistics;
