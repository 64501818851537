import { useState } from "react";
import DetailsTab from "./tabs/DetailsTab";
import DocumentsTab from "./tabs/DocumentsTab";
import VideosTab from "./tabs/VideosTab";
import UpdatesTab from "./tabs/UpdatesTab";
import QuestionAndAnswers from "./tabs/QuestionAndAnswers/QuestionAndAnswers";
import ContactUsForm from "../../../../../components/section/contact/ContactUsForm";

const data = [
  { tab: "offer_details", title: "Offer Details" },
  { tab: "documents", title: "Documents" },
  { tab: "video", title: "Video" },
  { tab: "updates", title: "Updates" },
  { tab: "q_a", title: "Q & A" },
  { tab: "contact_us", title: "Contact us" },
];

export const Tabs = () => {
  const [selectedTab, setSelectedTab] =
    useState<(typeof data)[number]["tab"]>("offer_details");
  return (
    <nav className="grid section-margin after:h-px after:w-full after:bg-primary-gray/25">
      <ul className="container flex flex-wrap gap-x-16 gap-y-2">
        {data.map(({ tab, title }) => (
          <li
            key={tab}
            className={`grid after:bottom-0 after:-mb-0.5 after:h-1 after:rounded-full after:bg-primary-green after:duration-200 ${selectedTab === tab ? "mt-0.5 font-extrabold after:w-full" : "after:w-0"}`}
          >
            <button
              onClick={() => setSelectedTab(tab)}
              title={title}
              className="mb-2 inline-block text-xl uppercase text-primary-green"
            >
              {title}
            </button>
          </li>
        ))}
      </ul>
      <Tab selected={selectedTab} />
    </nav>
  );
};
export default Tabs;

const Tab = ({ selected }: { selected: (typeof data)[number]["tab"] }) => {
  switch (selected) {
    case "documents":
      return <DocumentsTab />;
    case "video":
      return <VideosTab />;
    case "updates":
      return <UpdatesTab />;
    case "q_a":
      return <QuestionAndAnswers />;
    case "contact_us":
      return (
        <section id="offering-tab-contact" className="container section-margin">
          <ContactUsForm />
        </section>
      );
    default:
      return <DetailsTab />;
  }
};
