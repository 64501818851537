import { TSvgComponent } from "../../../types/utils/components";

const SvgPlus: TSvgComponent = ({ ...props }) => {
  return (
    <svg
      viewBox="0 0 14 15"
      fill="#547C2C"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M0 5.83101V9.55763H4.93525V14.5936H8.9976V9.55763H14V5.83101H8.9976V0.828613H4.93525V5.83101H0Z" />
    </svg>
  );
};

export default SvgPlus;
