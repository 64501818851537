import CustomDropdown from "../../../../components/common/inputs/Dropdown";
import SliderComponent from "../../../../components/common/InputFields/SliderComponent";
import CustomSearch from "../../../../components/common/inputs/Search";
import { useCareersFilters } from "../hooks";
const CareersFilters = () => {
  const { allCareersFilters, isLoading, careersFilters, setCareersFilters } =
    useCareersFilters();

  return (
    <div className="section-padding bg-primary-green bg-opacity-20">
      <div className="container grid items-center gap-6 text-primary-green md:grid-cols-[2fr_3fr_3fr]">
        <CustomDropdown
          options={
            !isLoading && allCareersFilters
              ? [...allCareersFilters.contract_types.map((item) => item.label)]
              : ["Loading..."]
          }
          value={careersFilters.contract_type}
          onChange={(_, item) => {
            setCareersFilters(
              (prev) =>
                (prev = { ...prev, contract_type: (item as string) ?? "" }),
            );
          }}
          name="type"
          label="Type of Contract"
        />
        <div>
          <p>Years of Experience</p>
          {isLoading ? (
            <p>Loading number of years requested</p>
          ) : (
            <SliderComponent
              changeInstantly={(number) => {
                setCareersFilters(
                  (prev) =>
                    (prev = {
                      ...prev,
                      minimum_years: (number as number[])[0],
                      maximum_years: (number as number[])[1],
                    }),
                );
              }}
              onChangeMouseUp={(number) => {
                setCareersFilters(
                  (prev) =>
                    (prev = {
                      ...prev,
                      minimum_years: (number as number[])[0],
                      maximum_years: (number as number[])[1],
                    }),
                );
              }}
              maxNumber={
                allCareersFilters ? allCareersFilters.max_year_experience : 0
              }
              value={[
                careersFilters.minimum_years,
                careersFilters.maximum_years,
              ]}
            />
          )}
        </div>
        <CustomDropdown
          options={
            allCareersFilters
              ? allCareersFilters.location.map((item) => item.label)
              : ["Loading"]
          }
          value={careersFilters.location}
          onChange={(_, item) => {
            setCareersFilters(
              (prev) => (prev = { ...prev, location: item as string }),
            );
          }}
          name="location"
          label="Location"
          className="max-md:row-start-1"
        />
        <div className="md:col-span-3">
          <CustomSearch
            label="Search"
            name="search"
            value={careersFilters.search}
            onChange={(e) => {
              setCareersFilters(
                (prev) => (prev = { ...prev, search: e.target.value }),
              );
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default CareersFilters;
