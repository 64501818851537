import { TSvgComponent } from "../../../types/utils/components";

const SvgTechnologies: TSvgComponent = (props) => {
  return (
    <svg
      viewBox="0 0 61 61"
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="33.2129"
        y="16.936"
        width="3.76353"
        height="18.8177"
        fill="url(#paint0_linear_5538_2408)"
      />
      <rect
        x="11.29"
        y="30.1084"
        width="3.76353"
        height="18.8177"
        fill="url(#paint1_linear_5538_2408)"
      />
      <rect
        x="43.2803"
        y="16.936"
        width="3.76353"
        height="18.8177"
        fill="url(#paint2_linear_5538_2408)"
      />
      <path d="M12.5456 20.0718C8.38065 20.0718 5.01855 23.4339 5.01855 27.5988V47.671C5.01855 51.836 8.38065 55.1981 12.5456 55.1981H16.3092C19.0691 55.1981 21.3272 52.94 21.3272 50.1801V25.0898C21.3272 22.3299 19.0691 20.0718 16.3092 20.0718H12.5456ZM15.0546 42.653C15.0546 43.6817 14.2016 44.5348 13.1729 44.5348C12.1442 44.5348 11.2911 43.6817 11.2911 42.653V32.6169C11.2911 31.5882 12.1442 30.7351 13.1729 30.7351C14.2016 30.7351 15.0546 31.5882 15.0546 32.6169V42.653Z" />
      <path d="M47.6711 5.01758H32.6169C28.4519 5.01758 25.0898 8.37967 25.0898 12.5446V47.671C25.0898 51.836 28.4519 55.198 32.6169 55.198H33.8714C34.574 55.198 35.1259 54.6461 35.1259 53.9435V45.1619C35.1259 43.782 36.255 42.6529 37.635 42.6529H42.653C44.033 42.6529 45.162 43.782 45.162 45.1619V53.9435C45.162 54.6461 45.714 55.198 46.4165 55.198H47.6711C51.836 55.198 55.1981 51.836 55.1981 47.671V12.5446C55.1981 8.37967 51.836 5.01758 47.6711 5.01758ZM37.0077 32.6168C37.0077 33.6455 36.1546 34.4986 35.1259 34.4986C34.0972 34.4986 33.2442 33.6455 33.2442 32.6168V20.0717C33.2442 19.043 34.0972 18.1899 35.1259 18.1899C36.1546 18.1899 37.0077 19.043 37.0077 20.0717V32.6168ZM47.0438 32.6168C47.0438 33.6455 46.1907 34.4986 45.162 34.4986C44.1333 34.4986 43.2803 33.6455 43.2803 32.6168V20.0717C43.2803 19.043 44.1333 18.1899 45.162 18.1899C46.1907 18.1899 47.0438 19.043 47.0438 20.0717V32.6168Z" />
      <defs>
        <linearGradient
          id="paint0_linear_5538_2408"
          x1="33.2129"
          y1="35.7537"
          x2="36.9764"
          y2="35.7537"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#40730E" />
          <stop offset="1" stopColor="#002B14" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_5538_2408"
          x1="11.29"
          y1="48.9261"
          x2="15.0536"
          y2="48.9261"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#40730E" />
          <stop offset="1" stopColor="#002B14" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_5538_2408"
          x1="43.2803"
          y1="35.7537"
          x2="47.0438"
          y2="35.7537"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#40730E" />
          <stop offset="1" stopColor="#002B14" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default SvgTechnologies;
