import { atom } from "jotai";

export const offeringBelongingsFiltersAtom = atom<{
  page: number;
  minPrice: number;
  maxPrice: number;
  filter: {
    type: "date" | "price";
    label: string;
    value: "lowest" | "highest" | "oldest" | "newest";
  } | null;
}>({
  page: 1,
  minPrice: 0,
  maxPrice: 0,
  filter: null,
});

export const offeringBelongingsMaxPriceAtom = atom(0);
