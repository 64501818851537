import ButtonPrimary from "../../../../../components/common/buttons/Button";
import CustomDropdown from "../../../../../components/common/inputs/Dropdown";
import CustomFile from "../../../../../components/common/inputs/File";
import { useIdentityVerification } from "../_hooks";

const IdentityVerification = () => {
  const { handleSubmit, countries, user, identifications } =
    useIdentityVerification();
  if (!countries) return null;
  const choices = countries.map(({ name, iso2 }) => ({
    value: iso2,
    label: name,
  }));
  return (
    <section
      id="account-identity-verification"
      className="container section-margin"
    >
      <h2 className="mb-4 text-3xl font-bold uppercase text-dark-green">
        Identity Verification
      </h2>
      <form
        onSubmit={handleSubmit}
        className="mb-4 grid items-center gap-x-6 gap-y-3 md:grid-cols-3"
      >
        <CustomDropdown
          options={choices}
          label
          title="Nationality"
          name="nationality"
          autoComplete={false}
          defaultValue={choices.find(
            (choice) => choice.label === user?.user.nationality,
          )}
        />
        <CustomDropdown
          options={choices}
          label
          title="Country of Residence"
          name="country_of_residence"
          defaultValue={choices.find(
            (choice) => choice.label === user?.user.country_of_residence,
          )}
        />
        <CustomDropdown
          options={identifications}
          label
          title="Identification Type"
          name="identification_type"
          defaultValue={identifications.find(
            (choice) => choice.label === user?.user.identification_type,
          )}
        />
        <CustomFile
          label
          name="identification_file"
          labelClass="md:col-span-3 my-4"
          defaultFile={user?.user.identification_file ?? ""}
        >
          <p className="mb-0.5 text-primary-gray">Upload Identification</p>
          <p className="text-lg">Click to add file</p>
        </CustomFile>
        <ButtonPrimary
          type="submit"
          title="Save"
          className="h-max w-max justify-self-end px-12 md:col-start-3"
        >
          Save
        </ButtonPrimary>
      </form>
    </section>
  );
};

export default IdentityVerification;
