import Main from "../../../../components/layout/Main";
import AccountBanner from "../../../../components/section/AccountBanner";
import Grid from "./Grid";

const PageUserOfferingsMyBelongings = () => {
  return (
    <Main id="my-belongings">
      <AccountBanner className="section-margin" id="my-belongings" listings />
      <Grid />
    </Main>
  );
};

export default PageUserOfferingsMyBelongings;
