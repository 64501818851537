const CareerThatFits = () => {
  return (
    <section
      id="careers-fits-your-calling"
      className="overflow-x-clip bg-primary-green bg-opacity-50"
    >
      <div className="bg-primary-yellow py-6">
        <h2 className="container text-center text-3xl italic text-white">
          A Career that fits your calling
        </h2>
      </div>
      <div className="lg:single-grid relative">
        <img
          className="absolute right-0 top-0 w-48 -translate-y-2/3 translate-x-1/3 max-md:hidden"
          src="/assets/images/pages/about/careers/careers-that-fit-deco.png"
          alt="Logo"
        />
        <div className="single-grid-item grid lg:grid-cols-2">
          <img
            className="h-full w-full rounded-r-2xl object-cover"
            src="/assets/images/pages/about/careers/career-that-fits.jpg"
            alt=""
          />
        </div>
        <div className="single-grid-item container grid py-10 lg:grid-cols-2 lg:gap-10 lg:py-20">
          <div className="col-start-2 grid items-center bg-[url('/public/assets/images/backgrounds/background-giant-blob.png')] bg-[length:100%_200%] bg-bottom">
            <h3 className="text-2xl text-white lg:text-5xl">
              Whether you're an <b>experienced professional</b> or a{" "}
              <b>recent graduate</b>, working with Aradina could be a
              challenging and rewarding next step in your career.
            </h3>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CareerThatFits;
