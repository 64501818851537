import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { TLaravelSuccess } from "../../../types/utils/laravel";

type TInvestmentFlow = {
  id: number;
  step_number: number;
  title: string;
  content: {
    sub_title?: string;
    description: string;
  }[];
};

export const useInvestmentFlow = () => {
  const { data, isLoading } = useQuery({
    queryKey: ["investment-flow"],
    queryFn: async () => {
      const response = await axios.get<TLaravelSuccess<TInvestmentFlow[]>>(
        "investment-workflows",
      );
      return response.data.data;
    },
  });
  return { data, isLoading };
};

export default useInvestmentFlow;
