import {
  DetailedHTMLProps,
  ForwardedRef,
  LabelHTMLAttributes,
  TextareaHTMLAttributes,
  forwardRef,
} from "react";
import { twMerge } from "tailwind-merge";

type TProps = DetailedHTMLProps<
  TextareaHTMLAttributes<HTMLTextAreaElement>,
  HTMLTextAreaElement
> & {
  label?: DetailedHTMLProps<
    LabelHTMLAttributes<HTMLLabelElement>,
    HTMLLabelElement
  >["children"];
  labelClass?: DetailedHTMLProps<
    LabelHTMLAttributes<HTMLLabelElement>,
    HTMLLabelElement
  >["className"];
  containerClass?: DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  >["className"];
  error?: string;
} & { ref?: ForwardedRef<HTMLTextAreaElement> };

const CustomTextArea = forwardRef<HTMLTextAreaElement, TProps>(
  ({ label, labelClass, containerClass, error, ...props }, ref) => {
    return (
      <div className={containerClass}>
        <label
          hidden={!label}
          htmlFor={
            props.id ? props.id : props.name ? `${props.name}-input` : undefined
          }
          className={twMerge(
            "block text-xl font-bold text-primary-green",
            labelClass,
          )}
        >
          {label === true ? props.title : label}
          {props.required && label && (
            <span className="text-red-600">&nbsp;*</span>
          )}
        </label>
        <textarea
          id={props.name ? `${props.name}-input` : undefined}
          {...props}
          ref={ref}
          className={twMerge(
            "w-full rounded-xl border border-primary-green px-4 py-2.5 text-xl outline-none",
            props.className,
          )}
        />
        <div
          className={`grid duration-300 ${error ? "grid-rows-[1fr]" : "grid-rows-[0fr]"}`}
        >
          <p className="overflow-hidden text-red-500">{error}</p>
        </div>
      </div>
    );
  },
);

export default CustomTextArea;
