import { Slider, styled } from "@mui/material";
import { SliderStyle } from "./SliderStyles";
import { SliderType } from "./types";
const CustomSlider = styled(Slider)({
  ...SliderStyle,
});
export const SliderComponent = (Props: SliderType) => {
  const { onChangeMouseUp, changeInstantly, maxNumber, value } = Props;
  const handleChange = (event: Event, newValue: number | number[]) => {
    changeInstantly(newValue);
  };
  const handleChangeCommitted = (event: any, newValue: number | number[]) => {
    onChangeMouseUp(newValue);
  };
  function valuetext(value: number) {
    return value.toString();
  }
  return (
    <CustomSlider
      min={0}
      max={maxNumber}
      getAriaLabel={() => "Price range"}
      value={value}
      onChange={(event, newValue: number | number[]) =>
        handleChange(event, newValue)
      }
      onChangeCommitted={handleChangeCommitted}
      valueLabelDisplay="auto"
      getAriaValueText={valuetext}
      valueLabelFormat={valuetext}
    />
  );
};
export default SliderComponent;
