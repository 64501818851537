import Main from "../../../components/layout/Main";

const PageUsers = () => {
  return (
    <Main id="users">
      <section id="users-description" className="container section-margin">
        <p>
          Users
        </p>
      </section>
    </Main>
  );
};

export default PageUsers;
