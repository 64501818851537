import CardOffering from "../../common/cards/Offering";
import ButtonPrimary from "../../common/buttons/Button";
import ThreeNeedlesHeading from "../../common/ThreeNeedlesHeading";
import SvgArrowCurved from "../../svgs/arrows/ArrowCurved";
import { useOurOfferings } from "./hooks";
import LoadingImage from "../../common/LoadingImage";
import { Link } from "react-router-dom";

export const OurOfferings = ({
  id,
  other = false,
  decoration = false,
}: {
  id: string;
  other?: boolean;
  decoration?: boolean;
}) => {
  const { data, isLoading } = useOurOfferings();
  return (
    <section
      id={`${id}-our-offerings`}
      className={`${
        other ? "bg-white" : "bg-[#F5F7F2]"
      } relative text-primary-green`}
    >
      <div className="container section-padding">
        <div className="mb-8 grid 2xl:grid-cols-3">
          <div className="flex flex-wrap gap-2 text-section-heading">
            <h2 className="py-1.5">{other ? "Other" : "Our"}</h2>
            <ThreeNeedlesHeading
              backgroundColor="primary-green"
              needlesColor="black"
              className="text-white"
            >
              Offerings
            </ThreeNeedlesHeading>
            <SvgArrowCurved className="z-10 -ml-4 mt-4 h-max w-24 max-md:hidden" />
          </div>
          <p className="text-primary-green 2xl:col-span-2 2xl:mt-20">
            At <b>Aradina</b>, we do things a little differently so that our
            investors can realize as much profit from their farm operation as
            possible. As part owner in the Aradina, you will receive annual
            returns from the crop sales.
          </p>
        </div>
        <div className="mb-8 grid-offerings">
          {!isLoading
            ? data?.map((offering, index) => (
                <CardOffering
                  key={`featured_offerings_${index}`}
                  offering={offering}
                />
              ))
            : [...Array(3)].map((_, index: number) => (
                <LoadingImage key={`loading_featured_offerings_${index}`} />
              ))}
        </div>
        <div className="flex justify-center lg:justify-end">
          <Link to={"/investors"}>
            <ButtonPrimary title="View more offerings">
              Start Exploring Opportunities
            </ButtonPrimary>
          </Link>
        </div>
      </div>
      {decoration && (
        <img
          className="absolute bottom-0 left-0 -translate-x-1/2 translate-y-[45%] scale-[0.6] max-xl:hidden"
          src="assets/images/circular-text/circular-gateway-opportunity-gold.png"
          alt=""
        />
      )}
    </section>
  );
};
export default OurOfferings;
