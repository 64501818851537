import React from "react";
import PageBanner from "../../../../components/section/PageBanner";
import BannerGradientHeading from "../../../../components/common/BannerGradientHeading";

const Banner = () => {
  return (
    <PageBanner
      id="partners"
      imageUrl="/assets/images/pages/about/partners/banner-background.jpg"
    >
      <div className="container grid place-items-center overflow-x-clip">
        <BannerGradientHeading
          title="Our Partners"
          text="Collaboration is the essence of life. Check out our lovely partners!"
        />
      </div>
      <img
        className="absolute bottom-0 right-0 origin-right translate-x-10 translate-y-1/3 max-lg:scale-75 max-md:hidden"
        src="/assets/images/pages/about/partners/banner-image.png"
        alt="Banner"
      />
    </PageBanner>
  );
};

export default Banner;
