import { TSvgComponent } from "../../../types/utils/components";

const SvgAdjustment: TSvgComponent = ({ ...props }) => {
  return (
    <svg
      viewBox="0 0 31 32"
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_217_773)">
        <path d="M0.000924628 4.4027C0.269067 3.76933 0.748024 3.55019 1.4267 3.56314C3.08641 3.59273 4.74612 3.56776 6.40582 3.57793C6.67766 3.57978 6.81913 3.52985 6.91992 3.23212C7.52092 1.45129 9.18341 0.264065 11.0373 0.252045C12.9134 0.240025 14.5934 1.40875 15.212 3.19976C15.322 3.51876 15.4681 3.57978 15.7714 3.57886C20.4056 3.56961 25.0389 3.57146 29.6732 3.57516C29.8553 3.57516 30.0458 3.58255 30.2187 3.63248C30.7282 3.78043 31.0499 4.28157 30.9926 4.79937C30.9362 5.31623 30.51 5.73509 29.9866 5.78132C29.8609 5.79242 29.7333 5.78687 29.6066 5.78687C24.9724 5.78687 20.339 5.78964 15.7048 5.77947C15.4246 5.77947 15.3155 5.86269 15.2258 6.12528C14.6063 7.93385 12.9263 9.12015 11.0391 9.10813C9.17138 9.09611 7.5126 7.89872 6.90697 6.09754C6.81913 5.83495 6.69708 5.77947 6.44188 5.78132C4.77016 5.79334 3.09843 5.76653 1.42763 5.79704C0.747099 5.80906 0.270916 5.5853 0 4.95655V4.4027H0.000924628ZM11.0743 6.89272C12.2939 6.8918 13.2897 5.8895 13.2851 4.66807C13.2804 3.46143 12.292 2.473 11.0863 2.46653C9.86393 2.46006 8.86164 3.45311 8.85886 4.67362C8.85609 5.89412 9.85376 6.89457 11.0743 6.89272Z" />
        <path d="M0.00195313 15.4741C0.270095 14.8408 0.749977 14.6318 1.42773 14.6346C6.04994 14.6521 10.6722 14.6411 15.2944 14.6512C15.5745 14.6512 15.6836 14.5689 15.7733 14.3063C16.3845 12.5144 18.0553 11.3253 19.9258 11.3235C21.8001 11.3226 23.4681 12.5024 24.0802 14.2999C24.18 14.593 24.316 14.6512 24.5915 14.6494C26.3548 14.6392 28.119 14.6401 29.8822 14.6466C30.4675 14.6484 30.9317 15.0729 30.9936 15.6286C31.0556 16.1843 30.6811 16.7094 30.1245 16.8306C29.9913 16.8592 29.8499 16.8574 29.7121 16.8574C27.9941 16.8592 26.2771 16.8639 24.5591 16.8528C24.3058 16.8509 24.1819 16.9036 24.0931 17.1671C23.4783 18.9877 21.8139 20.1796 19.9277 20.1786C18.0442 20.1786 16.3725 18.9794 15.7641 17.1644C15.6744 16.8962 15.5459 16.8518 15.2962 16.8528C10.674 16.8602 6.05179 16.8491 1.42958 16.8676C0.749977 16.8703 0.272869 16.6567 0.00195312 16.0271L0.00195313 15.4741ZM22.1422 15.7645C22.1477 14.5412 21.1556 13.5398 19.9342 13.537C18.7266 13.5343 17.7289 14.5153 17.716 15.7182C17.7021 16.9415 18.6887 17.9531 19.9064 17.9632C21.1288 17.9734 22.1366 16.9831 22.1422 15.7645Z" />
        <path d="M0.00195312 26.5447C0.270095 25.9113 0.749977 25.6931 1.42865 25.7051C3.10038 25.7347 4.77211 25.7088 6.44384 25.7209C6.69903 25.7227 6.82016 25.6663 6.908 25.4037C7.51825 23.5896 9.19275 22.3922 11.0771 22.394C12.9514 22.3959 14.6129 23.5794 15.2287 25.3797C15.3193 25.6441 15.4321 25.7227 15.7095 25.7227C20.402 25.7125 25.0954 25.7144 29.7879 25.7162C30.6469 25.7162 31.2035 26.4337 30.9317 27.1864C30.7717 27.6293 30.4416 27.8715 29.9765 27.9242C29.8508 27.9381 29.7232 27.9298 29.5965 27.9298C24.9614 27.9298 20.3262 27.9326 15.691 27.9233C15.4257 27.9233 15.323 28.0047 15.2333 28.2506C14.6388 29.8771 13.4877 30.8627 11.7808 31.19C11.7272 31.2002 11.6782 31.2307 11.6264 31.2511H10.5196C10.4909 31.2335 10.465 31.2048 10.4345 31.2002C8.64075 30.8803 7.45815 29.8438 6.86916 28.1304C6.79242 27.9076 6.65927 27.9298 6.50116 27.9298C4.81002 27.9298 3.11795 27.9104 1.4268 27.94C0.748128 27.952 0.272869 27.7273 0.00195313 27.0995V26.5456L0.00195312 26.5447ZM11.0688 29.0347C12.2893 29.0366 13.2879 28.037 13.2861 26.8165C13.2842 25.6099 12.2986 24.6178 11.0938 24.6085C9.87051 24.5993 8.86544 25.5896 8.85989 26.8091C8.85434 28.0306 9.84924 29.0329 11.0688 29.0347Z" />
      </g>
      <defs>
        <clipPath id="clip0_217_773">
          <rect
            width="31"
            height="30.9982"
            fill="white"
            transform="translate(0 0.251953)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SvgAdjustment;
