import { DetailedHTMLProps, HTMLAttributes } from "react";

type TProps = Omit<
  DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>,
  "id"
> & {
  id: string;
};

const Main = ({ id, ...props }: TProps) => {
  return <main id={`${id}-content`} role="main" {...props} />;
};

export default Main;
