import ButtonPrimary from "../../../../components/common/buttons/Button";
import { TCareer } from "../hooks";

const CareerCard = ({ data }: { data: TCareer }) => {
  const {
    job_title,
    type_of_contract,
    location,
    minimum_years_of_experience,
    maximum_years_of_experience,
    number_of_position,
  } = data;
  return (
    <article className="rounded-2xl bg-white p-10">
      <h4 className="text-2xl font-extrabold text-primary-green">
        {job_title}
      </h4>
      <p className="text-lg">
        {type_of_contract} | {location}
      </p>
      <div className="w-ful my-2 h-px bg-primary-gray"></div>
      <div className="flex flex-wrap justify-between gap-y-4 max-md:flex-col">
        <div className="items-start">
          <p className="text-lg">
            Years of experience: (
            {minimum_years_of_experience + " - " + maximum_years_of_experience})
          </p>
          <p className="text-lg">Number of positions: {number_of_position}</p>
        </div>
        <div className="self-end justify-self-end md:mt-10">
          <ButtonPrimary onClick={() => {}}>Apply now</ButtonPrimary>
        </div>
      </div>
    </article>
  );
};

export default CareerCard;
