import { useQuery } from "@tanstack/react-query";
import { TLaravelSuccess } from "../../../types/utils/laravel";
import axios from "axios";
import { TTeamMember, TTeamQuote } from "./types";

type TResponse = {
  team: TTeamMember[];
  quotes: TTeamQuote[];
};

const useTeam = () => {
  const { data, isLoading } = useQuery({
    queryKey: ["team-members"],
    queryFn: async () => {
      const response =
        await axios.get<TLaravelSuccess<TResponse>>("team_members");
      return response.data;
    },
  });
  return {
    members: data?.data.team,
    quotes: data?.data.quotes,
    isLoading,
  };
};

export default useTeam;
