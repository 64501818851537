import { atomWithStorage } from "jotai/utils";
import { TLaravelObject } from "../../../types/utils/laravel";

export type TUser = {
  token: string;
  user: {
    email: string;
    email_verified_at: string;
    is_admin: 0 | 1;
    country: string;
    otp: number | null;
    gender: string | null;
    username: string;
    date_of_birth: string | null;
    phone_number: string | null;
    profile_picture: string;
    google_id: unknown | null;
    facebook_id: unknown | null;
    apple_id: unknown | null;
    middle_name: string | null;
    title: string | null;
    city: string | null;
    state: string | null;
    zip_code: string | null;
    street_address: string | null;
    second_street_address: string | null;
    nationality: string | null;
    country_of_residence: string | null;
    identification_type: unknown | null;
    identification_file: unknown | null;
    next_of_kin: unknown | null;
    net_worth: unknown | null;
    annual_net_worth: unknown | null;
    highest_education: unknown | null;
    investment_experience: unknown | null;
    risk_tolerance: unknown | null;
    stripe_id: unknown | null;
    pm_type: unknown | null;
    pm_last_four: unknown | null;
    trial_ends_at: unknown | null;
    selected_language: string;
    phone_number_info: unknown | null;
    is_investor: 0 | 1;
    first_name: string | null;
    last_name: string | null;
    device_token: string | null;
    customer_id: string;
    payment_intent_id: string;
    listings_count: number;
    total_sales: number;
    total_profit: number;
  } & TLaravelObject;
  email_verified: boolean;
} & TLaravelObject;

const userAtom = atomWithStorage<TUser | null>("user", null, undefined, {
  getOnInit: true,
});

export default userAtom;
