import { useOfferingQuantity } from "../../hooks";

const Quantity = () => {
  const { offering, handleDecrement, handleIncrement, quantity } =
    useOfferingQuantity();
  return (
    <section id="offering-quantity" className="container section-margin">
      <h2 className="mb-2 section-heading">
        Unit
        {offering.unit && offering.unit !== "unit" && (
          <>
            &nbsp;<span className="text-lg">({offering.unit})</span>
          </>
        )}
      </h2>
      <div className="grid items-center gap-6 lg:grid-cols-2">
        <p>
          Lorem ipsum dolor sit amet consectetur. Cursus lobortis nunc neque
          libero tortor. Ac in a ultrices gravida dis sem a. Tellus donec
        </p>
        <div className="flex flex-wrap items-center justify-center gap-6">
          <div className="grid grid-cols-3 rounded-md bg-secondary-green/25">
            <button
              type="button"
              title="Increment"
              className="px-4 py-2 hover-link"
              onClick={handleDecrement}
            >
              -
            </button>
            <input
              id="quantity-input"
              type="number"
              className="w-12 bg-transparent text-center"
              readOnly
              value={quantity}
              tabIndex={-1}
            />
            <button
              type="button"
              title="Increment"
              className="px-4 py-2 hover-link"
              onClick={handleIncrement}
            >
              +
            </button>
          </div>
          <div className="flex items-center gap-4">
            <p>Total Amount</p>
            <div className="h-10 content-center rounded-lg border border-primary-green px-8">
              <span>${quantity * +offering.unit_price}</span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Quantity;
