import { useAtom, useAtomValue } from "jotai";
import { offeringDetailsAtom, offeringDetailsInsuranceAtom } from "../../atoms";
import { useState } from "react";
import { TOfferingInsurance } from "../../../../types/offering";
import CustomRadio from "../../../../components/common/inputs/Radio";
import SvgArrowFilled from "../../../../components/svgs/arrows/ArrowFilled";
import SvgErrorX from "../../../../components/svgs/icons/ErrorX";

const Insurance = () => {
  const offering = useAtomValue(offeringDetailsAtom);
  const [insurance, setInsurance] = useAtom(offeringDetailsInsuranceAtom);
  return (
    <>
      {offering.insurances && offering.insurances.length > 0 && (
        <section id="offering-insurance" className="container section-margin">
          <h2 className="section-heading">Insurance</h2>
          <p>Select the insurance of choice!</p>
          <ul className="my-4 grid gap-4">
            {offering.insurances.map((insurance) => (
              <li key={`offering_insurance_${insurance.id}`}>
                <InsuranceOption data={insurance} />
              </li>
            ))}
          </ul>
          <div
            className={`grid duration-200 ease-linear ${insurance ? "grid-rows-[1fr]" : "grid-rows-[0fr]"}`}
          >
            <button
              type="button"
              title="Remove Insurance"
              className="group flex w-fit items-center gap-1 overflow-hidden text-red-500 hover-link"
              onClick={() => setInsurance(null)}
            >
              <SvgErrorX className="size-3 duration-300 group-hover:rotate-180" />
              Remove Insurance
            </button>
          </div>
        </section>
      )}
    </>
  );
};
export default Insurance;

type TProps = {
  data: TOfferingInsurance;
};

const InsuranceOption = ({ data }: TProps) => {
  const [insurance, setInsurance] = useAtom(offeringDetailsInsuranceAtom);
  const [open, setOpen] = useState(false);
  const { id, name, description, price } = data;
  return (
    <article
      className={`rounded-lg p-4 transition-colors duration-300 ${insurance === id ? "bg-secondary-green/25" : "bg-secondary-green/10"} `}
    >
      <div className="flex flex-wrap-reverse gap-4">
        <CustomRadio
          checked={insurance === id}
          labelClass="text-lg"
          id={`offering-insurance-${id}`}
          name="insurance"
          title={name}
          label
          value={id}
          onChange={() => setInsurance(id)}
        />
        <div className="ml-auto flex items-center gap-x-10">
          <span className="text-lg font-semibold">${price}</span>
          <button
            className="hover-link"
            type="button"
            title={open ? "Close" : "Open"}
            onClick={() => setOpen((_prev) => (_prev = !_prev))}
          >
            <SvgArrowFilled
              className={`size-5 fill-primary-green duration-300 ${open ? "rotate-0" : "-rotate-90"}`}
            />
          </button>
        </div>
      </div>
      <div
        className={`grid duration-300 ease-linear ${open ? "mt-2 grid-rows-[1fr]" : "grid-rows-[0fr]"}`}
      >
        <p className="overflow-hidden">{description}</p>
      </div>
    </article>
  );
};
