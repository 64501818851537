import PassiveInvestment from "./components/PassiveInvestment";
import DownloadAradinaMobileApp from "../../components/section/DownloadAradinaMobileApp";
import TestimonialsSection from "../../components/section/testimonials/TestimonialsSection";
import OfferingsFilters from "../../components/section/offerings/OfferingsFilters";
import OfferingsGrid from "../../components/section/offerings/OfferingsGrid";
import Main from "../../components/layout/Main";

export const PageInvestors = () => {
  return (
    <Main id="investors">
      <OfferingsFilters />
      <OfferingsGrid />
      <PassiveInvestment />
      <DownloadAradinaMobileApp />
      <TestimonialsSection page="investors" type="investors" />
    </Main>
  );
};
export default PageInvestors;
