import { z } from "zod";
import { TLabelOption, TModal } from "../types/utils/components";

export const scrollToId = (id: string) => {
  const section = document.getElementById(id);
  section && section.scrollIntoView();
};

export const zodRequiredString = z.string().min(1, "This field is required");

export const uselessAutompleteWarning = (option: unknown, value: unknown) =>
  (option as TLabelOption).id === (value as TLabelOption).id;

export const toggleModal = (id: TModal, state?: "open" | "close") => {
  const modal = document.getElementById(`${id}-modal`) as HTMLDialogElement;
  if (!state) {
    if (modal.open) {
      modal.close();
      return false;
    } else {
      modal.showModal();
      return true;
    }
  }
  if (state === "open") modal.showModal();
  else modal.close();
  return modal.open;
};
