import { useMutation } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { useRef, useState } from "react";
import { ZodFormattedError, z } from "zod";
import { TLaravelError, TLaravelSuccess } from "../../../types/utils/laravel";

export const useContactUs = () => {
  type TContactUsRequest = {
    full_name: string;
    email: string;
    subject: string;
    message: string;
  };

  const form = useRef(null);
  const [validationErrors, setValidationErrors] = useState<ZodFormattedError<
    TContactUsRequest,
    string
  > | null>();

  const formSchema = z.object({
    full_name: z.string().min(1, "The full name is required"),
    email: z
      .string()
      .min(1, "The email is required.")
      .email("Please enter a valid email"),
    subject: z.string().min(1, "Please provide a subject"),
    message: z
      .string()
      .min(1, "Message is required")
      .min(10, "Please enter a valid message"),
  });
  type TContactUs = z.infer<typeof formSchema>;

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(form.current ?? undefined);
    const data: TContactUs = Object.fromEntries(formData) as TContactUs;
    const newMessage = formSchema.safeParse(data);
    setValidationErrors((prev) => (prev = null));
    if (newMessage.success) {
      mutate(data);
    } else {
      setValidationErrors((prev) => (prev = newMessage.error.format()));
    }
  };

  const {
    mutate,
    isSuccess: isResponseSuccess,
    data: responseData,
    error: responseError,
    isPending,
  } = useMutation({
    mutationFn: async (data: TContactUsRequest) => {
      const response = await axios.post<
        TLaravelSuccess<TContactUs & { id: number }>
      >("contactus", data);
      return response.data.data;
    },
    onSuccess: (_) => {
      (document.getElementById("form-contact-us") as HTMLFormElement)?.reset();
    },
    onError: (_: AxiosError<TLaravelError<TContactUsRequest>>) => {},
    gcTime: 0,
  });
  return {
    form,
    handleSubmit,
    validationErrors,
    responseData,
    responseError,
    isResponseSuccess,
    isPending,
  };
};
